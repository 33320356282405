import RelatedArticles from 'features/RelatedArticles';
import { SliceMap } from 'types';

export enum TARGETS_SLICES {
  RelatedArticles = 'related_articles',
}

export const targetsSliceMap: SliceMap<TARGETS_SLICES> = {
  [TARGETS_SLICES.RelatedArticles]: RelatedArticles,
};
