import IpHeroBlock from 'features/IpHeroBlock';
import { SliceMap } from 'types';

export enum IP_CHECKER_SLICES {
  IpHeroBlock = 'hero_block',
}

export const IpCheckerSliceMap: SliceMap<IP_CHECKER_SLICES> = {
  [IP_CHECKER_SLICES.IpHeroBlock]: IpHeroBlock,
};
