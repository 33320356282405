import GatsbyImg from 'components/GatsbyImg';
import { Box } from 'components/v2/Box';
import { LinkButton } from 'components/v2/LinkButton';
import { Slice, SliceHeader } from 'components/v2/Slice';
import { ToggleButton } from 'components/v2/Toggle';
import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { Card } from './Card';
import { SimpleCardsSliceProps } from './types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const SimpleCardsSlice: React.FC<SimpleCardsSliceProps> = ({
  primary: {
    title,
    description,
    background_color: backgroundColor,
    is_slice_dark: isDark,
    cta_link: ctaLink,
    cta_label: ctaLabel,
    cta_position: ctaPosition = 'top',
    content_alignment: align = 'center',
    toggle_one_label: toggleOneTitle,
    toggle_two_label: toggleTwoTitle,
    cta_icon: ctaIcon,
    cta_icon_position: ctaIconPosition = 'left',
  },
  items = [],
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const [isSecondTabSelected, setIsSecondTabSelected] = React.useState(false);
  const hasToggle = toggleOneTitle && toggleTwoTitle;
  const hasCta = !!ctaLink?.url && !!ctaLabel?.text;

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.SIMPLE_CARDS_SLICE,
    prefix: type,
  });

  const Cta = hasCta && (
    <LinkButton
      to={ctaLink?.url}
      fullWidthOnMobile
      minWidth="200px"
      variant="outlined"
      color="blue"
      leftIcon={ctaIconPosition === 'left' && <GatsbyImg image={ctaIcon!} />}
      rightIcon={ctaIconPosition === 'right' && <GatsbyImg image={ctaIcon!} />}
      data-tracker={trackingSelector({ context: 'cta-' + ctaLabel?.text })}
      onClick={() => {
        logPostHogEvent(trackingSelector({ context: 'cta-' + ctaLabel?.text }));
        if (sliceId) {
          logGAElementClickEvent({
            elementId: ctaLabel.text,
            sectionId: sliceId,
          });
        }
      }}
    >
      {ctaLabel?.text}
    </LinkButton>
  );

  const HeaderActionItems = (
    <>
      {hasToggle && (
        <ToggleButton
          isSecondTabSelected={isSecondTabSelected}
          firstTabText={toggleOneTitle}
          secondTabText={toggleTwoTitle}
          setIsSecondTabSelected={setIsSecondTabSelected}
        />
      )}
      {ctaPosition === 'top' && hasCta && (
        <Box sx={{ width: { base: '100%', md: 'fit-content' } }}>{Cta}</Box>
      )}
    </>
  );

  const filteredCards = items.filter(({ assign_to_toggle }) => {
    if (!hasToggle || !assign_to_toggle) return true;
    return assign_to_toggle === (isSecondTabSelected ? 'two' : 'one');
  });

  return (
    <Slice
      backgroundColor={backgroundColor}
      isDark={isDark}
      id={generateSectionsId(SECTION_NAMES.SIMPLE_CARDS_SLICE, tags, type)}
    >
      <SliceHeader
        title={title}
        description={description}
        align={align}
        bottomItems={(hasToggle || hasCta) && HeaderActionItems}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: {
            base: 'flex-start',
            md: align === 'left' ? 'flex-start' : 'center',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '24px',
            rowGap: { base: '20px', sm: '36px' },
            width: '100%',
            justifyContent: {
              base: 'flex-start',
              md: align === 'left' ? 'flex-start' : 'center',
            },
            flexDirection: {
              sm: 'row',
              base: 'column',
            },
          }}
        >
          {filteredCards.map(
            ({ card_body_text, card_heading, card_icon, card_link }, index) => {
              const trackerId = trackingSelector({
                context: 'card-' + card_heading?.text,
              });
              return (
                <Card
                  key={index}
                  bodyText={card_body_text}
                  heading={card_heading}
                  icon={card_icon}
                  link={card_link}
                  trackerId={trackerId}
                  onClick={() => {
                    if (!card_link?.url) return;
                    logPostHogEvent(trackerId);
                    if (sliceId) {
                      logGAElementClickEvent({
                        elementId: card_heading?.text ?? `${index}`,
                        sectionId: sliceId,
                      });
                    }
                  }}
                />
              );
            }
          )}
        </Box>
        {ctaPosition === 'bottom' && (
          <Box
            sx={{
              marginTop: { base: '32px', md: '36px' },
              width: { base: '100%', md: 'fit-content' },
            }}
          >
            {Cta}
          </Box>
        )}
      </Box>
    </Slice>
  );
};

export default SimpleCardsSlice;
