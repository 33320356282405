import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { appendPlanIdToURL, ctaLinkHandler } from 'features/utils';
import React from 'react';
import { COLOR_SHADE } from 'theme';
import {
  CardContainer,
  CardDescription,
  CardFeature,
  CardFeaturesContainer,
  CardFeaturesPrice,
  CardInfoContainer,
  CardPrice,
  CardTitle,
  DiscountLabelComponent,
  HideableCardFeatures,
  HideableCrossedOutPrice,
  HideableFlag,
  InfoText,
  MostPopularFlagBuleImage,
  MostPopularFlagGreenImage,
  NewBadge,
  ResidentialCardAmount,
  StyledCta,
  TimePeriod,
} from '../PricingCards/styledComponents';
import { IPricingCardResidential } from '../PricingCards/types';
import { getPlanId } from 'utils/plans';
import { RichText } from 'components/v2/RichText';

const PricingCard: React.FC<IPricingCardResidential> = ({
  theme,
  pricing_plan: {
    document: {
      data: {
        show_discount_label: showDiscountLabel,
        discount,
        crossed_out_price: crossedPrice,
        cta_link: buttonLink,
        cta_text: buttonText,
        gb_value: gbValue,
        price_per_gb: gbPrice,
        subtitle,
        title,
        username,
        whitelisted,
        is_most_popular: isMostPopular,
        info_text: infoText,
        price_per_gb_text: pricePerGbText,
        price_per_gb_crossed_out: pricePerGbCrossedOut,
        username_text: usernameText,
        whitelisted_text: whitelistedText,
        price_per_month: pricePerMont,
        vat_price_and_text: vatInfo,
        is_new: isNew,
        billing_name: billingName,
      },
    },
  },
  selector,
  product = 'residential',
}) => {
  const isAdditionalTheme = theme.toLowerCase() === BUTTON_TYPES.ADDITIONAL;
  const planId = getPlanId(title.text, product);
  const ctaUrl = !planId
    ? buttonLink.url
    : appendPlanIdToURL(buttonLink.url, planId);
  const themeName = isAdditionalTheme
    ? BUTTON_THEMES.IMPORTANT
    : BUTTON_THEMES.FOLLOWING;

  const amountColorShade = isAdditionalTheme
    ? COLOR_SHADE.PRIMARY
    : COLOR_SHADE.SECONDARY;

  return (
    <CardContainer>
      {showDiscountLabel && (
        <DiscountLabelComponent>
          <RichText field={discount} />
        </DiscountLabelComponent>
      )}
      <CardInfoContainer>
        <HideableFlag isVisible={isNew}>
          <NewBadge />
        </HideableFlag>
        <HideableFlag isVisible={isMostPopular}>
          {isAdditionalTheme ? (
            <MostPopularFlagBuleImage />
          ) : (
            <MostPopularFlagGreenImage />
          )}
        </HideableFlag>
        <CardTitle>{title.text}</CardTitle>
        {subtitle.text && <CardDescription>{subtitle.text}</CardDescription>}
        <ResidentialCardAmount colorShade={amountColorShade}>
          {gbValue}
        </ResidentialCardAmount>
        <CardPrice>
          <HideableCrossedOutPrice isVisible={!!crossedPrice}>
            {crossedPrice}
          </HideableCrossedOutPrice>
          {pricePerMont}
          <TimePeriod>/{billingName}</TimePeriod>
        </CardPrice>
        <InfoText>{vatInfo}</InfoText>
        <CardFeaturesContainer>
          <CardFeaturesPrice isBolded={!!pricePerGbCrossedOut}>
            <CardFeature>{pricePerGbText}</CardFeature>
            <div>
              <span>{pricePerGbCrossedOut}</span>
              <CardFeature>{gbPrice}</CardFeature>
            </div>
          </CardFeaturesPrice>
          <HideableCardFeatures isVisible={!!usernameText}>
            <CardFeature>{usernameText}</CardFeature>
            <CardFeature>{username}</CardFeature>
          </HideableCardFeatures>
          <HideableCardFeatures isVisible={!!whitelistedText}>
            <CardFeature>{whitelistedText}</CardFeature>
            <CardFeature>{whitelisted}</CardFeature>
          </HideableCardFeatures>
        </CardFeaturesContainer>
        {infoText && <InfoText>{infoText}</InfoText>}
      </CardInfoContainer>
      <StyledCta
        linkId={selector}
        linkClassName={selector}
        dataTracker={selector}
        isVisible={buttonLink.url && buttonText.text?.length}
        themeName={themeName}
        ctaText={buttonText.text}
        ctaLink={ctaLinkHandler(ctaUrl)}
        fontSize={TSHIRT_SIZE.M}
        themeType={BUTTON_TYPES.PRIMARY}
        gutterTop={TSHIRT_SIZE.S}
        gutterBot={TSHIRT_SIZE.XXS}
        sendTrackingEvent={() => track(selector)}
      />
    </CardContainer>
  );
};

export default PricingCard;
