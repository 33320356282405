import { PrismicTextField } from 'types';

export interface ISimulatorNoCode {
  primary: {
    title?: PrismicTextField;
    description?: PrismicTextField;
    background?: string;
  };
}

export const headerText = {
  Keyword: 'Adidas',
  Location: 'United States',
};

export enum TITLES {
  CSV = 'CSV Format',
  JSON = 'JSON Format',
}

export const CSVTableTitles = ['Position', 'Title', 'Description'];

export const CSVResults = {
  'adidas.com - adidas® Basketball - Shop Official Site Today': `Don't Miss Out On Your Favorites.Order From The Official adidas® Store.`,
  'Adidas releases an adorable Hello Kitty collection of sneakers...': `1 day ago — Sanrio's beloved feline has teamed up with Adidas for a new line of Adidas Originals footwear and children's accessories, which are now ...
    `,
  'adidas Reviews - Glassdoor': `4264 adidas reviews. A free inside look at company reviews and salaries posted anonymously by employees.`,
  'Adidas | Company Overview & News - Forbes': `adidas AG engages in design, distribution, and marketing of athletic and sporting lifestyle products. It operates through the following segments: Europe,`,
  'Buy adidas Originals Shoes & Clothes Online Australia': `884 items — Shop the latest range of adidas Originals shoes, clothing and sportswear at THE ICONIC today. Enjoy the option of free and fast delivery throughout ...`,
  'adidas - Statistics & Facts | Statista': `Adidas AG is the largest sportswear manufacturer in Europe and is second only to Nike worldwide. Adidas is a multinational corporation, founded and ...`,
  'Kanye West Says He Will Split From Adidas & Gap in ...': `2 days ago — In a Bloomberg interview, Kanye West said he wanted to cut ties with Adidas and Gap, the corporate entities linked to his Yeezy brand.`,
  'Limited-edition NYCFC x adidas COPA shoe drops': `2 days ago — Sneakerheads, set your alarms. A limited-edition adidas Ultraboost x COPA shoe with New York City FC colors and details will be released ...`,
  'adidas – History + Official Release Dates 2022 - Sneaker News': `adidas is a German sportswear outfitter founded in 1949 Adolf "Adi" Dassler. Click here to learn more about The Three Stripes Brand and its sneakers.`,
};
