import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import hideable from 'utils/hideable';
import { FlagWrapper } from './styledComponents';
import { IFlagsItem } from './types';
import { RichText } from 'components/v2/RichText';

const FlagContent: React.FC<IFlagsItem> = ({ icon, text }) => (
  <>
    <FlagWrapper>
      <GatsbyImg image={icon} />
    </FlagWrapper>
    <RichText field={text} />
  </>
);

export default hideable(FlagContent);
