import GatsbyImg from 'components/GatsbyImg';
import { usePageContext } from 'contexts/PageContext';
import { IconArrowLeft, IconArrowRight } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import { buildTrackingSelector } from 'utils/page';
import {
  Description,
  ImageContainer,
  LeftArrow,
  Name,
  RightArrow,
  Role,
  TestimonialWrapper,
} from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';
import { track } from '@smartproxy/sp-tracker-js';

const Testimonial: React.FC<IItem> = ({
  name,
  isTextLight = false,
  description,
  role,
  image,
  onRightArrowClick,
  onLeftArrowClick,
}) => {
  const { tags, type } = usePageContext();
  const leftArrowSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'testimonials',
    context: 'arrow-left',
  });
  const rightArrowSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'testimonials',
    context: 'arrow-right',
  });

  const handleLeftArrowClick = () => {
    onLeftArrowClick();
    track(leftArrowSelector);
  };
  const handleRightArrowClick = () => {
    onRightArrowClick();
    track(rightArrowSelector);
  };
  return (
    <TestimonialWrapper>
      <div>
        <Description isTextLight={isTextLight}>
          <RichText field={description} />
        </Description>
        <Name>
          <RichText field={name} />
        </Name>
        <Role>
          <RichText field={role} />
        </Role>
      </div>
      <ImageContainer>
        <GatsbyImg image={image} />
      </ImageContainer>
      <LeftArrow
        onClick={handleLeftArrowClick}
        data-tracker={leftArrowSelector}
      >
        <IconArrowLeft />
      </LeftArrow>
      <RightArrow
        onClick={handleRightArrowClick}
        data-tracker={rightArrowSelector}
      >
        <IconArrowRight />
      </RightArrow>
    </TestimonialWrapper>
  );
};

export default hideable(Testimonial);
