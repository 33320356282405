import { usePageContext } from 'contexts/PageContext';
import { IconPlayOutlinedv2, IconPlayv2 } from 'images';
import { useState } from 'react';
import { TGatsbyImage } from 'types';
import { initBuildTrackingSelector } from 'utils/page';
import { convertEmbedDimensionsToFill } from './helpers';
import {
  StyledHeroImageWrapper,
  StyledHeroLightboxTrigger,
} from './styledComponents';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';
import { GatsbyImg, Lightbox } from '@smartproxy-web/ui';

interface HeroImageProps {
  image: TGatsbyImage;
  embedCode?: string;
}

export const HeroImage = ({ image, embedCode }: HeroImageProps) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();

  const [isLightboxVisible, setIsLightboxVisible] = useState(false);

  const toggleLightbox = (isOpen?: boolean) => {
    setIsLightboxVisible((prevState) => isOpen ?? !prevState);
  };

  const buildEmbedTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero-embed',
  });

  const handleOpenLightbox = () => {
    toggleLightbox(true);
    logPostHogEvent(
      buildEmbedTrackingSelector({
        context: 'open',
        type: 'button',
      })
    );
    if (sliceId) {
      logGAElementClickEvent({
        elementId: 'open-lightbox-button',
        sectionId: sliceId,
      });
    }
  };

  const handleCloseLightbox = () => {
    toggleLightbox(false);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: 'close-lightbox-button',
        sectionId: sliceId,
      });
    }
  };

  return (
    <StyledHeroImageWrapper>
      <GatsbyImg image={image} loading="eager" fetchPriority="high" />
      {embedCode && (
        <StyledHeroLightboxTrigger
          onClick={handleOpenLightbox}
          color="gray"
          variant="filled"
          size="lg"
          data-tracker={buildEmbedTrackingSelector({
            context: 'open',
            type: 'button',
          })}
        >
          <IconPlayv2 data-layer-top />
          <IconPlayOutlinedv2 />
        </StyledHeroLightboxTrigger>
      )}
      {embedCode && (
        <Lightbox
          closeBtnTrackingId={buildEmbedTrackingSelector({
            context: 'close',
            type: 'button',
          })}
          isOpen={isLightboxVisible}
          handleClose={handleCloseLightbox}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: convertEmbedDimensionsToFill(embedCode),
            }}
          />
        </Lightbox>
      )}
    </StyledHeroImageWrapper>
  );
};
