import { PrismicProvider } from '@prismicio/react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { useEffect } from 'react';
import Head from './src/components/Head';
import GlobalStyleWrapper from './src/components/Layout/GlobalStylesWrapper';
import PageWrapper from './src/components/Layout/PageWrapper';
import PageContextProvider from './src/contexts/PageContext';
import SplitTestingProvider from './src/contexts/SplitTesting';
import { linkResolver } from './src/prismicConfig';
import { repositoryConfigs } from './src/prismicPreviews';
import { buildExpVarId } from './src/utils/page';
import {
  GA4_MEASUREMENT_ID,
  GA_MEASUREMENT_ID,
  PH_PROJECT_NAME,
} from './src/constants/common';
import { BuildContextProvider } from '@smartproxy-web/features';
import { getCurrentSiteDomain, siteLang } from 'constants/build';
import { Scripts } from 'components/Scripts';
import { SpTrackerContextProvider } from 'contexts/SpTrackerContext';
import WrapWithProvider from 'components/GatsbyLink/wrapWithProvider';

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <PrismicProvider linkResolver={linkResolver}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <BuildContextProvider
        value={{ site: getCurrentSiteDomain(), lang: siteLang }}
      >
        <WrapWithProvider>
          <GlobalStyleWrapper>{element}</GlobalStyleWrapper>
        </WrapWithProvider>
      </BuildContextProvider>
    </PrismicPreviewProvider>
  </PrismicProvider>
);

// Wraps every page in a component
/**
 *
 * @param {import('gatsby').WrapPageElementBrowserArgs} args
 * @returns
 */
export const wrapPageElement = ({ element, props }) => {
  const seoData = props.data && props.data[Object.keys(props?.data)[0]]?.data;
  const path = props.location.pathname;
  const enhancePageContext = (oldPageContext) => {
    return {
      ...oldPageContext,
      bodyKey: `body${parseInt(oldPageContext.variant) || ''}`,
    };
  };

  const updatedPageContext = enhancePageContext(props.pageContext);
  const baseGAConfigParams = {
    send_page_view: false,
  };

  useEffect(() => {
    const { experimentId, variant } = updatedPageContext;
    const expVarId = buildExpVarId({ experimentId, variant });
    const pageviewEventParams = {
      page_title: seoData?.meta_title ?? document.title,
      page_path: window.location.pathname,
      ...(!!expVarId && {
        exp: expVarId,
        exp_experiment_id: experimentId,
        exp_variant_id: variant,
      }),
      send_page_view: true,
    };
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', GA4_MEASUREMENT_ID, baseGAConfigParams);
    gtag('config', GA_MEASUREMENT_ID, baseGAConfigParams);
    gtag('event', 'page_view', pageviewEventParams);
  }, [updatedPageContext]);

  return (
    <SplitTestingProvider data={updatedPageContext}>
      <SpTrackerContextProvider projectName={PH_PROJECT_NAME}>
        <Head
          {...seoData}
          path={path}
          experiment={{
            id: updatedPageContext.experimentId,
            variant: updatedPageContext.variant,
          }}
        />
        <PageContextProvider
          pageContext={{
            type: updatedPageContext.type || '',
            tags: updatedPageContext.tags || [],
            isGatedContent: updatedPageContext?.isGatedContent || false,
          }}
        >
          <PageWrapper {...props}>{element}</PageWrapper>
        </PageContextProvider>

        <Scripts />
      </SpTrackerContextProvider>
    </SplitTestingProvider>
  );
};
