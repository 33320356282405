import RelatedArticles from 'features/RelatedArticles';
import { SliceMap } from 'types';

export enum WHAT_IS_WEB_SRAPING_SLICES {
  RelatedArticles = 'related_articles',
}

export const whatIsWebScrapingSliceMap: SliceMap<WHAT_IS_WEB_SRAPING_SLICES> = {
  [WHAT_IS_WEB_SRAPING_SLICES.RelatedArticles]: RelatedArticles,
};
