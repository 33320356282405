import React from 'react';
import Input, { IInputProps } from './Input';
import { StyledFormGroup } from '../styledComponents';
import { track } from '@smartproxy/sp-tracker-js';

interface IInputFormControlProps extends IInputProps {
  label: React.ReactNode | string;
}

const InputFormControl = ({
  id,
  dataTracker,
  name,
  type,
  label,
  value,
  error,
  onBlur,
  onChange,
}: IInputFormControlProps) => (
  <StyledFormGroup>
    <label htmlFor={id}>
      {typeof label === 'string' ? <p>{label}</p> : label}
    </label>
    <div>
      <Input
        id={id}
        dataTracker={dataTracker}
        type={type}
        name={name}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        onClick={() => track(dataTracker)}
      />
    </div>
  </StyledFormGroup>
);

export default InputFormControl;
