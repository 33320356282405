import { track } from '@smartproxy/sp-tracker-js';
import {
  CONTACT_SALES_URL_HASH,
  PRISMIC_VALUES,
  RESIDENTIAL_PRICING_VALUES,
  SECTION_NAMES,
} from 'constants/common';
import { getSliderTheme } from 'features/PricingSlider/utils';
import { generateSectionsId } from 'features/utils';
import { IconScaleDash } from 'images';
import React, { useEffect, useState } from 'react';
import { isBrowser } from 'utils/build';
import { initBuildTrackingSelector } from 'utils/page';
import { IScreenProps } from 'utils/screenTypeHOC';
import BottomContainerItem from '../PricingSlider/BottomContainer';
import Handle from '../PricingSlider/Handle';
import {
  BlockContainer,
  Container,
  ContentContainer,
  HideableTitleWrapper,
  Range,
  RangeContainer,
  Scale,
  ScaleItem,
  ScaleText,
} from '../PricingSlider/styledComponents';
import { IPricingSliderResidential } from '../PricingSlider/types';
import NeedMoreBanner from './NeedMoreBanner';
import PricingPlan from './PricingPlan';
import {
  monthlyItems,
  ONE_HALF,
  RANGE_STEP,
  scaleLabelsHandler,
  yearlyItems,
} from './utils';
import { Heading } from 'components/v2/Heading';
import { RichText } from 'components/v2/RichText';

const ResidentialPricingSlider: React.FC<
  IPricingSliderResidential & IScreenProps
> = ({
  isTablet,
  items,
  primary: { subtitle, theme, background, is_yearly_visible: isYearlyVisible },
  pageContext: { type, tags },
}) => {
  const [isBillingYearly, setIsBillingYearly] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [contactSalesPath, setContactSalesPath] = useState('');
  const url = isBrowser && window.location.pathname;

  useEffect(() => {
    setContactSalesPath(url + CONTACT_SALES_URL_HASH);
  }, [url]);

  const pricingPlans = isBillingYearly
    ? yearlyItems(items)
    : monthlyItems(items);
  const stepValue = inputValue - 0.04;
  const details = pricingPlans[Math.round(stepValue)]?.pricing_slider_details;
  const isMaxValue = stepValue >= pricingPlans.length - ONE_HALF;
  const rangeHandleTitle = isMaxValue ? '' : details?.document.data.plan_name;
  const scaleLabels = scaleLabelsHandler(pricingPlans);
  const handleSwitchBillingType = () => {
    setIsBillingYearly(!isBillingYearly);
  };
  const ctaText = isMaxValue
    ? PRISMIC_VALUES.CONTACTSALES_BUTTON_TEXT
    : details?.document.data.cta_text;

  const getPlanTitle = () => {
    if (details) {
      return details.document.data.plan_name;
    }
    return 'contact-sales';
  };

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_SLIDER,
    prefix: type,
  });

  const handleOnChange = (value) => {
    track(trackingSelector({ type: 'trigger' }));
    setInputValue(value);
  };

  const marks = pricingPlans.reduce((acc, current, index) => {
    acc[index + 0.5] = current.scale_label;
    return acc;
  }, {});

  const sliderTheme = getSliderTheme(theme);

  return (
    <BlockContainer
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRICING_SLIDER,
        tags,
        type
      )}
    >
      <Heading level={2}>
        {RESIDENTIAL_PRICING_VALUES.PRICING_SLIDER_TITLE}
      </Heading>
      <HideableTitleWrapper isVisible={!!subtitle?.text}>
        <RichText field={subtitle} />
      </HideableTitleWrapper>
      <Container>
        <NeedMoreBanner isVisible={isMaxValue} />
        <PricingPlan
          isVisible={!isMaxValue}
          isTablet={isTablet}
          pricing_slider_details={details}
          sliderTheme={sliderTheme}
          {...details}
        />
        <ContentContainer>
          <RangeContainer>
            <Range
              colorShade={sliderTheme}
              step={RANGE_STEP}
              value={inputValue}
              max={pricingPlans.length}
              onChange={(value) => handleOnChange(value)}
              marks={marks}
              handle={(props) => (
                <Handle
                  isTablet={isTablet}
                  text={rangeHandleTitle}
                  {...props}
                />
              )}
            />
            <Scale>
              {scaleLabels.map((label, key) => (
                <ScaleItem key={key}>
                  <IconScaleDash />
                  <ScaleText>{label}</ScaleText>
                </ScaleItem>
              ))}
            </Scale>
          </RangeContainer>
          <BottomContainerItem
            switchYearlyLabel={`${RESIDENTIAL_PRICING_VALUES.BILLED_YEARLY} (${RESIDENTIAL_PRICING_VALUES.PRICING_DISCOUNT})`}
            switchMonthlyLabel={RESIDENTIAL_PRICING_VALUES.BILLED_MONTHLY}
            isYearlyVisible={isYearlyVisible}
            handleSwitchBillingType={handleSwitchBillingType}
            isBillingYearly={isBillingYearly}
            ctaText={ctaText}
            selector={trackingSelector({
              context: getPlanTitle(),
              type: 'cta',
            })}
            ctaLink={contactSalesPath}
            buttonTheme={theme}
            radioButtonTheme={sliderTheme}
          />
        </ContentContainer>
      </Container>
    </BlockContainer>
  );
};

export default ResidentialPricingSlider;
