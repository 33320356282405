import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import Spinner from 'components/Spinner';
import React from 'react';
import { FORM_FIELD_LABELS } from './constants';
import { BottomText, StyledButton } from './styledComponents';
import { IButton } from './types';

const Button: React.FC<IButton> = ({
  isDisabled,
  isLoading,
  className,
  buttonColor,
}) => (
  <>
    <BottomText>
      Smartproxy.com will process your data in order to administer your inquiry
      and inform you about our services. Please visit our
      <Link to="/legal"> Privacy Policy.</Link>
    </BottomText>
    <StyledButton
      id="hero-contact-sales-form-request-trial-cta"
      fontSize={TSHIRT_SIZE.L}
      gutterTop={TSHIRT_SIZE.XXS}
      gutterBot={TSHIRT_SIZE.XXS}
      themeName={BUTTON_THEMES[buttonColor] || BUTTON_THEMES.CONVERSION}
      themeType={BUTTON_TYPES.PRIMARY}
      isDisabled={isDisabled}
      isLoading={isLoading}
      className={className}
    >
      {!isLoading ? FORM_FIELD_LABELS.BUTTON_TEXT : <Spinner />}
    </StyledButton>
  </>
);

export default Button;
