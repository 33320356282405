import styled from '@emotion/styled';
import { ComponentPropsWithoutRef } from 'react';
import { Box } from '../Box';
import { BoxResponsiveStylesProps } from '../Box/types';

type ListAs = 'ul' | 'ol';

const StyledList = styled(Box)({
  padding: 0,
  margin: 0,
});

type ListProps = ComponentPropsWithoutRef<'ul'> & {
  as?: ListAs;
  sx?: BoxResponsiveStylesProps;
};

export const List = ({ as, ...rest }: ListProps) => {
  return <StyledList as={as} {...rest} />;
};
