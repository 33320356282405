import { IP_CHECKER_URL } from 'constants/common';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { BackgroundContainer } from 'features/ColumnsLinks/styledComponents';
import {
  CtaContainer,
  CtaStart,
  ImageContainer,
  StyledContainer,
  StyledTextContainer,
  StyledWrapper,
} from 'features/Hero/styledComponents';
import React, { useEffect, useState } from 'react';
import BrowserColumn from './ColumnsItems/BrowserColumn';
import IspColumn from './ColumnsItems/IspColumn';
import LocationColumn from './ColumnsItems/LocationColumn';
import PlatformColumn from './ColumnsItems/PlatformColumn';
import { LinksList } from './styledComponents';
import TitleBlock from './Title';
import { IIpHeroSlice } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const IpHeroBlock: React.FC<IIpHeroSlice> = ({
  primary: {
    title,
    link_label_start: linkLabelStart,
    link_to_start: linkToStart,
    image,
    accent_color: accentColor,
    button_color: buttonColor,
  },
}) => {
  const [userInfos, setUserInfos] = useState({});

  useEffect(() => {
    const getUserInfo = async () => {
      const response = await fetch(IP_CHECKER_URL);
      const data = await response.json();
      setUserInfos(data);
    };

    getUserInfo();
  }, []);

  return (
    <>
      <StyledWrapper>
        <StyledContainer>
          <StyledTextContainer color={accentColor}>
            <TitleBlock
              isVisible={!!userInfos}
              color={accentColor}
              title={title}
              info={userInfos}
            />
            <CtaContainer>
              <CtaStart
                isVisible={linkLabelStart.text && linkToStart.url}
                ctaText={linkLabelStart.text}
                ctaLink={linkToStart.url}
                themeName={BUTTON_THEMES[buttonColor]}
                themeType={BUTTON_TYPES.PRIMARY}
                isExternal
              />
            </CtaContainer>
          </StyledTextContainer>
          <ImageContainer>
            <GatsbyImg image={image} loading="eager" />
          </ImageContainer>
        </StyledContainer>
      </StyledWrapper>
      <BackgroundContainer>
        <LinksList>
          <LocationColumn isVisible={!!userInfos} info={userInfos} />
          <IspColumn isVisible={!!userInfos} info={userInfos} />
          <PlatformColumn isVisible={!!userInfos} info={userInfos} />
          <BrowserColumn isVisible={!!userInfos} info={userInfos} />
        </LinksList>
      </BackgroundContainer>
    </>
  );
};

export default IpHeroBlock;
