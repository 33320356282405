import styled from '@emotion/styled';
import { mediaQueries, theme, toRem } from '@smartproxy-web/ui';

export const BlogWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '65.25rem',
  width: '100%',
  paddingBottom: '1.5rem',

  [mediaQueries.md]: {
    flexDirection: 'row',
  },
});

export const FullwidthWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const MainColumnWrapper = styled.div<{ fullwidth?: boolean }>(
  ({ fullwidth }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRight: 'none',
    width: '100%',
    marginTop: '2rem',
    gap: toRem(32),

    [mediaQueries.md]: {
      borderRight: fullwidth
        ? 'unset'
        : `1px solid ${theme.colors.neutral.N20}`,
      width: fullwidth ? '100%' : '66%',
      marginTop: '3.75rem',
      gap: toRem(48),
    },

    '& > div, & > section': {
      padding: `0 ${toRem(20)}`,
      [mediaQueries.sm]: {
        padding: `0 ${toRem(40)}`,
      },
      [mediaQueries.lg]: {
        padding: `0 ${toRem(48)}`,
      },
    },
  })
);

export const ArticlesShortcutsWrapper = styled.div({
  display: 'none',
  [mediaQueries.md]: {
    display: 'block',
  },
});

export const AsideColumnWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: '0',
  width: '100%',

  [mediaQueries.md]: {
    width: '33%',
    gap: '3rem',
    padding: '3.75rem 0.813rem 3rem 3rem',
    maxHeight: '100vh',
    overflowY: 'auto',
  },

  position: 'sticky',
  top: '3rem',
  height: 'fit-content',
});
