import { apiEndpoints } from 'apiEndpoints';
import { WEBINAR_FORM } from 'constants/common';
import { checkForEmail } from 'features/ScrapingSimulator/utils';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  initialErrorMessage,
  initialFormState,
  initialFormTouchedState,
} from './utils';

export const useWebinarForm = (info: string) => {
  const [touched, setTouched] = useState(initialFormTouchedState);
  const [formField, setFormField] = useState(initialFormState);
  const { isLoading, isError, isSuccess, resetRequestStatus, request } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.webinarRegistration
  );

  const formSubmitEnabled =
    !!formField.name &&
    !!formField.email &&
    !!formField.email.match(checkForEmail);
  const shouldShowErrorMessage =
    !formSubmitEnabled && touched.name && touched.email;
  const isNameFieldCorrect = touched.name && !formField.name;
  const isEmailFieldCorrect =
    touched.email && !formField.email.match(checkForEmail);

  const computeErrorMessage = () => {
    if (shouldShowErrorMessage) {
      return WEBINAR_FORM.MESSAGE_TEXT_ERROR;
    }
    if (isError) {
      return WEBINAR_FORM.MESSAGE_TEXT_NOT_SENT;
    }
    return initialErrorMessage;
  };
  const errorMessage = computeErrorMessage();

  const computeButtonText = () => {
    if (isError) {
      return WEBINAR_FORM.BUTTON_TEXT_ERROR;
    }
    if (isLoading) {
      return WEBINAR_FORM.BUTTON_TEXT_LOADING;
    }
    return WEBINAR_FORM.BUTTON_TEXT_DEFAULT;
  };
  const buttonText = computeButtonText();

  useEffect(() => {
    if (isSuccess) {
      setFormField(initialFormState);
      setTouched(initialFormTouchedState);
    }
  }, [isSuccess]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, field: string) => {
    setFormField({
      ...formField,
      [field]: e.target.value,
    });
  };

  const handleForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formSubmitEnabled) {
      request({
        body: {
          username: formField.name,
          email: formField.email,
          company_name: formField.companyName || WEBINAR_FORM.NO_COMPANY_NAME,
          webinar: info,
        },
        onFinally: () => {},
      });
    } else {
      setTouched({ name: true, email: true, companyName: true });
    }
  };

  const handleClose = () => {
    resetRequestStatus();
    setFormField(initialFormState);
  };

  return {
    errorMessage,
    buttonText,
    isNameFieldCorrect,
    isEmailFieldCorrect,
    isLoading,
    isSuccess,
    setTouched,
    touched,
    handleChange,
    handleForm,
    handleClose,
    formField,
  };
};
