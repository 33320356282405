import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TARGET, TSHIRT_SIZE } from 'components/constants';
import React from 'react';
import { IScreenProps } from 'utils/screenTypeHOC';
import Radio from './Radio';
import {
  BottomContainer,
  HideableRadioButtonsContainer,
  StyledCta,
  StyledCtaContainer,
} from './styledComponents';
import { IBottomContainerItem } from './types';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const BottomContainerItem: React.FC<IBottomContainerItem & IScreenProps> = ({
  isYearlyVisible,
  switchMonthlyLabel,
  switchYearlyLabel,
  handleSwitchBillingType,
  isBillingYearly,
  ctaLink,
  ctaText,
  selector,
  buttonTheme,
  radioButtonTheme,
}) => {
  const { id: sliceId } = useSliceDetails();
  const buttonColor = buttonTheme || BUTTON_THEMES.FOLLOWING;

  return (
    <BottomContainer>
      <HideableRadioButtonsContainer isVisible={isYearlyVisible}>
        <Radio
          gutterRight={TSHIRT_SIZE.L}
          gutterTop={TSHIRT_SIZE.XXS}
          isSelected={!isBillingYearly}
          title={switchMonthlyLabel}
          onChange={handleSwitchBillingType}
          radioButtonColor={radioButtonTheme}
        />
        <Radio
          gutterTop={TSHIRT_SIZE.XXS}
          isSelected={isBillingYearly}
          title={switchYearlyLabel}
          onChange={handleSwitchBillingType}
          radioButtonColor={radioButtonTheme}
        />
      </HideableRadioButtonsContainer>
      <StyledCtaContainer>
        <StyledCta
          isVisible
          linkId={selector}
          linkClassName={selector}
          dataTracker={selector}
          onClick={() => {
            logPostHogEvent(selector);
            if (sliceId) {
              logGAElementClickEvent({
                elementId: ctaText,
                sectionId: sliceId,
              });
            }
          }}
          themeName={BUTTON_THEMES[buttonColor.toUpperCase()]}
          ctaText={ctaText}
          ctaLink={ctaLink}
          fontSize={TSHIRT_SIZE.M}
          themeType={BUTTON_TYPES.PRIMARY}
          gutterTop={TSHIRT_SIZE.XXS}
          gutterBot={TSHIRT_SIZE.XXS}
          isExternal
          target={TARGET.SELF}
        />
      </StyledCtaContainer>
    </BottomContainer>
  );
};

export default BottomContainerItem;
