import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';

export const BackgroundContainer = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,

    '> h2': {
      marginBottom: themev2.spacings.L,
    },
  })
);

export const Subtitle = styled.div<ITheme>((props) => ({
  'h2,h3,h4,h5,h6': {
    fontSize: '22px',
    fontWeight: '500',
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const TextContent = styled.div<ITheme>({
  width: '100%',
  maxWidth: '1024px',
});

export const FaqList = styled.ul<ITheme>({
  listStyle: 'none',
  padding: 0,
  width: '100%',
  maxWidth: '1024px',
});

export const FaqItem = styled.li<ITheme>((props) => ({
  padding: 0,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));
