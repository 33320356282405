import { IconConfiguration } from 'images';
import React from 'react';
import { RequestConfigurationContainer } from '../styledComponents';
import { IParametersActions } from '../types';

const ParametersActionsContainer: React.FC<IParametersActions> = ({
  setParametersIsVisible,
  trackingSelector,
  configurationTitle,
}) => (
  <RequestConfigurationContainer
    onClick={setParametersIsVisible}
    data-tracker={trackingSelector}
  >
    <IconConfiguration />
    {configurationTitle}
  </RequestConfigurationContainer>
);

export default ParametersActionsContainer;
