import React from 'react';
import hideable from 'utils/hideable';
import {
  FeatureDescription,
  FeatureTitle,
  HeroIcon,
  ListItem,
  StyledFeaturesContainer,
  StyledList,
} from './styledComponents';
import { IFeatures } from './types';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const Features: React.FC<IFeatures> = ({ features, accentColor }) => (
  <StyledFeaturesContainer>
    <StyledList>
      {features.map(
        ({
          feature_title: title,
          hero_icon: icon,
          feature_description: description,
        }) => (
          <ListItem key={title.text}>
            <HeroIcon>
              <GatsbyImg image={icon} loading="eager" />
            </HeroIcon>
            <FeatureTitle accentColor={accentColor}>
              <RichText field={title} />
            </FeatureTitle>
            <FeatureDescription>
              <RichText field={description} />
            </FeatureDescription>
          </ListItem>
        )
      )}
    </StyledList>
  </StyledFeaturesContainer>
);

export default hideable(Features);
