import { WEBINAR_FORM } from 'constants/common';
import { IconSuccess } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import {
  MessageButton,
  MessageContainer,
  MessageText,
  MessageTitle,
} from '../styledComponents';
import { ISuccessMessage } from '../types';

const SuccessMessage: React.FC<ISuccessMessage> = ({ handleClose }) => (
  <MessageContainer>
    <IconSuccess />
    <MessageTitle>{WEBINAR_FORM.MESSAGE_TITLE}</MessageTitle>
    <MessageText>{WEBINAR_FORM.MESSAGE_TEXT_SUCCESS}</MessageText>
    <MessageButton onClick={handleClose}>
      {WEBINAR_FORM.MESSAGE_BUTTON}
    </MessageButton>
  </MessageContainer>
);

export default hideable(SuccessMessage);
