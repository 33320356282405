export const apiEndpoints = {
  inquiryFormSubmit: '/api/v1/contact-sales',
  simulators: {
    SERP: '/api/v1/serp-simulator',
    ECOMMERCE: '/api/v1/ecommerce-simulator',
    WEB: '/api/v1/web-scraping-simulator',
  },
  webinarRegistration: '/api/v1/webinar',
  jobApplication: '/api/v1/career-page',
  scraperPlayground: {
    SOCIAL: '/scraping/social-media/scraper-api',
  },
};
