import { TSHIRT_SIZE } from 'components/constants';
import TextareaInput from 'components/Form/TextareaInput';
import TextInput from 'components/Form/TextInput';
import {
  chatServicesOptions,
  FIELD_NAMES,
  initialValues,
  phoneCodeOptions,
} from 'features/InquiryForm/constants';
import {
  IFormState,
  IInquiryFormAuthMessages,
} from 'features/InquiryForm/types';
import { getValidationSchema } from 'features/InquiryForm/utils';
import { Formik } from 'formik';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import Button from './Button';
import { FORM_FIELD_LABELS } from './constants';
import {
  StyledForm,
  StyledRowContainer,
  StyledSelectInput,
} from './styledComponents';
import { IForm } from './types';

const Form: React.FC<IForm & IInquiryFormAuthMessages> = ({
  tags,
  type,
  onSubmit,
  isLoading,
  buttonColor,
  ...rest
}) => {
  const validationSchema = getValidationSchema(rest);
  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero-contact-sales-form',
    type: 'field',
  });

  return (
    <Formik<IFormState>
      initialValues={{
        ...initialValues,
        countryCode: phoneCodeOptions[0].value,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(context) => (
        <StyledForm id="hero-contact-sales-form">
          <StyledRowContainer>
            <TextInput
              name={FIELD_NAMES.fullName}
              label={FORM_FIELD_LABELS.FULL_NAME}
              context={context}
              className={buildTrackingSelector({
                context: FORM_FIELD_LABELS.FULL_NAME,
              })}
            />
            <TextInput
              name={FIELD_NAMES.companyName}
              label={FORM_FIELD_LABELS.COMPANY_NAME}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              gutterBot={TSHIRT_SIZE.XXS}
              className={buildTrackingSelector({
                context: FORM_FIELD_LABELS.COMPANY_NAME,
              })}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <TextInput
              name={FIELD_NAMES.companyEmail}
              label={FORM_FIELD_LABELS.COMPANY_EMAIL}
              context={context}
              className={buildTrackingSelector({
                context: FORM_FIELD_LABELS.COMPANY_EMAIL,
              })}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <StyledSelectInput
              name={FIELD_NAMES.contactChannel}
              label={FORM_FIELD_LABELS.SOCIAL_MEDIA}
              options={chatServicesOptions}
              context={context}
              className={buildTrackingSelector({
                context: FORM_FIELD_LABELS.SOCIAL_MEDIA,
              })}
            />
            <TextInput
              name={FIELD_NAMES.contactChannelId}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              className={buildTrackingSelector({
                context: FIELD_NAMES.contactChannelId,
              })}
            />
          </StyledRowContainer>
          <StyledRowContainer>
            <StyledSelectInput
              isSearchable
              name={FIELD_NAMES.countryCode}
              label={FORM_FIELD_LABELS.PHONE_NUMBER}
              options={phoneCodeOptions}
              context={context}
              className={buildTrackingSelector({
                context: FORM_FIELD_LABELS.PHONE_NUMBER,
              })}
            />
            <TextInput
              name={FIELD_NAMES.companyPhone}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              className={buildTrackingSelector({
                context: FIELD_NAMES.companyPhone,
              })}
            />
          </StyledRowContainer>
          <TextareaInput
            name={FIELD_NAMES.useCase}
            label={FORM_FIELD_LABELS.USE_CASE}
            context={context}
            className={buildTrackingSelector({
              context: FORM_FIELD_LABELS.USE_CASE,
            })}
          />
          <Button
            buttonColor={buttonColor}
            isLoading={isLoading}
            isDisabled={!(context.isValid && context.dirty) || isLoading}
            className={buildTrackingSelector({
              context: FORM_FIELD_LABELS.BUTTON_TEXT,
              type: 'cta',
            })}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default Form;
