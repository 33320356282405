import { apiEndpoints } from 'apiEndpoints';
import {
  IScraperFormConfig,
  IScraperLocationConfig,
  TSerpScraperTarget,
  TSocialScraperTarget,
} from '../types';

export const serpSplashScreenLabels = {
  submit_btn_loading: 'Scraping...',
  submit_btn_text: 'Send request',
};

export const codeBlockLanguage: Record<string, string> = {
  curl: 'bash',
  node: 'javascript',
  python: 'python',
};

export const commonScraperLocations: IScraperLocationConfig[] = [
  {
    label: 'United States',
    shortLabel: 'US',
    value: 'United States',
  },
  {
    label: 'United Kingdom',
    shortLabel: 'UK',
    value: 'United Kingdom',
  },
  {
    label: 'Germany',
    shortLabel: 'DE',
    value: 'Germany',
  },
];

export const commonSocialScraperRequestParams: Record<
  TSocialScraperTarget,
  Record<string, unknown>
> = {
  instagram_profile: {
    locale: 'en',
  },
  tiktok_post: {
    locale: 'en',
  },
  twitter_user: {
    locale: 'en',
  },
  reddit_post: {
    locale: 'en',
  },
};

export const commonSerpScraperRequestParams: Record<
  TSerpScraperTarget,
  Record<string, unknown>
> = {
  google_search: {
    domain: 'com',
    page_from: 1,
    num_pages: 10,
    locale: 'en',
    device_type: 'desktop',
    google_results_language: 'en',
    parse: true,
  },
  bing_search: {
    domain: 'com',
    page_from: 1,
    num_pages: 10,
    locale: 'en',
    device_type: 'desktop',
    parse: true,
  },
};

export const commonSocialScraperFormConfig: IScraperFormConfig = {
  action: apiEndpoints.scraperPlayground.SOCIAL,
  queryField: {
    key: 'url',
    placeholder: 'Enter URL',
  },
};

export const commonSerpScraperFormConfig: IScraperFormConfig = {
  action: apiEndpoints.scraperPlayground.SOCIAL,
  queryField: {
    key: 'query',
    placeholder: 'Enter search query',
  },
};
