import { DotsContainer, StyledDot } from './styledComponents';
import { DotsProps } from './types';

export const Dots = ({
  items,
  isActive,
  setActiveCard,
  setIsCarouselTouched,
  swiper,
}: DotsProps) => {
  const handleDotClick = (index) => {
    setActiveCard(index + 1);
    setIsCarouselTouched(true);
    swiper!.slideTo(index);
  };

  return (
    <DotsContainer>
      {items.map((_, index) => (
        <StyledDot
          key={`dot-${index}`}
          isActive={isActive === index + 1}
          data-tracker={`card-position-${index + 1}`}
          onClick={() => handleDotClick(index)}
        />
      ))}
    </DotsContainer>
  );
};
