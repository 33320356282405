import { Wrapper } from 'commonStyles';
import SliceContextProvider from 'contexts/SliceContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { IWithBlogProps } from 'pageUtils/blog/types';
import { DefaultSlice, PrismicTextField, TGatsbyImage } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

interface IWithPrismicHomePage {
  prismicHomePage: {
    type: string;
    tags?: string[];
    data: {
      is_experiment_in_preview: boolean;
      title: PrismicTextField;
      description: PrismicTextField;
      cta_text: PrismicTextField;
      cta_link: string;
      homepage_hero: TGatsbyImage;
      body: DefaultSlice[];
      body1: DefaultSlice[];
    };
  };
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

export interface Props {
  data: IWithPrismicHomePage;
}

const HomePage = ({
  data,
  pageContext,
}: Props & PageTemplateProps & IWithBlogProps) => {
  const { bodyKey } = useSplitTesting();
  const { allPrismicBlog } = data;
  const { prismicHomePage } = data;
  const { is_experiment_in_preview: isExperimentPreviewOn } =
    prismicHomePage.data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    isExperimentPreviewOn
      ? prismicHomePage.data.body1
      : prismicHomePage.data[bodyKey],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <SliceContextProvider key={key} position={key} name={slice.slice_type}>
          <Component
            {...slice}
            pageContext={buildPageContext({
              context: pageContext,
              tags: prismicHomePage.tags,
              type: prismicHomePage.type,
            })}
            title={prismicHomePage.data.title}
            description={prismicHomePage.data.description}
            ctaText={prismicHomePage.data.cta_text}
            ctaLink={prismicHomePage.data.cta_link}
            homepageHero={prismicHomePage.data.homepage_hero}
            articles={allPrismicBlog.nodes}
          />
        </SliceContextProvider>
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(HomePage);

export const queryName = 'HomePage';
export const query = graphql`
  query HomePageQuery {
    prismicHomePage {
      _previewable
      tags
      type
      data {
        is_experiment_in_preview
        experiment_id
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicHomePageDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyTrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              link_to {
                url
              }
              trustpilot_logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyHeroLogos {
            id
            slice_type
            items {
              logos {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
            primary {
              background
              title_for_logos {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_action
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_action
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicHomePageDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicHomePageDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                text
                richText
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              available_cities {
                richText
                text
              }
              background
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyProductCardsBlock {
            id
            items {
              badge {
                richText
                text
              }
              link {
                url
              }
              product_description {
                richText
                text
              }
              product_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_title {
                richText
                text
              }
            }
            slice_type
            primary {
              background
              block_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyUseCaseBlock {
            id
            items {
              group_name
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            primary {
              number_of_columns
              background_color
              column_title_1 {
                richText
                text
              }
              column_title_2 {
                richText
                text
              }
              column_title_3 {
                richText

                text
              }
              main_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyPromoBanner {
            id
            slice_type
            items {
              end_date
              banner_icon {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content
              cta_text
              cta_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBodyHomepagePricingCards {
            id
            items {
              benefit_1 {
                text
                richText
              }
              benefit_2 {
                richText
                text
              }
              benefit_3 {
                richText
                text
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_name: card_title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link_field {
                url
              }
              card_price: price {
                richText
                text
              }
            }
            primary {
              column_number
              background
              title_field {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyTextImageColumnList {
            id
            slice_type
            primary {
              column_background
              column_list_title {
                richText
                text
              }
            }
            items {
              link_text
              column_description {
                richText
                text
              }
              column_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              column_link {
                url
              }
              column_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyBlogPostAutomated {
            id
            slice_type
            primary {
              background
              title1 {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodySocialProofBlock {
            id
            slice_type
            primary {
              is_dark
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyRelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                html
                text
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicBlog {
                    id
                    type
                    tags
                    data {
                      article_image {
                        alt
                        url
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      title {
                        html
                        text
                      }
                      description {
                        html
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBodyLogosCarousel {
            id
            slice_type
            primary {
              small_text
            }
            items {
              logo {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBodyCarouselHero {
            id
            slice_type
            primary {
              checkmarks_icon {
                gatsbyImageData(placeholder: BLURRED)
              }
              hero_subtitle {
                richText
                text
              }
              hero_title {
                richText
                text
              }
              primary_button_text
              primary_button_url {
                url
              }
              secondary_button_text
              secondary_button_url {
                url
              }
            }
            items {
              feature_text
            }
          }
          ... on PrismicHomePageDataBodyImageCarouselBlock {
            id
            items {
              badge_text {
                richText
                text
              }
              big_image {
                gatsbyImageData(placeholder: BLURRED)
              }
              cardDescription: card_description {
                text
                richText
              }
              card_heading {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyExpandableProductsPricingOverview {
            id
            items {
              badge_color
              badge_label
              checklist {
                richText
                text
              }
              product {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_starting_price
              price_prefix
            }
            slice_type
            primary {
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              checkmark {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
              background
            }
          }
          ... on PrismicHomePageDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicHomePageDataBodyNewProductCards {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_badge_color
              card_badge_label {
                richText
                text
              }
              card_cta_label {
                richText
                text
              }
              card_cta_link {
                url
              }
              card_item_description {
                richText
                text
              }
              card_download_cta_link {
                url
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_item_title {
                richText
                text
              }
            }
            primary {
              background
              content_alignment
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicHomePageDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              columns
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicHomePageDataBody1ImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1LogosCarousel {
            id
            slice_type
            primary {
              small_text
            }
            items {
              logo {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBody1CarouselHero {
            id
            slice_type
            primary {
              checkmarks_icon {
                gatsbyImageData(placeholder: BLURRED)
              }
              hero_subtitle {
                richText
                text
              }
              hero_title {
                richText
                text
              }
              primary_button_text
              primary_button_url {
                url
              }
              secondary_button_text
              secondary_button_url {
                url
              }
            }
            items {
              feature_text
            }
          }
          ... on PrismicHomePageDataBody1ImageCarouselBlock {
            id
            items {
              badge_text {
                richText
                text
              }
              big_image {
                gatsbyImageData(placeholder: BLURRED)
              }
              cardDescription: card_description {
                text
                richText
              }
              card_heading {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1TrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              link_to {
                url
              }
              trustpilot_logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1HeroLogos {
            id
            slice_type
            items {
              logos {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
            primary {
              background
              title_for_logos {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1HeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_action
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_action
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicHomePageDataBody1HeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1HeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1LocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                text
                richText
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              available_cities {
                richText
                text
              }
              background
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1ProductCardsBlock {
            id
            items {
              badge {
                richText
                text
              }
              link {
                url
              }
              product_description {
                richText
                text
              }
              product_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_title {
                richText
                text
              }
            }
            slice_type
            primary {
              background
              block_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1UseCaseBlock {
            id
            items {
              group_name
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            primary {
              number_of_columns
              background_color
              column_title_1 {
                richText
                text
              }
              column_title_2 {
                richText
                text
              }
              column_title_3 {
                richText

                text
              }
              main_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1GettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicHomePageDataBody1ImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1HomepagePricingCards {
            id
            items {
              benefit_1 {
                text
                richText
              }
              benefit_2 {
                richText
                text
              }
              benefit_3 {
                richText
                text
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_name: card_title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link_field {
                url
              }
              card_price: price {
                richText
                text
              }
            }
            primary {
              column_number
              background
              title_field {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1ExpandableProductsPricingOverview {
            id
            items {
              badge_color
              badge_label
              checklist {
                richText
                text
              }
              product {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_starting_price
              time_unit
              price_prefix
            }
            slice_type
            primary {
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              checkmark {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
              background
            }
          }
          ... on PrismicHomePageDataBody1TextImageColumnList {
            id
            slice_type
            primary {
              column_background
              column_list_title {
                richText
                text
              }
            }
            items {
              link_text
              column_description {
                richText
                text
              }
              column_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              column_link {
                url
              }
              column_title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1BlogPostAutomated {
            id
            slice_type
            primary {
              background
              title1 {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1SimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }

          ... on PrismicHomePageDataBody1RelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                html
                text
                richText
              }
            }
            items {
              article {
                document {
                  ... on PrismicBlog {
                    id
                    type
                    tags
                    data {
                      article_image {
                        alt
                        url
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      title {
                        html
                        text
                      }
                      description {
                        html
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBody1Title {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicHomePageDataBody1PromoBanner {
            id
            slice_type
            items {
              end_date
              banner_icon {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content
              cta_text
              cta_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBody1Spacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1FooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicHomePageDataBody1SocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBody1FeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicHomePageDataBody1BlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomePageDataBody1NewProductCards {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_badge_color
              card_badge_label {
                richText
                text
              }
              card_cta_label {
                richText
                text
              }
              card_cta_link {
                url
              }
              card_item_description {
                richText
                text
              }
              card_download_cta_link {
                url
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_item_title {
                richText
                text
              }
            }
            primary {
              background
              content_alignment
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicHomePageDataBody1CarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicHomePageDataBody1QuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___release_date, order: DESC }
      filter: { tags: { nin: "test" } }
      limit: 1
    ) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMM DD, yyyy")
        url
        data {
          new_until
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMM DD, yyyy")
          body {
            ... on PrismicBlogDataBodyAuthorV2 {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
                is_global
              }
            }
            ... on PrismicBlogDataBodyBlogAuthor {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
