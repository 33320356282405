import { LinksListItem } from 'features/ColumnsLinks/styledComponents';
import React from 'react';
import hideable from 'utils/hideable';
import { ipCheckerMsgs } from '../ipCheckerMsgs';
import { IIpInfo } from '../types';
import ListItem from './ListItem';

const LocationColumn: React.FC<IIpInfo> = ({ info }) => {
  const {
    ipCheckerCity,
    ipCheckerCityCode,
    ipCheckerCityState,
    ipCheckerCityTimeZone,
    ipCheckerLocation,
  } = ipCheckerMsgs;

  return (
    <LinksListItem>
      <h2>{ipCheckerLocation.defaultMessage}</h2>
      <ListItem text={ipCheckerCity.defaultMessage} info={info.city?.name} />
      <ListItem
        text={ipCheckerCityCode.defaultMessage}
        info={info.city?.code}
      />
      <ListItem
        text={ipCheckerCityState.defaultMessage}
        info={info.city?.state}
      />
      <ListItem
        text={ipCheckerCityTimeZone.defaultMessage}
        info={info.city?.time_zone}
      />
    </LinksListItem>
  );
};

export default hideable(LocationColumn);
