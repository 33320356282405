import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

type SkeletonProps = {
  round?: boolean;
  width?: string;
  height?: string;
};

export function Skeleton(props: SkeletonProps) {
  return <StyledSkeleton {...props} />;
}

const animation = keyframes({
  '0%': {
    backgroundPosition: '-200px 0',
  },
  '100%': {
    backgroundPosition: 'calc(200px + 100%) 0',
  },
});

const StyledSkeleton = styled.div<SkeletonProps>(
  ({ round, width, height }) => ({
    backgroundColor: '#eee',
    backgroundImage: 'linear-gradient(90deg, #eee, #f5f5f5, #eee)',
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: round ? '9999px' : '4px',
    display: 'inline-block',
    lineHeight: 1,
    width: width ?? '100%',
    minWidth: width ?? '100%',
    height: height ?? '50px',
    animation: `${animation} 1.5s ease-in-out infinite`,
  })
);
