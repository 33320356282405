import { PRISMIC_VALUES, SECTION_NAMES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { Link } from 'components/GatsbyLink';
import * as React from 'react';
import { IProductCard } from '../types';
import {
  ProductBadge,
  ProductCard,
  ProductCardContainer,
  ProductCards,
  ProductCardWrapper,
  ProductDescription,
  ProductImageWrapper,
  ProductLink,
  ProductTextContainer,
  Subtitle,
  TitleWrapper,
} from './styledComponents';
import { generateSectionsId, slugify } from 'features/utils';
import { initBuildTrackingSelector } from 'utils/page';
import hideable from 'utils/hideable';
import { RichText } from 'components/v2/RichText';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const HideableSubtitle = hideable(Subtitle);

const ProductCardBlock: React.FC<IProductCard> = ({
  primary: {
    block_title: title,
    background,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
  },
  pageContext: { tags, type },
  items,
}) => {
  const { id: sliceId } = useSliceDetails();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'tools',
  });
  return (
    <ProductCardWrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRODUCT_CARDS,
        tags,
        type
      )}
    >
      <ProductCardContainer>
        <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
          <RichText field={title} />
        </TitleWrapper>
        <HideableSubtitle
          isVisible={subtitle?.text}
          isSubtitleCentered={isSubtitleCentered}
        >
          <RichText field={subtitle} />
        </HideableSubtitle>
        <ProductCards>
          {items.map(
            (
              {
                product_title: title,
                product_logo: logo,
                badge,
                product_description: description,
                link,
              },
              index
            ) => (
              <ProductCard key={index}>
                <ProductImageWrapper>
                  <GatsbyImg image={logo} />
                </ProductImageWrapper>
                <ProductTextContainer>
                  <ProductBadge>{badge.text}</ProductBadge>
                  <RichText field={title} />
                  <ProductDescription>
                    <RichText field={description} />
                  </ProductDescription>
                  <ProductLink>
                    <Link
                      id={`tool-${slugify(title.text)}`}
                      className={trackingSelector({ context: title.text })}
                      data-tracker={trackingSelector({ context: title.text })}
                      onClick={() => {
                        logPostHogEvent(
                          trackingSelector({ context: title.text })
                        );
                        if (sliceId) {
                          logGAElementClickEvent({
                            elementId: `tool-${slugify(title.text)}`,
                            sectionId: sliceId,
                          });
                        }
                      }}
                      to={link.url}
                    >
                      {PRISMIC_VALUES.LEARN_MORE}
                    </Link>
                  </ProductLink>
                </ProductTextContainer>
              </ProductCard>
            )
          )}
        </ProductCards>
      </ProductCardContainer>
    </ProductCardWrapper>
  );
};
export default ProductCardBlock;
