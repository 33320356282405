import React from 'react';
import hideable from 'utils/hideable';
import { LoaderContainer, StyledLoaderWrapper } from '../styledComponents';

const LoaderWrapper: React.FC = () => (
  <StyledLoaderWrapper>
    <LoaderContainer />
    <p>Scraping in progress...</p>
  </StyledLoaderWrapper>
);

export default hideable(LoaderWrapper);
