import { track } from '@smartproxy/sp-tracker-js';
import { useScrollBlock } from 'hooks/useScrollBlock';
import { IconVideoModal } from 'images';
import React, { useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import { ImageContainer, StyledPlayIconWrapper } from './styledComponents';
import { IImageVideoPlayer } from './types';
import VideoModal from './VideoModal';
import { GatsbyImg } from '@smartproxy-web/ui';

const ImageVideoPlayer: React.FC<IImageVideoPlayer> = ({
  image,
  video,
  buildClickId,
  tags,
  type,
}) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const isScrollable = useScrollBlock(isModalVisible);
  const showModal = () => {
    track(trackingSelector);
    setModalVisibility((isModalVisible) => !isModalVisible);
    isScrollable;
  };

  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'video-hero',
    prefix: type,
  });

  return (
    <>
      {!video ? (
        <ImageContainer>
          <GatsbyImg image={image} loading="eager" />
        </ImageContainer>
      ) : (
        <>
          <ImageContainer
            isPointerVisible={!!video}
            onClick={showModal}
            id={buildClickId('video')}
            className={trackingSelector}
            data-tracker={trackingSelector}
          >
            <StyledPlayIconWrapper>
              <IconVideoModal />
            </StyledPlayIconWrapper>
            <GatsbyImg image={image} />
          </ImageContainer>
          <VideoModal
            video={video}
            isVisible={isModalVisible}
            onClose={showModal}
          />
        </>
      )}
    </>
  );
};

export default ImageVideoPlayer;
