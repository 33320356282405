import React, { ChangeEvent, useCallback } from 'react';
import { buildTrackingSelector } from 'utils/page';
import InputError from '../InputError';
import {
  HideableIconWrapper,
  HideableLabelWrapper,
  InputContainer,
  InputWithContent,
  InputWrapper,
  Label,
  StyledInput,
} from './styledComponents';
import { TInput } from './types';

const Input: React.FC<TInput> = ({
  error,
  gutterBot,
  gutterLeft,
  gutterRight,
  gutterTop,
  icon,
  inputHeight,
  inputWidth,
  label,
  onChange,
  ...rest
}) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onChange(event.target.value);
  }, []);

  return (
    <InputWrapper
      gutterBot={gutterBot}
      gutterTop={gutterTop}
      gutterLeft={gutterLeft}
      gutterRight={gutterRight}
    >
      <HideableLabelWrapper isVisible={!!label}>
        <Label>{label}</Label>
      </HideableLabelWrapper>
      <InputContainer inputWidth={inputWidth}>
        <InputWithContent>
          <StyledInput
            onChange={handleChange}
            inputHeight={inputHeight}
            hasError={!!error}
            inputWidth={inputWidth}
            {...rest}
          />
          <HideableIconWrapper isVisible={!!icon}>{icon}</HideableIconWrapper>
        </InputWithContent>
        <InputError
          error={error}
          isVisible={error}
          dataTracker={buildTrackingSelector({
            section: 'contact-sales-error',
            context: label?.toString() || error?.toString(),
          })}
        />
      </InputContainer>
    </InputWrapper>
  );
};

export default Input;
