import styled from '@emotion/styled';
import { richTextParagraphElements } from 'constants/common';
import { MAX_BODY_CONTENT_WIDTH } from 'constants/styles';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';

export const StyledHeroFeaturesWrapper = styled.div<{ isDarkTheme: boolean }>(
  ({ isDarkTheme }) => ({
    ...(isDarkTheme && { background: themev2.colors.neutral.N99 }),
    overflow: 'hidden',
    padding: '0px 20px',
    width: '100%',
    [String(FeaturesTop)]: {
      [richTextParagraphElements]: {
        color: isDarkTheme
          ? themev2.colors.neutral.N20
          : themev2.colors.black.default,
      },
    },
    [String(FeaturesBottom)]: {
      [richTextParagraphElements]: {
        color: isDarkTheme
          ? themev2.colors.neutral.N50
          : themev2.colors.black.default,
      },
    },
  })
);

export const StyledHeroFeaturesContainer = styled.div({
  maxWidth: MAX_BODY_CONTENT_WIDTH,
  margin: '0 auto',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
  gap: themev2.spacings.S,
});

export const StyledHeroFeature = styled.div({
  width: '100%',

  [MEDIA_QUERIES.TABLET]: {
    textAlign: 'left',
  },

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '75%',
    minWidth: '200px',
  },
});

export const FeaturesTop = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: themev2.spacings.XS,
  gap: themev2.spacings.XS,
  [richTextParagraphElements]: {
    fontSize: themev2.fonts.size.p_small,
  },
});

export const FeaturesBottom = styled.div({
  color: themev2.colors.neutral.N50,
  fontWeight: '300',
  [richTextParagraphElements]: {
    fontSize: themev2.fonts.size.p_xsmall,
  },
});
