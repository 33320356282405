import styled from '@emotion/styled';
import { hexToRgba } from '@smartproxy-web/shared/utils';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { richTextParagraphElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_POSITION,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeNeutralColor,
  getThemeTextColor,
  getThemeMainColor,
  MAIN_COLOR,
  ITheme,
  getThemeAccentColor,
  ACCENT_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';

export const TitleWrapper = styled.div<ITheme & { isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded, ...props }) => ({
    textAlign: TEXT_ALIGN.CENTER,
    'h1, h2, h3': {
      marginBottom: isSubtitleAdded
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XL),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
    },
  })
);

export const Subtitle = styled.div<ITheme & { isSubtitleCentered: boolean }>(
  ({ isSubtitleCentered, ...props }) => ({
    textAlign: isSubtitleCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    'h2, h3, h4, h5, h6': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      textAlign: TEXT_ALIGN.LEFT,
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
    [richTextParagraphElements]: {
      fontSize: themev2.fonts.size.p_large,
    },
  })
);

export const StyledTableRowGrid = styled.div<{ noOfCols: number }>(
  ({ noOfCols, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${noOfCols}, minmax(270px, 1fr))`,

    div: {
      '--half-gutter': getThemeGutter(props, TSHIRT_SIZE.S),
      paddingLeft: 'var(--half-gutter)',
      paddingRight: 'var(--half-gutter)',
      '&:first-of-type': {
        paddingLeft: 0,
      },
      '&:last-of-type': {
        paddingRight: 0,
      },
    },

    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: `240px repeat(${noOfCols - 1}, minmax(270px, 1fr))`,
      div: {
        '--half-gutter': getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
      },
    },
  })
);

export const StyledTableHeader = styled(StyledTableRowGrid)((props) => ({
  div: {
    paddingBottom: '17px',
  },
  ['p, p *']: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
    fontWeight: 500,
  },
}));

export const StyledTableBodyRow = styled(StyledTableRowGrid)((props) => ({
  gridAutoRows: 'minmax(70px, 1fr)',

  div: {
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_ALIGN.FLEX_START,
    paddingTop: '20px',
    paddingBottom: '20px',
    'p, li, a': {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    },

    '& > *, ul li:last-of-type': {
      marginBottom: 0,
    },
  },
}));

export const StyledTableBodyRowCell = styled.div<IColor>(
  ({ background, ...props }) => ({
    background,
    'ul, ol': {
      li: {
        marginBottom: 0,
      },
    },
    a: {
      color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
    },
  })
);

export const StyledTableBody = styled.div((props) => ({
  width: '100%',
  '> * + * div': {
    borderTop: `1px solid ${getThemeNeutralColor(
      props,
      COLOR_SHADE.INVISIBLE
    )}`,
  },
  [`${StyledTableBodyRow}:first-of-type div`]: {
    borderTop: `2px solid ${getThemeTextColor(props, COLOR_SHADE.MAIN)}`,
  },
  [`${StyledTableBodyRow}:last-of-type div`]: {
    borderBottom: `2px solid ${getThemeTextColor(props, COLOR_SHADE.MAIN)}`,
  },
  [`${StyledTableBodyRow} div:first-of-type`]: {
    height: '100%',
    ['p, p *']: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
      fontWeight: '500',
    },
  },
}));

export const StyledContainer = styled.section<IColor>(({ background }) => ({
  width: '100%',
  background,
}));
export const StyledContainedContainer = styled.section((props) => ({
  padding: '80px 20px',
  width: '100%',
  maxWidth: '1024px',
  margin: '0 auto',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  overflow: 'hidden',
}));

export const StyledTableFooter = styled(StyledTableRowGrid)((props) => ({
  div: {
    paddingTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  },

  button: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    fontWeight: '500',
    lineHeight: '20px',
  },
}));

export const StyledTableWrapper = styled.div<
  IColor & {
    scrollX: number;
  }
>(({ background, scrollX }) => ({
  position: CSS_POSITION.RELATIVE,

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    '&::before, &::after': {
      pointerEvents: 'none',
      position: CSS_POSITION.ABSOLUTE,
      content: '""',
      top: 0,
      height: '100%',
      width: '6rem',
      zIndex: 1,
      display: scrollX === -1 ? CSS_DISPLAY.NONE : 'initial',
    },
    '&::after': {
      transformOrigin: 'right',
      right: -1,
      background: `linear-gradient(to right, ${hexToRgba(
        background || '#fff',
        0
      )}, ${hexToRgba(background || '#fff', 1)})`,
      transform: [`scaleX(${1 - scrollX})`],
    },
    '&::before': {
      transformOrigin: 'left',
      left: 0,
      background: `linear-gradient(to left, ${hexToRgba(
        background || '#fff',
        0
      )}, ${hexToRgba(background || '#fff', 1)})`,
      transform: [`scaleX(${scrollX})`],
    },
  },
}));

export const StyledTable = styled.div((props) => ({
  overflowX: 'auto',
  [`${StyledTableHeader} div, ${StyledTableBodyRow} div, ${StyledTableFooter} div`]:
    {
      '*': {
        fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
      },
      '& > *': {
        marginBottom: 0,
      },
    },
}));

export const StyledHandIconWrapper = styled.div((props) => ({
  height: '42px',
  display: 'flex',
  svg: {
    width: '42px',
    height: '42px',
    margin: `${getThemeGutter(props, TSHIRT_SIZE.XS)} auto 0`,
  },
}));
