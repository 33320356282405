import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { LinkButtonProps } from 'components/v2/LinkButton';
import { GOOGLE_SIGN_UP_TEXT, SECTION_NAMES } from 'constants/common';
import { IconGoogleColor } from 'images';
import { initBuildTrackingSelector } from 'utils/page';

const ctaActions = ['google_auth'] as const;

export type CtaAction = (typeof ctaActions)[number];

const trackingSelector = initBuildTrackingSelector({
  section: SECTION_NAMES.HERO,
  type: 'cta',
});

export const ctaActionsMap: Readonly<
  Record<CtaAction, (children?: string) => Partial<LinkButtonProps>>
> = {
  google_auth: (children = GOOGLE_SIGN_UP_TEXT) => ({
    color: 'white',
    variant: 'filled',
    leftIcon: <IconGoogleColor />,
    children,
    onClick: () => {
      logGAElementClickEvent({
        elementId: 'cta-signup-with-google',
        sectionId: SECTION_NAMES.HERO,
      });
      logPostHogEvent(trackingSelector({ context: 'cta-signup-with-google' }));
    },
  }),
};
