import { track } from '@smartproxy/sp-tracker-js';
import { scrollSmooth, scrollSmoothUnset } from 'features/utils';
import { StyledHeaderNav } from 'features/v2/Header/Nav';
import { StyledMenuWrapperMobile } from 'features/v2/Header/styledComponents';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { headerCarrerQuery } from '../query';
import {
  StyledHeaderNavInnerCareers,
  StyledLink,
  StyledPagedNavCareers,
} from '../styledComponents';
import { HEADER_SLICES, IHeaderComponents, INavItems } from '../types';

const Navigation: React.FC<IHeaderComponents> = ({
  buildTrackingId,
  setIsOpen,
  isOpen,
}) => {
  const { data } = useMergePrismicPreviewData(headerCarrerQuery());
  const navTemplates: INavItems = data.prismicHeaderCareer.data.body.find(
    (slice) => slice.slice_type === HEADER_SLICES.Nav
  );

  const outOfNavigationTrackerId = buildTrackingId({
    context: 'out-of-menu',
    section: 'header-careers-dropdown-close',
    type: 'field',
  });

  const closeDrawer = () => {
    setIsOpen(false);
    track(outOfNavigationTrackerId);
  };

  return (
    <StyledHeaderNav
      id="header-careers-nav-menu"
      data-state={isOpen ? 'open' : 'closed'}
    >
      <StyledHeaderNavInnerCareers>
        <StyledPagedNavCareers>
          {navTemplates.items.map(
            ({ nav_title: navTitle, nav_url: navUrl }) => {
              const trackingId = buildTrackingId({
                context: navTitle,
                type: 'link',
              });
              return (
                <StyledLink
                  to={navUrl.url}
                  onMouseLeave={scrollSmoothUnset}
                  onMouseDown={scrollSmooth}
                  key={navTitle}
                  data-tracker={trackingId}
                  onClick={() => {
                    setIsOpen(false), track(trackingId);
                  }}
                >
                  {navTitle}
                </StyledLink>
              );
            }
          )}
        </StyledPagedNavCareers>
      </StyledHeaderNavInnerCareers>
      <StyledMenuWrapperMobile
        data-tracker={outOfNavigationTrackerId}
        onClick={closeDrawer}
      />
    </StyledHeaderNav>
  );
};

export default Navigation;
