import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import hideable from 'utils/hideable';
import { StyledImageWrapper, StyledReviews } from './styledComponents';
import { IReviews } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const Reviews: React.FC<IReviews> = ({
  review_stars: reviewStars,
  review_trustpilot: reviewTrustpilot,
  review_text: reviewText,
  isStarsAdded,
}) => {
  return (
    <StyledReviews isStarsAdded={isStarsAdded}>
      <StyledImageWrapper isStarsAdded={isStarsAdded}>
        <GatsbyImg image={reviewTrustpilot} loading="eager" />
      </StyledImageWrapper>
      <StyledImageWrapper isStarsAdded={isStarsAdded}>
        <GatsbyImg image={reviewStars} loading="eager" />
      </StyledImageWrapper>
      <NewRichText text={reviewText} />
    </StyledReviews>
  );
};

export default hideable(Reviews);
