import { GooogleTagManager } from '@smartproxy-web/features';
import { GTM_ID } from 'constants/common';
import { Script, ScriptStrategy } from 'gatsby';

export const Scripts = () => (
  <>
    {process.env.NODE_ENV === 'production' && <GooogleTagManager id={GTM_ID} />}
    <Script strategy={ScriptStrategy.idle}>
      {`
          window.__lc = window.__lc || {};
          window.__lc.license = 12092754;
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `}
    </Script>
    <Script
      strategy={ScriptStrategy.idle}
      src="https://www.youtube.com/iframe_api"
      key="yt-iframe-api"
    />
    <Script
      src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      strategy={ScriptStrategy.postHydrate}
    />
    <Script key="hotjar" strategy={ScriptStrategy.postHydrate}>
      {`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:2911625,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
    </Script>
    <Script
      key="clearbit-tags"
      src="https://tag.clearbitscripts.com/v1/pk_438a89d94859260c96dc3f82fb045690/tags.js"
      strategy={ScriptStrategy.postHydrate}
    />
  </>
);
