import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  WithPrismicPreviewProps,
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { useHasMounted } from 'hooks/useHasMounted';
import { sliceMap, SliceType } from 'features';

interface IWithPrismic404 {
  prismic404: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismic404;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const NotFoundPage = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismic404 } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismic404.data[bodyKey],
    sliceMap
  );

  const hasMounted = useHasMounted();

  if (!hasMounted) return null;

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismic404.tags,
            type: prismic404.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery {
    prismic404 {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on Prismic404DataBody404Hero {
            id
            primary {
              title {
                richText
                text
              }
              cta_text {
                richText
                text
              }
              cta_link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;
