import { SliceContextProvider } from '@smartproxy-web/features';
import { DefaultSlice } from '@smartproxy-web/features/src/types';
import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { SliceType, sliceMap } from 'features';
import { PageProps, graphql } from 'gatsby';
import {
  WithPrismicPreviewProps,
  withPrismicPreview,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

interface PrismicGlossaryData {
  prismicGlossary: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
interface GlossaryProps {
  data: PrismicGlossaryData;
}

const GlossaryHub = ({
  data,
  pageContext,
}: GlossaryProps & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicGlossary } = data;

  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicGlossary?.data[bodyKey] ?? [],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, idx) => (
        <SliceContextProvider name={slice.slice_type} position={idx} key={idx}>
          <Component
            {...slice}
            pageContext={buildPageContext({
              context: pageContext,
              tags: prismicGlossary.tags,
              type: prismicGlossary.type,
            })}
          />
        </SliceContextProvider>
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query PrismicGlossaryQuery($id: String!) {
    prismicGlossary(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicGlossaryDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicGlossaryDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicGlossaryDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicGlossaryDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicGlossaryDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicGlossaryDataBodyColumnLinks {
            id
            items {
              text {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              number_of_columns
            }
            slice_type
          }
          ... on PrismicGlossaryDataBodyFaqQuestions {
            id
            primary {
              title {
                richText
                text
              }
            }
            items {
              question_document {
                tags
                type
                document {
                  ...QuestionsFragment
                }
              }
            }
            slice_type
          }
          ... on PrismicGlossaryDataBodyCodeBlockV2 {
            id
            slice_label
            slice_type
            items {
              code {
                richText
                text
              }
              language
            }
          }
          ... on PrismicGlossaryDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(GlossaryHub);
