import { PRISMIC_VALUES } from 'constants/common';
import {
  HideableCrossedOutTotalPrice,
  TimePeriod,
} from 'features/PricingCards/styledComponents';
import React from 'react';
import hideable from 'utils/hideable';
import { IScreenProps } from 'utils/screenTypeHOC';
import {
  AnimationWrapper,
  HideableAnimationWrapper,
  InfoText,
  PlanAmount,
  PlanContainer,
  PlanFeature,
  PlanFeatures,
  PlanFeaturesContainer,
  PlanPrice,
  PlanTitle,
} from './styledComponents';
import { IItem } from './types';

const PricingPlan: React.FC<IItem & IScreenProps> = ({
  amount,
  first_feature_label: firstFeatureLabel,
  first_feature_value: firstFeatureValue,
  price,
  second_feature_label: secondFeatureLabel,
  second_feature_value: secondFeatureValue,
  third_feature_label: thirdFeaturelabel,
  third_feature_value: thirdFeatureValue,
  fourth_feature_label: fourthFeaturelabel,
  fourth_feature_value: fourthFeatureValue,
  vat_text: vatText,
  plan_title: planTitle,
  isTablet,
  crossed_out_total_price: crossedOutTotalPrice,
  sliderTheme,
  billing_name: billingName,
}) => (
  <PlanContainer>
    <AnimationWrapper>
      <HideableAnimationWrapper key={planTitle} isVisible={!isTablet}>
        <PlanTitle>{planTitle}</PlanTitle>
      </HideableAnimationWrapper>
      <AnimationWrapper key={amount}>
        <PlanAmount colorShade={sliderTheme}>{amount}</PlanAmount>
      </AnimationWrapper>
      <AnimationWrapper key={price}>
        <PlanPrice>
          {price}
          <TimePeriod>/{billingName}</TimePeriod>
        </PlanPrice>
      </AnimationWrapper>
      <InfoText>
        {PRISMIC_VALUES.TOTAL}
        <HideableCrossedOutTotalPrice isVisible={crossedOutTotalPrice}>
          {crossedOutTotalPrice}
        </HideableCrossedOutTotalPrice>
        {vatText}
      </InfoText>
      <PlanFeaturesContainer>
        <PlanFeatures>
          <PlanFeature>{firstFeatureLabel}</PlanFeature>
          <AnimationWrapper key={firstFeatureValue}>
            <PlanFeature>{firstFeatureValue}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{secondFeatureLabel}</PlanFeature>
          <AnimationWrapper key={secondFeatureValue}>
            <PlanFeature>{secondFeatureValue}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{thirdFeaturelabel}</PlanFeature>
          <AnimationWrapper key={thirdFeatureValue}>
            <PlanFeature>{thirdFeatureValue}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{fourthFeaturelabel}</PlanFeature>
          <AnimationWrapper key={fourthFeatureValue}>
            <PlanFeature>{fourthFeatureValue}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
      </PlanFeaturesContainer>
    </AnimationWrapper>
  </PlanContainer>
);

export default hideable(PricingPlan);
