import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import {
  FormButton,
  FormInput,
  FormInputContainer,
  FormSelectDropdownBox,
  FormSelectTarget,
  IconInfoContainer,
  InfoPopup,
} from 'features/ScrapersPlayground/styledComponents';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  MAIN_COLOR,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeMainColor,
  getThemePlaygroundColor,
} from 'theme';

export const StyledSplashScreenFormInputContainer = styled(FormInputContainer)({
  width: '100%',

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
});

export const StyledSplashScreenIconInfoContainer = styled(IconInfoContainer)(
  (props) => ({
    position: 'relative',

    '&::after': {
      position: 'absolute',
      content: '""',
      top: 8,
      right: 0,
      height: 'calc(100% - 16px)',
      width: '1px',
      background: getThemePlaygroundColor(props, COLOR_SHADE.CHARCOAL),
    },
  })
);

export const StyledSplashScreen = styled.div((props) => ({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.S)} 40px`,
  backgroundColor: getThemePlaygroundColor(props, COLOR_SHADE.SECONDARY),
  color: getThemeMainColor(props, MAIN_COLOR.WHITE),

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    padding: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const StyledSplashScreenHeader = styled.div((props) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexFlow: 'wrap',
  gap: getThemeGutter(props, TSHIRT_SIZE.S),

  h6: {
    fontWeight: '500',
  },

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: 'center',
  },
}));

export const StyledSplashScreenTargetList = styled.ul((props) => ({
  display: 'flex',
  alignItems: 'center',
  gap: getThemeGutter(props, TSHIRT_SIZE.S),
  listStyle: 'none',
  padding: 0,
}));

export const StyledSplashScreenTargetListItem = styled.li((props) => ({
  paddingBottom: 0,

  label: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: getThemeGutter(props, TSHIRT_SIZE.XS),

    'input[type=radio]': {
      cursor: 'pointer',
      appearance: 'none',
      margin: 0,
      color: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
      width: '14px',
      height: '14px',
      boxShadow: '0px 0px 0px 2px #557089',
      border: `5px solid ${getThemePlaygroundColor(
        props,
        COLOR_SHADE.SECONDARY
      )}`,
      borderRadius: '50%',
      transition: '0.2s ease-in-out',

      '&:checked': {
        backgroundColor: getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
        borderWidth: '2.5px',
        boxShadow: `0px 0px 0px 2px ${getThemeFunctionalColor(
          props,
          COLOR_SHADE.PRIMARY
        )}`,
      },
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    display: 'none',
  },
}));

export const StyledSplashScreenMain = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '66px 0',

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: '120px 0',
  },
});

export const StyledSplashScreenImage = styled.div((props) => ({
  svg: {
    display: 'block',
    height: '100%',
  },
  height: 56,
  margin: `${getThemeGutter(props, TSHIRT_SIZE.S)} 0`,
}));

export const StyledSplashScreenForm = styled.form({
  maxWidth: '400px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [`${InfoPopup}`]: {
    maxWidth: '225px',
  },

  [`${StyledSplashScreenFormInputContainer}:nth-of-type(1)`]: {
    display: 'none',

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      display: 'flex',

      [`${FormSelectTarget}`]: {
        flexGrow: 1,
      },
    },
  },
  [`${StyledSplashScreenFormInputContainer}:nth-of-type(2)`]: {
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      [`${StyledSplashScreenIconInfoContainer}`]: {
        display: 'none',
      },
    },
  },
  [`${FormSelectDropdownBox}`]: {
    maxWidth: 164,
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: 'calc(100% + 2px)',
      maxWidth: 'unset',
    },
  },
});

export const StyledSplashScreenFormInput = styled(FormInput)({
  flexGrow: 1,
});

export const StyledSplashScreenSubmitBtn = styled(FormButton)(
  ({ disabled, ...props }) => ({
    backgroundColor: disabled
      ? '#334C64'
      : getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY),
    color: getThemeMainColor(props, MAIN_COLOR.WHITE),
    width: 'unset',
    minWidth: '148px',
    transition: '0.3s ease-in-out',

    '&, &:hover': {
      border: 'none',
    },

    '&:disabled': {
      cursor: 'not-allowed',
    },

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '99%',
    },
  })
);
