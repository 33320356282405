import loadable from '@loadable/component';
import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_TYPES } from 'components/Button';
import * as React from 'react';
import { PrismicTextField } from 'types';
import { buildTrackingSelector } from 'utils/page';
import { defaultAnimationOptions404 } from '../utils';
import {
  NotFoundPage,
  NotFoundPageAnimationWrapper,
  NotFoundPageContainer,
  NotFoundPageCtaContainer,
  NotFoundPageDescription,
  NotFoundPageSubtitle,
  NotFoundPageTextContainer,
  NotFoundPageTitle,
  NotFoundPageWrapper,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';
const Lottie = loadable(() => import('react-lottie'));

export interface TNotFoundSlice {
  primary: {
    title: PrismicTextField;
    subtitle: PrismicTextField;
    text: PrismicTextField;
    cta_text: PrismicTextField;
    cta_link: {
      url: string;
    };
  };
}

const NotFoundHero: React.FC<TNotFoundSlice> = ({
  primary: { title, subtitle, text, cta_text: ctaText, cta_link: link },
}) => {
  const [animationData, setAnimationData] = React.useState<unknown>(null);

  React.useEffect(() => {
    import('images/animations/404.json').then((res) =>
      setAnimationData(res.default as unknown)
    );
  }, []);

  const trackingSelector = buildTrackingSelector({
    section: 'page-not-found',
    context: ctaText.text,
  });

  return (
    <NotFoundPageWrapper>
      <NotFoundPageContainer>
        <NotFoundPage>
          <NotFoundPageTextContainer>
            <NotFoundPageTitle>
              <RichText field={title} />
            </NotFoundPageTitle>
            <NotFoundPageSubtitle>
              <RichText field={subtitle} />
            </NotFoundPageSubtitle>
            <NotFoundPageDescription>
              <RichText field={text} />
            </NotFoundPageDescription>
            <NotFoundPageCtaContainer
              isVisible
              ctaText={ctaText.text}
              ctaLink={link.url}
              themeType={BUTTON_TYPES.SECONDARY}
              dataTracker={trackingSelector}
              onClick={() => track(trackingSelector)}
            />
          </NotFoundPageTextContainer>
          <NotFoundPageAnimationWrapper>
            <Lottie
              options={{ ...defaultAnimationOptions404, animationData }}
            />
          </NotFoundPageAnimationWrapper>
        </NotFoundPage>
      </NotFoundPageContainer>
    </NotFoundPageWrapper>
  );
};

export default NotFoundHero;
