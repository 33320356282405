import { Carousel, CarouselRenderItem, GatsbyImg } from '@smartproxy-web/ui';
import { LogosContainer, Text } from './styledComponents';
import { swiperConfig } from './constants';
import { LogoCarouselProps, LogoItemProps } from './types';

export const LogosCarousel = ({
  primary: { small_text: text },
  items,
}: LogoCarouselProps) => {
  const doubledItems = items.concat(items);
  const renderLogoItems: CarouselRenderItem<LogoItemProps> = ({ item }) => (
    <GatsbyImg image={item.logo} />
  );

  return (
    <LogosContainer>
      <Text>{text}</Text>
      <Carousel
        renderItem={renderLogoItems}
        items={doubledItems}
        config={{
          ...swiperConfig,
          loop: true,
        }}
        sideGradient={false}
      />
    </LogosContainer>
  );
};
