import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeBackgroundColor,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  ITheme,
} from 'theme';

export const PaymentMethodContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'auto 1fr',
  rowGap: '16px',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  width: '100%',
  maxWidth: '1024px',
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
  },
}));

export const SecurityContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '44px auto',
  columnGap: '15px',
  color: getThemeBackgroundColor(props, COLOR_SHADE.DEEP),
  'h1, h2, h3, h4': {
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    margin: 0,
    textAlign: TEXT_ALIGN.LEFT,
  },
  p: {
    margin: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  },
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2,auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    textAlign: TEXT_ALIGN.CENTER,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    'h1, h2, h3, h4': {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const PaymentMethods = styled.ul({
  listStyle: 'none',
  marginBottom: '9px',
  maxHeight: '26px',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    maxHeight: 'unset',
    flexWrap: CSS_FLEX_WRAP.WRAP,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
});
export const ListItem = styled.li<ITheme>((props) => ({
  paddingBottom: 0,
  img: {
    height: '26px',
  },
  [MEDIA_QUERIES.TABLET]: {
    margin: `0 ${getThemeGutter(props, TSHIRT_SIZE.S)} ${getThemeGutter(
      props,
      TSHIRT_SIZE.S
    )}`,
  },
}));
