import styled from '@emotion/styled';
import { DOM_STATE, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBackgroundColor,
  getThemeBrandedColor,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputHeightShape,
  getThemeInputPaddingShape,
  TEXT_OVERFLOW,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledFileUpload = styled.div((props) => ({
  display: CSS_DISPLAY.INLINE_FLEX,
  height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
  width: 'max-content',
  maxWidth: '100%',
  alignItems: CSS_ALIGN.CENTER,
  gap: '12px',
}));

export const StyledFileUploadInput = styled.input({
  width: 0,
  height: 0,
  position: CSS_POSITION.ABSOLUTE,
  pointerEvents: 'none',
  '::-webkit-file-upload-button': {
    display: 'none',
  },
  '&:focus + label::before': {
    borderWidth: '2px',
  },
});

export const StyledFileUploadLabel = styled.label<{ hasFile }>(
  ({ hasFile, ...props }) => ({
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    position: CSS_POSITION.RELATIVE,
    cursor: 'pointer',
    height: '100%',
    padding: '0 25px',
    display: CSS_DISPLAY.FLEX,
    alignItems: CSS_ALIGN.CENTER,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    gap: getThemeInputPaddingShape(props, TSHIRT_SIZE.XS),
    flexGrow: 1,
    overflowX: 'hidden',

    '&::before': {
      content: '""',
      position: CSS_POSITION.ABSOLUTE,
      height: '100%',
      width: '100%',
      borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
      border: `1px solid ${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}`,
    },

    ...(hasFile && {
      background: `${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}19`,
    }),

    '&:hover::before': {
      borderWidth: '2px',
    },

    span: {
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      fontWeight: '500',
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
      overflow: 'hidden',
      textOverflow: TEXT_OVERFLOW.ELLIPSIS,
      whiteSpace: CSS_FLEX_WRAP.NOWRAP,
      maxWidth: '100%',
    },

    p: {
      fontSize: themev2.fonts.size.p_small,
    },

    small: {
      fontSize: '10px',
      fontWeight: '300',
    },

    svg: {
      height: '20px',
      flexShrink: 0,

      path: {
        fill: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      },
    },
  })
);

export const StyledFileUploadDeleteButton = styled.button((props) => ({
  background: getThemeBackgroundColor(props, COLOR_SHADE.SECONDARY),
  border: 'none',
  padding: getThemeGutter(props, TSHIRT_SIZE.XS),
  cursor: 'pointer',
  opacity: 0.7,
  transition: '0.2s ease-in',

  [DOM_STATE.HOVER]: {
    opacity: 1,
  },
}));
