import Spinner from 'components/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  IframeModalContainer,
  StyledBookingIframe,
  StyledBookingLoader,
} from '../styledComponents';

const InquiryFormBooking: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  useEffect(() => {
    if (!iframeRef.current) return;

    iframeRef.current.onload = function () {
      setIsLoaderVisible(false);
    };
  }, []);

  return (
    <IframeModalContainer>
      <Container>
        <StyledBookingIframe
          ref={iframeRef}
          frameBorder="0"
          src="https://smartproxy.getoutreach.com/calendar/team/t/1#/select-time"
        />
      </Container>
      <StyledBookingLoader isVisible={isLoaderVisible}>
        <Spinner color="black" size={32} />
        <br />
        <p>Checking to see available times...</p>
      </StyledBookingLoader>
    </IframeModalContainer>
  );
};

export default InquiryFormBooking;
