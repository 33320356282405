import { TitleWrapper } from './styledComponents';
import React from 'react';
import { IQuestionTitle } from './types';
import { NewRichText } from 'components/PrismicRichText';

const QuestionTitle: React.FC<IQuestionTitle> = ({
  title,
  primary: { background, is_centered: isCentered },
}) => (
  <TitleWrapper background={background} isCentered={isCentered}>
    <NewRichText text={title} />
  </TitleWrapper>
);

export default QuestionTitle;
