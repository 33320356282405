import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  ColumnsContainer,
  Content,
  StyledBackground,
  TitleWrapper,
} from './styledComponents';
import { INewTextImageColumn } from './types';
import { RichText } from 'components/v2/RichText';

const NewTextImageColumn: React.FC<INewTextImageColumn> = ({
  primary: {
    background,
    is_text_light: isTextLight,
    main_title: mainTitle,
    number_of_columns: numbOfColumns,
  },
  items,
}) => (
  <StyledBackground background={background}>
    <TitleWrapper text={mainTitle} isTextLight={isTextLight}>
      <RichText field={mainTitle} />
    </TitleWrapper>
    <ColumnsContainer numbOfColumns={numbOfColumns || 3}>
      {items.map(({ description, icon, small_title: smallTitle }) => (
        <Content isTextLight={isTextLight} key={smallTitle.text}>
          <GatsbyImg image={icon} />
          <RichText field={smallTitle} />
          <RichText field={description} />
        </Content>
      ))}
    </ColumnsContainer>
  </StyledBackground>
);

export default NewTextImageColumn;
