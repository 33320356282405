import { IconContactSalesv2, IconMoneyBackv2 } from 'images';
import {
  CardTitle,
  CtaContainer,
  MoneyBack,
  PricingCardContainer,
  StyledCta,
  PriceWrapper,
  ExtraDescription,
} from './styled';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';

export type ExtraPricingCardProps = {
  title: string;
  icon: React.ReactNode;
  description: string;
  buttonText: string;
  buttonLink: string;
  moneyBackText: string;
};

export default function ExtraPricingCard({
  title,
  icon,
  description,
  buttonText,
  buttonLink,
  moneyBackText,
}: ExtraPricingCardProps) {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });

  const trackId = `ContactUs-pricing-cards`;
  function handleTracking() {
    logPostHogEvent(trackingSelector({ context: trackId }));
    if (sliceId) {
      logGAElementClickEvent({
        elementId: trackId,
        sectionId: sliceId,
      });
    }
  }

  return (
    <PricingCardContainer>
      <CardTitle>{title}</CardTitle>

      <PriceWrapper style={{ height: 'auto' }}>{icon}</PriceWrapper>

      <ExtraDescription>{description}</ExtraDescription>

      <CtaContainer>
        <StyledCta
          isButtonBlue={false}
          ctaText={buttonText}
          ctaLink={buttonLink}
          isVisible={true}
          themeName={BUTTON_THEMES.DEFAULT}
          themeType={BUTTON_TYPES.SECONDARY}
          icon={<IconContactSalesv2 />}
          onClick={handleTracking}
          dataTracker={trackingSelector({ context: trackId })}
        />
      </CtaContainer>
      <MoneyBack>
        <IconMoneyBackv2 /> <p>{moneyBackText}</p>
      </MoneyBack>
    </PricingCardContainer>
  );
}
