import styled from '@emotion/styled';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import {
  COMPACT_HEADER_MEDIA_QUERY,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'features/v2/Header/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeGutter,
  ITheme,
  TEXT_OVERFLOW,
} from 'theme';
import { IColor } from 'types';

export const flexRowContainerStyles = {
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.ROW,
};
export const flexColContainerStyles = {
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
};

export const HeadingsSyles = {
  width: '100%',
  maxWidth: '1024px',
  textAlign: TEXT_ALIGN.CENTER,
  wordBreak: 'break-word',
};

export const FontsTypesStyles = {
  'strong, a, em': {
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  },
};

export const ListItemStyles = {
  ...FontsTypesStyles,
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto 12px',
  fontSize: '18px',
  lineHeight: '27px',
  fontWeight: 400,
  padding: 0,
};

export const flexShrinkBasisStyles = {
  flexShrink: 0,
  flexBasis: 'auto',
};

export const ColContainer = styled.div({
  ...flexColContainerStyles,
});
export const RowContainer = styled.div({
  ...flexRowContainerStyles,
});

export const FullSizeFlex = styled.div({
  flex: 1,
});

export const BackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    maxWidth: '1024px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
    width: '100%',
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
  })
);

export const Wrapper = styled.main<{ sidePadding?: string }>(
  ({ sidePadding = '0' }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    padding: `${HEADER_HEIGHT_DESKTOP}px ${sidePadding} 0`,
    width: '100%',

    [COMPACT_HEADER_MEDIA_QUERY]: {
      padding: `${HEADER_HEIGHT_MOBILE}px ${sidePadding} 0px`,
    },

    // TODO(https://datatroops.atlassian.net/browse/WS-2552): Remove this when new hero is applied everywhere
    '[data-section-name=hero]': {
      marginTop: -HEADER_HEIGHT_DESKTOP,
      [COMPACT_HEADER_MEDIA_QUERY]: {
        marginTop: -HEADER_HEIGHT_MOBILE,
      },
    },
  })
);

export const BadgeBackgroundImage = {
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: '30px',
};

export const BadgeBackgroundImageSmall = {
  ...BadgeBackgroundImage,
  height: '143px',
};

export const HideOnMobile = {
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.NONE,
  },
};

export const textEllipsisStyles = {
  textOverflow: TEXT_OVERFLOW.ELLIPSIS,
  display: CSS_DISPLAY.WEBKIT_BOX,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
};
