import { FOOTER_LINKS, PRISMIC_VALUES } from 'constants/common';
import {
  IconDiscordv2,
  IconGithubv2,
  IconLinkedinv2,
  IconMailv2,
  IconSkypev2,
  IconSupport,
  IconTelegramv2,
  IconYoutubev2,
} from 'images';
import { ActionContainer, ActionsWrapper } from '../styledComponents';
import ActionItem from './ActionItem';
import FooterLogo from './FooterLogo';

const Actions = ({ isDealsOrLpPage }) => {
  const renderConnectImage = (key) => {
    const connectImages = {
      mail: IconMailv2,
      chat: IconSupport,
      skype: IconSkypev2,
      telegram: IconTelegramv2,
    };
    const ConnectImage = connectImages[key];
    return <ConnectImage />;
  };
  const renderSocialImage = (key) => {
    const socialImages = {
      youtube: IconYoutubev2,
      github: IconGithubv2,
      linkedin: IconLinkedinv2,
      discord: IconDiscordv2,
    };

    const SocialImage = socialImages[key];

    return <SocialImage />;
  };
  return (
    <ActionsWrapper>
      <FooterLogo />
      <ActionContainer isDealsOrLpPage={isDealsOrLpPage}>
        <ActionItem
          isDealsOrLpPage={isDealsOrLpPage}
          sectionName="connect"
          icons={renderConnectImage}
          title={PRISMIC_VALUES.GET_IN_TOUCH}
          links={FOOTER_LINKS.connect}
        />
        <ActionItem
          isDealsOrLpPage={isDealsOrLpPage}
          sectionName="follow"
          icons={renderSocialImage}
          title={PRISMIC_VALUES.FOLLOW}
          links={FOOTER_LINKS.social}
        />
      </ActionContainer>
    </ActionsWrapper>
  );
};

export default Actions;
