import { MAX_ALLOWED_RELATED_JOBS } from './constants';
import { IJob, IPrismicJob } from './types';

export const transformRawJob = (rawJob: IPrismicJob): IJob => {
  return {
    location: rawJob.data.location,
    title: rawJob.data.title,
    type: rawJob.data.type,
    id: rawJob.id,
    url: rawJob.url,
  };
};

export const prepareJobsFromSelectedAndRecents = ({
  selectedJobs = [],
  recentJobs = [],
  currentJobId,
}: {
  selectedJobs: IJob[];
  recentJobs: IJob[];
  currentJobId?: IJob['id'];
}) => {
  const relatedJobs: IJob[] = [];

  if (!currentJobId) return recentJobs;

  const jobsExcludingCurrent = [...selectedJobs, ...recentJobs].filter(
    (job) => job.id !== currentJobId
  );

  for (const job of jobsExcludingCurrent) {
    if (relatedJobs.length >= MAX_ALLOWED_RELATED_JOBS) return relatedJobs;
    relatedJobs.push(job);
  }

  return relatedJobs;
};
