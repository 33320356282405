import React from 'react';
import theme from 'theme';
import { StyledRadialProgress } from './styledComponents';
import { IRadialProgressProps } from './types';

export const RadialProgress: React.FC<IRadialProgressProps> = ({
  progress,
  size = 20,
  color = theme.colors.main.black,
  trackColor = theme.colors.neutral.invisible,
  trackWidth = 4,
}) => {
  const center = size / 2;
  const radius = center - trackWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const filledCircumference = (1 - progress) * circumference;

  return (
    <StyledRadialProgress
      size={size}
      trackWidth={trackWidth}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        r={radius}
        cx={center}
        cy={center}
        fill="transparent"
        stroke={trackColor}
      ></circle>
      <circle
        r={radius}
        cx={center}
        cy={center}
        fill="transparent"
        strokeDasharray={circumference}
        strokeDashoffset={filledCircumference}
        stroke={color}
      ></circle>
    </StyledRadialProgress>
  );
};
