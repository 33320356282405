import { NewRichText } from 'components/PrismicRichText';
import { enhanceWithLazyLoading } from 'helpers/lazyLoadHelper';
import useIntersection from 'hooks/useIntersection';
import React, { useRef } from 'react';
import { IScreenProps } from 'utils/screenTypeHOC';
import CtaContainer from './components/CtaContainer';
import LoaderContainer from './components/SpinerContainer';
import {
  StyledWrapper,
  VideoComponent,
  VideoContainer,
  VideoStreamingWrapper,
} from './styledComponents';
import { IVideo } from './types';
import { usePageContext } from 'contexts/PageContext';

const VideoStreaming: React.FC<IVideo & IScreenProps> = ({
  primary: {
    title,
    embed_code: video,
    link_label_one: labelOne,
    link_label_two: labelTwo,
    link_to_label_one: linkOne,
    link_to_label_two: linkTwo,
  },
}) => {
  const videoContainerEl = useRef<HTMLDivElement>(null);
  const intersectionObserverEntry = useIntersection(videoContainerEl, {
    fireOnce: true,
  });
  const { tags, type } = usePageContext();

  return (
    <VideoStreamingWrapper>
      <StyledWrapper>
        <NewRichText text={title} />
        <VideoContainer ref={videoContainerEl}>
          <VideoComponent
            dangerouslySetInnerHTML={
              intersectionObserverEntry
                ? {
                    __html: enhanceWithLazyLoading(video),
                  }
                : undefined
            }
          ></VideoComponent>
          {!intersectionObserverEntry && <LoaderContainer />}
        </VideoContainer>
        <CtaContainer
          labelOne={labelOne}
          labelTwo={labelTwo}
          linkOne={linkOne}
          linkTwo={linkTwo}
          tags={tags}
          type={type}
        />
      </StyledWrapper>
    </VideoStreamingWrapper>
  );
};

export default VideoStreaming;
