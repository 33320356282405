import styled from '@emotion/styled';
import { StyledBaseSection } from 'components/Section';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { getThemeGutter, ITheme } from 'theme';
import { IColor } from 'types';

export const TitleWrapper = styled(StyledBaseSection)<
  IColor & ITheme & { isCentered: boolean }
>(({ background, isCentered, ...props }) => ({
  background,
  padding: '80px 20px 60px',
  width: '100%',
  'h1, h2, h3,h4, h5, h6': {
    margin: '0 auto',
    textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    paddingTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));
