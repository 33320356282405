import { NewRichText } from 'components/PrismicRichText';
import React, { useEffect, useRef } from 'react';
import { PrismicInputField, PrismicTextField } from 'types';
import {
  StyledVideoWrapper,
  StyledWrapper,
  VideoBlockWrapper,
} from './styledComponents';

interface IVideoNoCode {
  primary: {
    title: PrismicTextField;
    description?: PrismicTextField;
    video_link: PrismicInputField;
    background?: string;
  };
}

const VideoNoCode: React.FC<IVideoNoCode> = ({
  primary: { title, description, video_link: video, background },
}) => {
  const vidRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    vidRef.current?.play();
  }, []);

  return (
    <VideoBlockWrapper background={background}>
      <StyledWrapper>
        <NewRichText text={title} />
        <NewRichText text={description} />
        <StyledVideoWrapper ref={vidRef} muted loop playsInline>
          <source type="video/mp4" src={video.url} />
        </StyledVideoWrapper>
      </StyledWrapper>
    </VideoBlockWrapper>
  );
};

export default VideoNoCode;
