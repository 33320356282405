import { ThemeProvider } from '@emotion/react';
import { globalStyle } from 'globalStyles';
import theme from 'theme';
import { css, Global } from '@emotion/react';

export const GlobalStyleWrapper = ({ children }: React.PropsWithChildren) => (
  <ThemeProvider theme={theme}>
    <Global
      styles={css`
        ${globalStyle}
      `}
    />
    {children}
  </ThemeProvider>
);

export default GlobalStyleWrapper;
