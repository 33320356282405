import { graphql, useStaticQuery } from 'gatsby';
import { AnchorHTMLAttributes } from 'react';
import { defaultNofollowExclusions } from './constants';
import { stripUrlProtocol } from '@smartproxy-web/shared/utils';

interface PrismicLinkConfig {
  prismicLinkConfig?: {
    data?: LinkConfig;
  };
}

interface LinkConfig {
  nofollow_exclusions: { domain: string }[];
}

export const transformRawConfig = (
  rawConfig: PrismicLinkConfig
): LinkConfig => {
  if (!rawConfig?.prismicLinkConfig?.data)
    return {
      nofollow_exclusions: defaultNofollowExclusions,
    };
  return {
    nofollow_exclusions: defaultNofollowExclusions.concat(
      rawConfig.prismicLinkConfig.data.nofollow_exclusions
    ),
  };
};

export const useLinkConfig = () => {
  const rawConfig = useStaticQuery(
    graphql`
      query LinkConfig {
        prismicLinkConfig {
          data {
            nofollow_exclusions {
              domain
            }
          }
        }
      }
    `
  ) satisfies PrismicLinkConfig;
  const config = transformRawConfig(rawConfig);

  const getFollowStatus = (
    urlString?: string
  ): AnchorHTMLAttributes<'a'>['rel'] => {
    if (!urlString?.startsWith('http')) return;

    const isUrlExcluded = config.nofollow_exclusions.some(
      ({ domain: excludedUrlString }) => {
        const normalizedUrlString = stripUrlProtocol(urlString);
        const normalizedExcludedUrlString = stripUrlProtocol(excludedUrlString);

        try {
          const url = new URL(`http://${normalizedUrlString}`);
          const excludedUrl = new URL(`http://${normalizedExcludedUrlString}`);

          const doDomainsMatch = url.hostname === excludedUrl.hostname;
          const doPathnamesPartiallyMatch = url.pathname.startsWith(
            excludedUrl.pathname
          );

          return doDomainsMatch && doPathnamesPartiallyMatch;
        } catch (error) {
          return false;
        }
      }
    );
    if (isUrlExcluded) return;
    return 'nofollow';
  };

  return { getFollowStatus };
};
