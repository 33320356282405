import styled from '@emotion/styled';
import Button, {
  BUTTON_THEMES,
  BUTTON_TYPES,
  TButton,
} from 'components/Button';
import { TEXT_ALIGN } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import { CONTACT_SALES_URL_HASH, SIGNUP_URL } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { clearPlanCookies } from 'helpers/cookiesHelper';
import React from 'react';
import hideable from 'utils/hideable';
import { ICta } from '../types';
import { TARGET } from './constants';
import { GatsbyLink } from '@smartproxy-web/features/src/components/GatsbyLink';

const StyledExternal = styled(GatsbyLink)({
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '50%',
    margin: '20px 0 5px 0',
    button: {
      width: '100%',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
});

const HideableExternal = hideable(StyledExternal);
const StyledInternal = hideable(StyledExternal.withComponent(Link));

const Cta: React.FC<ICta & TButton> = ({
  ctaText,
  ctaLink,
  onClick,
  isExternal,
  themeName = BUTTON_THEMES.CONVERSION,
  themeType = BUTTON_TYPES.PRIMARY,
  target: targetType = TARGET.BLANK,
  linkId,
  linkClassName,
  icon,
  dataTracker,
  sendTrackingEvent,
  ...props
}) => {
  const isSalesLink = !!ctaLink
    ? ctaLink?.includes(CONTACT_SALES_URL_HASH)
    : false;

  const target = isSalesLink ? TARGET.SELF : targetType;
  const url = ctaLink || SIGNUP_URL;

  const updatedLink = url.includes('https://#contact-sales')
    ? '#contact-sales'
    : url;

  return (
    <>
      <HideableExternal
        target={target}
        to={updatedLink}
        id={linkId}
        className={linkClassName}
        data-tracker={dataTracker}
        isVisible={isSalesLink || isExternal}
        onClick={sendTrackingEvent}
      >
        <Button
          {...props}
          themeName={themeName}
          themeType={themeType}
          onClick={onClick || clearPlanCookies}
        >
          <>
            {ctaText}
            {icon && icon}
          </>
        </Button>
      </HideableExternal>
      <StyledInternal
        id={linkId}
        className={linkClassName}
        to={url}
        isVisible={!isSalesLink && !isExternal}
        data-tracker={dataTracker}
        onClick={sendTrackingEvent}
      >
        <Button
          {...props}
          themeName={themeName}
          themeType={themeType}
          onClick={onClick || clearPlanCookies}
        >
          <>
            {ctaText}
            {icon && icon}
          </>
        </Button>
      </StyledInternal>
    </>
  );
};

export default hideable(Cta);
