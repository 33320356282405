import { track } from '@smartproxy/sp-tracker-js';
import { INPUT_TYPE } from 'components/constants';
import { serpSplashScreenLabels } from 'features/ScrapersPlayground/config/common';
import { FormSelectTarget } from 'features/ScrapersPlayground/styledComponents';
import { IScraperTargetConfig } from 'features/ScrapersPlayground/types';
import { IconArrowNav, IconInfo } from 'images';
import { getListItemByKey } from 'utils/common';
import FormSelectDropdown from '../Form/FormSelectDropdown';
import InfoPopupContainer from '../Form/InfoPopupContainer';
import { LocationsDropdownOption } from '../LocationsDropdownOption';
import { TargetsDropdownOption } from '../TargetsDropdownOption';
import {
  StyledSplashScreenForm,
  StyledSplashScreenFormInput,
  StyledSplashScreenFormInputContainer,
  StyledSplashScreenIconInfoContainer,
  StyledSplashScreenSubmitBtn,
} from './styledComponents';
import { ISerpSplashScreenFormProps } from './types';
import { normalizeString } from '@smartproxy-web/shared/utils';

const SerpSplashScreenForm = ({
  targets,
  activeTarget,
  handleActiveTargetChange,
  onSubmit,
  setFormField,
  isSubmitDisabled,
  isLoading,
  isTargetValuesVisible,
  formField,
  isLocationValuesVisible,
  isPopupVisible,
  setIsLocationValuesVisible,
  setIsTargetValuesVisible,
  setIsPopupVisible,
  buildClickSelector,
}: ISerpSplashScreenFormProps) => {
  const selectedGeo = activeTarget.locations.find(
    (location) => location.value === formField.geo
  );
  const handleClick = () => {
    setIsPopupVisible((prevState) => !prevState);
    track(
      buildClickSelector({
        context: 'targets-info-popup',
        type: 'trigger',
      })
    );
  };
  return (
    <StyledSplashScreenForm noValidate autoComplete="off" onSubmit={onSubmit}>
      <StyledSplashScreenFormInputContainer>
        <StyledSplashScreenIconInfoContainer
          onClick={handleClick}
          data-tracker={buildClickSelector({
            context: 'targets-info-popup',
            type: 'trigger',
          })}
        >
          <IconInfo />
        </StyledSplashScreenIconInfoContainer>
        <FormSelectTarget
          isLocationsSelect={false}
          onClick={() => {
            setIsTargetValuesVisible(!isTargetValuesVisible);
            setIsLocationValuesVisible(false);
            track(
              buildClickSelector({
                context: 'target-dropdown',
                type: 'trigger',
              })
            );
          }}
          valuesVisible={isTargetValuesVisible}
          data-tracker={buildClickSelector({
            context: 'target-dropdown',
            type: 'trigger',
          })}
        >
          {activeTarget.label}
          <IconArrowNav />
        </FormSelectTarget>
        <FormSelectDropdown
          isVisible={isTargetValuesVisible}
          options={targets.map((target) => ({
            value: target.value,
            label: target.label,
          }))}
          onChange={(target) => {
            handleActiveTargetChange(
              targets.find((targetItem) => targetItem.value === target)
            );
            setFormField({ ...formField, target });
            setIsTargetValuesVisible(false);
          }}
          selected={formField.target}
          renderOption={(option) => (
            <TargetsDropdownOption
              target={
                getListItemByKey(
                  targets,
                  'value',
                  option.value
                ) as IScraperTargetConfig
              }
            />
          )}
          buildClickSelector={buildClickSelector}
        />
      </StyledSplashScreenFormInputContainer>
      <StyledSplashScreenFormInputContainer>
        <InfoPopupContainer
          isVisible={isPopupVisible}
          title={activeTarget.info.title}
          info={activeTarget.info.description}
          dataTracker={`${normalizeString(
            activeTarget.shortLabel ?? ''
          )}-popup`}
        />
        <StyledSplashScreenIconInfoContainer
          data-tracker={buildClickSelector({
            context: 'targets-info-popup',
            type: 'trigger',
          })}
          onClick={handleClick}
        >
          <IconInfo />
        </StyledSplashScreenIconInfoContainer>
        <StyledSplashScreenFormInput
          placeholder={activeTarget.formConfig.queryField.placeholder}
          value={formField.query}
          disabledSubmitButton={false}
          onChange={(e) =>
            setFormField({ ...formField, query: e.target.value })
          }
          data-tracker={buildClickSelector({
            context: activeTarget.formConfig.queryField.key,
            type: 'field',
          })}
          onClick={() =>
            track(
              buildClickSelector({
                context: activeTarget.formConfig.queryField.key,
                type: 'field',
              })
            )
          }
        />
        <FormSelectTarget
          isLocationsSelect
          onClick={() => {
            setIsTargetValuesVisible(false);
            setIsLocationValuesVisible(!isLocationValuesVisible);
            track(
              buildClickSelector({
                context: 'location',
                type: 'field',
              })
            );
          }}
          valuesVisible={isTargetValuesVisible}
          data-tracker={buildClickSelector({
            context: 'location',
            type: 'field',
          })}
        >
          {selectedGeo?.shortLabel}
          <IconArrowNav />
        </FormSelectTarget>
        <FormSelectDropdown
          isVisible={isLocationValuesVisible}
          options={activeTarget.locations}
          onChange={(geo) => {
            setFormField({ ...formField, geo });
            setIsLocationValuesVisible(false);
          }}
          selected={formField.geo as string}
          renderOption={(option) => (
            <LocationsDropdownOption location={option} />
          )}
          buildClickSelector={buildClickSelector}
        />
      </StyledSplashScreenFormInputContainer>
      <StyledSplashScreenSubmitBtn
        type={INPUT_TYPE.SUBMIT}
        data-tracker={buildClickSelector({
          context: 'submit',
          type: 'button',
        })}
        onClick={() =>
          track(
            buildClickSelector({
              context: 'submit',
              type: 'button',
            })
          )
        }
        disabled={isSubmitDisabled || isLoading}
      >
        {isLoading
          ? serpSplashScreenLabels.submit_btn_loading
          : serpSplashScreenLabels.submit_btn_text}
      </StyledSplashScreenSubmitBtn>
    </StyledSplashScreenForm>
  );
};

export default SerpSplashScreenForm;
