import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import { ColumnsTextContainer, ColumnsTextWrapper } from './styledComponents';
import { IColumnsTextBlock } from './types';

const ColumnsTextBlock: React.FC<IColumnsTextBlock> = ({
  primary: { columns_number: columns },
  items,
}) => (
  <ColumnsTextWrapper>
    <ColumnsTextContainer columns={columns}>
      {items.map(({ text }, idx) => (
        <NewRichText key={idx} text={text} />
      ))}
    </ColumnsTextContainer>
  </ColumnsTextWrapper>
);

export default ColumnsTextBlock;
