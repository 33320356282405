import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { IPaymentMethod } from './types';
import { Wrapper } from '../BiggerPlansBanner/styledComponents';
import {
  PaymentMethodContainer,
  PaymentMethods,
  ListItem,
  SecurityContainer,
} from './styledComponents';

import { generateSectionsId } from 'features/utils';
import { SECTION_NAMES } from 'constants/common';
import { RichText } from 'components/v2/RichText';

const PaymentMethod: React.FC<IPaymentMethod> = ({
  primary: {
    security_icon: securityIcon,
    security_title: securityTitle,
    security_description: securityDescription,
    background,
  },
  items,
  pageContext: { tags, type },
}) => (
  <Wrapper
    background={background}
    data-section-id={generateSectionsId(
      SECTION_NAMES.PAYMENT_METHOD,
      tags,
      type
    )}
  >
    <PaymentMethodContainer>
      <SecurityContainer>
        <GatsbyImg image={securityIcon} />
        <div>
          <RichText field={securityTitle} />
          <RichText field={securityDescription} />
        </div>
      </SecurityContainer>
      <div>
        <PaymentMethods>
          {items.map(({ payment_method_icon: paymentMethodIcon }, idx) => (
            <ListItem key={idx}>
              <GatsbyImg image={paymentMethodIcon} />
            </ListItem>
          ))}
        </PaymentMethods>
      </div>
    </PaymentMethodContainer>
  </Wrapper>
);

export default PaymentMethod;
