import { track } from '@smartproxy/sp-tracker-js';
import { WEBINAR_FORM } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { useWebinarForm } from '../hooks';
import { ErrorMessage, FormButton, StyledForm } from '../styledComponents';
import { FIELD_NAMES, IForm } from '../types';
import InputLabelFields from './InputLabelFields';
import SuccessMessage from './SuccessMessage';

const Form: React.FC<IForm> = ({ info }) => {
  const {
    isSuccess,
    errorMessage,
    buttonText,
    isNameFieldCorrect,
    isEmailFieldCorrect,
    handleChange,
    handleForm,
    handleClose,
    isLoading,
    setTouched,
    touched,
    formField,
  } = useWebinarForm(info);
  const { type, tags } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'webinar-registration',
    prefix: type,
  });

  return (
    <>
      <StyledForm noValidate onSubmit={handleForm} isLoading={isLoading}>
        <InputLabelFields
          label={WEBINAR_FORM.NAME}
          type="text"
          name={FIELD_NAMES.NAME}
          value={formField.name}
          required
          isError={isNameFieldCorrect}
          id={trackingSelector({ context: 'name-field' })}
          onBlur={() => setTouched({ ...touched, name: true })}
          onChange={(e) => handleChange(e, FIELD_NAMES.NAME)}
          onClick={() => track(trackingSelector({ context: 'name-field' }))}
        />
        <InputLabelFields
          label={WEBINAR_FORM.EMAIL}
          type={FIELD_NAMES.EMAIL}
          name={FIELD_NAMES.EMAIL}
          value={formField.email}
          required
          isError={isEmailFieldCorrect}
          id={trackingSelector({ context: 'email-field' })}
          onBlur={() => setTouched({ ...touched, email: true })}
          onChange={(e) => handleChange(e, FIELD_NAMES.EMAIL)}
          onClick={() => track(trackingSelector({ context: 'email-field' }))}
        />
        <InputLabelFields
          label={WEBINAR_FORM.COMPANY}
          type="text"
          value={formField.companyName}
          name={FIELD_NAMES.COMPANY_NAME}
          id={trackingSelector({ context: 'company-name-field' })}
          onBlur={() => setTouched({ ...touched, companyName: true })}
          onChange={(e) => handleChange(e, FIELD_NAMES.COMPANY_NAME)}
          onClick={() =>
            track(trackingSelector({ context: 'company-name-field' }))
          }
        />
        <ErrorMessage data-tracker={errorMessage}>{errorMessage}</ErrorMessage>
        <FormButton
          type="submit"
          id={trackingSelector({ context: 'registration-button' })}
          className={trackingSelector({ context: 'registration-button' })}
          data-tracker={trackingSelector({ context: 'registration-button' })}
          onClick={() =>
            track(trackingSelector({ context: 'registration-button' }))
          }
        >
          {buttonText}
        </FormButton>
      </StyledForm>
      <SuccessMessage handleClose={handleClose} isVisible={isSuccess} />
    </>
  );
};

export default Form;
