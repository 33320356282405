import { TEXT_LENGTH_VALUES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';

import {
  ArticleContainer,
  ArticleDescription,
  ArticleDescriptionContainer,
  ArticleImageWrapper,
  ArticleTitleShorter,
  RelatedArticlesContainer,
  StyledBackgroundWrapper,
} from 'features/ArticleThumbnails/styledComponents';
import { shortenText } from 'features/utils';
import { Link } from 'components/GatsbyLink';
import * as React from 'react';
import { ListCaseItemsContainer, StyledCaseList } from './styledComponents';
import { ICaseList } from './types';
import { buildTrackingSelector } from 'utils/page';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import { themev2 } from 'theme-v2';

const CaseList: React.FC<ICaseList> = ({
  primary: { title, background },
  items,
  pageContext: { tags, type },
}) => (
  <StyledBackgroundWrapper background={background}>
    <RelatedArticlesContainer>
      <StyledCaseList>
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: themev2.spacings.L,
          }}
        >
          <RichText field={title} />
        </Box>
        <ListCaseItemsContainer>
          {items.map(
            (
              {
                case_image: image,
                case_title: caseTitle,
                case_description: description,
                cta_link: link,
                cta_text: ctaText,
              },
              index
            ) => (
              <ArticleContainer key={index}>
                <ArticleImageWrapper>
                  <GatsbyImg image={image} />
                </ArticleImageWrapper>
                <ArticleDescriptionContainer>
                  <ArticleTitleShorter>{caseTitle.text}</ArticleTitleShorter>
                  <ArticleDescription>
                    {shortenText(
                      description.text,
                      TEXT_LENGTH_VALUES.CASE_STUDIES
                    )}
                  </ArticleDescription>
                  <Link
                    className={buildTrackingSelector({
                      page: tags[0] || type,
                      section: caseTitle.text,
                      type: 'link',
                    })}
                    to={link.url}
                  >
                    {ctaText.text}
                  </Link>
                </ArticleDescriptionContainer>
              </ArticleContainer>
            )
          )}
        </ListCaseItemsContainer>
      </StyledCaseList>
    </RelatedArticlesContainer>
  </StyledBackgroundWrapper>
);

export default CaseList;
