import { graphql, useStaticQuery } from 'gatsby';

export const CookieBannerQuery = () =>
  useStaticQuery(
    graphql`
      query CookieBanner {
        prismicCookieBanner {
          data {
            body {
              ... on PrismicCookieBannerDataBodyCookieContent {
                id
                slice_type
                primary {
                  cookie_cta_text {
                    text
                    richText
                  }
                  cookie_link {
                    url
                  }
                  cookie_link_text {
                    richText
                    text
                  }
                  cookie_text {
                    richText
                    text
                  }
                  cookies_icon {
                    alt
                    url
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    `
  );
