import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { appendPlanIdToURL, ctaLinkHandler } from 'features/utils';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { getPlanId } from 'utils/plans';
import { IPricingCard } from '../../types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';
import { Badge, theme } from '@smartproxy-web/ui';
import {
  StyledPricingCardContainer,
  StyledPricingCardContent,
  StyledPricingCardDescription,
  StyledPricingCardHeader,
  StyledPricingCardPerPrice,
  StyledPricingCardPerTotalPrice,
  StyledPricingCardPrice,
  StyledPricingCardPriceInnerWrapper,
  StyledPricingCardPriceWrapper,
  StyledCta,
  StyledPricingCardFlag,
  StyledCtaExternal,
  StyledTableItemNeedMoreDescription,
  StyledTableItemNeedMoreTitle,
  PricingCardNeedMoreWrapper,
  PricingCardNeedMore,
} from 'features/AutomatedPricingCards/ABTest/Version1/styled';

export const PricingCard: React.FC<IPricingCard & { index: number }> = ({
  data: {
    gb_value: gbValue,
    save_percentage: savePercentage,
    new_price: newPrice,
    new_total: newTotal,
    billing_name: billingName,
    cta_label: ctaLabel,
    cta_url: ctaUrl,
    old_total: oldTotal,
    flag_text: flagText,
    product,
    card_plan_name: cardPlanName,
    is_ddc_non_seq: isDDCNonSeq,
  },
  index,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });
  const planId = cardPlanName && getPlanId(cardPlanName, product, isDDCNonSeq);
  const normalizedCtaLink = ctaLinkHandler(ctaUrl.url!);
  const checkedCtaLink =
    !planId || ctaLabel === 'Contact sales'
      ? normalizedCtaLink
      : appendPlanIdToURL(normalizedCtaLink, planId);

  const [extractedPrice] = newTotal.text.split('+');
  const newPriceWithDecimal = newPrice?.includes('.')
    ? newPrice
    : `${newPrice}.0`;

  if (!product) {
    return (
      <StyledPricingCardContainer>
        <PricingCardNeedMoreWrapper>
          <PricingCardNeedMore>
            <StyledTableItemNeedMoreTitle>
              Need a bigger subscription?
            </StyledTableItemNeedMoreTitle>
            <StyledTableItemNeedMoreDescription>
              Chat with us and we’ll find the best solution for you.
            </StyledTableItemNeedMoreDescription>
          </PricingCardNeedMore>

          <StyledCtaExternal>
            <StyledCta
              ctaText={ctaLabel}
              ctaLink={checkedCtaLink}
              isVisible={ctaUrl.url}
              themeName={'default'}
              themeType={'secondary'}
              sendTrackingEvent={() => {
                logPostHogEvent(trackingSelector({ context: cardPlanName }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `${ctaLabel}:${index}`,
                    sectionId: sliceId,
                  });
                }
              }}
              dataTracker={trackingSelector({ context: cardPlanName })}
            />
          </StyledCtaExternal>
        </PricingCardNeedMoreWrapper>
      </StyledPricingCardContainer>
    );
  }

  return (
    <StyledPricingCardContainer
      color={oldTotal && flagText ? theme.colors.neutral.N99 : undefined}
    >
      {oldTotal && flagText && (
        <StyledPricingCardFlag variant="filled" color="black" size="lg">
          {flagText}
        </StyledPricingCardFlag>
      )}

      <StyledPricingCardHeader>
        {!oldTotal && flagText ? (
          <span style={{ textTransform: 'capitalize' }}>
            {flagText.toLowerCase()}
          </span>
        ) : (
          gbValue
        )}
      </StyledPricingCardHeader>

      <StyledPricingCardContent>
        <StyledPricingCardDescription>
          <StyledPricingCardPriceWrapper>
            <StyledPricingCardPriceInnerWrapper>
              <StyledPricingCardPrice>
                ${newPriceWithDecimal}
              </StyledPricingCardPrice>
              <StyledPricingCardPerPrice>
                /{billingName}
              </StyledPricingCardPerPrice>
            </StyledPricingCardPriceInnerWrapper>
            {savePercentage && (
              <Badge color="gradient" variant="outlined" size="lg">
                Save {savePercentage}
              </Badge>
            )}
          </StyledPricingCardPriceWrapper>

          {gbValue === 'No commitment' ? (
            <div>
              <div>{gbValue}</div>
              <StyledPricingCardPerTotalPrice>
                + VAT / Billed per {billingName}
              </StyledPricingCardPerTotalPrice>
            </div>
          ) : (
            <div>
              <div>{extractedPrice} total</div>
              <StyledPricingCardPerTotalPrice>
                + VAT / Billed monthly
              </StyledPricingCardPerTotalPrice>
            </div>
          )}
        </StyledPricingCardDescription>
        <StyledCtaExternal>
          <StyledCta
            isButtonBlue
            ctaText={ctaLabel}
            ctaLink={checkedCtaLink}
            isVisible={ctaUrl.url}
            themeName="default"
            themeType="primary"
            sendTrackingEvent={() => {
              logPostHogEvent(trackingSelector({ context: cardPlanName }));
              if (sliceId) {
                logGAElementClickEvent({
                  elementId: `${ctaLabel}:${index}`,
                  sectionId: sliceId,
                });
              }
            }}
            dataTracker={trackingSelector({ context: cardPlanName })}
          />
        </StyledCtaExternal>
      </StyledPricingCardContent>
    </StyledPricingCardContainer>
  );
};
