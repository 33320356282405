import React from 'react';
import { IScraperTargetConfig } from '../types';

export const TargetsDropdownOption: React.FC<{
  target: IScraperTargetConfig;
}> = ({ target }) => {
  const Icon = target.iconRegular;

  return (
    <span>
      {Icon && <Icon />}
      <span>{target.label}</span>
    </span>
  );
};
