import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { whatIsWebScrapingSliceMap } from './constants';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicWhatIsWebScraping {
  prismicWhatIsWebScraping: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

export interface Props {
  data: IWithPrismicWhatIsWebScraping;
}

const WhatIsWebScraping = ({
  data,
  pageContext,
}: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicWhatIsWebScraping } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicWhatIsWebScraping.data[bodyKey],
    { ...sliceMap, ...whatIsWebScrapingSliceMap }
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicWhatIsWebScraping.tags,
            type: prismicWhatIsWebScraping.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query WhatIsWebScrapingQuery($id: String!) {
    prismicWhatIsWebScraping(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicWhatIsWebScrapingDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyCodeBlock {
            id
            slice_type
            primary {
              copy_option
              code_field {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodySideImageTextBlock {
            id
            slice_type
            primary {
              side_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              text_field {
                richText
                text
              }
              title_field {
                richText
                text
              }
              is_image_right
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyCtaBlock {
            id
            primary {
              is_centered
              background
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyTableOfContent {
            id
            slice_type
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyRelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                richText
                text
              }
            }
            items {
              article {
                document {
                  ...BlogItemFragment
                }
              }
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
            }
          }
          ... on PrismicWhatIsWebScrapingDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyIconListBlock {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
                target
              }
              text_field {
                richText
                text
              }
            }
            primary {
              is_text_centered
              column_number
              list_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsWebScrapingDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(WhatIsWebScraping);
