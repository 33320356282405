import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';

import { usePageContext } from 'contexts/PageContext';
import { useScrollRatio } from 'hooks/useScrollRatio';
import { IconHandSwipe } from 'images';
import React, { useMemo, useRef } from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  StyledContainedContainer,
  StyledContainer,
  StyledHandIconWrapper,
  StyledTable,
  StyledTableBody,
  StyledTableBodyRow,
  StyledTableFooter,
  StyledTableHeader,
  StyledTableWrapper,
  Subtitle,
  TitleWrapper,
} from './styledComponents';
import { TableBodyRowCell } from './TableBodyRowCell';
import { IFeatureComparisonTableProps } from './types';
import { generateTableFromCells } from './utils';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const HideableSubtitle = hideable(Subtitle);

const FeatureComparisonTable: React.FC<IFeatureComparisonTableProps> = ({
  items,
  primary: {
    background,
    title,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
  },
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const tableElRef = useRef<HTMLDivElement>(null);

  const { columns, rows } = useMemo(() => generateTableFromCells(items), []);

  const tableScrollX = useScrollRatio({
    el: tableElRef.current,
    direction: 'x',
  });

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'feature-comparison-table',
    prefix: type,
  });

  return (
    <StyledContainer background={background}>
      <StyledContainedContainer>
        <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
          <RichText field={title} />
        </TitleWrapper>
        <HideableSubtitle
          isVisible={subtitle?.text}
          isSubtitleCentered={isSubtitleCentered}
        >
          {!!subtitle && (
            <Box
              sx={{
                marginBottom: '2rem',
              }}
            >
              <RichText field={subtitle} />
            </Box>
          )}
        </HideableSubtitle>
        <StyledTableWrapper background={background} scrollX={tableScrollX}>
          <StyledTable ref={tableElRef}>
            <StyledTableHeader noOfCols={columns.length}>
              {columns.map((column, colIndex) => (
                <div key={column.value?.text || colIndex}>
                  <RichText field={column.value} />
                </div>
              ))}
            </StyledTableHeader>
            <StyledTableBody>
              {rows.map((row, rowIndex) => (
                <StyledTableBodyRow key={rowIndex} noOfCols={columns.length}>
                  {row.map(
                    (cell, cellIndex) =>
                      cell && (
                        <TableBodyRowCell
                          key={cell.cell_value?.text || cellIndex}
                          cell={cell}
                        />
                      )
                  )}
                </StyledTableBodyRow>
              ))}
            </StyledTableBody>
            <StyledTableFooter noOfCols={columns.length}>
              {columns.map(({ action }, colIndex) => (
                <div key={colIndex}>
                  {action && (
                    <Cta
                      linkClassName={trackingSelector({
                        context: action.label,
                      })}
                      dataTracker={trackingSelector({ context: action.label })}
                      sendTrackingEvent={() => {
                        logPostHogEvent(
                          trackingSelector({ context: action.label })
                        );
                        if (sliceId) {
                          logGAElementClickEvent({
                            elementId: action.label,
                            sectionId: sliceId,
                          });
                        }
                      }}
                      ctaText={action.label}
                      ctaLink={action.url}
                      height={TSHIRT_SIZE.L}
                      width={TSHIRT_SIZE.XL}
                      themeName={BUTTON_THEMES.IMPORTANT}
                      themeType={BUTTON_TYPES.PRIMARY}
                      isVisible
                    />
                  )}
                </div>
              ))}
            </StyledTableFooter>
          </StyledTable>
        </StyledTableWrapper>

        <StyledHandIconWrapper>
          {tableScrollX != -1 ? <IconHandSwipe /> : null}
        </StyledHandIconWrapper>
      </StyledContainedContainer>
    </StyledContainer>
  );
};

export default FeatureComparisonTable;
