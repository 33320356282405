import { scrollSmooth, scrollSmoothUnset } from 'features/utils';
import { IconBlueDot } from 'images';
import React from 'react';
import {
  StyledToc,
  StyledTocUl,
  TocListItem,
  StyledLink,
} from './styledComponents';
import { ITableOfContent } from './types';
import { RichText } from 'components/v2/RichText';

const TableOfContent: React.FC<ITableOfContent> = ({
  primary: { table_title },
  items,
}) => (
  <StyledToc>
    <RichText field={table_title} />
    <StyledTocUl rows={items.length}>
      {items.map(({ link_to: linkTo, link_label: linkLabel }, idx) => (
        <TocListItem key={idx}>
          <IconBlueDot />
          <StyledLink
            to={linkTo.url}
            onMouseLeave={scrollSmoothUnset}
            onMouseDown={scrollSmooth}
          >
            {linkLabel.text}
          </StyledLink>
        </TocListItem>
      ))}
    </StyledTocUl>
  </StyledToc>
);

export default TableOfContent;
