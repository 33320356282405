import { track } from '@smartproxy/sp-tracker-js';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import { useState } from 'react';
import { defaultScraperErrorMessage } from './constants';
import { IScraperRequestLanguageConfig, IScraperTargetConfig } from './types';

export const useScraperForm = (targetConfig: IScraperTargetConfig) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isParametersVisible, setIsParametersVisible] = useState(false);
  const [isTargetValuesVisible, setIsTargetValuesVisible] = useState(false);
  const [isLocationValuesVisible, setIsLocationValuesVisible] = useState(false);

  const [formField, setFormField] = useState({
    target: targetConfig.value,
    query: '',
    geo: targetConfig.locations[0].value,
  });

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [isResponseWindowVisible, setResponseWindowVisible] = useState(false);
  const [responseValue, setResponseValue] = useState('');
  const { request, isLoading } = useApi(
    HTTP_METHOD.POST,
    targetConfig.formConfig.action,
    {
      'Content-Type': 'application/json',
    }
  );

  const submitForm = async (e) => {
    e.preventDefault();

    setIsLocationValuesVisible(false);
    setIsTargetValuesVisible(false);

    if (!!formField.query) {
      const { query, ...reqBody } = formField;

      await request({
        body: {
          ...targetConfig.requestParams.node,
          ...reqBody,
          [targetConfig.formConfig.queryField.key]: query,
        },
        onSuccess: (data) => {
          if (data) {
            setResponseValue(JSON.stringify(data, null, '  '));
          }
        },
        onError: (error) => {
          setResponseValue(() => {
            if (error) {
              return JSON.stringify(error, null, '  ');
            } else {
              return defaultScraperErrorMessage;
            }
          });
        },
        onFinally: () => {
          setResponseWindowVisible(true);
        },
      });
    } else {
      setSubmitDisabled(true);
    }
  };

  return {
    isPopupVisible,
    setIsPopupVisible,
    isParametersVisible,
    setIsParametersVisible,
    isTargetValuesVisible,
    setIsTargetValuesVisible,
    isLocationValuesVisible,
    setIsLocationValuesVisible,
    setFormField,
    isSubmitDisabled,
    isResponseWindowVisible,
    setResponseWindowVisible,
    isLoading,
    setSubmitDisabled,
    submitForm,
    formField,
    responseValue,
  };
};

export interface IUseResReqWindow {
  url: string;
  activeTarget: IScraperTargetConfig;
  geo: string;
  languages: IScraperRequestLanguageConfig[];
  setResponseWindowVisible?: () => void;
}

export const useResponseRequestWindow = ({
  url,
  activeTarget,
  geo,
  languages,
  setResponseWindowVisible = () => {},
}: IUseResReqWindow) => {
  const [selectedLangIndex, setSelectedLangIndex] = useState<number>(0);
  const [isWindowClosed, setIsWindowClosed] = useState(false);

  const selectedLang = languages[selectedLangIndex];

  const template = selectedLang?.template
    ? selectedLang.template({
        query: url,
        target: activeTarget.value,
        geo,
        params: activeTarget.requestParams[selectedLang.value],
      })
    : JSON.stringify(activeTarget.responseSchema, null, '  ');

  const handleLanguageSelect = (index) => {
    setSelectedLangIndex(index);
    setIsWindowClosed(false);
    setResponseWindowVisible();
  };

  const handleRequestClose = (trackingSelector) => {
    setIsWindowClosed(true);
    setSelectedLangIndex(-1);
    track(trackingSelector);
  };

  return {
    selectedLangIndex,
    isWindowClosed,
    requestValue: template,
    handleLanguageSelect,
    handleRequestClose,
  };
};
