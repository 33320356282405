import { TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import GatsbyImg from 'components/GatsbyImg';
import { useScroll } from 'hooks/useScrollBlock';
import React, { useState } from 'react';
import { OFFSET_START, SCROLL_POSITION } from './constants';
import {
  CloseIcon,
  Content,
  Description,
  HideableContainer,
  ImageWrapper,
  Title,
} from './styledComponents';
import { TDialogContainer } from './types';
import { RichText } from 'components/v2/RichText';

const DialogContainer: React.FC<TDialogContainer> = ({
  cta_link: ctaLink,
  cta_text: ctaText,
  description,
  image,
  title,
  theme,
  ctaId,
  ctaClassName,
  closeBtnClassName,
  isVisible,
  setIsDialogOpen,
  onClose,
}) => {
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState(OFFSET_START);

  const onScroll = () => {
    const offsetButton = window.pageYOffset / -OFFSET_START + OFFSET_START;
    const dialogVisible = window.pageYOffset >= SCROLL_POSITION;
    const dialogOpacity =
      (window.pageYOffset - SCROLL_POSITION) / SCROLL_POSITION;
    setIsDialogOpen(dialogVisible);
    setOpacity(dialogOpacity);
    if (offsetButton < 0) {
      setOffset(0);
    } else {
      setOffset(offsetButton);
    }
  };

  useScroll(isVisible, onScroll);

  return (
    <HideableContainer
      opacity={opacity}
      offset={Math.round(offset)}
      isVisible={isVisible}
    >
      <CloseIcon onClick={onClose} className={closeBtnClassName} />
      <ImageWrapper>
        <GatsbyImg image={image} />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Description>
          <RichText field={description} />
        </Description>
      </Content>
      <Cta
        linkId={ctaId}
        linkClassName={ctaClassName}
        sidePadding="12px"
        fontSize={TSHIRT_SIZE.L}
        ctaText={ctaText}
        ctaLink={ctaLink.url}
        themeName={theme}
        isVisible={ctaText && ctaLink.url}
      />
    </HideableContainer>
  );
};

export default DialogContainer;
