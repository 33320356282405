export const buildReqBodyFromMap = (
  params: Record<string, unknown>,
  padding = 4
) => {
  let code = '';

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];

      const paddedSpace = ''.padStart(padding);

      const formattedValue = typeof value === 'string' ? `"${value}"` : value;

      if (key === 'parse') {
        code += `\n${paddedSpace}"${key}": ${value},`;
        continue;
      }

      code += `\n${paddedSpace}"${key}": ${formattedValue},`;
    }
  }

  return code;
};
