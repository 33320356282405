import styled from '@emotion/styled';
import { HideOnMobile } from 'commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { IconCloseDark } from 'images';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_POSITION,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';
import { TContainer } from './types';
import { themev2 } from 'theme-v2';

const Container = styled.div<TContainer>(({ opacity, offset, ...props }) => ({
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  backgroundColor: getThemeMainColor(props),
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 12%)',
  transform: `translateY(${offset}px)`,
  transition: 'transform 0.1s linear',
  alignItems: CSS_ALIGN.CENTER,
  position: CSS_POSITION.FIXED,
  display: CSS_DISPLAY.FLEX,
  margin: '0 0 30px 30px',
  borderRadius: '12px',
  zIndex: 102,
  bottom: 0,
  left: 0,
  opacity,
  ...HideOnMobile,
}));

export const ImageWrapper = styled.div<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  height: '70px',
  width: '70px',
}));

export const Content = styled.div<ITheme>((props) => ({
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  maxWidth: '280px',
}));

export const Title = styled.div<ITheme>((props) => ({
  fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
  marginBottom: '6px',
  lineHeight: '20px',
  fontWeight: 500,
}));

export const Description = styled.div<ITheme>({
  p: {
    lineHeight: themev2.fonts.lineHeight.p_small,
  },
});

export const CloseIcon = styled(IconCloseDark)({
  position: CSS_POSITION.ABSOLUTE,
  cursor: 'pointer',
  margin: '12px',
  right: 0,
  top: 0,
});

export const HideableContainer = hideable(Container);
