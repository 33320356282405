import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  DynamicSelectedTabBackground,
  DynamicTab,
  DynamicToggleButtonContainer,
  SelectedTabBackground,
  Tab,
  ToggleButtonContainer,
} from './styledComponents';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';
import useFlexWrapDetector from 'hooks/useFlexWrapDetector';

export interface IToggleTab {
  title: string;
  key: string;
}
export interface IToggleButton {
  firstTabText: string;
  secondTabText: string;
  isSecondTabSelected: boolean;
  setIsSecondTabSelected: (isSecondTabSelected: boolean) => void;
}

export const ToggleButton: React.FC<IToggleButton> = ({
  firstTabText,
  secondTabText,
  isSecondTabSelected = false,
  setIsSecondTabSelected,
}) => {
  const { id: sliceId } = useSliceDetails();

  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    prefix: type,
  });

  return (
    <ToggleButtonContainer>
      <Tab
        data-tracker={trackingSelector({ context: firstTabText })}
        isActive={!isSecondTabSelected}
        onClick={() => {
          setIsSecondTabSelected(!isSecondTabSelected);
          logPostHogEvent(trackingSelector({ context: firstTabText }));
          if (sliceId) {
            logGAElementClickEvent({
              elementId: `toggle:${firstTabText}`,
              sectionId: sliceId,
            });
          }
        }}
      >
        {firstTabText}
      </Tab>
      <Tab
        data-tracker={trackingSelector({ context: secondTabText })}
        isActive={isSecondTabSelected}
        onClick={() => {
          setIsSecondTabSelected(!isSecondTabSelected);
          logPostHogEvent(trackingSelector({ context: secondTabText }));
          if (sliceId) {
            logGAElementClickEvent({
              elementId: `toggle:${secondTabText}`,
              sectionId: sliceId,
            });
          }
        }}
      >
        {secondTabText}
      </Tab>
      <SelectedTabBackground
        sx={{
          transform: isSecondTabSelected ? 'translateX(100%)' : 'translate(0%)',
        }}
      />
    </ToggleButtonContainer>
  );
};

export type TabProps = {
  key: string;
  label: string;
};

type DynamicToggleProps = {
  onSelect?: (key: string) => void;
  tabs: TabProps[];
};

// TODO will be renamed, improved and moved to new repo with nextjs. Including extracting tracking
export function DynamicToggle({ onSelect, tabs }: DynamicToggleProps) {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
  const [activeTabElement, setActiveTabElement] =
    React.useState<HTMLDivElement | null>(null);
  const tabsContainerRef = React.useRef<HTMLDivElement | null>(null);

  const isWrapped = useFlexWrapDetector({ parentRef: tabsContainerRef });

  const { id: sliceId } = useSliceDetails();

  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    prefix: type,
  });

  const offset = () => {
    let cumulativeWidth = 0;

    if (!tabsContainerRef.current) {
      return cumulativeWidth;
    }

    const tabs = tabsContainerRef.current.children;
    for (let i = 0; i < selectedTabIndex; i++) {
      cumulativeWidth += tabs[i].clientWidth;
    }

    return cumulativeWidth;
  };

  function handleSelectTab(key: string, index: number) {
    if (tabs.length === 2 && index === selectedTabIndex) {
      index === 0 ? selectTab(tabs[1].key, 1) : selectTab(tabs[0].key, 0);
      return;
    }
    if (index === selectedTabIndex) return;

    selectTab(key, index);
  }

  function selectTab(key: string, index: number) {
    setSelectedTabIndex(index);
    onSelect?.(key);
    handleTracking(key);
  }

  function handleTracking(trackId: string) {
    logPostHogEvent(trackingSelector({ context: trackId }));
    if (sliceId) {
      logGAElementClickEvent({
        elementId: `toggle:${trackId}`,
        sectionId: sliceId,
      });
    }
  }

  return (
    <DynamicToggleButtonContainer
      ref={tabsContainerRef}
      showBorder={!isWrapped}
      data-tracker={trackingSelector({ context: 'pricing-cards-toggle' })}
    >
      {tabs.map((tab, index) => (
        <DynamicTab
          key={tab.key}
          isActive={selectedTabIndex === index}
          ref={(el) => selectedTabIndex === index && setActiveTabElement(el)}
          onClick={() => handleSelectTab(tab.key, index)}
          showBorder={isWrapped}
          data-tracker={trackingSelector({ context: tab.key })}
        >
          {tab.label}
        </DynamicTab>
      ))}
      <DynamicSelectedTabBackground
        sx={{
          minWidth: `${activeTabElement?.clientWidth}px !important`,
          minHeight: `${activeTabElement?.clientHeight}px !important`,
          transform: { md: `translateX(calc(${offset()}px + 2px))` },
          visibility: !isWrapped ? 'visible' : 'hidden',
        }}
      />
    </DynamicToggleButtonContainer>
  );
}
