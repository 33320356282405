export const groupTableData = (data) =>
  data.reduce((itemsByKeyValue, item) => {
    const value = item.header_name.trim();
    itemsByKeyValue[value] = (itemsByKeyValue[value] || []).concat(item);
    return itemsByKeyValue;
  }, {});

const getUniqueItems = (value: string, index: number, self: string[]) =>
  self.indexOf(value) === index;

export const getTableHeader = (data) => {
  const newTrimedArr = data.map((a) => a.header_name.trim());
  return newTrimedArr.map((item) => item).filter(getUniqueItems);
};
