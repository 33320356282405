import { themev2 } from 'theme-v2';
import { ThemeColor } from 'theme-v2/colors';
import { ButtonVariant } from './types';

export const BUTTON_SIZES = {
  sm: 32,
  md: 40,
  lg: 42,
};

export const sizeStyles = {
  sm: {
    fontSize: 14,
    height: BUTTON_SIZES.sm,
    paddingLeft: 12,
    paddingRight: 12,
  },
  md: {
    fontSize: 16,
    height: BUTTON_SIZES.md,
    paddingLeft: 24,
    paddingRight: 24,
  },
  lg: {
    fontSize: 16,
    height: BUTTON_SIZES.lg,
    paddingLeft: 24,
    paddingRight: 24,
  },
};

export const variantStyles: Record<
  ButtonVariant,
  Record<
    Extract<ThemeColor, 'red' | 'blue' | 'gray' | 'white' | 'black' | 'green'>,
    Record<'base' | 'hover', Partial<CSSStyleDeclaration>>
  >
> = {
  filled: {
    red: {
      base: {
        color: themev2.colors.neutral.N00,
        backgroundColor: themev2.colors.red.default,
      },
      hover: {
        backgroundColor: themev2.colors.red.S400,
      },
    },
    blue: {
      base: {
        color: themev2.colors.neutral.N00,
        backgroundColor: themev2.colors.blue.default,
      },
      hover: {
        backgroundColor: themev2.colors.blue.S600,
      },
    },
    gray: {
      base: {
        color: themev2.colors.neutral.N20,
        backgroundColor: themev2.colors.neutral.N50,
      },
      hover: {
        color: themev2.colors.neutral.N00,
      },
    },
    green: {
      base: {
        color: themev2.colors.neutral.N99,
        backgroundColor: themev2.colors.green.default,
      },
      hover: {
        background: themev2.colors.green.S600,
      },
    },
    white: {
      base: {
        color: themev2.colors.neutral.N99,
        backgroundColor: themev2.colors.neutral.N00,
      },
      hover: {
        backgroundColor: themev2.colors.neutral.N10,
      },
    },
    black: { base: {}, hover: {} },
  },
  outlined: {
    red: {
      base: {
        color: themev2.colors.red.default,
        border: `1px solid ${themev2.colors.red.default}`,
      },
      hover: {
        backgroundColor: themev2.colors.red.default,
      },
    },
    blue: {
      base: {
        color: themev2.colors.blue.default,
        border: `1px solid ${themev2.colors.blue.default}`,
        transition: 'none',
      },
      hover: {
        boxShadow: `0px 0px 0px 1px ${themev2.colors.blue.default} inset`,
        transition: 'none',
      },
    },
    gray: { base: {}, hover: {} },
    white: {
      base: {
        color: themev2.colors.neutral.N00,
        border: `1px solid ${themev2.colors.neutral.N00}`,
      },
      hover: {
        boxShadow: `0px 0px 0px 1px ${themev2.colors.neutral.N00} inset`,
        transition: 'none',
      },
    },
    black: {
      base: {
        color: themev2.colors.neutral.N99,
        border: `1px solid ${themev2.colors.neutral.N99}`,
      },
      hover: {
        color: themev2.colors.neutral.N00,
        backgroundColor: themev2.colors.neutral.N99,
      },
    },
  },
  text: {
    red: {
      base: {
        color: themev2.colors.red.default,
      },
      hover: {
        color: themev2.colors.red.S400,
      },
    },
    blue: {
      base: {
        color: themev2.colors.blue.default,
      },
      hover: {
        color: themev2.colors.blue.S400,
      },
    },
    gray: {
      base: {
        color: themev2.colors.gray.S500,
      },
      hover: {
        color: themev2.colors.neutral.N00,
      },
    },
    white: {
      base: {
        color: themev2.colors.neutral.N00,
      },
      hover: {
        color: themev2.colors.gray.S500,
      },
    },
    black: { base: {}, hover: {} },
  },
};
