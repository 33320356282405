import { track } from '@smartproxy/sp-tracker-js';
import { useScroll } from 'hooks/useScrollBlock';
import { IconClose } from 'images';
import React, { useState } from 'react';
import {
  ADDITIONAL_DESKTOP_OFFSET_START,
  DEFAULT_DESKTOP_OFFSET,
  DESKTOP_OFFSET_START,
  DESKTOP_SCROLL_POSITION,
} from '../constants';
import {
  ButtonsContainer,
  ContentContainer,
  CtaBlock,
  HideableDesktopContainer,
} from '../styledComponents';
import { TBannerContainer } from '../types';
import TextComponent from './TextComponent';

const BannerDesktopContainer: React.FC<TBannerContainer> = ({
  cta_link: ctaLink,
  cta_label: ctaLabel,
  info,
  icon,
  title,
  background,
  is_background_gradient: isBackgroundGradient,
  is_background_dark: isBackgroundDark,
  ctaId,
  ctaClassName,
  closeBtnClassName,
  isVisible,
  isAdditionalHeader,
  onClose,
}) => {
  const [offset, setOffset] = useState(DEFAULT_DESKTOP_OFFSET);

  const onScroll = () => {
    const offsetPosition = isAdditionalHeader
      ? ADDITIONAL_DESKTOP_OFFSET_START
      : DESKTOP_OFFSET_START;

    const scrollPosition = isAdditionalHeader
      ? ADDITIONAL_DESKTOP_OFFSET_START
      : DESKTOP_SCROLL_POSITION;

    if (window.pageYOffset < scrollPosition) {
      setOffset(DEFAULT_DESKTOP_OFFSET);
    } else {
      setOffset(offsetPosition);
    }
  };

  useScroll(isVisible, onScroll);

  return (
    <HideableDesktopContainer
      isVisible={isVisible}
      offset={Math.round(offset)}
      background={background}
      isBackgroundGradient={isBackgroundGradient}
      isBackgroundDark={isBackgroundDark}
    >
      <ContentContainer>
        <TextComponent icon={icon} title={title} info={info} />
        <ButtonsContainer>
          <CtaBlock
            isVisible={ctaLabel.text && ctaLink.url}
            ctaText={ctaLabel.text}
            ctaLink={ctaLink.url}
            className={ctaClassName}
            dataTracker={ctaClassName}
            sendTrackingEvent={() => track(ctaClassName)}
            id={ctaId}
          />
          <IconClose
            onClick={onClose}
            className={closeBtnClassName}
            data-tracker={closeBtnClassName}
            id={ctaId}
          />
        </ButtonsContainer>
      </ContentContainer>
    </HideableDesktopContainer>
  );
};

export default BannerDesktopContainer;
