import { track } from '@smartproxy/sp-tracker-js';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { PrismicTextField } from 'types';
import { initBuildTrackingSelector } from 'utils/page';
import {
  BackgroundContainer,
  CountriesList,
  CountriesListContainer,
  CountriesListItem,
  CountriesListWrapper,
  Title,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';

interface IProxyListCountries {
  primary: {
    background: string;
    main_title: PrismicTextField;
    title_position: boolean;
  };
  items: IItem[];
}

interface IItem {
  column_title: PrismicTextField;
  first_column: PrismicTextField;
  second_column: PrismicTextField;
  third_column: PrismicTextField;
}

const ProxyListCountries: React.FC<IProxyListCountries> = ({
  primary: { background, main_title: title, title_position: position },
  items,
}) => {
  const { type, tags } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'countries-list',
    prefix: type,
  });
  return (
    <BackgroundContainer background={background}>
      <CountriesListWrapper>
        <Title position={position}>
          <RichText field={title} />
        </Title>
        {items.map(
          (
            {
              column_title: title,
              first_column: first,
              second_column: second,
              third_column: third,
            },
            index
          ) => (
            <CountriesListItem key={index}>
              <div
                data-tracker={trackingSelector({ context: title.text })}
                onClick={() => track(trackingSelector({ context: title.text }))}
              >
                <RichText field={title} />
              </div>
              <CountriesListContainer>
                <CountriesList>
                  <RichText field={first} />
                </CountriesList>
                <CountriesList>
                  <RichText field={second} />
                </CountriesList>
                <CountriesList>
                  <RichText field={third} />
                </CountriesList>
              </CountriesListContainer>
            </CountriesListItem>
          )
        )}
      </CountriesListWrapper>
    </BackgroundContainer>
  );
};

export default ProxyListCountries;
