import { IconCheckmarkGreen } from 'images';
import React, { ReactNode } from 'react';
import hideable from 'utils/hideable';
import { CheckmarkWrapper, FeatureContainer } from './styledComponents';

const HideableFeature = hideable(FeatureContainer);

interface IFeatures {
  children?: ReactNode;
}

const Feature: React.FC<IFeatures> = ({ children }) => (
  <HideableFeature isVisible={!!children}>
    <CheckmarkWrapper>
      <IconCheckmarkGreen />
    </CheckmarkWrapper>
    {children}
  </HideableFeature>
);

export default Feature;
