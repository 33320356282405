import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import { LanguageToggle } from '../LanguageToggle';
import { RightNavMenuListItem } from './RightNavMenuListItem';
import { StyledRightNavMenuList } from './styledComponents';
import { RightNavMenuListProps } from './types';
import { Box } from 'components/v2/Box';

export const RightNavMenuList: React.FC<RightNavMenuListProps> = ({
  languages,
  links,
  buildTrackingId,
}) => (
  <Box sx={{ flexShrink: '0' }}>
    <StyledRightNavMenuList>
      <LanguageToggle
        languages={languages.children}
        buildTrackingId={buildTrackingId}
      />
      {links.map((link, index) => (
        <RightNavMenuListItem
          key={link.key}
          link={link}
          trackingId={buildTrackingId({ context: link.label, type: 'link' })}
          index={index}
          linksCount={links.length}
          handleClick={(trackingId) => track(trackingId)}
        />
      ))}
    </StyledRightNavMenuList>
  </Box>
);
