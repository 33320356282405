import { slugify } from 'features/utils';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { useState } from 'react';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'utils/browserStorage';
import { isBrowser } from 'utils/build';
import { buildTrackingSelector, getPageContextFromPathname } from 'utils/page';
import { DIALOG_CLOSED_ITEM, DIALOG_SLICES } from './constants';
import DialogContainer from './DialogContainer';
import { query } from './query';
import { IPrismicDialog, TDialog } from './types';
import { mapItemsToPaths } from './utils';

const Dialog: React.FC<TDialog> = ({ isMobile, pathname }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data } = useMergePrismicPreviewData(query());
  const {
    body,
    is_visible: isVisible,
    ...rest
  }: IPrismicDialog = data.prismicDialog.data;
  const pageListSlice = body?.filter(
    (slice) => slice.slice_type === DIALOG_SLICES.PageList
  );

  const paths = pageListSlice && pageListSlice[0].items.map(mapItemsToPaths);
  const isPathnameInPaths =
    isBrowser && paths ? paths.includes(window.location.pathname) : true;

  const isDialogClosed = isBrowser
    ? !!getSessionStorageItem(DIALOG_CLOSED_ITEM)
    : true;

  const isDialogVisible =
    isVisible &&
    !isDialogClosed &&
    isDialogOpen &&
    isPathnameInPaths &&
    !isMobile;

  const handleClose = () => {
    setIsDialogOpen(false);
    setSessionStorageItem(DIALOG_CLOSED_ITEM, 'TRUE');
  };

  const { type, tags } = getPageContextFromPathname(pathname);

  return (
    <DialogContainer
      onClose={handleClose}
      isVisible={isDialogVisible}
      setIsDialogOpen={setIsDialogOpen}
      ctaId={`dialog-${slugify(tags.length ? tags.join('-') : type)}-cta`}
      closeBtnClassName={buildTrackingSelector({
        page: tags.length ? tags.join('-') : type,
        section: 'dialog',
        context: 'close',
      })}
      ctaClassName={buildTrackingSelector({
        page: tags.length ? tags.join('-') : type,
        section: 'dialog',
        type: 'cta',
      })}
      {...rest}
    />
  );
};

export default Dialog;
