import React from 'react';
import {
  StyledCountdownTimeComponent,
  StyledCountdownTimeComponentsWrapper,
  StyledCountdownTimeWrapper,
  StyledCountdownTimer,
} from './styledComponents';
import { getCountForm } from 'utils/string';

export const CountdownTimer: React.FC<{
  label?: string;
  timeComponents: {
    days: string;
    mins: string;
    hours: string;
    secs: string;
  };
  isGreen?: boolean;
  isTimerVisible?: boolean;
}> = ({ label, timeComponents, isTimerVisible = true }) => (
  <StyledCountdownTimer>
    <span>
      {!!label && label}
      {isTimerVisible && !!label && <span className="hide-on-mobile"> -</span>}
    </span>
    {isTimerVisible && (
      <StyledCountdownTimeComponentsWrapper data-hidden="false">
        <CountdownTimeComponent
          time={timeComponents.days}
          unit={getCountForm(Number(timeComponents.days), 'DAY', 'DAYS')}
        />
        :
        <CountdownTimeComponent
          time={timeComponents.hours}
          unit={getCountForm(Number(timeComponents.hours), 'HR', 'HRS')}
        />
        :
        <CountdownTimeComponent time={timeComponents.mins} unit="MIN" />:
        <CountdownTimeComponent time={timeComponents.secs} unit="SEC" />
      </StyledCountdownTimeComponentsWrapper>
    )}
  </StyledCountdownTimer>
);

const CountdownTimeComponent = ({
  time,
  unit,
}: {
  time: string;
  unit: string;
}) => {
  const MIN_PLACEHOLDER_CHAR_LENGTH = 1;

  const getPlaceholderCharacters = (length: number) => {
    return ''.padStart(Math.max(MIN_PLACEHOLDER_CHAR_LENGTH, length), '0');
  };

  return (
    <StyledCountdownTimeComponent>
      <StyledCountdownTimeWrapper>
        <span data-hidden="false">{time}</span>
        <span data-hidden="true">{getPlaceholderCharacters(time.length)}</span>
      </StyledCountdownTimeWrapper>
      <small>{unit}</small>
    </StyledCountdownTimeComponent>
  );
};
