import React from 'react';
import {
  StyledTypeformEmbedContainer,
  StyledTypeformWidget,
} from './styledComponents';
import { ITypeformEmbed } from './types';

const TypeformEmbed: React.FC<ITypeformEmbed> = ({
  primary: { form_id: formId },
  slice_label: sliceLabel,
}) => {
  const handleSubmit = () => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({
      event: 'TypeformSubmit',
    });
  };

  return (
    <StyledTypeformEmbedContainer
      shouldContentBleed={sliceLabel !== 'contained'}
    >
      <StyledTypeformWidget id={formId} onSubmit={handleSubmit} />
    </StyledTypeformEmbedContainer>
  );
};

export default TypeformEmbed;
