import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import hideable from 'utils/hideable';
import { INeedMoreBanner } from './types';
import {
  AnimationWrapper,
  NeedMoreContainer,
  NeedMoreDescription,
  NeedMoreIcon,
  NeedMoreTitle,
} from './styledComponents';

const NeedMoreBanner: React.FC<INeedMoreBanner> = ({
  description,
  icon,
  title,
}) => (
  <AnimationWrapper>
    <NeedMoreContainer>
      <NeedMoreIcon>
        <GatsbyImg image={icon} />
      </NeedMoreIcon>
      <NeedMoreTitle>{title}</NeedMoreTitle>
      <NeedMoreDescription>{description}</NeedMoreDescription>
    </NeedMoreContainer>
  </AnimationWrapper>
);

export default hideable(NeedMoreBanner);
