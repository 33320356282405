import Hero from 'features/Blog';
import ContentTags from 'features/ContentTags';
import { SliceMap } from 'types';

export enum BLOG_PAGE_SLICES {
  ContentTags = 'content_tags',
  Hero = 'hero_block',
}

export const BlogPageSliceMap: SliceMap<BLOG_PAGE_SLICES> = {
  [BLOG_PAGE_SLICES.ContentTags]: ContentTags,
  [BLOG_PAGE_SLICES.Hero]: Hero,
};
