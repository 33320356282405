import { apiEndpoints } from 'apiEndpoints';
import { usePageContext } from 'contexts/PageContext';
import { useFormik } from 'formik';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import { useRef, useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import { stripHash } from 'utils/string';
import AdditionalInfoForm from './AdditionalInfoForm';
import CandidateInfoForm from './CandidateInfoForm';
import { DEFAULT_HR_EMAIL, JOB_APPLICATION_FORM_ID } from './constants';
import {
  StyledButtonTextContainer,
  StyledForm,
  StyledFormSection,
  StyledFormSectionTitle,
  StyledJobApplicationFormContainer,
  StyledSubmitBtn,
  StyledSubmitBtnWrapper,
} from './styledComponents';
import { IJobApplicationForm, IJobApplicationFormProps } from './types';
import { applicationFormValidationSchema } from './validations';
import { track } from '@smartproxy/sp-tracker-js';

const JobApplicationForm = ({ job }: IJobApplicationFormProps) => {
  const { tags, type } = usePageContext();
  const formRef = useRef<HTMLFormElement>(null);

  const [shouldShowMessage, setShouldShowMessage] = useState(false);

  const { isError, isSuccess, request, resetRequestStatus } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.jobApplication
  );

  const formik = useFormik<IJobApplicationForm>({
    initialValues: {
      full_name: '',
      email: '',
      phone: '',
      profile_link: '',
      resume: undefined,
      consent: false,
      info: '',
    },
    validationSchema: applicationFormValidationSchema,
    validateOnMount: true,
    onSubmit: (values, helpers) => {
      if (!formRef.current || !values.resume || !job) return;

      const formData = new FormData(formRef.current);
      formData.delete('resume');
      formData.delete('consent');
      formData.append('file', values.resume);
      formData.append('role', job.title);
      formData.append('hr_email', job.hrEmail ?? DEFAULT_HR_EMAIL);

      request({
        body: formData,
        onError: () => {
          setShouldShowMessage(true);
        },
        onSuccess: () => {
          setShouldShowMessage(true);
          helpers.resetForm();
          formRef.current?.reset();
          setTimeout(() => {
            resetRequestStatus();
            setShouldShowMessage(false);
          }, 3000);
        },
        onFinally: () => {
          helpers.setSubmitting(false);
        },
      });
    },
  });

  const formButtonTrackingSelector = buildTrackingSelector({
    page: tags[1] || type,
    section: 'job-application-form',
    context: 'submit',
    type: 'button',
  });
  const isFormValid = !Object.keys(formik.errors).length;

  const isSubmittingCleanForm = !formik.dirty && formik.submitCount > 0;
  const isOnlyPrivacyConsentMissing =
    formik.touched.consent &&
    Object.keys(formik.errors).length === 1 &&
    formik.errors.consent;

  const handleResumeSelect = (file: File | null) => {
    touchFileUploadField();
    formik.setFieldValue('resume', file);
  };

  const touchFileUploadField = () => {
    formik.setFieldTouched('resume', true);
    formik.validateField('resume');
  };

  const getSubmitButtonText = () => {
    if (isSuccess) {
      return 'Application sent';
    }
    if (isError) {
      return 'Try again';
    }
    return 'Submit application';
  };

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formik.handleSubmit();
    track(formButtonTrackingSelector);
  };

  return (
    <StyledJobApplicationFormContainer>
      <StyledForm
        ref={formRef}
        onSubmit={(event) => onFormSubmit(event)}
        id={stripHash(JOB_APPLICATION_FORM_ID)}
      >
        <StyledFormSection>
          <StyledFormSectionTitle>
            Submit your application
          </StyledFormSectionTitle>
          <CandidateInfoForm
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            handleResumeSelect={handleResumeSelect}
          />
        </StyledFormSection>
        <StyledFormSection>
          <StyledFormSectionTitle>
            Additional Information
          </StyledFormSectionTitle>
          <AdditionalInfoForm
            values={formik.values}
            touched={formik.touched}
            errors={formik.errors}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </StyledFormSection>
        <StyledSubmitBtnWrapper>
          <StyledSubmitBtn
            shouldShowMessage={shouldShowMessage}
            loading={formik.isSubmitting}
            disabled={!isFormValid || formik.isSubmitting}
            data-tracker={formButtonTrackingSelector}
            type="submit"
            onClick={touchFileUploadField}
          >
            <StyledButtonTextContainer>
              <span aria-hidden={shouldShowMessage}>Submit application</span>
              {shouldShowMessage && (
                <span aria-hidden={!shouldShowMessage}>
                  {getSubmitButtonText()}
                </span>
              )}
            </StyledButtonTextContainer>
          </StyledSubmitBtn>
          <ButtonFormError
            isError={isError}
            isOnlyPrivacyConsentMissing={!!isOnlyPrivacyConsentMissing}
            isSubmittingCleanForm={isSubmittingCleanForm}
          />
        </StyledSubmitBtnWrapper>
      </StyledForm>
    </StyledJobApplicationFormContainer>
  );
};

const ButtonFormError = ({
  isOnlyPrivacyConsentMissing,
  isSubmittingCleanForm,
  isError,
}: {
  isOnlyPrivacyConsentMissing: boolean;
  isSubmittingCleanForm: boolean;
  isError: boolean;
}) => {
  if (isOnlyPrivacyConsentMissing) {
    return <small>You have to agree with Privacy Notice</small>;
  }
  if (isSubmittingCleanForm) {
    return <small>You have to fill all fields</small>;
  }
  if (isError) {
    return <small>Something went wrong, try again.</small>;
  }
  return null;
};

export default JobApplicationForm;
