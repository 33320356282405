import { PrismicTextField } from 'types';

export interface IWebinarRegistrationForm {
  primary: {
    title: PrismicTextField;
    subtitle: PrismicTextField;
    description: PrismicTextField;
    form_title: PrismicTextField;
    webinar_info: PrismicTextField;
    webinar_date: PrismicTextField;
    background?: string;
  };
}

export interface IForm {
  info: string;
  formButtonId: string;
}

export interface ISuccessMessage {
  handleClose: () => void;
}

export interface IInputLabel {
  label: string;
  type: string;
  name: string;
  value: string;
  required?: boolean;
  isError?: boolean;
  id: string;
  onBlur: () => void;
  onChange: (e) => void;
  onClick: () => void;
}

export interface IFormComponent extends IForm {
  title: PrismicTextField;
  date: PrismicTextField;
}

export enum FIELD_NAMES {
  NAME = 'name',
  EMAIL = 'email',
  COMPANY_NAME = 'companyName',
}
