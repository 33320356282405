import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { IColor } from 'types';
import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import hideable from 'utils/hideable';
import { HideOnMobile } from 'commonStyles';

export const StyledHomePageHeroWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    overflow: 'hidden',
    padding: `62px 20px ${getThemeGutter(props, TSHIRT_SIZE.XXXL)}`,
    height: 'auto',
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    [MEDIA_QUERIES.TABLET]: {
      padding: `20px 20px ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
    },
  })
);

export const StyledHomePageHeroContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
}));

export const HeroHomePage = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.XXXL),
  gridTemplateColumns: '1fr 360px',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.START,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageTextBlock = styled.div<ITheme>((props) => ({
  gridColumnStart: 1,
  gridColumnEnd: 2,
  gridRowStart: 1,
  gridRowEnd: 2,
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.FLEX_START,
  'h1, h2, h3': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    textAlign: TEXT_ALIGN.LEFT,
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  p: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    color: getThemeMainColor(props),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    [MEDIA_QUERIES.TABLET]: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  a: {
    button: {
      minWidth: '200px',
      fontFamily: getThemeFontFamily(props),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
      lineHeight: '20px',
      margin: 0,
      padding: '15px 0',
      [DOM_STATE.HOVER]: {
        padding: '15px 0',
      },
    },
    width: 'auto',
    height: 'auto',
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    color: getThemeMainColor(props),
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRowStart: 2,
    gridRowEnd: 3,
    gridColumnStart: 1,
    gridColumnEnd: 2,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    alignItems: CSS_ALIGN.CENTER,
    order: 1,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    },
  },
}));

export const HeroHomePageImageBlock = styled.div<ITheme>((props) => ({
  maxWidth: '360px',
  maxHeight: 'none',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: '100%',
  ...HideOnMobile,
}));

export const HideableHeroHomePageImageBlock = hideable(HeroHomePageImageBlock);

export const HeroHomePageLogosContainer = styled.div<ITheme>((props) => ({
  gridRowStart: 'span 1',
  gridRowEnd: 'span 1',
  gridColumnStart: 'span 2',
  gridColumnEnd: 'span 2',
  height: 'auto',
  clear: 'left',
  p: {
    margin: 0,
    lineHeight: '20px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 400,
    fontFamily: getThemeFontFamily(props),
    color: getThemeMainColor(props),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroHomePageLogos = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  alignItems: CSS_ALIGN.CENTER,
  div: {
    marginTop: '20px',
    verticalAlign: 'middle',
    display: CSS_DISPLAY.INLINE_BLOCK,
    img: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      height: 'auto',
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
    div: {
      padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
      img: {
        width: 'auto',
      },
    },
  },
}));
