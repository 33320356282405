import { useIsMounted, useABTest } from '@smartproxy-web/shared/hooks';
import { Skeleton } from 'components/Skeleton';
import {
  StyledSkeletonCardWrapper,
  StyledSkeletonWrapper,
} from 'features/ABtestPricingCards/styled';
import { AutomatedPricingCards } from 'features/AutomatedPricingCards';
import { ABV1AutomatedPricingCards } from 'features/AutomatedPricingCards/ABTest/Version1';
import { IAutomatedPricingCards } from 'features/AutomatedPricingCards/types';

export const ABtestPricingCards = (props: IAutomatedPricingCards) => {
  const isMounted = useIsMounted();
  const { abValue, isGettingABTest } = useABTest(
    'experiment-ab-residential-pricing',
    'control'
  );

  const isABTest = abValue === 'test';

  if (isMounted === null || isGettingABTest) {
    return (
      <StyledSkeletonWrapper id="loading-pricing-cards">
        {Array.from({ length: 6 }).map((_, index) => (
          <StyledSkeletonCardWrapper key={index}>
            <Skeleton height="300px" width="200px" />
          </StyledSkeletonCardWrapper>
        ))}
      </StyledSkeletonWrapper>
    );
  }

  return isABTest ? (
    <ABV1AutomatedPricingCards {...props} />
  ) : (
    <AutomatedPricingCards {...props} />
  );
};
