import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { PRISMIC_VALUES } from 'constants/common';
import React from 'react';
import { COLOR_SHADE } from 'theme';
import {
  CardContainer,
  CardFeature,
  CardFeaturesPrice,
  DiscountLabelComponent,
  HideableCardAmount,
  HideableCardDescription,
  HideableCardFeatures,
  HideableCardFeaturesContainer,
  HideableCardImageContainer,
  HideableCardInfoContainer,
  HideableCardPrice,
  HideableCardTitle,
  HideableCrossedOutPrice,
  HideableFlag,
  HideableInfoText,
  MostPopularFlagBuleImage,
  MostPopularFlagGreenImage,
  NewBadge,
  StyledCta,
  TimePeriod,
} from './styledComponents';
import { IPricingCard } from './types';
import { getPlanId } from 'utils/plans';
import { appendPlanIdToURL } from 'features/utils';
import { RichText } from 'components/v2/RichText';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const PricingCard: React.FC<IPricingCard> = ({
  amount,
  price,
  product,
  card_title: cardTitle,
  theme,
  card_description: cardDescription,
  first_feature_label: firstFeatureLabel,
  first_feature_value: firstFeatureValue,
  second_feature_label: secondFeatureLabel,
  second_feature_value: secondFeatureValue,
  third_feature_label: thirdFeaturelabel,
  third_feature_value: thirdFeatureValue,
  is_most_popular: isMostPopular,
  info_text: infoText,
  cta_link: ctaLink,
  cta_text: ctaText,
  vat_text: vatText,
  crossed_out_price: crossedOutPrice,
  image,
  selector,
  billing_name: billingName,
  is_new: isNew,
  is_ddc_non_seq: isDDCNonSeq,
  card_plan_name: cardPlanName,
  card_product_type: cardProductType,
  show_discount_label: showDiscountLabel,
  discount,
  crossedOutPrices,
  price_per_gb_crossed_out: pricePerGbCrossedOut,
  index,
}) => {
  const { id: sliceId } = useSliceDetails();
  const isAdditionalTheme = theme.toLowerCase() === BUTTON_TYPES.ADDITIONAL;
  const planId =
    product?.toLowerCase() === 'mixed'
      ? getPlanId(cardPlanName!, cardProductType, isDDCNonSeq)
      : getPlanId(cardTitle || cardPlanName!, product, isDDCNonSeq);

  const ctaUrl = !planId ? ctaLink.url : appendPlanIdToURL(ctaLink.url, planId);
  const themeName = isAdditionalTheme
    ? BUTTON_THEMES.IMPORTANT
    : BUTTON_THEMES.FOLLOWING;

  const amountColorShade = isAdditionalTheme
    ? COLOR_SHADE.PRIMARY
    : COLOR_SHADE.SECONDARY;

  return (
    <CardContainer>
      {showDiscountLabel && (
        <DiscountLabelComponent>
          <RichText field={discount} />
        </DiscountLabelComponent>
      )}
      <HideableCardImageContainer isVisible={!!image.url}>
        <GatsbyImg image={image} />
      </HideableCardImageContainer>
      <HideableCardInfoContainer isVisible={!image?.url}>
        <HideableFlag isVisible={isNew}>
          <NewBadge />
        </HideableFlag>
        <HideableFlag isVisible={isMostPopular}>
          {isAdditionalTheme ? (
            <MostPopularFlagBuleImage />
          ) : (
            <MostPopularFlagGreenImage />
          )}
        </HideableFlag>
        <HideableCardTitle isVisible={!!cardTitle}>
          {cardTitle}
        </HideableCardTitle>
        <HideableCardDescription isVisible={!!cardDescription}>
          {cardDescription}
        </HideableCardDescription>
        <HideableCardAmount isVisible={!!amount} colorShade={amountColorShade}>
          {amount}
        </HideableCardAmount>
        <HideableCardPrice isVisible={!!price} align={!vatText}>
          <HideableCrossedOutPrice isVisible={crossedOutPrices}>
            {crossedOutPrice}
          </HideableCrossedOutPrice>
          {price}
          <TimePeriod>/{billingName}</TimePeriod>
        </HideableCardPrice>
        <HideableInfoText isVisible={!!vatText}>
          {PRISMIC_VALUES.TOTAL}
          {vatText}
        </HideableInfoText>
        <HideableCardFeaturesContainer isVisible={!!firstFeatureLabel}>
          <CardFeaturesPrice isBolded={!!pricePerGbCrossedOut}>
            <HideableCardFeatures isVisible={!!firstFeatureLabel}>
              <CardFeature>{firstFeatureLabel}</CardFeature>
              <div>
                <span>{pricePerGbCrossedOut}</span>
                <CardFeature>{firstFeatureValue}</CardFeature>
              </div>
            </HideableCardFeatures>
          </CardFeaturesPrice>
          <HideableCardFeatures isVisible={!!secondFeatureLabel}>
            <CardFeature>{secondFeatureLabel}</CardFeature>
            <CardFeature>{secondFeatureValue}</CardFeature>
          </HideableCardFeatures>
          <HideableCardFeatures isVisible={!!thirdFeaturelabel}>
            <CardFeature>{thirdFeaturelabel}</CardFeature>
            <CardFeature>{thirdFeatureValue}</CardFeature>
          </HideableCardFeatures>
        </HideableCardFeaturesContainer>
        <HideableInfoText isVisible={!!infoText}>{infoText}</HideableInfoText>
      </HideableCardInfoContainer>
      <StyledCta
        linkId={selector}
        linkClassName={selector}
        dataTracker={selector}
        isVisible={ctaLink.url && ctaText?.length}
        themeName={themeName}
        ctaText={ctaText}
        ctaLink={ctaUrl}
        fontSize={TSHIRT_SIZE.M}
        themeType={BUTTON_TYPES.PRIMARY}
        gutterTop={TSHIRT_SIZE.XS}
        gutterBot={TSHIRT_SIZE.XXS}
        sendTrackingEvent={() => {
          logPostHogEvent(selector);
          if (sliceId) {
            logGAElementClickEvent({
              elementId: `${ctaText}:${index}`,
              sectionId: sliceId,
            });
          }
        }}
      />
    </CardContainer>
  );
};

export default PricingCard;
