import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
  getThemeMainColor,
  MAIN_COLOR,
  getThemeFontFamily,
  FONT_FAMILY,
} from 'theme';

export const Container = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const TitleWrapper = styled.div<ITheme & { isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded, ...props }) => ({
    textAlign: TEXT_ALIGN.CENTER,
    'h1, h2, h3': {
      marginBottom: isSubtitleAdded
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XL),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
    },
  })
);

export const Subtitle = styled.div<ITheme & { isSubtitleCentered: boolean }>(
  ({ isSubtitleCentered, ...props }) => ({
    textAlign: isSubtitleCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    'h2, h3, h4, h5, h6': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      textAlign: TEXT_ALIGN.LEFT,
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  })
);

export const UseCasesContainer = styled.ul<ITheme & { numbOfColumns: number }>(
  ({ numbOfColumns }) => ({
    width: '100%',
    maxWidth: '1024px',
    padding: 0,
    listStyle: 'none',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${numbOfColumns || 2}, 1fr)`,
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      justifyItems: CSS_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const ListItem = styled.li<ITheme>((props) => ({
  padding: 0,
  'h2, h3, h4': {
    fontSize: '22px',
    fontWeight: '500',
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
  },
}));

export const StyledLink = styled(Link)<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    marginTop: '4px',
    marginBottom: '2px',
    fontWeight: 600,
  },
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    p: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
}));

export const ImageWapper = styled.div({
  marginRight: '15px',
  [MEDIA_QUERIES.TABLET]: {
    marginRight: 0,
  },
});
