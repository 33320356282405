import { useLocation } from '@reach/router';
import { usePageContext } from 'contexts/PageContext';
import React, { useEffect, useState } from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import { StyledHeader, StyledHeaderContents } from '../Header/styledComponents';
import Logo from './components/Logo';
import Navigation from './components/Navigation';
import ToggleButton from './components/ToggleButton';
import { verticalScrollOffset } from './types';
import { Box } from 'components/v2/Box';

const HeaderCareer: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { type, tags } = usePageContext();
  const location = useLocation();
  const mainNavHeight = verticalScrollOffset;

  useEffect(() => {
    //for scroling to specific position on a page load
    if (location.hash) {
      setTimeout(function () {
        const item = document.querySelector(`${location.hash}`) as HTMLElement;
        const itemTopPos = item && item.offsetTop;

        window.scrollTo({
          top: itemTopPos - mainNavHeight,
          behavior: 'smooth',
        });
      }, 10);
    }
  }, [location]);

  const buildTrackingId = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'header-careers',
  });

  return (
    <StyledHeader>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <StyledHeaderContents>
          <Logo buildTrackingId={buildTrackingId} />
          <ToggleButton
            buildTrackingId={buildTrackingId}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
          <Navigation
            buildTrackingId={buildTrackingId}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </StyledHeaderContents>
      </Box>
    </StyledHeader>
  );
};

export default hideable(HeaderCareer);
