export type Site = (typeof sites)[number];

export const sites = ['smartproxy.com', 'smartconnection.io'] as const;
export const siteLang = 'en';
export const defaultSiteDomain = sites[0];
export const additionalSiteDomain = sites[1];

export const assertSite = (site?: string): site is Site =>
  !!site && sites.includes(site as Site);

export const getCurrentSiteDomain = () =>
  assertSite(process.env.GATSBY_SITE_DOMAIN)
    ? process.env.GATSBY_SITE_DOMAIN
    : defaultSiteDomain;
