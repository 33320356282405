import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { IWithBlogProps } from 'pageUtils/blog/types';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

interface IWithPrismicDeals {
  prismicDeals: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicDeals;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const DealsPage = ({
  data,
  pageContext,
}: Props & PageTemplateProps & IWithBlogProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicDeals, allPrismicBlog } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicDeals.data[bodyKey],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicDeals.tags,
            type: prismicDeals.type,
          })}
          {...slice}
          articles={allPrismicBlog?.nodes}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query DealsQuery($id: String!) {
    prismicDeals(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicDealsDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicDealsDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDealsDataBodyExpandableProductsPricingOverview {
            id
            items {
              badge_color
              badge_label
              checklist {
                richText
                text
              }
              product {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_starting_price
              time_unit
              price_prefix
            }
            slice_type
            primary {
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              checkmark {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
              background
            }
          }
          ... on PrismicDealsDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicDealsDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              accent_color
              is_text_centered
              is_image_on_left
              is_text_white
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicDealsDataBodyPaymentMethod {
            id
            slice_type
            primary {
              icons_text {
                richText
                text
              }
              background
              security_description {
                richText
                text
              }
              security_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              security_title {
                richText
                text
              }
            }
            items {
              payment_method_icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicDealsDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicDealsDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyPromoBanner {
            id
            slice_type
            items {
              end_date
              banner_icon {
                url
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              content
              cta_text
              cta_link {
                url
              }
            }
          }
          ... on PrismicDealsDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyComparisonTable {
            id
            items {
              cell_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              cell_value {
                richText
                text
              }
              column
              cta_color
              cta_text
              cta_url {
                url
              }
            }
            primary {
              is_upper_content_centered
              background
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_text
              cta_link {
                url
              }
              fifth_column_header
              fifth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              fourth_column_header
              second_column_header
              sixth_column_header
              fourth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              second_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sixth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              subtitle {
                richText
                text
              }
              table_text_alignment
              third_column_header
              third_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyTrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              trustpilot_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              link_to {
                url
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              is_background_dark
              is_split_equally
              video_embed
              image_zoom_modal
              is_image_right
              background
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyImageTextColumn {
            id
            items {
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            primary {
              cta_url {
                url
                target
              }
              cta_label {
                richText
                text
              }
              background
              numbers_of_columns
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              columns
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicDealsDataBodyPricingCards {
            id
            items {
              card_product_type
              card_plan_name
              scraping_api_plan
              is_ddc_non_seq
              billing_name
              is_new
              amount
              billing_type
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              info_text
              is_most_popular
              price
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              vat_text
              card_title
              card_description
              crossed_out_price
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
                url
              }
              show_discount_label
              discount {
                richText
                text
              }
              price_per_gb_crossed_out
            }
            primary {
              is_yearly_visible
              background
              feature_one
              feature_three
              feature_two
              feature_four
              feature_five
              number_of_columns
              switch_discount_label
              switch_monthly_label
              switch_yearly_label
              theme
              product
              toggle_label_text
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyPricingSlider {
            id
            slice_type
            items {
              amount
              billing_type
              billing_name
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              plan_title
              price
              scale_label
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              fourth_feature_label
              fourth_feature_value
              vat_text
              crossed_out_total_price
            }
            primary {
              is_yearly_visible
              background
              bigger_plan_cta_link {
                url
              }
              bigger_plan_cta_text
              bigger_plan_description
              bigger_plan_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              bigger_plan_title
              switch_monthly_label
              switch_yearly_label
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              theme
              product
            }
          }
          ... on PrismicDealsDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              available_cities {
                richText
                text
              }
              background
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
              is_subtitle_centered
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
              cta_title {
                text
                richText
              }
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicDealsDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicDealsDataBodyImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicDealsDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
          }
          ... on PrismicDealsDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyPricingCardsNew {
            id
            slice_type
            primary {
              show_top_label
              top_label {
                richText
                text
              }
              background
              is_yearly_visible_
              number_of_columns
              theme
              toggle_label_text
            }
            items {
              pricing_plan {
                document {
                  ...PricingDetailsFragment
                }
              }
            }
          }
          ... on PrismicDealsDataBodyPricingSliderNew {
            id
            slice_type
            primary {
              theme
              background
              is_yearly_visible
            }
            items {
              pricing_slider_details {
                document {
                  ...PricingSliderDetailsFragment
                }
              }
            }
          }
          ... on PrismicDealsDataBodyHomepagePricingCards {
            id
            items {
              benefit_1 {
                text
                richText
              }
              benefit_2 {
                richText
                text
              }
              benefit_3 {
                richText
                text
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_name: card_title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link_field {
                url
              }
              card_price: price {
                richText
                text
              }
            }
            primary {
              column_number
              background
              title_field {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicDealsDataBodyProductCardsBlock {
            id
            items {
              badge {
                richText
                text
              }
              link {
                url
              }
              product_description {
                richText
                text
              }
              product_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_title {
                richText
                text
              }
            }
            slice_type
            primary {
              background
              block_title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicDealsDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicDealsDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyAutomatedPricingCards {
            id
            items {
              pricing_card {
                type
                document {
                  ...PricingCardFragment
                  ...PricingCardsFeaturesFragment
                }
              }
            }
            slice_type
            primary {
              background_color
              is_limited_time_offer_visible
              enterprise_benefit
              subtitle {
                richText
                text
              }
              switch_enterprise_label
              switch_regular_label
              title {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyPpcLpHero {
            id
            slice_type
            slice_label
            primary {
              award_badge {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              description {
                richText
                text
              }
              features {
                richText
                text
              }
              highlighted_text
              primary_cta_label {
                richText
                text
              }
              primary_cta_link {
                url
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              show_money_back_guarantee
              title {
                text
              }
              reviews_title {
                richText
                text
              }
            }
            items {
              review {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyLpDealsHeader {
            id
            slice_label
            slice_type
            primary {
              logo_alignment
              logo_clickable
              logo {
                document {
                  ... on PrismicLpDealsHeaderLogo {
                    id
                    data {
                      logo {
                        gatsbyImageData(placeholder: BLURRED)
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDealsDataBodyNewProductCards {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_badge_color
              card_badge_label {
                richText
                text
              }
              card_cta_label {
                richText
                text
              }
              card_cta_link {
                url
              }
              card_item_description {
                richText
                text
              }
              card_download_cta_link {
                url
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_item_title {
                richText
                text
              }
            }
            primary {
              background
              content_alignment
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicDealsDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicDealsDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicDealsDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___release_date, order: DESC }
      filter: { tags: { nin: "test" } }
      limit: 1
    ) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy")
        url
        data {
          new_until
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMM DD, yyyy")
          time_spent_reading
          body {
            ... on PrismicBlogDataBodyBlogAuthor {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodyAuthorV2 {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
                is_global
              }
            }
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(DealsPage);
