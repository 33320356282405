import { track } from '@smartproxy/sp-tracker-js';
import GatsbyImg from 'components/GatsbyImg';

import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import { Link } from 'prismic-reactjs';
import React from 'react';
import { PageContext, PrismicTextField, TGatsbyImage } from 'types';
import hideable from 'utils/hideable';
import { buildTrackingSelector } from 'utils/page';
import {
  ContentContainer,
  HideableStyledCtaContainer,
  StyledContainer,
  StyledCta,
  StyledFeaturesWrapper,
  Subtitle,
  TitleWrapper,
  Wrapper,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';

const HideableSubtitle = hideable(Subtitle);
interface IItem {
  image: TGatsbyImage;
  description: PrismicTextField;
}

interface IImageTextColumn {
  primary: {
    title: PrismicTextField;
    subtitle: PrismicTextField;
    is_subtitle_centered: boolean;
    background: string;
    numbers_of_columns?: number;
    cta_url?: Link;
    cta_label?: PrismicTextField;
  };
  items: IItem[];
  pageContext: PageContext;
}

const ImageTextColumn: React.FC<IImageTextColumn> = ({
  primary: {
    background,
    title,
    subtitle,
    numbers_of_columns: numberOfColumns,
    is_subtitle_centered: isSubtitleCentered,
    cta_url: ctaUrl,
    cta_label: ctaLabel,
  },
  items,
  pageContext: { tags, type },
}) => {
  const trackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: title.text,
    context: ctaLabel?.text,
    type: 'cta',
  });
  return (
    <Wrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.IMAGE_TEXT_COLUMN,
        tags,
        type
      )}
    >
      <ContentContainer
        id={buildTrackingSelector({
          page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
          section: title.text,
          prefix: type,
        })}
      >
        {(!!title?.text || !!subtitle?.text) && (
          <Box sx={{ marginBottom: '2rem' }}>
            <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
              <RichText field={title} />
            </TitleWrapper>
            <HideableSubtitle
              isVisible={subtitle?.text}
              isSubtitleCentered={isSubtitleCentered}
            >
              <RichText field={subtitle} />
            </HideableSubtitle>
          </Box>
        )}
        <StyledContainer numberOfColumns={numberOfColumns || 5}>
          {items.map(({ image, description }, index) => (
            <StyledFeaturesWrapper key={index}>
              <GatsbyImg image={image} />
              <RichText field={description} />
            </StyledFeaturesWrapper>
          ))}
        </StyledContainer>
        <HideableStyledCtaContainer isVisible={ctaLabel?.text && ctaUrl?.url}>
          <StyledCta
            ctaText={ctaLabel?.text}
            linkClassName={trackingSelector}
            dataTracker={trackingSelector}
            onClick={() => track(trackingSelector)}
            ctaLink={ctaUrl?.url}
            isVisible
          />
        </HideableStyledCtaContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default ImageTextColumn;
