import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { BaseSection } from 'components/Section';
import { MEDIA_QUERIES } from 'globalStyles';
import { getThemeFontSize, getThemeGutter } from 'theme';
import { themev2 } from 'theme-v2';

export const StyledJobIntroductionSection = styled(BaseSection)((props) => ({
  paddingBottom: '30px',
  [MEDIA_QUERIES.TABLET]: {
    paddingTop: getThemeGutter(props, TSHIRT_SIZE.L),
  },
  'h1,h2,h3,h4,h5,h6': {
    textAlign: TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  p: {
    margin: 0,
    fontSize: themev2.fonts.size.p_large,
  },
  'a button': {
    marginBottom: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
    minWidth: '282px',
    fontWeight: 500,
    width: 'unset',

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '100%',
    },
  },
}));
