import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBrandedColor,
  getThemeInputBorderShape,
  getThemeInputHeightShape,
  getThemeNeutralColor,
} from 'theme';

export const StyledCheckbox = styled.div<{ hasError?: boolean }>(
  ({ hasError, ...props }) => ({
    position: CSS_POSITION.RELATIVE,
    lineHeight: 0,

    'input[type="checkbox"]': {
      cursor: 'pointer',
      height: getThemeInputHeightShape(props, TSHIRT_SIZE.XS),
      width: getThemeInputHeightShape(props, TSHIRT_SIZE.XS),
      opacity: 0,
      margin: 0,
      padding: 0,

      '&:checked+div': {
        borderColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      },
      '&:hover+div, &:focus+div': {
        borderColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        borderWidth: '2px',
      },
      '&:checked+div svg': {
        visibility: 'visible',
        transform: 'scale(1)',
      },
    },

    div: {
      borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
      position: CSS_POSITION.ABSOLUTE,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      display: CSS_DISPLAY.FLEX,
      alignItems: CSS_ALIGN.CENTER,
      justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
      border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.LIGHT)}`,
      ...(hasError && {
        borderColor: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      }),

      svg: {
        transformOrigin: 'center',
        transform: 'scale(0)',
        transition: `transform 0.2s ease-in-out`,
        visibility: 'hidden',
        path: {
          fill: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        },
      },
    },
  })
);
