import { track } from '@smartproxy/sp-tracker-js';
import { TARGET } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { NewRichText } from 'components/PrismicRichText';
import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  ImageWrapper,
  Review,
  ReviewsContainer,
  ReviewsList,
  StarRatingWrapper,
  StyledLinkWrapper,
  Username,
  Wrapper,
} from './styledComponents';
import { ITruspilotReviews } from './types';

const TrustpilotReviews: React.FC<ITruspilotReviews> = ({
  primary: { background, link_to: linkTo, trustpilot_logo: trustpilotLogo },
  pageContext: { tags, type },
  items,
}) => {
  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.TRUSTPILOT_REVIEWS,
    prefix: type,
    type: 'link',
  });
  return (
    <Wrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.TRUSTPILOT_REVIEWS,
        tags,
        type
      )}
    >
      <ReviewsContainer>
        <StyledLinkWrapper
          to={linkTo.url}
          target={TARGET.BLANK}
          onClick={() => track(trackingSelector)}
        >
          <GatsbyImg image={trustpilotLogo} />
        </StyledLinkWrapper>
        <ReviewsList>
          {items.map(
            (
              {
                username,
                review,
                user_image: userImage,
                star_rating: starRating,
              },
              idx
            ) => (
              <Review key={idx}>
                <ImageWrapper>
                  <GatsbyImg image={userImage} />
                </ImageWrapper>
                <Username>
                  <NewRichText text={username} />
                </Username>
                <StarRatingWrapper>
                  <GatsbyImg image={starRating} />
                </StarRatingWrapper>
                <NewRichText text={review} />
              </Review>
            )
          )}
        </ReviewsList>
      </ReviewsContainer>
    </Wrapper>
  );
};

export default TrustpilotReviews;
