import { TGetArticles } from './types';

export const getArticles: TGetArticles = (nodes) =>
  nodes.map(
    ({
      data: {
        article_image,
        description,
        release_date,
        time_spent_reading,
        title,
        body,
      },
      type,
      tags,
      id,
    }) => ({
      data: {
        articleImage: article_image,
        description,
        releaseDate: release_date,
        readingTime: time_spent_reading,
        title,
        body,
      },
      type,
      tags,
      id,
    })
  );
