import { usePageContext } from 'contexts/PageContext';
import { IconArrowLeft, IconArrowRight } from 'images';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  LeftArrowContainerDesktop,
  MobileTabsContainer,
  RightArrowContainerDesktop,
} from './styledComponents';
import { ArrowsProps } from './types';
import { RichText } from 'components/v2/RichText';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const ArrowsContainer: React.FC<ArrowsProps> = ({
  itemsLength,
  isLeftArrowActive,
  isRightArrowActive,
  onArrowClick,
  tabName,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { type, tags } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    prefix: type,
  });

  const handleArrowClick = (direction: 'left' | 'right') => {
    onArrowClick(direction);
    logPostHogEvent(
      trackingSelector({
        section: !!sliceId ? sliceId : 'social-proof',
        context: `arrow-${direction}`,
      })
    );
    if (sliceId) {
      logGAElementClickEvent({
        elementId: `arrow-${direction}`,
        sectionId: sliceId,
      });
    }
  };

  return (
    <>
      <LeftArrowContainerDesktop
        isActive={isLeftArrowActive}
        itemsLength={itemsLength}
        onClick={() => handleArrowClick('left')}
        data-tracker={trackingSelector({
          section: !!sliceId ? sliceId : 'social-proof',
          context: 'arrow-left',
        })}
      >
        <IconArrowLeft />
      </LeftArrowContainerDesktop>
      <RightArrowContainerDesktop
        isActive={isRightArrowActive}
        itemsLength={itemsLength}
        onClick={() => handleArrowClick('right')}
        data-tracker={trackingSelector({
          section: !!sliceId ? sliceId : 'social-proof',
          context: 'arrow-right',
        })}
      >
        <IconArrowRight />
      </RightArrowContainerDesktop>
      {!!tabName && (
        <MobileTabsContainer>
          <RichText field={tabName} />
        </MobileTabsContainer>
      )}
    </>
  );
};

export default ArrowsContainer;
