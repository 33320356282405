import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import {
  Block,
  BlocksContainer,
  BlockText,
  BlockTitle,
} from './styledComponents';
import { IBlock } from './types';

const Blocks: React.FC<IBlock> = ({
  blockOneText,
  blockOneTitle,
  blockTwoText,
  blockTwoTitle,
  updatedAccentColor,
}) => (
  <BlocksContainer color={updatedAccentColor}>
    <Block>
      <BlockTitle>
        <NewRichText text={blockOneTitle} />
      </BlockTitle>
      <BlockText>
        <NewRichText text={blockOneText} />
      </BlockText>
    </Block>
    <Block>
      <BlockTitle>
        <NewRichText text={blockTwoTitle} />
      </BlockTitle>
      <BlockText>
        <NewRichText text={blockTwoText} />
      </BlockText>
    </Block>
  </BlocksContainer>
);

export default Blocks;
