import { useEffect } from 'react';
import { useLiveChat } from './useLiveChat';

export const useScrollBlock = (isActive?: boolean) => {
  const { hideChatBubble, showChatBubble } = useLiveChat();

  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
      hideChatBubble();
    } else {
      document.body.style.overflow = 'unset';
      showChatBubble();
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isActive]);
};

export const useLockBodyScroll = (isHeaderToggled?: boolean) => {
  useEffect(() => {
    if (isHeaderToggled) {
      let scrollPosition = 0;
      scrollPosition = window.pageYOffset;
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollPosition}px`;
      document.body.style.width = '100%';
      return () => {
        document.body.style.removeProperty('overflow');
        document.body.style.removeProperty('position');
        document.body.style.removeProperty('top');
        document.body.style.removeProperty('width');
        window.scrollTo(0, scrollPosition);
      };
    }
  }, [isHeaderToggled]);
};

export const useScroll = (isVisible: boolean, action: () => void) => {
  useEffect(() => {
    window.addEventListener('scroll', action);
    return () => {
      window.removeEventListener('scroll', action);
    };
  }, [isVisible]);
};
