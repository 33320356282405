import styled from '@emotion/styled';

export const StyledSkeletonCardWrapper = styled.div({
  display: 'flex',
  gap: '1rem',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: '350px',
});

export const StyledSkeletonWrapper = styled.div({
  width: '100%',
  display: 'flex',
  gap: '2rem',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '80px 20px',
});
