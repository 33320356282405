import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';

import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId, scrollSmooth } from 'features/utils';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { IScreenProps } from 'utils/screenTypeHOC';
import Features from './Features';
import ImageVideoPlayer from './ImageVideoPlayer';
import Reviews from './Reviews';
import {
  CtaContainer,
  CtaPricing,
  CtaStart,
  StyledContainer,
  StyledTextContainer,
  StyledWrapper,
  Subtitle,
  Title,
} from './styledComponents';
import { THeroSlice } from './types';

const Hero: React.FC<THeroSlice & IScreenProps> = ({
  primary: {
    title,
    sub_title: subtitle,
    link_label_start: linkLabelStart,
    link_to_start: linkToStart,
    link_label_pricing: linkLabelPricing,
    link_to_pricing: linkToPricing,
    image,
    review_trustpilot: reviewTrustpilot,
    review_stars: reviewStars,
    review_text: reviewText,
    accent_color: accentColor,
    button_color: buttonColor,
    video_embed: videoEmbed,
    is_money_back_visible,
  },
  items,
  pageContext: { tags, type },
}) => {
  const isReviewOn = reviewStars.url || reviewTrustpilot.url || reviewText.text;

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.HERO,
    prefix: type,
    type: 'cta',
  });
  const isMoneyBackVisible = is_money_back_visible ?? true;
  return (
    <StyledWrapper
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledContainer>
        <StyledTextContainer color={accentColor} isReviewOn={isReviewOn}>
          <Reviews
            isVisible={isReviewOn}
            review_stars={reviewStars}
            review_trustpilot={reviewTrustpilot}
            review_text={reviewText}
            isStarsAdded={!!reviewStars.url}
          />
          <Title isReviewOn={isReviewOn} color={accentColor}>
            <RichText field={title} />
          </Title>
          <Subtitle>
            <RichText field={subtitle} />
          </Subtitle>
          <CtaContainer>
            <CtaStart
              linkId={trackingSelector({ context: 'primary' })}
              linkClassName={trackingSelector({ context: 'primary' })}
              dataTracker={trackingSelector({ context: 'primary' })}
              sendTrackingEvent={() =>
                track(trackingSelector({ context: 'primary' }))
              }
              isVisible={linkLabelStart.text && linkToStart.url}
              ctaText={linkLabelStart.text}
              ctaLink={linkToStart.url}
              themeName={BUTTON_THEMES[buttonColor]}
              themeType={BUTTON_TYPES.PRIMARY}
            />
            <CtaPricing
              linkId={trackingSelector({ context: 'secondary' })}
              linkClassName={trackingSelector({ context: 'secondary' })}
              dataTracker={trackingSelector({ context: 'secondary' })}
              sendTrackingEvent={() =>
                track(trackingSelector({ context: 'secondary' }))
              }
              isVisible={linkLabelPricing.text && linkToPricing.url}
              ctaText={linkLabelPricing.text}
              ctaLink={linkToPricing.url}
              themeName={BUTTON_THEMES.CONVERSION}
              themeType={BUTTON_TYPES.SECONDARY}
              onClick={scrollSmooth}
            />
          </CtaContainer>
          <MoneyBackTag isVisible={isMoneyBackVisible && !!linkToStart.url} />
        </StyledTextContainer>
        <ImageVideoPlayer
          image={image}
          video={videoEmbed}
          buildClickId={() => trackingSelector({ context: 'image-video' })}
          tags={tags}
          type={type}
        />
      </StyledContainer>
      <Features isVisible={!!items.length} features={items} />
    </StyledWrapper>
  );
};

export default Hero;
