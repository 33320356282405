import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { usePageContext } from 'contexts/PageContext';
import React, { useEffect, useState } from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  BadgeText,
  ButtonContainer,
  HideableBadge,
  StyledButton,
  SwitchContainer,
} from './styledComponents';

import { IRadioButtons } from './types';

const RadioButtons: React.FC<IRadioButtons> = ({
  badgeText,
  options,
  onChange,
  labelTextPlacement,
  name,
}) => {
  const { tags, type } = usePageContext();
  const [selectedValue, setSelectedValue] = useState(options[0].value);

  const getButtonType = (value: string, selectedValue: string) => {
    return selectedValue === value
      ? BUTTON_TYPES.ADDITIONAL
      : BUTTON_TYPES.SECONDARY;
  };

  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);

  const buildClickSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: `${name}-radio-button`,
    type: 'button',
  });

  return (
    <>
      <SwitchContainer>
        <ButtonContainer>
          {options.map(({ label, value }, index) => (
            <StyledButton
              key={index}
              gutterTop={TSHIRT_SIZE.XXS}
              gutterBot={TSHIRT_SIZE.XXS}
              fontSize={TSHIRT_SIZE.M}
              themeName={BUTTON_THEMES.UNIMPORTANT}
              themeType={getButtonType(value, selectedValue)}
              onClick={() => {
                setSelectedValue(value),
                  track(buildClickSelector({ context: label }));
              }}
              id={buildClickSelector({ context: label })}
              className={buildClickSelector({ context: label })}
              data-tracker={buildClickSelector({ context: label })}
            >
              {label}
            </StyledButton>
          ))}
          <HideableBadge
            isVisible={badgeText?.length}
            labelTextPlacement={labelTextPlacement}
          >
            <BadgeText>{badgeText}</BadgeText>
          </HideableBadge>
        </ButtonContainer>
      </SwitchContainer>
    </>
  );
};

export default hideable(RadioButtons);
