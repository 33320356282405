import { IconBannerClose } from 'images';
import { useEffect, useState } from 'react';
import {
  Disclaimer,
  HideableBackdrop,
  HideableForm,
  TextContainer,
} from './styledComponents';
import { useHash } from 'hooks/useHash';
import { removeScript, shouldScriptLoad } from './utils';
import { ISP_WAITLIST } from '../../constants/common';

const LeadsPopup = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { clearHash, hash } = useHash();

  useEffect(() => {
    setIsPopupVisible(hash === ISP_WAITLIST);
  }, [hash]);

  useEffect(() => {
    isPopupVisible && shouldScriptLoad();

    return () => removeScript();
  }, [isPopupVisible]);

  const closePopup = () => {
    setIsPopupVisible(false);
    clearHash();
  };
  return (
    <>
      <HideableBackdrop isVisible={isPopupVisible} onClick={closePopup} />
      <HideableForm isVisible={isPopupVisible}>
        <IconBannerClose onClick={closePopup} />
        <TextContainer>
          <h2>
            Exciting news: Static residential (ISP) proxies with Pay/IP option
            coming soon!
          </h2>
        </TextContainer>
        <div className="_form_13"></div>
        <Disclaimer>
          <p>
            By providing your email address, you agree to receive occasional
            communications from us, like newsletters or exclusive offers. You
            can opt out anytime by using an unsubscribe link in the emails.
          </p>
        </Disclaimer>
      </HideableForm>
    </>
  );
};

export default LeadsPopup;
