import { useLocation } from '@reach/router';

export const useGetLangLink = () => {
  const location = useLocation();

  const getLangLink = (language: { key: string; url?: string }) => {
    switch (language.key.toLowerCase()) {
      case 'english':
        return location.pathname.startsWith('/') ? undefined : language.url;
      default:
        return language.url;
    }
  };

  return getLangLink;
};
