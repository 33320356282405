import { BUTTON_THEMES } from 'components/Button';
import { COLOR_SHADE } from 'theme';
import { PrismicInputField } from 'types';
import { IItem } from './types';

export const getPricingPlans = (
  theme: string,
  items: IItem[],
  setIsBillingYearly: (arg: boolean) => void,
  biggerPlanCtaLink: PrismicInputField,
  biggerPlanCtaText: string,
  isBillingYearly: boolean,
  inputValue: number
) => {
  const monthlyItems = items.filter(
    ({ billing_type: billingType }) => !billingType
  );

  const yearlyItems = items.filter(
    ({ billing_type: billingType }) => billingType
  );
  const pricingPlansModel = isBillingYearly ? yearlyItems : monthlyItems;
  const pricingPlansLength = pricingPlansModel.length;
  const pricingPlans = pricingPlansModel.map((item, index) => ({
    ...item,
    position: index + 0.5,
  }));
  const currentPlan = pricingPlans.find((item) => item.position >= inputValue);
  const isMaxValue = inputValue > pricingPlans[pricingPlansLength - 1].position;
  const ctaLink = isMaxValue
    ? biggerPlanCtaLink.url
    : currentPlan!.cta_link.url;
  const ctaText = isMaxValue ? biggerPlanCtaText : currentPlan!.cta_text;
  const rangeHandleTitle = isMaxValue ? '' : currentPlan!.plan_title;

  const handleSwitchBillingType = () => {
    setIsBillingYearly(!isBillingYearly);
  };
  const marks = pricingPlans.reduce((acc, current, index) => {
    acc[index + 0.5] = current.scale_label;
    return acc;
  }, {});

  const sliderTheme = getSliderTheme(theme);

  return {
    pricingPlans,
    currentPlan,
    isMaxValue,
    ctaLink,
    ctaText,
    rangeHandleTitle,
    handleSwitchBillingType,
    marks,
    sliderTheme,
  };
};

export const getSliderTheme = (theme: string) => {
  const sliderThemeMap = new Map([
    [BUTTON_THEMES.FOLLOWING, COLOR_SHADE.SECONDARY],
    [BUTTON_THEMES.CONVERSION, COLOR_SHADE.ERROR],
    [BUTTON_THEMES.IMPORTANT, COLOR_SHADE.PRIMARY],
    [BUTTON_THEMES.UNIMPORTANT, COLOR_SHADE.ACCENT],
  ]);

  return sliderThemeMap.get(theme as BUTTON_THEMES) || COLOR_SHADE.SECONDARY;
};
