import { Link } from 'components/GatsbyLink';
import { SECTION_NAMES } from 'constants/common';
import {
  ArticleContainer,
  StyledArticleInfo,
  StyledAuthorWrapper,
  BlogArticleImageWrapper,
  ArticleTitleShorter,
  ArticleDescription,
  StyledPostTags,
} from 'features/ArticleThumbnails/styledComponents';
import * as React from 'react';
import { PrismicTextField } from 'types';
import { initBuildTrackingSelector } from 'utils/page';
import GatsbyImg from './GatsbyImg';
import { Badge, IconClock } from '@smartproxy-web/ui';
import theme from 'theme';
import { Box, TGatsbyImg } from '@smartproxy-web/ui';
import { PostTopic } from 'features/ArticleThumbnails/types';
import { usePageContext } from 'contexts/PageContext';
import { StyledAuthorAvatar } from '@smartproxy-web/features/src/components/BlogPostIntro/styledComponents';
import { Text } from './v2/Text';
import { PostTag } from '@smartproxy-web/features/src/components/BlogPostIntro/PostTags';
import { logPostHogEvent } from '@smartproxy-web/analytics';

interface IThumbnail {
  url: string;
  title?: string;
  isNew?: boolean;
  excerpt?: PrismicTextField;
  heroImage?: TGatsbyImg;
  topics?: PostTopic[];
  author?: {
    image: TGatsbyImg;
    name: string;
  };
  publishDate?: string;
  readingTime?: number;
}

const ArticleThumbnail: React.FC<IThumbnail> = ({
  url,
  isNew,
  title,
  excerpt,
  heroImage,
  author,
  publishDate,
  readingTime,
  topics,
}) => {
  const { tags, type } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.RELATED_ARTICLES,
    prefix: type,
  });

  return (
    <ArticleContainer>
      <Link
        to={url}
        data-tracker={trackingSelector({ context: 'image-' + title })}
        onClick={() =>
          logPostHogEvent(trackingSelector({ context: 'image-' + title }))
        }
      >
        <BlogArticleImageWrapper>
          <GatsbyImg image={heroImage} />
        </BlogArticleImageWrapper>
      </Link>
      <StyledPostTags>
        {isNew && (
          <Badge variant="outlined" size="lg" color="gradient">
            New
          </Badge>
        )}
        {!!topics &&
          topics.map(({ label, url }) => (
            <PostTag
              key={label}
              url={url}
              label={label}
              trackingId={trackingSelector({ context: `${label}-tag` })}
            />
          ))}
      </StyledPostTags>
      <Link
        to={url}
        data-tracker={trackingSelector({ context: 'image-' + title })}
        onClick={() =>
          logPostHogEvent(trackingSelector({ context: 'image-' + title }))
        }
      >
        <ArticleTitleShorter>{title}</ArticleTitleShorter>
        <ArticleDescription>{excerpt.text}</ArticleDescription>
      </Link>
      <StyledArticleInfo>
        <Box
          sx={{
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '12px 16px',
            }}
          >
            {!!author && (
              <StyledAuthorWrapper>
                <StyledAuthorAvatar>
                  <GatsbyImg image={author.image} />
                </StyledAuthorAvatar>
                <Text size="sm">{author.name}</Text>
              </StyledAuthorWrapper>
            )}
            <Text size="sm" sx={{ color: theme.colors.neutral.N40 }}>
              {publishDate}
            </Text>
            {!!readingTime && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  color: theme.colors.neutral.N40,
                }}
              >
                <IconClock />
                <Text size="sm">{readingTime} min read</Text>
              </Box>
            )}
          </Box>
        </Box>
      </StyledArticleInfo>
    </ArticleContainer>
  );
};

export default ArticleThumbnail;
