import Hero from 'features/Blog';
import { SliceMap } from 'types';

export enum FAQ_SLICES {
  Hero = 'hero_block',
}

export const faqSliceMap: SliceMap<FAQ_SLICES> = {
  [FAQ_SLICES.Hero]: Hero,
};
