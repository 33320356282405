import styled from '@emotion/styled';
import { FontsTypesStyles, HeadingsSyles, ListItemStyles } from 'commonStyles';
import { Link } from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_DISPLAY,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { DOM_STATE, TEXT_TYPE, TSHIRT_SIZE } from '../constants';

export const Heading1 = styled.h1<ITheme>((props) => ({
  ...HeadingsSyles,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    lineHeight: '48px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const Heading2 = styled.h2<ITheme>((props) => ({
  ...HeadingsSyles,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H2),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
  },
}));

export const Heading3 = styled.h3<ITheme>((props) => ({
  ...HeadingsSyles,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
}));

export const Heading4 = styled.h4<ITheme>((props) => ({
  ...HeadingsSyles,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
}));

export const Heading5 = styled.h5<ITheme>((props) => ({
  ...HeadingsSyles,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H5),
}));

export const Heading6 = styled.h6<ITheme>((props) => ({
  ...HeadingsSyles,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
}));

export const ListOrdered = styled.ol<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  paddingLeft: '15px',
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  li: {
    ...ListItemStyles,
  },
}));

export const List = styled.ul<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
  paddingLeft: '15px',
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  li: {
    ...ListItemStyles,
  },
}));

export const Paragraph = styled.p<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H6),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
  fontWeight: 400,
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  margin: '0 auto 27px',
  ...FontsTypesStyles,
  '&:empty': {
    display: CSS_DISPLAY.NONE,
    ':last-child': {
      marginBottom: '0',
    },
  },
}));

export const PreformattedText = styled.pre<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  fontWeight: 400,
  color: getThemeNeutralColor(props, COLOR_SHADE.LIGHT),
}));

export const Hyperlink = styled(Link)({
  [DOM_STATE.HOVER]: {
    textDecoration: 'underline',
  },
});
