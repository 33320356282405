import styled from '@emotion/styled';
import { textEllipsisStyles } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_TYPE,
  TSHIRT_SIZE,
  TEXT_ALIGN,
} from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeAccentColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  getThemeNeutralColors,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';

export const ProductCardWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const TitleWrapper = styled.div<{ isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded, ...props }) => ({
    textAlign: TEXT_ALIGN.CENTER,
    'h1, h2, h3': {
      marginBottom: isSubtitleAdded
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XL),
    },
  })
);

export const Subtitle = styled.div<ITheme & { isSubtitleCentered: boolean }>(
  ({ isSubtitleCentered, ...props }) => ({
    textAlign: isSubtitleCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    'h2, h3, h4, h5, h6': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      textAlign: TEXT_ALIGN.LEFT,
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  })
);

export const ProductCardContainer = styled.div(() => ({
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  margin: '0 auto',
  width: '100%',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  [MEDIA_QUERIES.TABLET]: {
    flexWrap: CSS_FLEX_WRAP.NOWRAP,
  },
}));

export const ProductCards = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  maxWidth: '1024px',
  gridAutoColumns: '1fr',
  gap: getThemeGutter(props, TSHIRT_SIZE.XL),
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  gridTemplateColumns: '1fr 1fr',
  [MEDIA_QUERIES.TABLET]: {
    rowGap: '30px',
    marginBottom: '30px',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    rowGap: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));

export const ProductCard = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  padding: '20px 20px 25px',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  borderRadius: '12px',
  backgroundColor: getThemeMainColor(props),
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 10%)',
  [MEDIA_QUERIES.TABLET]: {
    width: '80%',
    marginBottom: '30px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: '40px 20px',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
}));

export const ProductImageWrapper = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  height: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  maxHeight: '120px',
  maxWidth: '96px',
  div: {
    img: {
      maxHeight: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      height: '100%',
      width: '100%',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const ProductTextContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  paddingLeft: '20px',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  'h2, h3, h4': {
    textAlign: CSS_ALIGN.START,
    display: CSS_DISPLAY.BLOCK,
    height: 'auto',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
    lineHeight: '26px',
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: CSS_ALIGN.CENTER,
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
      whiteSpace: 'normal',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    paddingLeft: getThemeGutter(props, TSHIRT_SIZE.XXS),
    alignItems: CSS_ALIGN.CENTER,
  },
}));

export const ProductDescription = styled.div<ITheme>((props) => ({
  height: '63px',
  p: {
    ...textEllipsisStyles,
    WebkitLineClamp: '3',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    height: 'auto',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    height: 'auto',
    p: {
      WebkitLineClamp: '4',
    },
  },
}));

export const ProductBadge = styled.p((props) => ({
  width: 'fit-content',
  alignSelf: CSS_ALIGN.FLEX_END,
  padding: `0 ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)} `,
  borderRadius: '10px',
  backgroundColor: getThemeNeutralColors(props).light,
  color: getThemeMainColor(props),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  fontWeight: 500,

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    alignSelf: CSS_ALIGN.CENTER,
  },
}));

export const ProductLink = styled.div<ITheme>((props) => ({
  marginTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.S),
  color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),

  a: {
    fontSize: themev2.fonts.size.p_small,
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
}));
