import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeFontFamily,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const Wrapper = styled.section<ITheme & { background: string }>(
  ({ background, ...props }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    width: '100%',
    background,
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const ReviewsContainer = styled.div({
  width: '100%',
  maxWidth: '1024px',
});

export const ReviewsList = styled.ul<ITheme>((props) => ({
  listStyle: 'none',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: '16px',
  columnGap: '16px',
  padding: 0,
  [MEDIA_QUERIES.TABLET]: {
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '80%',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
  },
}));

export const StyledLinkWrapper = styled(Link)<ITheme>((props) => ({
  display: CSS_DISPLAY.BLOCK,
  textAlign: TEXT_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
}));

export const Review = styled.li<ITheme>((props) => ({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  borderRadius: '12px',
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  boxShadow: '0 0 24px rgba(0,0,0,.1)',
  'p:last-child': {
    margin: 0,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: '15px',
    },
  },
}));

export const Username = styled.div<ITheme>((props) => ({
  p: {
    fontWeight: 500,
    lineHeight: '20px',
    fontFamily: getThemeFontFamily(props),
  },
}));

export const ImageWrapper = styled.div<ITheme>((props) => ({
  width: '60px',
  height: '60px',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  div: {
    borderRadius: '50%',
    img: {
      //for safari browser
      border: '1px solid #fff',
      WebkitBorderRadius: '50%',
    },
  },
}));

export const StarRatingWrapper = styled.div<ITheme>((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  marginTop: getThemeGutter(props),
  height: '20px',
  maxWidth: '127px',
  div: {
    maxHeight: '20px',
  },
}));
