import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import * as React from 'react';
import {
  StyledHeroLogos,
  StyledLogoWrapper,
  StyledHeroLogosWrapperInner,
  StyledHeroLogosTitle,
  StyledHeroLogosWrapper,
} from './styledComponents';
import { HeroLogosProps } from './types';
import { usePageContext } from 'contexts/PageContext';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

export const HeroLogos: React.FC<HeroLogosProps> = ({
  items,
  primary: { title, is_dark_theme: isDarkTheme },
}) => {
  const { tags, type } = usePageContext();

  return (
    <StyledHeroLogosWrapper
      isDarkTheme={isDarkTheme}
      data-section-id={generateSectionsId(SECTION_NAMES.HERO_LOGOS, tags, type)}
    >
      <StyledHeroLogosWrapperInner>
        <StyledHeroLogosTitle>
          <RichText field={title} />
        </StyledHeroLogosTitle>
        <StyledHeroLogos>
          {items.map(({ logo }, index) => (
            <StyledLogoWrapper key={index}>
              <GatsbyImg image={logo} loading="eager" />
            </StyledLogoWrapper>
          ))}
        </StyledHeroLogos>
      </StyledHeroLogosWrapperInner>
    </StyledHeroLogosWrapper>
  );
};
