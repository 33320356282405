import { track } from '@smartproxy/sp-tracker-js';
import {
  DEALS,
  headerIsAdditional,
  headerIsCareers,
  LP,
} from 'constants/common';
import { mapItemsToPaths } from 'features/Dialog/utils';
import { slugify } from 'features/utils';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { useState } from 'react';
import {
  getSessionStorageItem,
  setSessionStorageItem,
} from 'utils/browserStorage';
import { isBrowser } from 'utils/build';
import { buildTrackingSelector, getPageContextFromPathname } from 'utils/page';
import BannerDesktopContainer from './components/BannerDesktopContainer';
import BannerMobileContainer from './components/BannerMobileContainer';
import { BANNER_TOP_CLOSED, BANNER_TOP_SLICES } from './constants';
import { query } from './query';
import { IPrismicBannerTop, TBannerTop } from './types';
import { isTypeExcludedFromPath } from './utils';

const BannerTop: React.FC<TBannerTop> = ({ pathname }) => {
  const [isBannerTopOpen, setIsBannerTopOpen] = useState(true);
  const { data } = useMergePrismicPreviewData(query());
  const {
    body,
    show_in_all_pages: showInAllPages,
    is_hidden_from_paths: isHiddenFromPaths,
    ...rest
  }: IPrismicBannerTop = data.prismicBannerTop.data;
  const pageListSlice = body?.filter(
    (slice) => slice.slice_type === BANNER_TOP_SLICES.PageList
  );
  const { type, tags } = getPageContextFromPathname(pathname);

  const paths = pageListSlice && pageListSlice[0]?.items?.map(mapItemsToPaths);
  const isPathnameInPaths =
    isBrowser && paths ? paths.includes(window.location.pathname) : false;

  const isBannerTopClosed = isBrowser
    ? !!getSessionStorageItem(BANNER_TOP_CLOSED)
    : true;

  const isDealLp =
    isBrowser &&
    isHiddenFromPaths &&
    isTypeExcludedFromPath(location.pathname, [DEALS, LP]);
  const isBannerShown = !isBannerTopClosed && isBannerTopOpen;

  const isBannerTopVisible =
    (isBannerShown && isPathnameInPaths) ||
    (isBannerShown && showInAllPages && !isDealLp);

  const handleClose = () => {
    setIsBannerTopOpen(false);
    setSessionStorageItem(BANNER_TOP_CLOSED, JSON.stringify(true));
    track(closeBtnTrackingSelector);
  };

  const buildClickId = (buttonText: string) => {
    const context = tags[1] ? `-${tags[1]}` : '';
    return `banner-top-${slugify(tags[0] || type)}${context}-${slugify(
      buttonText
    )}-cta`;
  };
  const ctaTrackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    context: 'banner-top',
    type: 'cta',
  });
  const closeBtnTrackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    context: 'banner-top-close',
    type: 'cta',
  });
  return (
    <>
      <BannerDesktopContainer
        isAdditionalHeader={headerIsAdditional(pathname)}
        onClose={handleClose}
        isVisible={isBannerTopVisible}
        setIsDialogOpen={setIsBannerTopOpen}
        ctaId={buildClickId(data.prismicBannerTop.data.cta_label.text)}
        ctaClassName={ctaTrackingSelector}
        closeBtnClassName={closeBtnTrackingSelector}
        {...rest}
      />
      <BannerMobileContainer
        isAdditionalHeader={headerIsCareers(pathname)}
        onClose={handleClose}
        isVisible={isBannerTopVisible}
        setIsDialogOpen={setIsBannerTopOpen}
        ctaId={buildClickId(data.prismicBannerTop.data.cta_label.text)}
        ctaClassName={ctaTrackingSelector}
        closeBtnClassName={closeBtnTrackingSelector}
        {...rest}
      />
    </>
  );
};

export default BannerTop;
