import { track } from '@smartproxy/sp-tracker-js';
import { LinkButton } from 'components/v2/LinkButton';
import React from 'react';
import { PrismicInputField, PrismicTextField } from 'types';
import { ClickTrackingSelectorGeneratorProps } from 'utils/page';
import { StyledCtasWrapper } from '../styledComponents';
import { ctaActionsMap } from 'features/v2/Hero/constants';
import { GOOGLE_SIGN_UP_TEXT } from 'constants/common';

interface CtasProps {
  primaryCtaLink?: PrismicInputField;
  primaryCtaLabel?: PrismicTextField;
  secondaryCtaLink?: PrismicInputField;
  secondaryCtaLabel?: PrismicTextField;
  secondaryCtaAction?: string;
  buildCtaTrackingSelector: (
    props?: ClickTrackingSelectorGeneratorProps
  ) => string;
}

export const Ctas: React.FC<CtasProps> = ({
  primaryCtaLink,
  primaryCtaLabel,
  secondaryCtaLink,
  secondaryCtaLabel,
  secondaryCtaAction,
  buildCtaTrackingSelector,
}) => (
  <StyledCtasWrapper>
    <LinkButton
      to={primaryCtaLink?.url}
      variant="filled"
      color="green"
      data-tracker={buildCtaTrackingSelector({
        context: 'primary',
      })}
      onClick={() =>
        track(
          buildCtaTrackingSelector({
            context: 'primary',
          })
        )
      }
    >
      {primaryCtaLabel?.text}
    </LinkButton>
    {!secondaryCtaAction &&
      !!secondaryCtaLabel?.text &&
      !!secondaryCtaLink?.url && (
        <LinkButton
          to={secondaryCtaLink.url}
          variant="outlined"
          color="white"
          data-tracker={buildCtaTrackingSelector({
            context: 'secondary',
          })}
          onClick={() =>
            track(
              buildCtaTrackingSelector({
                context: 'secondary',
              })
            )
          }
        >
          {secondaryCtaLabel.text}
        </LinkButton>
      )}
    {!!secondaryCtaAction && secondaryCtaLink?.url && (
      <LinkButton
        to={secondaryCtaLink.url}
        variant="filled"
        color="white"
        data-tracker={buildCtaTrackingSelector({
          context: 'secondary',
        })}
        {...ctaActionsMap?.[secondaryCtaAction](
          secondaryCtaLabel?.text || GOOGLE_SIGN_UP_TEXT
        )}
      />
    )}
  </StyledCtasWrapper>
);
