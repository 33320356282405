import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import React from 'react';
import {
  AllTagsLabel,
  AllTagsWrapper,
  ContentContainer,
  HideableButtonContainer,
  StyledAllButton,
  StyledButton,
} from './styledComponents';
import { IAllTagsList } from './types';
import { getTopicLabel, getTopicUrl } from './utils';

const AllTagsList: React.FC<IAllTagsList> = ({
  primary: { label },
  items,
  topicLabel,
}) => (
  <AllTagsWrapper>
    <ContentContainer>
      <AllTagsLabel>{label}</AllTagsLabel>
      <HideableButtonContainer isVisible={!!items.length}>
        <StyledAllButton
          themeName={BUTTON_THEMES.IMPORTANT}
          themeType={BUTTON_TYPES.ADDITIONAL}
          fontSize={TSHIRT_SIZE.M}
          gutterBot={TSHIRT_SIZE.XXS}
          gutterRight={TSHIRT_SIZE.XS}
          gutterTop={TSHIRT_SIZE.XXS}
        >
          {topicLabel}
        </StyledAllButton>
        {items.map(({ topic: { tags, document } }, key) => (
          <Link key={key} to={getTopicUrl(tags)}>
            <StyledButton
              themeName={BUTTON_THEMES.IMPORTANT}
              themeType={BUTTON_TYPES.SECONDARY}
              fontSize={TSHIRT_SIZE.M}
              gutterBot={TSHIRT_SIZE.XXS}
              gutterRight={TSHIRT_SIZE.XS}
              gutterTop={TSHIRT_SIZE.XXS}
            >
              {getTopicLabel(document)}
            </StyledButton>
          </Link>
        ))}
      </HideableButtonContainer>
    </ContentContainer>
  </AllTagsWrapper>
);

export default AllTagsList;
