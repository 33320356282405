import { StyledHeroCtaWrapper } from './styledComponents';
import { LinkButton, LinkButtonProps } from 'components/v2/LinkButton';

interface HeroCtasProps {
  order: number;
  ctasProps: Omit<LinkButtonProps, 'ref'>[];
}

export const HeroCtas = ({ order, ctasProps }: HeroCtasProps) => (
  <StyledHeroCtaWrapper order={order}>
    {ctasProps.map((ctaProps) => (
      <LinkButton
        isForcedInternalLink={!!ctaProps.ctaAction}
        key={ctaProps.to}
        size="lg"
        fullWidth
        {...ctaProps}
      />
    ))}
  </StyledHeroCtaWrapper>
);
