import { NewRichText } from 'components/PrismicRichText';
import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import Item from './ColumnsItem';
import {
  Columns,
  ImageTextColumnContainer,
  Subtitle,
  TitleWrapper,
  Wrapper,
} from './styledComponents';
import { IImageTextColumnsCta } from './types';

const HideableSubtitle = hideable(Subtitle);

const ImageTextColumnsCta: React.FC<IImageTextColumnsCta> = ({
  primary: {
    background_color: background,
    title_field: title,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
    subtitle_and_text_alignment: textAlign,
  },
  items,
  pageContext: { tags, type },
}) => {
  const columnCount = items.length === 4;
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.TEXT_IMAGE_CTA,
    prefix: type,
  });

  return (
    <Wrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.TEXT_IMAGE_CTA,
        tags,
        type
      )}
    >
      <ImageTextColumnContainer>
        <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
          <NewRichText text={title} />
        </TitleWrapper>
        <HideableSubtitle
          isVisible={subtitle?.text}
          isSubtitleCentered={isSubtitleCentered}
        >
          <NewRichText text={subtitle} />
        </HideableSubtitle>
        <Columns
          colNumber={items.length}
          columnCount={columnCount}
          textAlign={textAlign}
        >
          {items.map((props, idx) => (
            <Item
              key={idx}
              {...props}
              selector={trackingSelector({ context: title.text })}
            />
          ))}
        </Columns>
      </ImageTextColumnContainer>
    </Wrapper>
  );
};

export default ImageTextColumnsCta;
