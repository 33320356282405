import Spinner from 'components/Spinner';
import { LoaderWrapper } from 'features/v2/VideoSlice/styledComponents';
import React from 'react';

const LoaderContainer: React.FC = () => {
  return (
    <LoaderWrapper>
      <Spinner color="black" size={32} />
      <p>Loading video...</p>
    </LoaderWrapper>
  );
};

export default LoaderContainer;
