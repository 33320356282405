import RelatedJobsListItem from './RelatedJobsListItem';
import { StyledJobsList } from './styledComponents';
import { IRelatedJobsListProps } from './types';

const RelatedJobsList = ({ jobs }: IRelatedJobsListProps) => (
  <StyledJobsList>
    {jobs.map((job) => (
      <RelatedJobsListItem key={job.title.text} job={job} />
    ))}
  </StyledJobsList>
);

export default RelatedJobsList;
