import { usePageContext } from 'contexts/PageContext';
import { JOB_APPLICATION_FORM_ID } from 'features/JobApplicationForm/constants';
import { buildTrackingSelector } from 'utils/page';
import { StyledJobIntroductionSection } from './styledComponents';
import { IJobIntroductionProps } from './types';
import { RichText } from 'components/v2/RichText';
import { track } from '@smartproxy/sp-tracker-js';
import Button, { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';

const JobIntroduction = ({ title, description }: IJobIntroductionProps) => {
  const { tags, type } = usePageContext();
  const trackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'intro',
    context: title.text,
    type: 'cta',
  });
  return (
    <StyledJobIntroductionSection>
      <RichText field={title} />
      <RichText field={description} />
      <a href={JOB_APPLICATION_FORM_ID}>
        <Button
          themeName={BUTTON_THEMES.CONVERSION}
          themeType={BUTTON_TYPES.PRIMARY}
          onClick={() => track(trackingSelector)}
          data-tracker={trackingSelector}
        >
          Apply now
        </Button>
      </a>
    </StyledJobIntroductionSection>
  );
};

export default JobIntroduction;
