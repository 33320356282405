import { PrismicRichText } from '@prismicio/react';
import React from 'react';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
  Hyperlink,
  List,
  ListOrdered,
  Paragraph,
  PreformattedText,
} from './styledComponents';

interface INewRichText {
  children: React.ReactNode;
}

export const NewRichText = ({ text }) => (
  <PrismicRichText
    field={text?.richText}
    components={{
      heading1: ({ children }: INewRichText) => <Heading1>{children}</Heading1>,
      heading2: ({ children }: INewRichText) => <Heading2>{children}</Heading2>,
      heading3: ({ children }: INewRichText) => <Heading3>{children}</Heading3>,
      heading4: ({ children }: INewRichText) => <Heading4>{children}</Heading4>,
      heading5: ({ children }: INewRichText) => <Heading5>{children}</Heading5>,
      heading6: ({ children }: INewRichText) => <Heading6>{children}</Heading6>,
      oList: ({ children }: INewRichText) => (
        <ListOrdered>{children}</ListOrdered>
      ),
      list: ({ children }: INewRichText) => <List>{children}</List>,
      paragraph: ({ children }: INewRichText) => (
        <Paragraph>{children}</Paragraph>
      ),
      preformatted: ({ children }: INewRichText) => (
        <PreformattedText>{children}</PreformattedText>
      ),
      hyperlink: ({ children, node }: INewRichText) => (
        <Hyperlink to={node.data.url} target={node.data.target}>
          {children}
        </Hyperlink>
      ),
    }}
  />
);
