import { getTypeFromPath } from 'components/utils';
import { CAREERS, DEALS, LP } from 'constants/common';
import {
  initCookiesHandling,
  parseCookies,
  setLastVisitedPageCookie,
} from 'cookies';
import BannerTop from 'features/BannerTop';
import Dialog from 'features/Dialog';
import CookieBanner from 'features/CookieBanner';
import InquiryForm from 'features/InquiryForm';
import NewFooter from 'features/v2/Footer';
import Header from 'features/v2/Header/Header';
import HeaderCareer from 'features/v2/HeaderCareer';
import { HideableHeaderLpDeals } from 'features/v2/HeaderLpDeals/Header';
import { PageProps } from 'gatsby';
import React, { useEffect } from 'react';
import { isBrowser } from 'utils/build';
import LeadsPopup from 'features/LeadsPopup';
import { additionalSiteDomain } from 'constants/build';
import { useBuildContext } from '@smartproxy-web/features';
import { useSpTrackerContext } from 'contexts/SpTrackerContext';
import { useSplitTesting } from 'contexts/SplitTesting';

const PageWrapper: React.FC<PageProps> = ({ children, location }) => {
  const { spTracker } = useSpTrackerContext();
  const currentPath = getTypeFromPath(location.pathname);
  const isDealsOrLpPage =
    location.pathname.startsWith(LP, 1) ||
    location.pathname.startsWith(DEALS, 1);
  const { site } = useBuildContext();
  const isSmartConnection = site === additionalSiteDomain;
  const isCareerPage = currentPath === CAREERS;
  const is404AtBuildTime =
    typeof window === 'undefined' && location.pathname.startsWith('404', 1);
  const { experimentId, variant } = useSplitTesting();

  if (isBrowser) {
    initCookiesHandling();
  }

  useEffect(() => {
    if (!spTracker) return;

    const eventProperties = experimentId
      ? {
          ga: parseCookies(window.document.cookie)['_ga'],
          experimentId,
          variant,
        }
      : undefined;
    spTracker.track('$pageview', eventProperties);
  }, [location, spTracker]);

  useEffect(() => {
    if (isBrowser) {
      setLastVisitedPageCookie();
    }
  }, [location]);

  return (
    <>
      {!is404AtBuildTime && (
        <>
          <Header
            location={location}
            isVisible={!isCareerPage && !isDealsOrLpPage}
          />
          <HeaderCareer isVisible={isCareerPage} />
          <HideableHeaderLpDeals
            isVisible={isDealsOrLpPage}
            primary={{
              logo_alignment: false,
              logo_clickable: true,
            }}
          />
        </>
      )}
      {children}
      {!is404AtBuildTime && (
        <>
          <NewFooter isDealsOrLpPage={isDealsOrLpPage} />
        </>
      )}
      <CookieBanner isVisible={isSmartConnection} />
      <Dialog pathname={location.pathname} />
      <BannerTop pathname={location.pathname} />
      <InquiryForm />
      <LeadsPopup />
    </>
  );
};

export default PageWrapper;
