import { IOption } from 'components/SearchBar/types';
import { PRISMIC_VALUES } from 'constants/common';
import { Paths } from 'paths';
import React, { useEffect, useState } from 'react';
import { isBrowser } from 'utils/build';

import { RichText } from 'components/v2/RichText';
import {
  BlogHero,
  BlogHeroBackgroundContainer,
  BlogHeroImage,
  BlogHeroSubtitle,
  BlogHeroText,
  BlogHeroTitle,
  StyledSearchBar,
} from './styledComponents';
import { IHero } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const Hero: React.FC<IHero> = ({
  primary: { background, accent_color: accentColor, title, subtitle, image },
  articles,
}) => {
  const path = isBrowser && location.pathname;
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const options: IOption[] = articles.map((article) => ({
    label: article.data.title.text,
    value: `/${article.type}/${article.tags.join('/')}`,
  }));

  useEffect(() => {
    path === Paths.blog_page
      ? setSearchPlaceholder(PRISMIC_VALUES.SEARCH_BLOG)
      : setSearchPlaceholder(PRISMIC_VALUES.SEARCH_FAQ);
  }, []);

  return (
    <BlogHeroBackgroundContainer background={background}>
      <BlogHero>
        <BlogHeroText>
          <BlogHeroTitle accentColor={accentColor}>
            <RichText field={title} />
          </BlogHeroTitle>
          <BlogHeroSubtitle accentColor={accentColor}>
            <RichText field={subtitle} />
          </BlogHeroSubtitle>
        </BlogHeroText>
        <BlogHeroImage>
          <GatsbyImg image={image} loading="eager" />
        </BlogHeroImage>
      </BlogHero>
      <StyledSearchBar options={options} placeholder={searchPlaceholder} />
    </BlogHeroBackgroundContainer>
  );
};

export default Hero;
