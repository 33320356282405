import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeAccentColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeGutter,
  getThemeInputFontSizeShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export interface ITableContainer {
  noOfCols: number;
  noOfRows: number;
}
export interface IBackground {
  background: string;
}

export const TabledContainer = styled.div<IBackground & ITheme>(
  ({ background, ...props }) => ({
    background,
    padding: '0 20px',
    width: '100%',
    paddingBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    svg: {
      width: '42px',
      height: '42px',
      margin: `${getThemeGutter(props, TSHIRT_SIZE.S)} auto ${getThemeGutter(
        props,
        TSHIRT_SIZE.XXS
      )}`,
    },
  })
);

export const TitleWrapper = styled.div<ITheme & { isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded, ...props }) => ({
    textAlign: TEXT_ALIGN.CENTER,
    'h1, h2, h3': {
      marginBottom: isSubtitleAdded
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XL),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H1),
    },
  })
);

export const Subtitle = styled.div<ITheme & { isSubtitleCentered: boolean }>(
  ({ isSubtitleCentered, ...props }) => ({
    textAlign: isSubtitleCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    'h2, h3, h4, h5, h6': {
      textAlign: TEXT_ALIGN.LEFT,
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
  })
);

export const StyledWrapper = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  borderRadius: '12px',
  [MEDIA_QUERIES.TABLET_PORTRAIT]: {
    overflow: 'scroll',
    overflowY: 'hidden',
  },
}));

export const TableContainer = styled.div<ITableContainer>(
  ({ noOfCols, noOfRows }) => ({
    height: 'auto',
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${noOfCols}, minmax(100px, 1fr))`,
    gridTemplateRows: `repeat(${noOfRows}, auto)`,
    gridAutoFlow: 'column',
    [`> div:nth-of-type(-n+${noOfRows})`]: {
      borderLeft: 'none',
      p: {
        textAlign: CSS_ALIGN.START,
      },
    },
  })
);

export const HeaderCell = styled.div<
  IBackground & ITheme & { isLast: boolean }
>(({ background, isLast, ...props }) => ({
  background,
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  color: getThemeMainColor(props),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
  lineHeight: '20px',
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  borderTopRightRadius: isLast ? '12px' : 0,
  ':first-of-type': {
    borderTopLeftRadius: '12px',
  },
}));

export const StyledCell = styled.div<IBackground & ITheme>(
  ({ background, ...props }) => ({
    background,
    borderTop: `1px solid ${getThemeNeutralColor(
      props,
      COLOR_SHADE.INVISIBLE
    )}`,
    borderLeft: `1px solid ${getThemeNeutralColor(
      props,
      COLOR_SHADE.INVISIBLE
    )}`,
    padding: `${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.M
    )} ${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.L
    )} ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    wordBreak: 'break-word',
    p: {
      textAlign: CSS_ALIGN.CENTER,
      fontFamily: getThemeFontFamily(props),
      fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
      margin: 0,
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
      a: {
        display: CSS_DISPLAY.BLOCK,
        marginTop: '4px',
        color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
        textDecoration: 'none',
        fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
        fontFamily: getThemeFontFamily(props),
      },
    },
  })
);

export const HandIconWrapper = styled.div({
  display: CSS_DISPLAY.NONE,
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  },
});
