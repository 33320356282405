import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import Cta from 'components/Cta';
import { usePageContext } from 'contexts/PageContext';
import { slugify } from 'features/utils';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import Job from './Job';
import {
  HeroDescription,
  HeroTextBlock,
  HeroTitle,
  HideableJobs,
  ImageWrapper,
  StyledHeroContainer,
  StyledHeroWrapper,
} from './styledComponents';
import { ICareerHero } from './types';
import { RichText } from 'components/v2/RichText';
import { track } from '@smartproxy/sp-tracker-js';
import { GatsbyImg } from '@smartproxy-web/ui';

const CareerHero: React.FC<ICareerHero> = ({
  primary: {
    accent_color: accentColor,
    button_color: buttonColor,
    cta_link: ctaLink,
    cta_text: ctaText,
    description,
    image,
    title,
  },
  items,
}) => {
  const { tags, type } = usePageContext();
  const buildClickId = (btnType: string) => {
    return `career-hero-${slugify(tags[0] || type)}-${btnType}-cta`;
  };
  const heroCtaTrackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'career-hero',
    context: 'primary',
    type: 'cta',
  });
  return (
    <StyledHeroWrapper>
      <StyledHeroContainer>
        <HeroTextBlock accentColor={accentColor}>
          <HeroTitle accentColor={accentColor}>
            <RichText field={title} />
          </HeroTitle>
          <HeroDescription accentColor={accentColor}>
            <RichText field={description} />
          </HeroDescription>
          <HideableJobs isVisible={!!items.length}>
            {items.map(
              ({ job_document: jobDocument }) =>
                !!jobDocument.document && (
                  <Job
                    key={jobDocument.document.url}
                    job={jobDocument.document}
                  />
                )
            )}
          </HideableJobs>
          <Cta
            ctaLink={ctaLink.url}
            ctaText={ctaText}
            isVisible={ctaLink.url && ctaText}
            themeName={BUTTON_THEMES[buttonColor]}
            themeType={BUTTON_TYPES.PRIMARY}
            linkId={buildClickId('primary')}
            dataTracker={heroCtaTrackingSelector}
            sendTrackingEvent={() => track(heroCtaTrackingSelector)}
          />
        </HeroTextBlock>
        <ImageWrapper>
          <GatsbyImg image={image} loading="eager" fetchPriority="high" />
        </ImageWrapper>
      </StyledHeroContainer>
    </StyledHeroWrapper>
  );
};

export default CareerHero;
