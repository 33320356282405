import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  CtaContainer,
  CtaPricing,
  CtaStart,
  CtaWrapper,
} from './styledComponents';
import { ICta } from './types';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';

const Cta: React.FC<ICta> = ({
  ctaStartLabel,
  ctaStartUrl,
  buttonColor,
  ctaPricingLabel,
  ctaPricingUrl,
  tags,
  type,
  isMoneyBackVisible,
}) => {
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'new-hero',
    prefix: type,
  });

  return (
    <CtaWrapper>
      <CtaContainer>
        <CtaStart
          linkId={trackingSelector({ context: ctaStartLabel.text })}
          linkClassName={trackingSelector({ context: ctaStartLabel.text })}
          dataTracker={trackingSelector({ context: ctaStartLabel.text })}
          sendTrackingEvent={() =>
            track(trackingSelector({ context: ctaStartLabel.text }))
          }
          isVisible={ctaStartLabel.text && ctaStartUrl.url}
          ctaText={ctaStartLabel.text}
          ctaLink={ctaStartUrl.url}
          themeName={BUTTON_THEMES[buttonColor]}
          themeType={BUTTON_TYPES.PRIMARY}
        />
        <CtaPricing
          linkId={trackingSelector({ context: ctaPricingLabel.text })}
          linkClassName={trackingSelector({ context: ctaPricingLabel.text })}
          dataTracker={trackingSelector({ context: ctaPricingLabel.text })}
          sendTrackingEvent={() =>
            track(trackingSelector({ context: ctaPricingLabel.text }))
          }
          isVisible={ctaPricingLabel.text && ctaPricingUrl.url}
          ctaText={ctaPricingLabel.text}
          ctaLink={ctaPricingUrl.url}
          themeName={BUTTON_THEMES.CONVERSION}
          themeType={BUTTON_TYPES.SECONDARY}
        />
      </CtaContainer>
      <MoneyBackTag isVisible={isMoneyBackVisible && !!ctaStartUrl.url} />
    </CtaWrapper>
  );
};

export default Cta;
