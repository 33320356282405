import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import { StyledHeaderLogoLink } from '../Header/styledComponents';

import { HeaderLpDealsLogoProps } from './types';
import { initDataLayer } from '../Header/helpers';
import { SPHeaderLogo } from 'images';
import { StyledHeaderLogoWrapper } from './styledComonents';

const HeaderLogo: React.FC<HeaderLpDealsLogoProps> = ({
  logoTrackerId,
  isClickable,
}) => (
  <>
    {isClickable ? (
      <StyledHeaderLogoLink
        to="/"
        data-tracker={logoTrackerId}
        onClick={() => {
          track(logoTrackerId), initDataLayer(logoTrackerId);
        }}
      >
        <StyledHeaderLogoWrapper>
          <SPHeaderLogo loading="eager" />
        </StyledHeaderLogoWrapper>
      </StyledHeaderLogoLink>
    ) : (
      <StyledHeaderLogoWrapper>
        <SPHeaderLogo loading="eager" />
      </StyledHeaderLogoWrapper>
    )}
  </>
);

export default HeaderLogo;
