import {
  ECommJsonCutsPositions,
  SERPJsonCutsPositions,
  SIMULATOR_PLANS,
  WebJsonCutsPositions,
} from 'constants/common';
import ECommJsonFile from 'mock/eCommScrapper.json';
import SERPJsonFile from 'mock/SERPScraper.json';
import WebJsonFile from 'mock/WebScraper.json';

export const getJsonCuts = (json, { position1, position2, position3 }) => {
  const stringifyJson = JSON.stringify(json, null, '\t');
  const jsonCut1 = stringifyJson.substring(position1.start, position1.end);
  const jsonCut2 = stringifyJson.substring(position2.start, position2.end);
  const jsonCut3 = stringifyJson.substring(position3.start);

  return { jsonCut1, jsonCut2, jsonCut3 };
};

export const checkForEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
export const initialFormState = { name: '', email: '' };

export const scrollWithMouse = (element) => {
  if (element !== null)
    element!.addEventListener('wheel', (e) => {
      e.preventDefault();
      element!.scrollLeft += e.deltaY;
    });
};

export const getSelectedSimulatorCuts = (selectedSimulator: string) => {
  const getCutsMap = new Map([
    [
      SIMULATOR_PLANS.ECOMMERCE,
      getJsonCuts(ECommJsonFile, ECommJsonCutsPositions),
    ],
    [SIMULATOR_PLANS.WEB, getJsonCuts(WebJsonFile, WebJsonCutsPositions)],
  ]);

  return (
    getCutsMap.get(selectedSimulator as SIMULATOR_PLANS) ||
    getJsonCuts(SERPJsonFile, SERPJsonCutsPositions)
  );
};
