import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice, PrismicTextField } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicQuestions {
  prismicQuestions: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
      question_title: PrismicTextField;
    };
  };
}
export interface Props {
  data: IWithPrismicQuestions;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const Questions = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicQuestions } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicQuestions.data[bodyKey],
    sliceMap
  );
  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          {...slice}
          title={prismicQuestions.data.question_title}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicQuestions.tags,
            type: prismicQuestions.type,
          })}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query QuestionsQuery($id: String!) {
    prismicQuestions(id: { eq: $id }) {
      _previewable
      tags
      type
      ...QuestionsFragment
    }
  }
`;

export default withPrismicPreview(Questions);
