import React from 'react';
import { Box } from 'components/v2/Box';
import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { StyledCard } from './styledComponents';
import { PrismicInputField, PrismicTextField, TGatsbyImage } from 'types';
import { Link } from 'components/GatsbyLink';

interface CardProps {
  icon?: TGatsbyImage;
  heading?: PrismicTextField;
  bodyText?: PrismicTextField;
  link?: PrismicInputField;
  onClick?: () => void;
  trackerId?: string;
}

export const Card: React.FC<CardProps> = ({
  icon,
  heading,
  bodyText,
  link,
  onClick,
  trackerId,
}) => {
  const Card = (
    <StyledCard
      onClick={onClick}
      isCentered={!bodyText?.text}
      isClickable={!!link?.url}
    >
      {icon && <GatsbyImg image={icon} />}
      {heading?.text && (
        <Box
          sx={{
            marginTop: '16px',
            textAlign: !bodyText?.text ? 'center' : 'left',
          }}
        >
          <RichText field={heading} />
        </Box>
      )}
      {bodyText?.text && (
        <Box sx={{ marginTop: heading?.text ? '24px' : '0px' }}>
          <RichText field={bodyText} />
        </Box>
      )}
    </StyledCard>
  );

  return (
    <Box data-tracker={trackerId}>
      {link?.url ? <Link to={link.url}>{Card}</Link> : Card}
    </Box>
  );
};
