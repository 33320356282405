import { IconCheckmarkGreen } from 'images';
import { StyledCheckbox } from './styledComponents';
import { ICheckboxProps } from './types';

const Checkbox = ({ error, handleChange, ...rest }: ICheckboxProps) => (
  <StyledCheckbox hasError={!!error}>
    <input type="checkbox" onChange={handleChange} {...rest} />
    <div aria-hidden="true">
      <IconCheckmarkGreen />
    </div>
  </StyledCheckbox>
);

export default Checkbox;
