export const ONE_HALF = 0.5;
export const RANGE_STEP = 0.01;

export const monthlyItems = (items) => {
  return items.filter(
    ({
      pricing_slider_details: {
        document: {
          data: { type: billingType },
        },
      },
    }) => !billingType
  );
};

export const yearlyItems = (items) => {
  return items.filter(
    ({
      pricing_slider_details: {
        document: {
          data: { type: billingType },
        },
      },
    }) => billingType
  );
};

export const scaleLabelsHandler = (items) => {
  return items.map(
    ({
      pricing_slider_details: {
        document: {
          data: { scale_label },
        },
      },
    }) => scale_label
  );
};
