import { IScraperPlaygroundConfig, TScraperProduct } from './types';
import { socialMediaScraperConfig } from './config/social';
import { serpScraperConfig } from './config/serp';

export const scraperConfig: Record<TScraperProduct, IScraperPlaygroundConfig> =
  {
    social_media: socialMediaScraperConfig,
    serp: serpScraperConfig,
    ecommerce: undefined,
    web: undefined,
  };

export const defaultScraperErrorMessage = `{\n  status: 'error',\n  message: 'Something went wrong. Try again.'\n}`;
