import NotFoundHero from './404';
import { AllArticles } from './AllArticles';
import { AutomatedPricingCards } from './AutomatedPricingCards';
import { NewLandingHero } from './B2BHero';
import BadgesBlock from './BadgesBlock';
import BlogAuthor from './Blog/BlogPost/BlogAuthor';
import CodeBlock from './Blog/BlogPost/CodeBlock';
import ColumnsTextBlock from './Blog/BlogPost/ColumnsTextBlock';
import SideImageTextBlock from './Blog/BlogPost/SideImageTextBlock';
import Breadcrumbs from './Breadcrumbs';
import CareerHero from './CareerHero';
import CaseList from './CaseList';
import CaseStudyColumns from './CaseStudyColumns';
import ColumnLinks from './ColumnsLinks';
import ContentTags from './ContentTags';
import AllTagsList from './ContentTags/AllTagsList';
import CtaBlock from './CtaBlock';
import ExpandablePricingCards from './ExpandablePricingCards';
import FaqQuestions from './FaqQuestions';
import FaqText from './FaqText';
import { FeatureComparisonTable } from './FeatureComparisonTable';
import FeaturedIn from './FeaturedIn';
import FooterBanner from './FooterBanner';
import GatedContent from './GatedContent';
import Hero from './Hero';
import HeroAboutUs from './HeroAboutUs';
import HeroInquiryForm from './HeroInquiryForm';
import Blog from './HomePage/Blog/Blog';
import HomepageHero from './HomePage/Hero';
import HomepageBlogPosts from './HomePage/HomepageBlogPosts';
import ImageTextColumnsCta from './HomePage/ImageTextColumnCta';
import HomepagePricingCards from './HomePage/PricingCards';
import ProductCardBlock from './HomePage/ProductCard/ProductCardBlock';
import TextImageColumn from './HomePage/TextImageColumns/TextImageColumn';
import IconListBlock from './IconListBlock';
import ImageTextColumn from './ImageTextColumn';
import { JobApplicationForm } from './JobApplicationForm';
import { ListWithTitle } from './ListWithTitle';
import LocationFlags from './LocationFlags';
import LpHero from './LpHero';
import NewHero from './NewHero';
import NewPricingCards from './NewPricingCards/NewPricingCards';
import NewTableBlock from './NewTableBlock';
import NewTextImageColumn from './NewTextImageColumn';
import PaymentMethod from './PaymentMethod';
import PricingCards from './PricingCards';
import PricingSlider from './PricingSlider';
import ProductCards from './ProductCards';
import ProxyListCountries from './ProxyListCountries';
import QuestionTitle from './QuestionsTitle';
import RecognisedByBlock from './RecognisedByLogos';
import { RelatedJobs } from './RelatedJobs';
import ResidentialPricingCards from './ResidentialPricingCards';
import ResidentialPricingSlider from './ResidentialPricingSlider';
import SalesRepContacts from './SalesRepContacts';
import ScrapersPlayground from './ScrapersPlayground';
import ScrapingSimulator from './ScrapingSimulator';
import SimulatorNoCode from './SimulatorNoCode';
import { SocialButtonsBar } from './SocialButtonsBar';
import {
  CarouselHero,
  ImageCarouselBlock,
  LogosCarousel,
  SocialProofBlock,
} from '@smartproxy-web/features';
import { Spacing } from './Spacing/Spacing';
import TableBlock from './TableBlock';
import TableOfContent from './TableOfContent';
import TargetsList from './TargetsList';
import Testimonials from './Testimonials';
import TextAndImageBlock from './TextAndImageBlock';
import TextColumns from './TextColumns';
import TextCtaBlock from './TextCtaBlock';
import Title from './Title';
import TrustpilotReviews from './TruspilotReviews';
import { TypeformEmbed } from './TypeformEmbed';
import UseCasesBlock from './UseCasesBlock';
import { HeaderLpDeals } from './v2/HeaderLpDeals';
import { Hero as Herov2 } from './v2/Hero';
import { HeroFeatures } from './v2/HeroFeatures';
import { HeroLogos } from './v2/HeroLogos';
import { HeroLpPPC } from './v2/HeroLpPPC';
import ImageTextBlock from './v2/ImageTextSlice';
import { ProductCardsComponent } from './v2/ProductCards';
import SimpleCardsSlice from './v2/SimpleCardsSlice/SimpleCardsSlice';
import VideoBlock from './v2/VideoSlice';
import VideoNoCode from './VideoNoCode';
import VideoStreaming from './VideoStreaming';
import VisionMission from './VisionMission';
import WebinarRegistrationForm from './WebinarRegistrationForm';
import Author from '@smartproxy-web/features/src/components/Blog/Author';
import {
  BlogParent,
  ComparisonTable,
  KHArticles,
  MiniTabbedCodeBlock,
  TabbedCodeBlock,
  KnowledgeHubHero,
  GettingStarted,
  PrismicCarouselBlock,
  BiggerPlansBanner,
  PrismicTextGrid,
  QuoteComponent,
  FaqBlock,
  SideBannerComponent,
  PrismicImageTabs,
  PrismicBlogImage,
  PromoBanner,
  TextImageDynamic,
  TextBlock,
} from '@smartproxy-web/features';
import { Divider } from '@smartproxy-web/ui';
import { ABtestPricingCards } from './ABtestPricingCards';

export const sliceMap = {
  '404_hero': NotFoundHero,
  all_articles: AllArticles,
  all_tags_list: AllTagsList,
  automated_pricing_cards: AutomatedPricingCards,
  badges_block: BadgesBlock,
  bigger_plans: BiggerPlansBanner,
  blog_author: BlogAuthor,
  blog_image: PrismicBlogImage,
  blog_post_automated: HomepageBlogPosts,
  breadcrumbs: Breadcrumbs,
  career_hero: CareerHero,
  case_study_columns: CaseStudyColumns,
  cases_list: CaseList,
  code_block: CodeBlock,
  code_block_v2: MiniTabbedCodeBlock,
  column_links: ColumnLinks,
  column_text: ColumnsTextBlock,
  content_tags: ContentTags,
  cta_block: CtaBlock,
  dynamic_text_and_image: TextImageDynamic,
  expandable_products_pricing_overview: ExpandablePricingCards,
  faq_block: FaqBlock,
  faq_questions: FaqQuestions,
  faq_text: FaqText,
  feature_comparison_table: FeatureComparisonTable,
  featured_in: FeaturedIn,
  footer_banner_slice: FooterBanner,
  hero_about_us: HeroAboutUs,
  hero_block: Hero,
  hero_logos: HomepageHero,
  hero_v2_features: HeroFeatures,
  hero_v2_logos: HeroLogos,
  hero_v2: Herov2,
  hero_with_contact_sales_form: HeroInquiryForm,
  homepage_pricing_cards: HomepagePricingCards,
  icon_list_block: IconListBlock,
  image_and_text_block: TextAndImageBlock,
  image_tabs: PrismicImageTabs,
  image_text_block: ImageTextBlock,
  image_text_column: ImageTextColumn,
  image_text_columns_with_cta: ImageTextColumnsCta,
  job_application_form: JobApplicationForm,
  list_with_title: ListWithTitle,
  lp_hero: LpHero,
  location_flags: LocationFlags,
  new_hero: NewHero,
  new_landing_hero: NewLandingHero,
  new_table: NewTableBlock,
  new_text_image_columns: NewTextImageColumn,
  payment_method: PaymentMethod,
  pricing_cards_new_: ResidentialPricingCards,
  pricing_cards: PricingCards,
  pricing_slider__new_: ResidentialPricingSlider,
  pricing_slider: PricingSlider,
  product_card: ProductCards,
  product_cards_block: ProductCardBlock,
  product_hero_block: Hero,
  proxy_list_countries: ProxyListCountries,
  proxy_table: TableBlock,
  question_title: QuestionTitle,
  recognised_by_block: RecognisedByBlock,
  related_articles: Blog,
  related_positions: RelatedJobs,
  sales_rep_contacts: SalesRepContacts,
  scraper_no_code: SimulatorNoCode,
  scrapers_playground: ScrapersPlayground,
  scraping_simulator_: ScrapingSimulator,
  side_image_text_block: SideImageTextBlock,
  social_buttons_bar: SocialButtonsBar,
  social_proof_block: SocialProofBlock,
  spacing: Spacing,
  tabbed_code_block: TabbedCodeBlock,
  table_of_content: TableOfContent,
  targets_list: TargetsList,
  testimonials: Testimonials,
  text_and_cta: TextCtaBlock,
  text_block: TextBlock,
  text_column: TextColumns,
  text_grid: PrismicTextGrid,
  text_image_column_list: TextImageColumn,
  title: Title,
  trustpilot_reviews: TrustpilotReviews,
  typeform_embed: TypeformEmbed,
  use_case_block: UseCasesBlock,
  video_block: VideoBlock,
  video_no_code: VideoNoCode,
  video_streaming: VideoStreaming,
  vision_and_mission: VisionMission,
  webinars_registration_form: WebinarRegistrationForm,
  gated_content: GatedContent,
  simple_cards_slice: SimpleCardsSlice,
  ppc_lp_hero: HeroLpPPC,
  lp_deals_header: HeaderLpDeals,
  new_product_cards: ProductCardsComponent,
  comparison_table: ComparisonTable,
  new_pricing_cards: NewPricingCards,
  author_v2: Author,
  divider: Divider,
  knowledge_hub_hero: KnowledgeHubHero,
  getting_started: GettingStarted,
  kh_articles: KHArticles,
  carousel_block: PrismicCarouselBlock,
  blog_parent_block: BlogParent,
  quote_component: QuoteComponent,
  side_banner_component: SideBannerComponent,
  promo_banner: PromoBanner,
  abtestpricingcards: ABtestPricingCards,
  carousel_hero: CarouselHero,
  image_carousel_block: ImageCarouselBlock,
  logos_carousel: LogosCarousel,
} as const;

export type SliceMap = typeof sliceMap;

export type SliceType = keyof SliceMap;
