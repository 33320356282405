import { FILE_SIZE } from 'constants/common';
import { formatList } from 'utils/string';
import { PHONE_REGEX, URL_REGEX } from './constants';
import * as Yup from 'yup';

export const SUPPORTED_FILE_TYPES = [
  '.pdf',
  '.doc',
  '.docx',
  '.png',
  '.jpg',
  '.jpeg',
];

const MAX_FILE_SIZE = 5 * FILE_SIZE.MB;

export const applicationFormValidationSchema = Yup.object().shape({
  full_name: Yup.string()
    .max(50, 'Max of 50 characters allowed')
    .required('Required field'),
  email: Yup.string()
    .email('Invalid email address')
    .max(50, 'Max of 50 characters allowed')
    .required('Required field'),
  phone: Yup.string()
    .max(25, 'Max of 25 characters allowed')
    .matches(PHONE_REGEX, 'Must be a valid phone number')
    .required('Required field'),
  profile_link: Yup.string().matches(URL_REGEX, 'Must be a valid link'),
  consent: Yup.boolean()
    .isTrue('You have to agree with Privacy Notice')
    .required('You have to agree with Privacy Notice'),
  info: Yup.string().max(500, 'Use no more than 500 characters'),
  resume: Yup.mixed()
    .required('Required')
    .test({
      name: 'isValidExtension',
      message: `Choose a ${formatList(SUPPORTED_FILE_TYPES, 'or')} file`,
      test: (file: File) =>
        file &&
        SUPPORTED_FILE_TYPES.some((type) =>
          file.name.toLowerCase().endsWith(type)
        ),
    })
    .test({
      name: 'isWithinSizeLimits',
      message: 'Choose a file lesser than 5MB',
      test: (file: File) => file && file.size < MAX_FILE_SIZE,
    }),
});
