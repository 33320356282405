import styled from '@emotion/styled';
import { BUTTON_TYPES } from 'components/Button';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeButtonConversionColor,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';

export const VideoStreamingWrapper = styled.div((props) => ({
  width: '100%',
  height: '100%',
  padding: `120px 20px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)}`,
  [MEDIA_QUERIES.TABLET]: {
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  },
}));

export const StyledWrapper = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  width: '100%',
  'h1, h2': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H2),
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
}));

export const VideoComponent = styled.div({
  width: '100%',
  aspectRatio: '16/9',
  transition: 'colors 0.5s',
  position: CSS_POSITION.RELATIVE,
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
  },
});

export const VideoContainer = styled.div({
  position: CSS_POSITION.RELATIVE,
});

export const CtaWrapper = styled.div((props) => ({
  width: '100%',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  padding: '60px 0 0',
  '& a:first-of-type': {
    marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      width: '200px',
      margin: 0,
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    paddingTop: '48px',
    a: {
      width: '100%',
    },
    '& a:first-of-type': {
      margin: `0 0 ${getThemeGutter(props, TSHIRT_SIZE.S)}`,
    },
  },
}));

export const Button = styled(Cta)<ITheme>((props) => ({
  width: '220px',
  height: '42px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  background: getThemeButtonConversionColor(props, BUTTON_TYPES.PRIMARY)
    ?.background.main,
  margin: 0,
  [MEDIA_QUERIES.TABLET]: {
    width: '220px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '180px',
    maxWidth: '100%',
  },
}));
