import { Link } from 'components/GatsbyLink';
import { initBuildTrackingSelector } from 'utils/page';
import { List, StyledNavItem } from '../styledComponents';
import { RichText } from 'components/v2/RichText';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const NavItem = ({ category, items }) => {
  const { id: sliceId } = useSliceDetails();
  const trackingSelector = initBuildTrackingSelector({
    section: `footer-${category.text}`,
  });
  return (
    <StyledNavItem>
      <RichText field={category} />
      <List>
        {items.map(({ link_label: linkLabel, link }) => (
          <li key={linkLabel.text}>
            <Link
              to={link.url}
              data-tracker={trackingSelector({ context: linkLabel.text })}
              onClick={() => {
                logPostHogEvent(trackingSelector({ context: linkLabel.text }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `${category.text}:${linkLabel.text}`,
                    sectionId: sliceId,
                  });
                }
              }}
            >
              {linkLabel.text}
            </Link>
          </li>
        ))}
      </List>
    </StyledNavItem>
  );
};

export default NavItem;
