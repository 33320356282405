export const useLiveChat = () => {
  const hideChatBubble = () => {
    if (window?.LiveChatWidget) {
      window.LiveChatWidget.call('hide');
    }
  };

  const showChatBubble = () => {
    if (!!window.LiveChatWidget) {
      window.LiveChatWidget.call('minimize');
    }
  };

  return {
    hideChatBubble,
    showChatBubble,
  };
};
