import { Link } from 'components/GatsbyLink';
import { initBuildTrackingSelector } from 'utils/page';
import {
  GroupContainer,
  LinksContainer,
  StyledTitle,
  SvgContainer,
} from '../styledComponents';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const ActionItem = ({ sectionName, icons, title, links, isDealsOrLpPage }) => {
  const { id: sliceId } = useSliceDetails();
  const trackingSelector = initBuildTrackingSelector({
    section: `footer-${sectionName}`,
  });

  return (
    <GroupContainer>
      <StyledTitle>{title}</StyledTitle>
      <LinksContainer
        itemsNumber={Object.entries(links).length}
        isDealsOrLpPage={isDealsOrLpPage}
      >
        {Object.entries(links).map(([key, link]) => (
          <SvgContainer key={key}>
            <Link
              to={link}
              data-tracker={trackingSelector({ context: key })}
              onClick={() => {
                logPostHogEvent(trackingSelector({ context: key }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `${title}:${key}`,
                    sectionId: sliceId,
                  });
                }
              }}
            >
              {icons(key)}
            </Link>
          </SvgContainer>
        ))}
      </LinksContainer>
    </GroupContainer>
  );
};

export default ActionItem;
