import styled from '@emotion/styled';
import { DOM_STATE } from 'components/constants';
import { Link } from 'components/GatsbyLink';
import { COMPACT_HEADER_MEDIA_QUERY } from 'features/v2/Header/constants';
import { themev2 } from 'theme-v2';

export const StyledHeaderLogoLinkCareers = styled(Link)({
  height: 26,
  width: 263,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    height: 19,
    width: 162,
  },
});

export const StyledHeaderNavInnerCareers = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  isolation: 'isolate',
  zIndex: 2,
  'a:last-of-type': {
    paddingRight: 0,
  },
  [COMPACT_HEADER_MEDIA_QUERY]: {
    position: 'fixed',
    right: 0,
    top: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: themev2.colors.black.transparent,
    backdropFilter: 'blur(10px)',
    width: '260px',
    height: '100%',
    transform: 'translateX(100%)',
    transition: 'transform 0.4s ease-in-out, visibility 0.4s ease-in-out',
  },
});

export const StyledPagedNavCareers = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  overflow: 'hidden',
  [COMPACT_HEADER_MEDIA_QUERY]: {
    flexDirection: 'column',
    padding: '56px 20px 0',
  },
});

export const StyledLink = styled(Link)({
  color: themev2.colors.gray.S300,
  padding: `9px ${themev2.spacings.XS}`,
  fontSize: themev2.fonts.size.p_small,
  lineHeight: themev2.fonts.lineHeight.p_small,
  [DOM_STATE.HOVER]: {
    color: themev2.colors.white.default,
    cursor: 'pointer',
    transition: '0.3s ease-in-out',
  },
  [COMPACT_HEADER_MEDIA_QUERY]: {
    width: '100%',
    justifyContent: 'space-between',
    color: themev2.colors.white.default,
    marginBottom: themev2.spacings.S,
    fontSize: themev2.fonts.size.p_large,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    padding: 0,
    fontWeight: 400,
  },
});
