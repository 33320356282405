import { PRISMIC_VALUES } from 'constants/common';
import { createContext, Dispatch, useContext, useState } from 'react';

const TopicContext = createContext<string>(PRISMIC_VALUES.ALL);
const LabelsContext = createContext<string[]>(['']);
const TopicUpdateContext = createContext<Dispatch<string>>(
  () => PRISMIC_VALUES.ALL
);

export const useTopic = () => useContext(TopicContext);
export const useLabels = () => useContext(LabelsContext);
export const useContextUpdate = () => useContext(TopicUpdateContext);

export const ContextProvider = ({ children, labels }) => {
  const [selectedTopic, setSelectedTopic] = useState(PRISMIC_VALUES.ALL);

  return (
    <TopicContext.Provider value={selectedTopic}>
      <LabelsContext.Provider value={labels}>
        <TopicUpdateContext.Provider value={setSelectedTopic}>
          {children}
        </TopicUpdateContext.Provider>
      </LabelsContext.Provider>
    </TopicContext.Provider>
  );
};
