import { graphql, useStaticQuery } from 'gatsby';

export const query = () =>
  useStaticQuery(graphql`
    query Dialog {
      prismicDialog {
        data {
          body {
            ... on PrismicDialogDataBodyPageList {
              id
              slice_type
              items {
                documents {
                  type
                  tags
                }
              }
            }
          }
          cta_link {
            url
          }
          cta_text
          description {
            richText
            text
          }
          image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          title
          is_visible
          theme
        }
      }
    }
  `);
