import { track } from '@smartproxy/sp-tracker-js';
import GatsbyImg from 'components/GatsbyImg';
import { Box } from 'components/v2/Box';
import { LinkButton } from 'components/v2/LinkButton';
import { Slice, SliceHeader } from 'components/v2/Slice';
import { ToggleButton } from 'components/v2/Toggle';
import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import React, { useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { ProductCard } from './ProductCard';
import { ProductCardsProps } from './types';

export const ProductCardsComponent: React.FC<ProductCardsProps> = ({
  primary: {
    background,
    content_alignment: align = 'center',
    cta_link: ctaLink,
    cta_label: ctaLabel,
    cta_position: ctaPosition = 'top',
    description,
    title,
    toggle_one_label: toggleOneTitle,
    toggle_two_label: toggleTwoTitle,
    cta_icon: ctaIcon,
    cta_icon_position: ctaIconPosition = 'left',
  },
  items = [],
}) => {
  const { tags, type } = usePageContext();
  const [isSecondTabSelected, setIsSecondTabSelected] = useState(false);
  const hasToggle = toggleOneTitle && toggleTwoTitle;
  const hasCta = !!ctaLink?.url && !!ctaLabel?.text;

  const filteredCards = items.filter(({ assign_to_toggle }) => {
    if (!hasToggle || !assign_to_toggle) return true;
    return assign_to_toggle === (isSecondTabSelected ? 'two' : 'one');
  });

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRODUCT_CARDS,
    prefix: type,
  });

  const Cta = hasCta && (
    <LinkButton
      to={ctaLink?.url}
      fullWidthOnMobile
      minWidth="200px"
      variant="outlined"
      color="blue"
      leftIcon={ctaIconPosition === 'left' && <GatsbyImg image={ctaIcon!} />}
      rightIcon={ctaIconPosition === 'right' && <GatsbyImg image={ctaIcon!} />}
      data-tracker={trackingSelector({ context: 'cta-' + ctaLabel?.text })}
      onClick={() =>
        track(trackingSelector({ context: 'cta-' + ctaLabel?.text }))
      }
    >
      {ctaLabel?.text}
    </LinkButton>
  );

  const HeaderActionItems = (
    <>
      {hasToggle && (
        <ToggleButton
          isSecondTabSelected={isSecondTabSelected}
          firstTabText={toggleOneTitle}
          secondTabText={toggleTwoTitle}
          setIsSecondTabSelected={setIsSecondTabSelected}
        />
      )}
      {ctaPosition === 'top' && (
        <Box sx={{ width: { base: '100%', md: 'fit-content' } }}>{Cta}</Box>
      )}
    </>
  );

  return (
    <Slice
      backgroundColor={background}
      id={generateSectionsId(SECTION_NAMES.PRODUCT_CARDS, tags, type)}
    >
      <SliceHeader
        title={title}
        description={description}
        align={align}
        bottomItems={(hasToggle || hasCta) && HeaderActionItems}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: {
            base: 'flex-start',
            md: align === 'left' ? 'flex-start' : 'center',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '24px',
            rowGap: { base: '20px', sm: '36px' },
            width: '100%',
            justifyContent: {
              base: 'flex-start',
              md: align === 'left' ? 'flex-start' : 'center',
            },
            flexDirection: {
              sm: 'row',
              base: 'column',
            },
          }}
        >
          {filteredCards.map((item, index) => {
            const trackerIdCta = trackingSelector({
              context: 'cta-' + item.card_item_title?.text,
            });
            const trackerIdCtaDownload = trackingSelector({
              context: 'cta-download-' + item.card_item_title?.text,
            });
            return (
              <ProductCard
                key={index}
                {...item}
                trackerIdCta={trackerIdCta}
                trackerIdCtaDownload={trackerIdCtaDownload}
              />
            );
          })}
        </Box>
        {ctaPosition === 'bottom' && (
          <Box
            sx={{
              marginTop: { base: '32px', md: '36px' },
              width: { base: '100%', md: 'fit-content' },
            }}
          >
            {Cta}
          </Box>
        )}
      </Box>
    </Slice>
  );
};
