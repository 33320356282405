import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';
import ExpandablePricingCardsListItem from './ExpandablePricingCardsListItem';
import {
  StyledExpandablePricingCardsContainer,
  StyledProductGroupWrapper,
  StyledProductsWrapper,
  StyledExpandableCardsSlice,
} from './styledComponents';
import { IExpandablePricingCardsProps } from './types';
import { RichText } from 'components/v2/RichText';
import { SliceHeader } from '@smartproxy-web/features/src/components/Slice/SliceHeader';

const ExpandablePricingCards = ({
  primary: {
    title,
    description,
    product_line: productLine,
    product_line_description: productLineDescription,
    background,
    checkmark,
  },
  items: products,
}: IExpandablePricingCardsProps) => {
  const { type, tags } = usePageContext();

  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: `expandable-products-pricing-cards-${productLine.text}`,
  });

  return (
    <StyledExpandableCardsSlice
      data-section-id={buildTrackingSelector()}
      background={background}
    >
      <SliceHeader
        title={title}
        description={description}
        sx={{
          paddingTop: { base: '8px', md: '0' },
          marginBottom: { base: '32px', md: '60px', lg: '80px' },
        }}
      />
      <StyledExpandablePricingCardsContainer>
        <StyledProductGroupWrapper>
          <RichText field={productLine} />
          <RichText field={productLineDescription} />
        </StyledProductGroupWrapper>
        <StyledProductsWrapper>
          {products.map((product) => (
            <ExpandablePricingCardsListItem
              key={product.product.text}
              product={product}
              buildTrackingSelector={buildTrackingSelector}
              checkmark={checkmark}
            />
          ))}
        </StyledProductsWrapper>
      </StyledExpandablePricingCardsContainer>
    </StyledExpandableCardsSlice>
  );
};

export default ExpandablePricingCards;
