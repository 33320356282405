import React from 'react';
import { RequestIconContainer, RequestTitle } from '../styledComponents';
import { ITitle } from '../types';

const TitleContainer: React.FC<ITitle> = ({ requestTitle, icon, title }) => (
  <>
    <RequestTitle>{requestTitle}</RequestTitle>
    <RequestIconContainer>
      {icon && <span className="logo-icon">{icon}</span>}
      {title && <span className="title-logo-icon">{title}</span>}
    </RequestIconContainer>
  </>
);

export default TitleContainer;
