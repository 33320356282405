import { graphql, useStaticQuery } from 'gatsby';
import { transformRawConfig } from './useLinkConfig';
import { LinkConfigProvider } from '@smartproxy-web/features';

interface PrismicLinkConfig {
  prismicLinkConfig: {
    data: {
      nofollow_exclusions: { domain: string }[];
    };
  };
}

interface WrapWithProviderProps {
  children: React.ReactNode;
}

const WrapWithProvider = ({ children }: WrapWithProviderProps) => {
  const rawConfig = useStaticQuery<PrismicLinkConfig>(
    graphql`
      query LinkConfig {
        prismicLinkConfig {
          data {
            nofollow_exclusions {
              domain
            }
          }
        }
      }
    `
  );

  const config = transformRawConfig(rawConfig);

  return <LinkConfigProvider config={config}>{children}</LinkConfigProvider>;
};

export default WrapWithProvider;
