import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { BaseSection } from 'components/Section';
import { List } from 'components/v2/List';
import {
  COLOR_SHADE,
  CSS_DISPLAY,
  CSS_POSITION,
  getThemeBrandedColor,
  getThemeFontLineHeight,
  getThemeGutter,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledListWithArticleSection = styled(BaseSection)((props) => ({
  paddingTop: '30px',
  paddingBottom: '30px',
  'h1, h2, h3, h4, h5, h6': {
    textAlign: TEXT_ALIGN.LEFT,
    fontWeight: '600',
  },
  'p, p *': {
    fontSize: themev2.fonts.size.p_large,
  },

  'div > *+*': {
    margin: 0,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
    fontFamily: 'inherit',
  },
}));

export const StyledList = styled(List)((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  listStyle: 'none',

  '&, li': {
    padding: 0,
  },

  'li + li': {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },

  strong: {
    fontWeight: '600',
  },
}));

export const StyledListItem = styled.li((props) => ({
  display: CSS_DISPLAY.FLEX,
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),

  p: {
    margin: `0 0 0 ${getThemeGutter(props, TSHIRT_SIZE.XS)}`,
  },

  svg: {
    position: CSS_POSITION.RELATIVE,
    flexShrink: 0,

    path: {
      fill: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    },
  },
}));
