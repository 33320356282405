import { track } from '@smartproxy/sp-tracker-js';
import { apiEndpoints } from 'apiEndpoints';
import { SCRAPER_VALUES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  FormButton,
  FormComponent,
  FormInput,
  FormLabel,
  FormTitle,
  HideableFormBlock,
  HideableFormContainer,
  ValidationMessage,
} from '../styledComponents';
import { IForm } from '../types';
import { checkForEmail, initialFormState } from '../utils';
import Messages from './Messages';

const Form: React.FC<IForm> = ({
  isFormVisible: formIsVisible,
  setShowMessage = () => {},
  showMessage,
  simulator,
}) => {
  const { type, tags } = usePageContext();
  const [formField, setFormField] = useState(initialFormState);
  const [touched, setTouched] = useState({ name: false, email: false });
  const { request, isError, isSuccess } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.simulators[simulator]
  );

  const submitButtonEnabled =
    !!formField.name &&
    !!formField.email &&
    !!formField.email.match(checkForEmail);

  useEffect(() => {
    setFormField(initialFormState);
    setTouched({ name: false, email: false });
  }, [formIsVisible]);

  const handleChange = (e, field: string) => {
    setFormField({
      ...formField,
      [field]: e.target.value,
    });
  };
  const submitForm = (e) => {
    e.preventDefault();

    if (submitButtonEnabled) {
      request({
        body: { username: formField.name, email: formField.email },
        onFinally: setShowMessage,
      });
    } else {
      setTouched({ name: true, email: true });
    }
  };

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: `scraping-simulator-${simulator}`,
    prefix: type,
  });

  return (
    <HideableFormContainer isVisible={formIsVisible}>
      <HideableFormBlock isVisible={!showMessage}>
        <FormTitle>{SCRAPER_VALUES.FORM_TITLE}</FormTitle>
        <FormComponent noValidate onSubmit={submitForm}>
          <FormLabel>{SCRAPER_VALUES.FORM_LABEL_NAME}</FormLabel>
          <FormInput
            type="text"
            name="name"
            required
            maxLength={25}
            onBlur={() => setTouched({ ...touched, name: true })}
            onChange={(e) => handleChange(e, 'name')}
            onClick={() =>
              track(trackingSelector({ context: 'form-name-field' }))
            }
          />
          <ValidationMessage>
            {touched.name && !formField.name && SCRAPER_VALUES.NAME_VALIDATION}
          </ValidationMessage>
          <FormLabel>{SCRAPER_VALUES.FORM_LABEL_EMAIL}</FormLabel>
          <FormInput
            type="email"
            name="email"
            required
            onBlur={() => setTouched({ ...touched, email: true })}
            onChange={(e) => handleChange(e, 'email')}
            onClick={() =>
              track(trackingSelector({ context: 'form-email-field' }))
            }
          />
          <ValidationMessage>
            {touched.email &&
              !formField.email.match(checkForEmail) &&
              SCRAPER_VALUES.EMAIL_VALIDATION}
          </ValidationMessage>
          <FormButton
            id={trackingSelector({ context: 'form-submit-button' })}
            className={trackingSelector({ context: 'form-submit-button' })}
            data-tracker={trackingSelector({ context: 'form-submit-button' })}
            type="submit"
            submitButtonEnabled={!!submitButtonEnabled}
            onClick={() =>
              track(trackingSelector({ context: 'form-submit-button' }))
            }
          >
            {SCRAPER_VALUES.FORM_SUBMIT_TEXT}
          </FormButton>
        </FormComponent>
      </HideableFormBlock>
      <Messages
        showMessage={showMessage!}
        isSuccess={isSuccess}
        isError={isError}
      />
    </HideableFormContainer>
  );
};

export default Form;
