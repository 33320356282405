import { usePageContext } from 'contexts/PageContext';
import { buildTrackingSelector } from 'utils/page';
import {
  StyledCta,
  StyledJobsListItem,
  StyledJobsListItemDetails,
} from './styledComponents';
import { IJob } from './types';
import { track } from '@smartproxy/sp-tracker-js';

interface IRelatedJobsListItemProps {
  job: IJob;
}

const RelatedJobsListItem = ({ job }: IRelatedJobsListItemProps) => {
  const { tags, type } = usePageContext();

  const clickSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'open-jobs',
    context: job.title.text,
    type: 'link',
  });

  return (
    <StyledJobsListItem>
      <StyledJobsListItemDetails
        to={job.url}
        data-tracker={clickSelector}
        onClick={() => track(clickSelector)}
        isForcedInternalLink
      >
        <p>{job.title.text}</p>
        {!!job.type && (
          <p>
            {!!job.location?.text && <span>{job.location?.text} / </span>}
            <span>{job.type}</span>
          </p>
        )}
      </StyledJobsListItemDetails>
      <div>
        <StyledCta
          to={job.url}
          data-tracker={clickSelector}
          onClick={() => track(clickSelector)}
        >
          <div>
            <span>Details</span>
          </div>
        </StyledCta>
      </div>
    </StyledJobsListItem>
  );
};

export default RelatedJobsListItem;
