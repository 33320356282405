import GatsbyImg from 'components/GatsbyImg';
import { SIGNIN_URL } from 'constants/common';
import React from 'react';
import { ImageWapper, StyledLink } from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import { themev2 } from 'theme-v2';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const ColumnItem: React.FC<IItem> = ({
  icon,
  subtitle,
  link_to: linkTo,
  selector,
}) => {
  const { id: sliceId } = useSliceDetails();
  return (
    <StyledLink
      to={linkTo.url || SIGNIN_URL}
      className={selector}
      data-tracker={selector}
      onClick={() => {
        logPostHogEvent(selector);
        if (sliceId) {
          logGAElementClickEvent({
            elementId: icon.alt,
            sectionId: sliceId,
          });
        }
      }}
    >
      <ImageWapper>
        <GatsbyImg image={icon} />
      </ImageWapper>
      <Box sx={{ color: themev2.colors.neutral.N99 }}>
        <RichText field={subtitle} />
      </Box>
    </StyledLink>
  );
};

export default ColumnItem;
