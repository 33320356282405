import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { DOM_STATE, TEXT_ALIGN } from 'components/constants';
import Cta from 'components/Cta';
import { MAX_BODY_CONTENT_WIDTH } from 'constants/styles';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
} from 'theme';
import { colors } from 'theme-v2/colors';
import { fonts } from 'theme-v2/fonts';
import { spacings } from 'theme-v2/spacings';
import { IColor } from 'types';

export const BackgroundWrapper = styled.div<
  IColor & { backgroundImage: string }
>(({ background, backgroundImage }) => ({
  background,
  backgroundImage: `url(${backgroundImage})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  paddingTop: '100px',
  width: '100%',
  [MEDIA_QUERIES.TABLET]: {
    paddingTop: '70px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    paddingTop: '30px',
  },
}));

export const HeroContent = styled.div({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
  color: colors.white.default,
  maxWidth: MAX_BODY_CONTENT_WIDTH,
  margin: '0 auto',
  padding: '0 20px',
  h1: {
    fontWeight: 600,
    fontSize: fonts.size.large,
    lineHeight: fonts.lineHeight.large,
    margin: '0 auto',
    maxWidth: 'unset',
    textAlign: 'center',
  },
  [MEDIA_QUERIES.TABLET]: {
    h1: {
      fontSize: '76px',
      lineHeight: '114px',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      marginTop: 0,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    alignItems: CSS_ALIGN.FLEX_START,
    h1: {
      textAlign: TEXT_ALIGN.LEFT,
      margin: 0,
      fontSize: '56px',
      lineHeight: '84px',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    h1: {
      fontSize: fonts.size.h1,
      lineHeight: fonts.size.h1,
    },
  },
});

export const TrustpilotContainer = styled.div({
  marginBottom: spacings.S,
  width: '188px',
  height: '40px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(2, auto)',
  gap: '8px',
  alignItems: CSS_ALIGN.CENTER,
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: '24px',
  padding: `0 ${spacings.S}`,
  'p, strong, em': {
    color: colors.white.default,
    marginBottom: 0,
    fontSize: fonts.size.p_medium,
    opacity: 0.5,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '113px',
    height: '30px',
    padding: `0 ${spacings.XS}`,
    gap: '8px',
    'p, em, strong': {
      fontSize: '10px',
    },
  },
});

export const Subtitle = styled.div({
  marginTop: spacings.S,
  'p, em, strong': {
    textAlign: TEXT_ALIGN.CENTER,
    color: colors.white.default,
    fontWeight: 400,
    fontSize: fonts.size.p_medium,
    marginBottom: 0,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      textAlign: TEXT_ALIGN.LEFT,
    },
  },
});

export const FeaturesContainer = styled.div({
  marginTop: spacings.S,
  display: CSS_DISPLAY.FLEX,
  gap: spacings.S,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gap: spacings.XS,
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  },
});

export const Feature = styled.div({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  'p, em, strong': {
    marginBottom: 0,
    marginLeft: '8px',
    color: colors.white.default,
    opacity: 0.8,
    fontWeight: 300,
    fontSize: fonts.size.p_small,
    lineHeight: fonts.size.p_small,
  },
});

export const CtaContainer = styled.div({
  width: '300px',
  height: '40px',
  margin: '36px 0 60px',
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: spacings.M,
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
});

export const StyledCta = styled(Cta)({
  margin: 0,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
  padding: 0,
  fontSize: fonts.size.p_medium,
  fontWeight: 500,
  lineHeight: fonts.lineHeight.p_medium,
  [DOM_STATE.HOVER]: {
    padding: 0,
  },
});

export const RecognisedByContainer = styled.div({
  margin: `0 auto ${spacings.L}`,
  maxWidth: '1024px',
  padding: '0 20px',
  'p, em, strong': {
    fontSize: fonts.size.p_small,
    color: colors.gray.S600,
    fontWeight: 400,
    lineHeight: fonts.lineHeight.p_small,
    marginBottom: spacings.S,
  },
});

export const LogosContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  opacity: 0.6,
  [MEDIA_QUERIES.TABLET]: {
    gap: spacings.S,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
    columnGap: spacings.S,
    rowGap: '16px',
  },
});
