import { graphql, useStaticQuery } from 'gatsby';

export const headerCarrerQuery = () =>
  useStaticQuery(
    graphql`
      query HeaderCareer {
        prismicHeaderCareer {
          data {
            body {
              ... on PrismicHeaderCareerDataBodyLogo {
                id
                slice_type
                primary {
                  logo {
                    gatsbyImageData
                    alt
                  }
                }
              }
              ... on PrismicHeaderCareerDataBodyNavItem {
                id
                slice_type
                items {
                  nav_title
                  nav_url {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  );
