import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import Actions from './components/Actions';
import NavItem from './components/NavItem';
import { FOOTER_SLICES } from './constants';
import FooterBottom from './FooterBottom';
import { footerQuery } from './query';
import { Footer, FooterContainer, NavItemContainer } from './styledComponents';
import SliceContextProvider from 'contexts/SliceContext';

const NewFooter: React.FC<{ isDealsOrLpPage: boolean }> = ({
  isDealsOrLpPage,
}) => {
  const { tags, type } = usePageContext();
  const {
    data: { prismicNewFooter },
  } = useMergePrismicPreviewData(footerQuery());
  const navItems = prismicNewFooter.data.body.filter(
    (slice) => slice.slice_type === FOOTER_SLICES.NAV
  );

  return (
    <SliceContextProvider id="footer" position={-1}>
      <Footer
        data-section-id={generateSectionsId(SECTION_NAMES.FOOTER, tags, type)}
      >
        <FooterContainer isDealsOrLpPage={isDealsOrLpPage}>
          <Actions isDealsOrLpPage={isDealsOrLpPage} />
          {!isDealsOrLpPage && (
            <NavItemContainer>
              {navItems.map(({ primary: { category }, items }) => (
                <NavItem
                  category={category}
                  items={items}
                  key={category.text}
                />
              ))}
            </NavItemContainer>
          )}
        </FooterContainer>
        <FooterBottom />
      </Footer>
    </SliceContextProvider>
  );
};

export default NewFooter;
