import { Highlight } from 'prism-react-renderer';
import React from 'react';
import {
  Line,
  LineContent,
  LineNo,
  StyledCodeBlock,
} from '../styledComponents';

const CodeBlockContainer: React.FC<{ code: string }> = ({ code }) => (
  <StyledCodeBlock data-tracker="scraper-api-response">
    <Highlight code={code} language="javascript">
      {({ className, tokens, getLineProps, getTokenProps }) => (
        <pre className={className}>
          {tokens.map((line, i) => (
            <Line key={i} {...getLineProps({ line, key: i })}>
              <LineNo>{i + 1}</LineNo>
              <LineContent>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token, key })} />
                ))}
              </LineContent>
            </Line>
          ))}
        </pre>
      )}
    </Highlight>
  </StyledCodeBlock>
);

export default CodeBlockContainer;
