import { track } from '@smartproxy/sp-tracker-js';
import { IconClosev2 } from 'images';
import React from 'react';
import { initDataLayer } from '../helpers';
import {
  CloseIconContainer,
  StyledMenuContainer,
  StyledMenuWrapper,
} from '../styledComponents';
import { MenuContent } from './MenuContent';
import { VisibilityContainer } from './styledComponents';
import { IMenuContent } from './types';

export const MenuContainer: React.FC<IMenuContent> = ({
  selected,
  category,
  handleClose,
  buildTrackingId,
}) => {
  const handleCloseClick = (item) => {
    track(
      buildTrackingId({
        section: 'header-dropdown-close',
        type: 'button',
        context: item,
      })
    );
    initDataLayer(
      buildTrackingId({
        section: 'header-dropdown-close',
        type: 'button',
        context: item,
      })
    );
    handleClose();
  };

  const handleCategoryItemClick = (item) => {
    track(
      buildTrackingId({
        context: item,
        section: 'header-dropdown',
        type: 'link',
      })
    );
    initDataLayer(
      buildTrackingId({
        context: item,
        section: 'header-dropdown',
        type: 'link',
      })
    );
    handleClose();
  };

  return (
    <>
      <StyledMenuContainer selected={selected}>
        <VisibilityContainer selected={selected}>
          <CloseIconContainer>
            <IconClosev2
              data-tracker={buildTrackingId({
                context: 'icon',
                section: 'header-dropdown-close',
                type: 'button',
              })}
              onClick={() => handleCloseClick('icon')}
            />
          </CloseIconContainer>
          <MenuContent
            category={category}
            handleClose={handleClose}
            handleCategoryItemClick={handleCategoryItemClick}
            buildTrackingId={buildTrackingId}
          />
        </VisibilityContainer>
      </StyledMenuContainer>
      <StyledMenuWrapper
        data-tracker={buildTrackingId({
          context: 'out-of-menu',
          section: 'header-dropdown-close',
          type: 'field',
        })}
        onClick={() => handleCloseClick('out-of-menu')}
        selected={selected}
      />
    </>
  );
};
