import styled from '@emotion/styled';
import { DOM_STATE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import hideable from 'utils/hideable';

const CookieWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  minHeight: 'auto',
  padding: '15px 20px',
  borderTop: '1px solid rgba(0,0,0,.1)',
  boxShadow: '0 0 24px 0 rgb(0 0 0 / 12%)',
  background: themev2.colors.neutral.N00,
  position: 'fixed',
  top: 'auto',
  bottom: 0,
  left: 0,
  zIndex: 6,
  width: '100%',
});
export const HideableCookieWrapper = hideable(CookieWrapper);

export const CookieContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'auto',
  height: '42px',
  alignItems: 'center',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    height: 'auto',
    flexDirection: 'column',
  },
});

export const CookieImageWrapper = styled.div({
  marginRight: '20px',
  minHeight: '36px',
  minWidth: '36px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginRight: 0,
    marginBottom: '10px',
  },
});

export const CookieTextWrapper = styled.div({
  fontFamily: themev2.fonts.family.additional,
  color: '#122130',
  fontSize: themev2.fonts.size.p_large,
  lineHeight: themev2.fonts.lineHeight.p_large,
  a: {
    fontFamily: themev2.fonts.family.additional,
    marginTop: '4px',
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: '15px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: 'center',
  },
});

export const CookieButtonWrapper = styled.div({
  marginLeft: '20px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: 0,
  },
});

export const CookieButton = styled.button({
  minWidth: '160px',
  background: '#3F51F2',
  padding: '9px 15px',
  margin: 0,
  border: 'none',
  borderRadius: '6px',
  color: themev2.colors.neutral.N00,
  fontSize: themev2.fonts.size.p_medium,
  fontWeight: 500,
  textAlign: 'center',
  lineHeight: '20px',
  [DOM_STATE.HOVER]: {
    opacity: 0.9,
    cursor: 'pointer',
  },
});
