import styled from '@emotion/styled';
import { richTextElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';

export const GatedContentWrapper = styled.div({
  boxShadow: '0 0 14px 0 rgba(29,29,31,.1)',
  background: theme.colors.white.default,
  minHeight: '600px',
  width: '100%',
  marginTop: '-100px',
  paddingTop: '48px',
  zIndex: 5,
  form: {
    fontFamily: theme.fonts.family.main,
    width: '100%',
    '._error': {
      zIndex: '0 !important',
    },
    '#_form_9_submit': {
      padding: '0 !important',
      fontSize: '16px !important',
    },
    '._form-label': {
      fontWeight: '300 !important',
      maxWidth: 'fit-content !important',
      top: '-10px !important',
      padding: '0 4px !important',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    form: {
      '._form-content': {
        width: '100% !important',
      },
    },
  },
});

export const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  rowGap: theme.spacings.S,
  margin: '36px auto 0',
  maxWidth: '600px',
  'h3,h4,h5,h6': {
    color: theme.colors.black.S900,
    fontSize: theme.fonts.size.h2,
    lineHeight: theme.fonts.lineHeight.h2,
    fontWeight: 600,
  },
  'p, a, strong, em': {
    fontFamily: theme.fonts.family.main,
    color: theme.colors.neutral.N50,
    fontSize: theme.fonts.size.p_medium,
    lineHeight: theme.fonts.lineHeight.p_medium,
    marginBottom: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: '400px',
    [richTextElements]: {
      textAlign: 'left',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: '36px 20px 0',
  },
});

export const Disclaimer = styled.div({
  paddingBottom: theme.spacings.S,
  'p, a, strong, em': {
    textAlign: 'center',
    fontFamily: theme.fonts.family.main,
    color: theme.colors.neutral.N50,
    maxWidth: '400px',
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    marginBottom: 0,
    fontWeight: 300,
  },
  [MEDIA_QUERIES.TABLET]: {
    padding: '0 20px',
    'p, a, strong, em': {
      textAlign: 'left',
    },
  },
});

export const HideableGatedContent = hideable(GatedContentWrapper);
