import { PrismicRichText, PrismicRichTextProps } from '@prismicio/react';
import { Hyperlink } from 'components/PrismicRichText/styledComponents';
import { RichTextList } from './StyledComponents';
import { Text } from '../Text';
import { Heading } from '../Heading';
import { PrismicTextField } from 'types';
import { RichTextField } from '@prismicio/types';

interface RichTextProps {
  field?: PrismicTextField;
  components?: PrismicRichTextProps['components'];
  className?: string;
}

export const RichText = ({ field, components, className }: RichTextProps) => {
  if (!(field && field.richText)) return null;

  return (
    <PrismicRichText
      field={field?.richText as RichTextField}
      components={{
        heading1: (props) => <Heading level={1} {...props} />,
        heading2: (props) => <Heading level={2} {...props} />,
        heading3: (props) => <Heading level={3} {...props} />,
        heading4: (props) => <Heading level={4} {...props} />,
        heading5: (props) => <Heading level={5} {...props} />,
        heading6: (props) => <Heading level={6} {...props} />,
        list: (props) => <RichTextList as="ul" {...props} />,
        oList: (props) => <RichTextList as="ol" {...props} />,
        strong: (props) => (
          <Text as="strong" sx={{ fontWeight: '600' }} {...props} />
        ),
        paragraph: (props) => <Text {...props} className={className} />,
        preformatted: (props) => <Text as="pre" {...props} />,
        hyperlink: ({ node, ...props }) => (
          <Hyperlink
            to={node.data.url!}
            target={node.data.target ?? ''}
            {...props}
          />
        ),
        ...components,
      }}
    />
  );
};
