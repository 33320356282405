export const stringContains = (string: string, searchString: string) =>
  string.indexOf(searchString) > -1;

export const getListItemByKey = (
  list: { value: string }[],
  key: string,
  value: string
) => {
  return list.find((item) => item[key] === value);
};
