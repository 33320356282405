import { PRISMIC_VALUES, TEXT_LENGTH_VALUES } from 'constants/common';
import { useLabels, useTopic } from 'context';
import React from 'react';
import { getFilteredArticles } from 'templates/Topic/utils';
import { ArticlesList } from './ArticlesList';
import {
  AllArticlesBackgroundWrapper,
  AllArticlesContainer,
} from './styledComponents';
import { IAllArticles } from './types';

export const AllArticles: React.FC<IAllArticles> = ({
  primary: { background },
  articles,
}) => {
  const selectedTopic = useTopic();
  const topicsLabels = useLabels();
  const hasTopicFilter =
    topicsLabels.includes(selectedTopic) &&
    selectedTopic !== PRISMIC_VALUES.ALL;

  return (
    <AllArticlesBackgroundWrapper background={background}>
      <AllArticlesContainer>
        <ArticlesList
          articles={
            hasTopicFilter
              ? getFilteredArticles(articles, selectedTopic)
              : articles
          }
          descriptionLength={TEXT_LENGTH_VALUES.ARTICLES_DESCRIPTION}
          isHomepage={false}
        />
      </AllArticlesContainer>
    </AllArticlesBackgroundWrapper>
  );
};
