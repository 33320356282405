import React from 'react';
import { StyledImageWrapper, StyledReviews } from './styledComponents';
import { IReviews } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const Reviews: React.FC<IReviews> = ({ trustpilotLogo, trustpilotStars }) => (
  <StyledReviews>
    <StyledImageWrapper>
      <GatsbyImg image={trustpilotLogo} loading="eager" />
    </StyledImageWrapper>
    <StyledImageWrapper>
      <GatsbyImg image={trustpilotStars} loading="eager" />
    </StyledImageWrapper>
  </StyledReviews>
);

export default Reviews;
