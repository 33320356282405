import { PAGE_URL } from 'constants/common';
import React from 'react';
import { Helmet } from 'react-helmet';
import { PrismicInputField, PrismicTextField, TGatsbyImage } from 'types';
import { SeoLinks } from './SeoLinks';
import { defaultSiteDomain } from 'constants/build';
import { useBuildContext } from 'hooks/useBuildContext';

export interface IHead {
  path: string;
  html_language?: PrismicTextField;
  meta_description?: string;
  meta_title?: string;
  canonical_url?: PrismicInputField;
  og_description?: PrismicTextField;
  og_image?: TGatsbyImage;
  og_locale?: PrismicTextField;
  og_page_url?: PrismicInputField;
  og_secure_image?: TGatsbyImage;
  og_site_name?: PrismicTextField;
  og_title: PrismicTextField;
  og_type?: PrismicTextField;
  robots?: string;
  twitter_site?: PrismicTextField;
  twitter_image?: TGatsbyImage;
  twitter_card?: PrismicTextField;
  twitter_title?: PrismicTextField;
  twitter_description?: PrismicTextField;
  twitter_creator?: PrismicTextField;
  twitter_image_alt?: PrismicTextField;
  code_snippet?: PrismicTextField;
}

const Head: React.FC<IHead> = ({
  path,
  html_language,
  meta_description,
  meta_title,
  canonical_url,
  og_description,
  og_image,
  og_locale,
  og_page_url,
  og_secure_image,
  og_site_name,
  og_title,
  og_type,
  robots,
  twitter_site,
  twitter_image,
  twitter_card,
  twitter_title,
  twitter_description,
  twitter_creator,
  twitter_image_alt,
  code_snippet: codeSnippet,
}) => {
  const { site } = useBuildContext();

  const meta = [
    {
      name: 'facebook-domain-verification',
      content: '73ba5tvgxdpmh4nkkf4hbhn38no7p3',
    },
    {
      name: 'description',
      content: meta_description,
    },
    {
      property: 'og:title',
      content: og_title?.text,
    },
    {
      property: 'og:description',
      content: og_description?.text,
    },
    {
      property: 'og:type',
      content: og_type?.text || 'website',
    },
    {
      property: 'og:image',
      content: og_image?.url,
    },
    {
      property: 'og:url',
      content: og_page_url?.url,
    },
    {
      property: 'og:locale',
      content: og_locale?.text || 'en_US',
    },
    {
      property: 'og:image',
      content: og_image?.url,
    },
    {
      property: 'og:image:secure_url',
      content: og_secure_image?.url,
    },
    {
      property: 'og:image:alt',
      content: og_image?.alt,
    },
    {
      property: 'og:site_name',
      content: og_site_name?.text || 'Smartproxy',
    },
    {
      name: 'robots',
      content:
        site !== defaultSiteDomain
          ? 'noindex, nofollow'
          : robots || 'index, follow',
    },
    {
      charSet: 'utf-8',
    },
    {
      name: 'viewPort',
      content: 'width=device-width, initial-scale=1.0',
    },
    {
      name: 'twitter:site',
      content: twitter_site?.text || '@smartproxy_com',
    },
    {
      name: 'twitter:image',
      content: twitter_image?.url,
    },
    {
      name: 'twitter:card',
      content: twitter_card?.text || 'summary_large_image',
    },
    {
      name: 'twitter:title',
      content: twitter_title?.text,
    },
    {
      name: 'twitter:description',
      content: twitter_description?.text,
    },
    {
      name: 'twitter:creator',
      content: twitter_creator?.text || '@smartproxy_com',
    },
    {
      name: 'twitter:image:alt',
      content: twitter_image_alt?.text || twitter_image?.alt,
    },
  ];

  const link = [
    {
      rel: 'canonical',
      href: canonical_url?.url || PAGE_URL + path,
    },
    ...SeoLinks,
  ];

  return (
    <Helmet
      link={link}
      title={meta_title}
      meta={meta}
      htmlAttributes={{ lang: html_language?.text || 'en' }}
    >
      <script type="text/javascript">
        {`(function(w,d,s,r,k,h,m){
	if(w.performance && w.performance.timing && w.performance.navigation) {
		w[r] = w[r] || function(){(w[r].q = w[r].q || []).push(arguments)};
		h=d.createElement('script');h.async=true;h.setAttribute('src',s+k);
		d.getElementsByTagName('head')[0].appendChild(h);
		(m = window.onerror),(window.onerror = function (b, c, d, f, g) {
		m && m(b, c, d, f, g),g || (g = new Error(b)),(w[r].q = w[r].q || []).push(["captureException",g]);})
	}
})(window,document,'//static.site24x7rum.eu/beacon/site24x7rum-min.js?appKey=','s247r','fdd80e82e18f7018a405c106bd50a6f6')`}
      </script>
      {codeSnippet?.text && (
        <script type="application/ld+json" async>
          {`${codeSnippet?.text}`}
        </script>
      )}
    </Helmet>
  );
};

export default Head;
