/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic.
 *
 * @see https://github.com/angeloashmore/gatsby-source-prismic/tree/alpha/packages/gatsby-plugin-prismic-previews
 */

import {
  componentResolverFromMap,
  PrismicUnpublishedRepositoryConfigs,
} from 'gatsby-plugin-prismic-previews';
import About from 'pages/about';
import BlogPage from 'pages/blog';
import CareersPage from 'pages/careers';
import CaseStudies from 'pages/case-studies';
import HowItWorks from 'pages/how-it-works';
import HomePage from 'pages/index';
import ProxyAffiliateProgram from 'pages/proxy-affiliate-program';
import SmartproxyQuickStartGuide from 'pages/smartproxy-quick-start-guide';
import IpCheckerPage from 'pages/what-is-my-ip';
import WhySmartproxy from 'pages/why-smartproxy';
import { linkResolver } from 'prismicConfig';
import Careers from 'templates/Careers';
import CasePost from 'templates/CaseStudies';
import Configuration from 'templates/Configuration';
import Faq from 'templates/Faq';
import Legal from 'templates/Legal';
import Lp from 'templates/Lp';
import Questions from 'templates/Questions';
import ScrapingPage from 'templates/Scraping';
import Targets from 'templates/Targets';
import Topic from 'templates/Topic';
import WhatIsAProxy from 'templates/WhatIsAProxy';
import WhatIsWebScraping from 'templates/WhatIsWebScraping';
import NotFoundPage from './pages/404';
import AppsPage from './templates/Apps';
import Blog from './templates/Blog';
import DealsPage from './templates/Deals';
import IntegrationsPage from './templates/Integrations';
import ProxyPage from './templates/Proxies';
import ProxyListPage from './templates/ProxyList';
import PartnersGuide from './templates/PartnersGuide';
import UseCases from './templates/UseCases';
import KHLanding from 'templates/Devs';
import CouponsPage from './templates/Coupons';
import GlossaryHub from 'templates/Glossary';
import SmartproxyQuickStartGuidePages from 'templates/smartproxy-quick-start-guide';
import Best from 'templates/Best';
/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `withPrismicPreview` and
 * `withPrismicUnpublishedPreview` higher order components.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 * @see https://github.com/angeloashmore/gatsby-source-prismic/tree/alpha/packages/gatsby-plugin-prismic-previews#content-pages-and-templates
 */

export const repositoryConfigs: PrismicUnpublishedRepositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      404: NotFoundPage,
      about: About,
      apps: AppsPage,
      blog_page: BlogPage,
      blog: Blog,
      case_post: CasePost,
      case_studies: CaseStudies,
      configuration: Configuration,
      deals: DealsPage,
      faq: Faq,
      home_page: HomePage,
      how_it_works: HowItWorks,
      integrations: IntegrationsPage,
      legal: Legal,
      lp: Lp,
      proxies: ProxyPage,
      proxy_affiliate_program: ProxyAffiliateProgram,
      proxy_list: ProxyListPage,
      questions: Questions,
      scraping: ScrapingPage,
      smartproxy_quick_start_guide: SmartproxyQuickStartGuide,
      smartproxy_quick_start_guide_pages: SmartproxyQuickStartGuidePages,
      topic: Topic,
      what_is_a_proxy: WhatIsAProxy,
      what_is_my_ip: IpCheckerPage,
      what_is_web_scraping: WhatIsWebScraping,
      why_smartproxy: WhySmartproxy,
      careers_page: CareersPage,
      careers_job_post: Careers,
      targtes: Targets,
      partners_guide: PartnersGuide,
      use_cases: UseCases,
      knowledge_hub_landing: KHLanding,
      coupons: CouponsPage,
      glossary: GlossaryHub,
      best: Best,
    }),
  },
];
