import styled from '@emotion/styled';
import { DOM_STATE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  ITheme,
  getThemeGutter,
  CSS_JUSTIFY_CONTENT,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
} from 'theme';

export const CtaContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  marginTop: '32px',
  a: {
    [DOM_STATE.HOVER]: {
      button: {
        padding: `9px 14px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
        borderWidth: '2px',
      },
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      margin: getThemeGutter(props, TSHIRT_SIZE.XXS),
      width: 'auto',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    },
  },
}));
