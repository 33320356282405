import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import RepeatableZoneItem from './RepeatableZoneItem';
import {
  StyledItem,
  StyledFeaturesWrapper,
  StyledLink,
} from './styledComponents';
import { IItem } from './types';

export const RepeatableZone: React.FC<{
  items?: IItem[];
  isDark?: boolean;
}> = ({ items, isDark }) => {
  const HideableStyledLink = hideable(StyledLink);
  const HideableStyledItem = hideable(StyledItem);

  const trackingSelector = initBuildTrackingSelector({
    section: 'image-text-block-repeatable-zone',
    type: 'link',
  });

  if (!items?.length) return null;

  return (
    <StyledFeaturesWrapper isDark={isDark}>
      {items.map(
        (
          {
            link,
            small_image: image,
            small_image_description: imageDescription,
          },
          index
        ) => (
          <div key={index}>
            <HideableStyledLink
              isVisible={link?.url}
              to={link?.url}
              onClick={() =>
                track(trackingSelector({ context: imageDescription?.text }))
              }
              dataTracker={trackingSelector}
            >
              <RepeatableZoneItem
                image={image}
                imageDescription={imageDescription}
              />
            </HideableStyledLink>
            <HideableStyledItem isVisible={!link?.url}>
              <RepeatableZoneItem
                image={image}
                imageDescription={imageDescription}
              />
            </HideableStyledItem>
          </div>
        )
      )}
    </StyledFeaturesWrapper>
  );
};
