import { LinksListItem } from 'features/ColumnsLinks/styledComponents';
import React from 'react';
import hideable from 'utils/hideable';
import { ipCheckerMsgs } from '../ipCheckerMsgs';
import { IIpInfo } from '../types';
import ListItem from './ListItem';

const BrowserColumn: React.FC<IIpInfo> = ({ info }) => {
  const { ipCheckerBrowser, ipCheckerBrowserName, ipCheckerBrowserVersion } =
    ipCheckerMsgs;

  return (
    <LinksListItem>
      <h2>{ipCheckerBrowser.defaultMessage}</h2>
      <ListItem
        text={ipCheckerBrowserName.defaultMessage}
        info={info.browser?.name}
      />
      <ListItem
        text={ipCheckerBrowserVersion.defaultMessage}
        info={info.browser?.version}
      />
    </LinksListItem>
  );
};

export default hideable(BrowserColumn);
