import Breadcrumbs from 'features/Breadcrumbs';
import { SliceMap } from 'types';

export enum QUESTIONS_SLICES {
  Breadcrumbs = 'breadcrumbs',
}

export const questionsSliceMap: SliceMap<QUESTIONS_SLICES> = {
  [QUESTIONS_SLICES.Breadcrumbs]: Breadcrumbs,
};
