import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

interface IWithPrismicPartnersGuide {
  prismicPartnersGuide: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicPartnersGuide;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const PartnersGuide = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicPartnersGuide } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicPartnersGuide.data[bodyKey],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicPartnersGuide.tags,
            type: prismicPartnersGuide.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query PartnersGuideQuery($id: String!) {
    prismicPartnersGuide(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicPartnersGuideDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyBreadcrumbs {
            id
            slice_label
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              video_embed
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              cta_color
              is_background_dark
              is_split_equally
              title_color
              video_embed
              is_text_centered
              image_zoom_modal
              is_image_right
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
              columns_number
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyTableOfContent {
            id
            slice_type
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              accent_color
              is_text_centered
              is_image_on_left
              is_text_white
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyProductCard {
            id
            slice_type
            items {
              old_product_price
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              feature_one {
                richText
                text
              }
              feature_three {
                richText
                text
              }
              feature_two {
                richText
                text
              }
              icon {
                gatsbyImageData
                url
                alt
              }
              product_price
              price_time_period {
                richText
                text
              }
              product_description {
                richText
                text
              }
              product_title {
                richText
                text
              }
              recommended
              tooltip
              tooltip_text {
                richText
                text
              }
            }
            primary {
              accent_color
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyPaymentMethod {
            id
            slice_type
            primary {
              icons_text {
                richText
                text
              }
              background
              security_description {
                richText
                text
              }
              security_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              security_title {
                richText
                text
              }
            }
            items {
              payment_method_icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyImageTextColumn {
            id
            items {
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            primary {
              background
              numbers_of_columns
              cta_url {
                url
                target
              }
              cta_label {
                richText
                text
              }
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              is_subtitle_centered
            }
          }
          ... on PrismicPartnersGuideDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              columns
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyPricingCards {
            id
            items {
              card_product_type
              card_plan_name
              scraping_api_plan
              is_ddc_non_seq
              billing_name
              is_new
              amount
              billing_type
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              info_text
              is_most_popular
              price
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              vat_text
              card_title
              card_description
              crossed_out_price
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
                url
              }
            }
            primary {
              is_yearly_visible
              background
              feature_one
              feature_three
              feature_two
              feature_four
              feature_five
              toggle_label_text
              switch_discount_label
              switch_monthly_label
              switch_yearly_label
              theme
              product
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              is_subtitle_centered
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              available_cities {
                richText
                text
              }
              background
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              is_subtitle_centered
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyPricingSlider {
            id
            slice_type
            items {
              amount
              billing_type
              billing_name
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              plan_title
              price
              scale_label
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              fourth_feature_label
              fourth_feature_value
              vat_text
              crossed_out_total_price
            }
            primary {
              is_yearly_visible
              background
              bigger_plan_cta_link {
                url
              }
              bigger_plan_cta_text
              bigger_plan_description
              bigger_plan_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              bigger_plan_title
              switch_monthly_label
              switch_yearly_label
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              theme
            }
          }
          ... on PrismicPartnersGuideDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
              cta_title {
                text
                richText
              }
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyPricingCardsNew {
            id
            slice_type
            primary {
              show_top_label
              top_label {
                richText
                text
              }
              background
              is_yearly_visible_
              number_of_columns
              theme
              toggle_label_text
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            items {
              pricing_plan {
                document {
                  ...PricingDetailsFragment
                }
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyPricingSliderNew {
            id
            slice_type
            primary {
              theme
              background
              is_yearly_visible
              subtitle {
                richText
                text
              }
            }
            items {
              pricing_slider_details {
                document {
                  ...PricingSliderDetailsFragment
                }
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicPartnersGuideDataBodyFeatureComparisonTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              cta_text
              cta_link {
                url
              }
              column_header {
                text
                richText
              }
            }
            primary {
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              is_subtitle_centered
            }
          }
          ... on PrismicPartnersGuideDataBodyDynamicTextAndImage {
            id
            primary {
              is_image_on_left
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              is_subtitle_centered
              theme
              background
            }
            items {
              cta_text
              cta_link {
                url
              }
              item_description {
                richText
                text
              }
              item_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              item_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicPartnersGuideDataBodyCodeBlock {
            id
            slice_type
            primary {
              copy_option
              code_field {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicPartnersGuideDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyAutomatedPricingCards {
            id
            items {
              pricing_card {
                type
                document {
                  ...PricingCardFragment
                  ...PricingCardsFeaturesFragment
                }
              }
            }
            slice_type
            primary {
              background_color
              is_limited_time_offer_visible
              enterprise_benefit
              subtitle {
                richText
                text
              }
              switch_enterprise_label
              switch_regular_label
              title {
                richText
                text
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyExpandableProductsPricingOverview {
            id
            items {
              badge_label
              badge_color
              checklist {
                richText
                text
              }
              product {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_starting_price
              price_prefix
            }
            slice_type
            primary {
              checkmark {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
              background
            }
          }
          ... on PrismicPartnersGuideDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
            slice_label
          }
          ... on PrismicPartnersGuideDataBodyCodeBlockV2 {
            id
            slice_label
            slice_type
            items {
              code {
                richText
                text
              }
              language
            }
          }
          ... on PrismicPartnersGuideDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicPartnersGuideDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicPartnersGuideDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPartnersGuideDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(PartnersGuide);
