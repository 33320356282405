import { graphql, useStaticQuery } from 'gatsby';
import { PrismicTextField } from 'types';

export interface PrismicResidentialPromotions {
  prismicPromotions: {
    data: {
      is_timer_visible: boolean;
      is_timer_visible1: boolean;
      offer_description: PrismicTextField;
      offer_description1: PrismicTextField;
      expiry: string;
      expiry1: string;
      should_use_expiry_duration: boolean;
      should_use_expiry_duration1: boolean;
      days: number;
      days1: number;
      hours: number;
      hours1: number;
      minutes: number;
      minutes1: number;
    };
  };
}

export const residentialPromoQuery = (): PrismicResidentialPromotions => {
  return useStaticQuery(graphql`
    query HeaderQuery {
      prismicPromotions(tags: { eq: "residential" }) {
        data {
          is_timer_visible
          is_timer_visible1
          offer_description {
            richText
            text
          }
          offer_description1 {
            richText
            text
          }
          expiry
          expiry1
          should_use_expiry_duration
          should_use_expiry_duration1
          days
          days1
          hours
          hours1
          minutes
          minutes1
        }
      }
    }
  `);
};
