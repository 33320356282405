import styled from '@emotion/styled';
import Button from 'components/Button';
import { DOM_STATE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  BORDER_PROPS,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeAccentColor,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';

export const BadgeText = styled.div<ITheme>((props) => ({
  padding: '2px 10px',
  color: getThemeMainColor(props),
  fontWeight: 600,
  borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
  backgroundColor: getThemeAccentColor(props, ACCENT_COLOR.COLOR2),
}));

export const ButtonContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  position: CSS_POSITION.RELATIVE,
  border: `1px solid ${getThemeMainColor(props, MAIN_COLOR.BLACK)}`,
  borderRadius: '12px',
  padding: '6px 12px',
  whiteSpace: 'nowrap',
  width: '298px',
  'button:first-of-type': {
    marginRight: '5px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const BadgeContainer = styled.div<
  ITheme & { labelTextPlacement: boolean }
>(({ labelTextPlacement }) => ({
  position: CSS_POSITION.ABSOLUTE,
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  top: '-14px',
  right: labelTextPlacement ? '-22px' : '274px',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
    alignSelf: CSS_ALIGN.FLEX_END,
    right: '20px',
    top: labelTextPlacement ? '55px' : '15px',
  },
}));

export const StyledButton = styled(Button)<ITheme>((props) => ({
  width: '140px',
  height: '42px',
  padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.M),
  border: '1px solid transparent',
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  fontWeight: 400,
  [DOM_STATE.HOVER]: {
    padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.M),
    border: `1px solid ${getThemeMainColor(props, MAIN_COLOR.BLACK)}`,
  },
}));

export const SwitchContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const HideableBadge = hideable(BadgeContainer);
