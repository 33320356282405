import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { InfoContainer, TitleAndIconContainer } from '../styledComponents';
import { IText } from '../types';
import { RichText } from 'components/v2/RichText';

const TextComponent: React.FC<IText> = ({ info, icon, title }) => (
  <>
    <TitleAndIconContainer>
      <GatsbyImg image={icon} />
      <RichText field={title} />
    </TitleAndIconContainer>
    <InfoContainer>
      <RichText field={info} />
    </InfoContainer>
  </>
);

export default TextComponent;
