import { SliceContextProvider } from '@smartproxy-web/features';
import { DefaultSlice } from '@smartproxy-web/features/src/types';
import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { SliceType, sliceMap } from 'features';
import { PageProps, graphql } from 'gatsby';
import {
  WithPrismicPreviewProps,
  withPrismicPreview,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

interface PrismicKnowledgeHubLandingData {
  prismicKnowledgeHubLanding: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
interface KnowledgeHubLandingProps {
  data: PrismicKnowledgeHubLandingData;
}

const KnowledgeHubLanding = ({
  data,
  pageContext,
}: KnowledgeHubLandingProps & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicKnowledgeHubLanding, allPrismicBlog } = data;

  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicKnowledgeHubLanding?.data[bodyKey] ?? [],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, idx) => (
        <SliceContextProvider name={slice.slice_type} position={idx} key={idx}>
          <Component
            {...slice}
            pageContext={buildPageContext({
              context: pageContext,
              tags: prismicKnowledgeHubLanding.tags,
              type: prismicKnowledgeHubLanding.type,
            })}
            articles={allPrismicBlog?.nodes}
          />
        </SliceContextProvider>
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query PrismicKnowledgeHubLandingQuery($id: String!) {
    prismicKnowledgeHubLanding(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicKnowledgeHubLandingDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicKnowledgeHubLandingDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicKnowledgeHubLandingDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicKnowledgeHubLandingDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicKnowledgeHubLandingDataBodyDivider {
            id
            slice_type
          }
          ... on PrismicKnowledgeHubLandingDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicKnowledgeHubLandingDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicKnowledgeHubLandingDataBodyKhArticles {
            id
            slice_type
            items {
              selected_topics {
                document {
                  ... on PrismicTopic {
                    id
                    data {
                      topic_label
                    }
                  }
                }
              }
            }
          }
          ... on PrismicKnowledgeHubLandingDataBodyKnowledgeHubHero {
            id
            primary {
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              primary_cta_label {
                text
                richText
              }
              primary_cta_link {
                url
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicKnowledgeHubLandingDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___release_date, order: DESC }
      filter: { tags: { nin: "test" } }
      limit: 10
    ) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy")
        url
        data {
          new_until
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMM DD, yyyy")
          time_spent_reading
          body {
            ... on PrismicBlogDataBodyBlogAuthor {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodyAuthorV2 {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
                is_global
              }
            }
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(KnowledgeHubLanding);
