import { HideableTitles } from 'features/ScrapingSimulator/styledComponents';
import React from 'react';
import Scraper from './components/Scraper';
import { Wrapper } from './styledComponents';
import { IScraperPlayground, TScraperProduct } from './types';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import { normalizeString } from '@smartproxy-web/shared/utils';

const ScrapersPlayground: React.FC<IScraperPlayground> = ({
  primary: { background, title, subtitle, product },
}) => {
  if (!product) return null;

  return (
    <Wrapper background={background}>
      <HideableTitles isVisible={!!title || !!subtitle}>
        <RichText field={title} />
        {!!subtitle && (
          <Box sx={{ marginBottom: '2rem' }}>
            <RichText field={subtitle} />
          </Box>
        )}
      </HideableTitles>
      <Scraper product={normalizeString(product) as TScraperProduct} />
    </Wrapper>
  );
};

export default ScrapersPlayground;
