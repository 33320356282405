import React from 'react';
import {
  Title,
  JSONWrapper,
  JSONContainer,
  JsonCodeField,
  JsonCodeContainer,
} from './styledComponents';
import { TITLES } from './utils';
import NoCodeScrapper from '../../mock/NoCodeScrapper.json';

const JsonResults: React.FC = () => {
  const JSONResults = JSON.stringify(NoCodeScrapper, null, '\t');
  return (
    <JSONWrapper>
      <JSONContainer>
        <Title>{TITLES.JSON}</Title>
        <JsonCodeContainer>
          <JsonCodeField>
            <span>{JSONResults}</span>
          </JsonCodeField>
        </JsonCodeContainer>
      </JSONContainer>
    </JSONWrapper>
  );
};

export default JsonResults;
