import { TSHIRT_SIZE } from 'components/constants';
import React from 'react';
import { IGutterProps } from 'components/utils';
import { IconError } from 'images';
import { ITextInput } from './types';
import { InputWrapper, StyledInput } from './styledComponents';

const TextInput: React.FC<ITextInput & IGutterProps> = ({
  name,
  label,
  context: {
    errors,
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    touched,
    isSubmitting,
  },
  gutterBot = TSHIRT_SIZE.XXS,
  gutterTop = TSHIRT_SIZE.XXS,
  className,
  ...rest
}) => {
  const error = touched[name] && errors[name];
  const handleChange = (value: string) => {
    setFieldValue(name, value);
    setFieldError(name, error);
  };

  const handleBlur = () => {
    setFieldTouched(name, true, true);
    setFieldError(name, error);
  };

  return (
    <InputWrapper gutterTop={gutterTop} gutterBot={gutterBot} {...rest}>
      <StyledInput
        disabled={isSubmitting}
        className={className}
        name={name}
        label={label}
        error={error}
        inputWidth={TSHIRT_SIZE.XXXL}
        inputHeight={TSHIRT_SIZE.M}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
        gutterTop={TSHIRT_SIZE.XXS}
        gutterBot={TSHIRT_SIZE.XXS}
        icon={error && <IconError />}
      />
    </InputWrapper>
  );
};

export default TextInput;
