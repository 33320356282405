import { TSHIRT_SIZE } from 'components/constants';
import { HideableLabelWrapper, Label } from 'components/Input/styledComponents';
import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import List from './components/List';
import { SelectContainer } from './styledComponents';
import { TSelect } from './types';
import { filterOptionsByInputValue, getLabelByValue } from './utils';

const Select: React.FC<TSelect> = ({
  gutterBot = TSHIRT_SIZE.XXS,
  gutterTop = TSHIRT_SIZE.XXS,
  gutterRight = TSHIRT_SIZE.XXS,
  gutterLeft = TSHIRT_SIZE.XXS,
  label: formLabel,
  isSearchable,
  placeholder,
  options,
  onChange,
  value,
  icon,
  disabled,
  dataTracker,
  ...rest
}) => {
  const label = getLabelByValue(options, value);
  const [selectedValue, setSelectedValue] = useState(label);
  const [isListVisible, setListVisibility] = useState(false);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setSelectedValue(label);
  }, [value, label]);

  const filteredOptions = filterOptionsByInputValue(options, inputValue);

  const handleListVisibility = () => {
    setListVisibility(!isListVisible);
  };

  const closeList = () => {
    setListVisibility(false);
  };

  const openList = () => {
    setListVisibility(true);
  };

  const handleSelect = (val: string) => {
    onChange(val);
    setInputValue('');
    setSelectedValue(getLabelByValue(options, val));
    closeList();
  };

  return (
    <SelectContainer
      data-tracker={dataTracker}
      gutterRight={gutterRight}
      gutterLeft={gutterLeft}
      gutterTop={gutterTop}
      gutterBot={gutterBot}
      {...rest}
    >
      <HideableLabelWrapper isVisible={!!formLabel}>
        <Label>{formLabel}</Label>
      </HideableLabelWrapper>
      <Header
        isSearchable={isSearchable}
        disabled={disabled}
        handleListVisibility={handleListVisibility}
        isListVisible={isListVisible}
        onFocus={openList}
        onBlur={closeList}
        placeholder={placeholder}
        onInputChange={setInputValue}
        inputValue={isListVisible ? inputValue : label}
        selectedValue={selectedValue}
        icon={icon}
      />
      <List
        dataTracker={dataTracker}
        options={filteredOptions}
        isVisible={isListVisible}
        onSelect={handleSelect}
      />
    </SelectContainer>
  );
};

export default Select;
