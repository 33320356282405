import { Paragraph } from 'components/PrismicRichText/styledComponents';
import { NO_JOBS_MESSAGE } from './constants';
import RelatedJobsList from './RelatedJobsList';
import {
  StyledRelatedJobsContainer,
  StyledRelatedJobsHeading,
} from './styledComponents';
import { IRelatedJobsProps } from './types';
import { prepareJobsFromSelectedAndRecents, transformRawJob } from './utils';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';

const RelatedJobs = ({
  primary: { background, related_positions_title: title, subtitle },
  jobId,
  items: selectedJobs = [],
  recentJobs = [],
}: IRelatedJobsProps) => {
  const transformedSelectedJobs = selectedJobs
    .filter((item) => !!item.job.document)
    .map((item) => transformRawJob(item.job.document));
  const transformedRecentJobs = recentJobs.map(transformRawJob);

  const relatedJobs = prepareJobsFromSelectedAndRecents({
    selectedJobs: transformedSelectedJobs,
    recentJobs: transformedRecentJobs,
    currentJobId: jobId,
  });

  return (
    <StyledRelatedJobsContainer background={background} id="jobs">
      <div>
        <StyledRelatedJobsHeading>
          <Box sx={{ textAlign: 'center' }}>
            <RichText field={title} />
            {!jobId && !relatedJobs.length && (
              <Paragraph>{NO_JOBS_MESSAGE}</Paragraph>
            )}
          </Box>
          {jobId && !!subtitle.text && <RichText field={subtitle} />}
        </StyledRelatedJobsHeading>
        {!!relatedJobs.length && <RelatedJobsList jobs={relatedJobs} />}
      </div>
    </StyledRelatedJobsContainer>
  );
};

export default RelatedJobs;
