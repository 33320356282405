import {
  IconAmexv2,
  IconApplePayv2,
  IconBTCv2,
  IconCryptov2,
  IconGooglePayv2,
  IconMastercardv2,
  IconPayPalv2,
  IconSSLSecurev2,
  IconVisav2,
} from 'images';
import { PAYMENT_METHODS } from '../constants';
import {
  PaymentIcons,
  PaymentMethodsContainer,
  PaymentMethodsText,
  SecurePayment,
} from '../styledComponents';
import { Product } from 'constants/products';

const productsToExcludeCrypto: Product[] = [
  'datacenter_ip',
  'isp',
  'dedicated_isp',
];

type PaymentMethodsProps = {
  product: Product;
  title?: string;
  description?: string;
};

export const PaymentMethods = ({
  product,
  title = PAYMENT_METHODS.SSL_SECURE_PAYMENT,
  description = PAYMENT_METHODS.SSL_PROTECTED_INFORMATION,
}: PaymentMethodsProps) => (
  <PaymentMethodsContainer>
    <SecurePayment>
      <IconSSLSecurev2 />
      <div>
        <PaymentMethodsText>{title}</PaymentMethodsText>
        <PaymentMethodsText>{description}</PaymentMethodsText>
      </div>
    </SecurePayment>
    <div>
      <PaymentIcons>
        <IconVisav2 />
        <IconMastercardv2 />
        <IconPayPalv2 />
        <IconAmexv2 />
        {!productsToExcludeCrypto.includes(product) && <IconBTCv2 />}
        {!productsToExcludeCrypto.includes(product) && <IconCryptov2 />}
        <IconApplePayv2 />
        <IconGooglePayv2 />
      </PaymentIcons>
    </div>
  </PaymentMethodsContainer>
);
