import { SECTION_NAMES } from 'constants/common';
import { NewRichText } from 'components/PrismicRichText';
import { generateSectionsId, slugify } from 'features/utils';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import { IHomepagePricingCards } from '../types';
import Card from './CardItem';
import {
  CardsList,
  HomepageCardsContainer,
  Wrapper,
  Subtitle,
  TitleWrapper,
} from './styledComponents';
import hideable from 'utils/hideable';

const HideableSubtitle = hideable(Subtitle);

const HomepagePricingCards: React.FC<IHomepagePricingCards> = ({
  primary: {
    background,
    title_field: title,
    column_number: columnNumber,
    subtitle: subtitle,
    is_subtitle_centered: isSubtitleCentered,
  },
  pageContext: { tags, type },
  items,
}) => (
  <Wrapper
    background={background}
    data-section-id={generateSectionsId(
      SECTION_NAMES.PRICING_CARDS,
      tags,
      type
    )}
  >
    <HomepageCardsContainer>
      <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
        <NewRichText text={title} />
      </TitleWrapper>
      <HideableSubtitle
        isVisible={subtitle?.text}
        isSubtitleCentered={isSubtitleCentered}
      >
        <NewRichText text={subtitle} />
      </HideableSubtitle>
      <CardsList columnNumber={columnNumber || 4}>
        {items.map((item, idx) => (
          <Card
            key={idx}
            {...item}
            linkId={`flexible-plan-${slugify(item.card_name?.text)}`}
            linkClassName={buildTrackingSelector({
              page: tags[0] || type,
              section: 'pricing',
              context: item.card_name?.text,
              type: 'cta',
            })}
          />
        ))}
      </CardsList>
    </HomepageCardsContainer>
  </Wrapper>
);

export default HomepagePricingCards;
