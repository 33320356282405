import Checkbox from 'components/Checkbox';
import React from 'react';
import { StyledConsentField } from '../styledComponents';

interface ICheckboxFormControlProps {
  name: string;
  dataTracker?: string;
  checked?: boolean;
  label: React.ReactNode | string;
  onChange?: React.ChangeEventHandler;
  error?: string;
}

const CheckboxFormControl = ({
  name,
  dataTracker,
  checked,
  label,
  onChange,
  error,
}: ICheckboxFormControlProps) => (
  <StyledConsentField>
    <Checkbox
      data-tracker={dataTracker}
      name={name}
      checked={checked}
      error={error}
      handleChange={onChange}
    />
    {typeof label === 'string' ? <p>{label}</p> : label}
  </StyledConsentField>
);

export default CheckboxFormControl;
