import { Heading } from '@smartproxy-web/ui';
import {
  CardBottomContainer,
  CardContainer,
  CardContent,
  StyledBadge,
} from './styledComponents';
import { RichText } from '../RichText';
import { Dots } from './Dots';
import { ProgressBar } from './ProgressBar';
import { CardProps } from './types';

export const CardContentItem = ({
  badgeText,
  cardHeading,
  cardDescription,
  items,
  activeCard,
  isCarouselTouched,
  setActiveCard,
  setIsCarouselTouched,
  swiper,
}: CardProps) => (
  <CardContainer>
    <CardContent>
      {badgeText.text && (
        <StyledBadge color="black" size="lg">
          {badgeText.text}
        </StyledBadge>
      )}
      <Heading
        sx={{
          marginTop: '12px',
          marginBottom: '16px',
        }}
        level={3}
      >
        {cardHeading.text}
      </Heading>
      <RichText field={cardDescription} />
    </CardContent>
    <CardBottomContainer>
      <Dots
        items={items}
        isActive={activeCard}
        setActiveCard={setActiveCard}
        setIsCarouselTouched={setIsCarouselTouched}
        swiper={swiper}
      />
      {!isCarouselTouched && <ProgressBar />}
    </CardBottomContainer>
  </CardContainer>
);
