import styled from '@emotion/styled';
import { MEDIA_QUERIES } from 'globalStyles';
import { theme } from 'theme-v2/theme';

export const StyledMoneyBackContainer = styled.div<{
  isOnHomepage: boolean;
}>(({ isOnHomepage }) => ({
  display: 'flex',
  alignItems: 'center',
  order: isOnHomepage ? 2 : 0,
  gap: '8px',
  marginTop: '16px',
  maxHeight: '18px',
  alignSelf: 'flex-start',
  justifyContent: 'center',
  maxWidth: '200px',
  span: {
    fontWeight: 300,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    color: theme.colors.neutral.N20,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: 'flex-start',
  },
}));
