import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { SCRAPER_VALUES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { copyCode } from 'features/utils';
import { IconCheckmarkGreen } from 'images';
import React, { useRef, useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  CodeBlockContainer,
  CodeField,
  CopyButtonContainer,
  HideablePopup,
  SearchField,
  StyledCodeBlock,
  StyledCopyButton,
} from '../styledComponents';
import { ISimulatorName } from '../types';
import { scrollWithMouse } from '../utils';

const RequestField: React.FC<ISimulatorName> = ({ simulator }) => {
  const { type, tags } = usePageContext();
  const [showPopup, setShowPopup] = useState(false);
  const codeBlockRef = useRef<HTMLDivElement>(null);

  scrollWithMouse(codeBlockRef.current);

  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: `scraping-simulator-${simulator}-copy-button`,
    prefix: type,
  });

  return (
    <SearchField>
      <CodeBlockContainer>
        <StyledCodeBlock ref={codeBlockRef}>
          <CodeField>{SCRAPER_VALUES.cURL_REQUESTS[simulator]}</CodeField>
        </StyledCodeBlock>
      </CodeBlockContainer>
      <HideablePopup isVisible={showPopup}>
        <IconCheckmarkGreen />
        {SCRAPER_VALUES.POPUP_TEXT}
      </HideablePopup>
      <CopyButtonContainer>
        <StyledCopyButton
          id={trackingSelector}
          className={trackingSelector}
          themeName={BUTTON_THEMES.IMPORTANT}
          themeType={BUTTON_TYPES.SECONDARY}
          fontSize={TSHIRT_SIZE.M}
          onClick={() => {
            copyCode(SCRAPER_VALUES.cURL_REQUESTS[simulator], setShowPopup),
              track(trackingSelector);
          }}
        />
      </CopyButtonContainer>
    </SearchField>
  );
};

export default RequestField;
