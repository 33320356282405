import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
} from 'theme';

export const BackgroundContainer = styled(BackgroundWrapper)<{
  isSubtitleCentered: boolean;
  isSubtitleAdded: boolean;
}>(({ background }) => ({
  background,
  maxWidth: 'unset',
  margin: 0,
  padding: '0 20px 80px',
}));

export const ContentContainer = styled.div((props) => ({
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '1024px',
  width: '100%',
  'p, em, strong': {
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: 500,
    marginBottom: 0,
  },
}));

export const IconsContainer = styled.div((props) => ({
  display: 'flex',
  alignItems: CSS_ALIGN.CENTER,
  gap: getThemeGutter(props, TSHIRT_SIZE.S),
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    flexWrap: 'wrap',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },

  '.gatsby-image-wrapper': {
    minWidth: 'max-content',
    height: 'auto',
  },
}));
