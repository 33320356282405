import styled from '@emotion/styled';
import { flexColContainerStyles, textEllipsisStyles } from 'commonStyles';
import { BUTTON_TYPES } from 'components/Button';
import { DOM_STATE, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { IGutterProps } from 'components/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  FONT_FAMILY,
  getThemeButtonOperationalColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeGutter,
  getThemeInputFontSizeShape,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeNeutralColor,
  ITheme,
} from 'theme';
import { IColor } from 'types';

export const StyledBackgroundWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: `${getThemeGutter(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const ArticlesContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  borderBottom: `1px solid ${getThemeNeutralColor(
    props,
    COLOR_SHADE.INVISIBLE
  )}`,
}));

export const ArticleDescriptionContainer = styled.div({
  ...flexColContainerStyles,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: '6px',
  },
});

export const ArticleContainer = styled.div<ITheme>((props) => ({
  borderTop: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  display: CSS_DISPLAY.FLEX,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.S)} 0`,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
}));

export const ArticleImageWrapper = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  verticalAlign: 'middle',
  div: {
    height: '140px',
    width: '140px',
    img: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      aspectRatio: '1/1',
      borderRadius: '6px',
      overflow: 'hidden',
      objectFit: 'contain',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.S)} auto`,
  },
}));

export const ArticleTitle = styled.h3<IGutterProps & ITheme>((props) => ({
  ...textEllipsisStyles,
  WebkitLineClamp: '2',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  fontFamily: getThemeFontFamily(props),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.XL),
  marginBottom: getThemeGutter(props),
  lineHeight: '26px',
  fontWeight: 600,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.L),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  },
  [DOM_STATE.HOVER]: {
    textDecoration: 'underline',
  },
}));

export const ArticleDescription = styled.p<IGutterProps & ITheme>((props) => ({
  color: getThemeButtonOperationalColor(props, BUTTON_TYPES.PRIMARY).text.main,
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
  lineHeight: '20px',
  maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));
