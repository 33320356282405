import {
  GOOGLE_SIGN_UP_TEXT,
  RESIDENTIAL_PRICING_VALUES,
  SECTION_NAMES,
} from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  StyledHeroDescriptionWrapper,
  HeroFeature,
  StyledHeroMainContentWrapper,
  HeroTitleWrapper,
  StyledHeroFeaturesWrapper,
  StyledHeroWrapperInner,
  StyledHeroWrapper,
  StyledCountdownTimerWrapper,
  ValidationTextComponent,
} from './styledComponents';
import { HeroProps } from './types';
import { IconDonev2 } from 'images';
import { usePageContext } from 'contexts/PageContext';
import { assertCtaAction, getHeroAccentColor } from './helpers';
import { HeroCtas } from './HeroCtas';
import { HeroImage } from './HeroImage';
import { ctaActionsMap } from './constants';
import { useSplitTesting } from 'contexts/SplitTesting';
import { mergeObjects } from 'utils/object';
import { LinkButtonProps } from 'components/v2/LinkButton';
import { useResiPromo } from 'features/ResidentialPricingCards/useResiPromo';
import { OfferCountdownStatus } from 'features/ResidentialPricingCards/OfferCountdownStatus';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';
import { HeroBadges } from '@smartproxy-web/features';
import { Heading } from '@smartproxy-web/ui';
import { normalizeString } from '@smartproxy-web/shared/utils';

export const Hero: React.FC<HeroProps> = ({
  primary: {
    hero_image: heroImage,
    cta_label: primaryCtaLabel,
    cta_link: primaryCtaLink,
    cta_action: primaryCtaAction,
    secondary_cta_action: secondaryCtaAction,
    secondary_cta_label: secondaryCtaLabel,
    secondary_cta_link: secondaryCtaLink,
    title,
    description,
    accent_color: accentColorName,
    video_embed: videoEmbedCode,
    is_money_back_visible,
  },
  items,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const { variant } = useSplitTesting();

  const createBadgeTrackingID = initBuildTrackingSelector({
    section: 'hero-badge',
    type: 'link',
  });

  const buildCtaTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'hero',
    type: 'cta',
  });

  const heroPrimaryCtaTrackingSelector = buildCtaTrackingSelector({
    context: 'primary',
  });
  const heroSecondaryCtaTrackingSelector = buildCtaTrackingSelector({
    context: 'secondary',
  });

  const normalizedPrimaryCtaAction = normalizeString(primaryCtaAction ?? '');
  const normalizedSecondaryCtaAction = normalizeString(
    secondaryCtaAction ?? ''
  );

  const isOnHomepage = type === 'home_page';
  const isMoneyBackVisible = is_money_back_visible ?? true;

  const handleCtaClick = (selector: string, ctaLabel: string) => {
    logPostHogEvent(selector);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: ctaLabel,
        sectionId: sliceId,
      });
    }
  };

  const handleBadgeClick = (badge: string) => {
    logPostHogEvent(
      createBadgeTrackingID({
        context: badge,
      })
    );
    if (sliceId) {
      logGAElementClickEvent({
        elementId: badge,
        sectionId: sliceId,
      });
    }
  };

  const ctasProps = [
    mergeObjects<Partial<LinkButtonProps>>(
      {
        'data-tracker': heroPrimaryCtaTrackingSelector,
        onClick: () =>
          handleCtaClick(heroPrimaryCtaTrackingSelector, primaryCtaLabel.text),
        children: primaryCtaLabel.text || GOOGLE_SIGN_UP_TEXT,
        to: primaryCtaLink.url,
        color: 'blue',
        ctaAction: primaryCtaAction,
      },
      assertCtaAction(normalizedPrimaryCtaAction)
        ? ctaActionsMap[normalizedPrimaryCtaAction](
            primaryCtaLabel.text || GOOGLE_SIGN_UP_TEXT
          )
        : {}
    ),
    mergeObjects<Partial<LinkButtonProps>>(
      {
        'data-tracker': heroSecondaryCtaTrackingSelector,
        onClick: () =>
          handleCtaClick(
            heroSecondaryCtaTrackingSelector,
            secondaryCtaLabel.text
          ),
        children: secondaryCtaLabel.text || GOOGLE_SIGN_UP_TEXT,
        to: secondaryCtaLink.url,
        color: 'white',
        variant: 'outlined',
        ctaAction: secondaryCtaAction,
      },
      assertCtaAction(normalizedSecondaryCtaAction)
        ? ctaActionsMap[normalizedSecondaryCtaAction](
            secondaryCtaLabel.text || GOOGLE_SIGN_UP_TEXT
          )
        : {}
    ),
  ].filter((cta): cta is LinkButtonProps => !!cta.to);
  const isResidentialProxiesPage = tags.includes('residential-proxies');
  const promo = useResiPromo(variant);
  const isPromoBannerVisible = !!promo.expiry && isResidentialProxiesPage;

  return (
    <StyledHeroWrapper
      data-section-name="hero"
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledHeroWrapperInner>
        <StyledHeroMainContentWrapper>
          <HeroBadges
            handleBadgeClick={handleBadgeClick}
            trackingSelectors={{
              trustpilot: createBadgeTrackingID({ context: 'trustpilot' }),
              g2: createBadgeTrackingID({ context: 'g2' }),
              proxyway: createBadgeTrackingID({ context: 'proxyway' }),
            }}
          />
          {isPromoBannerVisible && (
            <StyledCountdownTimerWrapper>
              <OfferCountdownStatus
                key={promo.expiry}
                expiry={promo.expiry}
                label={RESIDENTIAL_PRICING_VALUES.LABEL}
                isTimerVisible={promo.isTimerVisible}
              />
            </StyledCountdownTimerWrapper>
          )}
          {!!promo.description?.text && isPromoBannerVisible && (
            <ValidationTextComponent>
              <RichText field={promo.description} />
            </ValidationTextComponent>
          )}
          <HeroTitleWrapper>
            <Heading level={1}>{title.text}</Heading>
          </HeroTitleWrapper>
          <StyledHeroDescriptionWrapper>
            <RichText field={description} />
          </StyledHeroDescriptionWrapper>
          <StyledHeroFeaturesWrapper order={isOnHomepage ? 0 : 1}>
            {items.map((item, index) => (
              <HeroFeature key={index}>
                <IconDonev2 color={getHeroAccentColor(accentColorName)} />
                <span>{item.feature_label.text}</span>
              </HeroFeature>
            ))}
          </StyledHeroFeaturesWrapper>
          {ctasProps.length > 0 && (
            <HeroCtas order={isOnHomepage ? 1 : 0} ctasProps={ctasProps} />
          )}
          <MoneyBackTag
            isOnHomepage={isOnHomepage}
            isVisible={isMoneyBackVisible && !!primaryCtaLink.url}
          />
        </StyledHeroMainContentWrapper>
        <HeroImage image={heroImage} embedCode={videoEmbedCode} />
      </StyledHeroWrapperInner>
    </StyledHeroWrapper>
  );
};
