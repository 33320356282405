import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { IScreenProps } from 'utils/screenTypeHOC';
import Scraper from './Scraper';
import {
  HideableTitles,
  ScraperContainer,
  ScraperImageContainer,
  Wrapper,
} from './styledComponents';
import { ISimulator } from './types';
import { RichText } from 'components/v2/RichText';

const ScrapingSimulator: React.FC<ISimulator & IScreenProps> = ({
  primary: { background, title, subtitle, image, select_simulator: simulator },
}) => (
  <Wrapper background={background}>
    <HideableTitles isVisible={!!title || !!subtitle}>
      <RichText field={title} />
      <RichText field={subtitle} />
    </HideableTitles>
    <ScraperContainer>
      <ScraperImageContainer>
        <GatsbyImg image={image} />
      </ScraperImageContainer>
      <Scraper simulator={simulator} />
    </ScraperContainer>
  </Wrapper>
);

export default ScrapingSimulator;
