import React from 'react';
import { StyledTextContainer } from '../styledComponents';
import { INew } from '../types';

const ListItem: React.FC<INew> = ({ text, info }) => (
  <StyledTextContainer>
    <p>{text}</p>
    &nbsp;
    <p>{info}</p>
  </StyledTextContainer>
);

export default ListItem;
