import { IGutterProps } from 'components/utils';
import React from 'react';
import { RadioButton, RadioContainer, RadioText } from './styledComponents';
import { IRadio } from './types';
import { initBuildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { track } from '@smartproxy/sp-tracker-js';

const Radio: React.FC<IRadio & IGutterProps> = ({
  isSelected,
  title,
  radioButtonColor,
  onChange,
  ...props
}) => {
  const { type, tags } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_SLIDER,
    prefix: type,
    context: title,
  });
  const handleClick = () => {
    track(trackingSelector());
    onChange(!isSelected);
  };
  return (
    <RadioContainer {...props} onClick={handleClick}>
      <RadioButton
        isSelected={isSelected}
        colorShade={radioButtonColor}
        data-tracker={trackingSelector()}
      />
      <RadioText>{title}</RadioText>
    </RadioContainer>
  );
};
export default Radio;
