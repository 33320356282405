import { useCountdown } from '@smartproxy-web/features/src/hooks/useCountdown';
import { CountdownTimer } from 'components/CountdownTimer/CountdownTimer';
import React from 'react';
import { splitToTimeComponents } from 'utils/time';

export const OfferCountdownStatus: React.FC<{
  expiry: string | null;
  label?: string;
  isGreen?: boolean;
  isTimerVisible?: boolean;
}> = ({ expiry, label, isGreen, isTimerVisible = false }) => {
  const currentMs = Date.now();
  const expiryMs = expiry ? new Date(expiry).getTime() : currentMs;

  const duration = useCountdown({
    time: expiryMs - currentMs,
    rateOfChange: 1000,
  });

  const splitTime = splitToTimeComponents(duration);

  return (
    <CountdownTimer
      label={label}
      timeComponents={splitTime}
      isGreen={isGreen}
      isTimerVisible={isTimerVisible}
    />
  );
};
