import React, { forwardRef } from 'react';
import { StyledButton } from '../Button/styledComponents';
import { StyledButtonInner, StyledLinkButton } from './styledComponents';
import { LinkButtonProps } from './types';

export const LinkButton = forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkButtonProps>
>((props, ref) => {
  const {
    children,
    leftIcon: LeftIcon,
    rightIcon: RightIcon,
    color = 'red',
    disabled = false,
    fullWidth = false,
    variant = 'filled',
    size = 'md',
    compact = false,
    onClick,
    to,
    fullWidthOnMobile,
    minWidth,
    ...rest
  } = props;

  return (
    <StyledLinkButton
      {...rest}
      ref={ref}
      to={to?.includes('contact-sales') ? '#contact-sales' : to}
      variant={variant}
      color={color}
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
    >
      <StyledButton
        as="div"
        size={size}
        variant={variant}
        compact={compact}
        fullWidth={fullWidth}
        color={color}
        disabled={disabled}
        fullWidthOnMobile={fullWidthOnMobile}
        $minWidth={minWidth}
      >
        <StyledButtonInner>
          {LeftIcon}
          <span>{children}</span>
          {RightIcon}
        </StyledButtonInner>
      </StyledButton>
    </StyledLinkButton>
  );
});
