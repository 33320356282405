import { IconCheckMarkDcIp } from 'images';
import React from 'react';
import {
  StyledList,
  StyledListItem,
  StyledListWithArticleSection,
} from './styledComponents';
import { IListWithTitleProps } from './types';
import { RichText } from 'components/v2/RichText';

const ListWithTitle: React.FC<IListWithTitleProps> = ({
  primary: { list_title: listTitle, introduction, footer },
  items,
}) => (
  <StyledListWithArticleSection>
    <RichText field={listTitle} />
    {!!introduction && <RichText field={introduction} />}
    <StyledList>
      {items.map(({ item }) => (
        <StyledListItem key={item.text}>
          <IconCheckMarkDcIp />
          <RichText field={item} />
        </StyledListItem>
      ))}
    </StyledList>
    {!!footer && <RichText field={footer} />}
  </StyledListWithArticleSection>
);

export default ListWithTitle;
