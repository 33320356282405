import { scrollWithMouse } from 'features/ScrapingSimulator/utils';
import React, { useRef } from 'react';
import {
  CSVWrapper,
  CSVContainer,
  Title,
  TableContainer,
  Table,
  TableHeader,
  TableBody,
} from './styledComponents';
import { CSVResults, CSVTableTitles, TITLES } from './utils';

const CVSResults: React.FC = () => {
  const tableBlockRef = useRef<HTMLTableElement>(null);
  scrollWithMouse(tableBlockRef.current);
  return (
    <CSVWrapper>
      <CSVContainer>
        <Title>{TITLES.CSV}</Title>
        <TableContainer>
          <Table ref={tableBlockRef}>
            <TableHeader>
              <tr>
                {CSVTableTitles.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </TableHeader>
            <TableBody>
              {Object.entries(CSVResults).map(([key, value], index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{key}</td>
                  <td>{value}</td>
                </tr>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CSVContainer>
    </CSVWrapper>
  );
};

export default CVSResults;
