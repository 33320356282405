import { HideableIconWrapper } from 'components/Input/styledComponents';
import { IconError } from 'images';
import React from 'react';
import {
  StyledErroIconContainer,
  StyledFormInput,
  StyledFormLabel,
} from '../styledComponents';
import { IInputLabel } from '../types';

const InputLabelFields: React.FC<IInputLabel> = ({
  label,
  type,
  name,
  required,
  isError,
  onBlur,
  onChange,
  onClick,
  value,
  id,
}) => (
  <StyledErroIconContainer>
    <StyledFormLabel>{label}</StyledFormLabel>
    <StyledFormInput
      type={type}
      name={name}
      required={required}
      isError={isError}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      value={value}
      id={id}
      data-tracker={id}
    />
    <HideableIconWrapper isVisible={isError}>
      <IconError />
    </HideableIconWrapper>
  </StyledErroIconContainer>
);

export default InputLabelFields;
