import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  ItemContainer,
  ItemTitle,
  LogoContainer,
  TextContainer,
} from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';

const Item: React.FC<IItem> = ({ title, description, icon }) => (
  <ItemContainer key={title.text}>
    <LogoContainer>
      <GatsbyImg image={icon} />
    </LogoContainer>
    <TextContainer>
      <ItemTitle>
        <RichText field={title} />
      </ItemTitle>
      <RichText field={description} />
    </TextContainer>
  </ItemContainer>
);

export default Item;
