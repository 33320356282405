import { IRequestLanguageTemplateArgs } from '../types';
import { buildReqBodyFromMap } from '../utils';

export const curlTemplate = ({
  target,
  query,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `curl --request POST \\
  --url https://scrape.smartproxy.com/v1/tasks \\
  --header 'accept: application/json' \\
  --header 'authorization: Basic AUTH' \\
  --header 'content-type: application/json' \\
  --data '
    {
      "target": "${target}",
      "query": "${query}",
      "geo": "${geo}",${buildReqBodyFromMap(params, padding ?? 6)}
    }
  '`;
};

const nodeTemplate = ({
  query,
  target,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `const request = require('request');

const username = 'YOUR_USERNAME';
const password = 'YOUR_PASSWORD';

const options = {
  method: 'POST',
  url: 'https://scrape.smartproxy.com/v1/tasks',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + Buffer.from(username + ':' + password).toString('base64')
  },
  body: JSON.stringify({
    "target": '${target}',
    "query": '${query}',
    "geo": '${geo}',${buildReqBodyFromMap(params, padding ?? 4)}
  })
};

request(options, function (error, response, body) {
  if (error) throw new Error(error);

  console.log(body);
});`;
};

const pythonTemplate = ({
  query,
  target,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `import requests

url = "https://scrape.smartproxy.com/v1/tasks"
    
payload = {
  "target": "${target}",
  "query": "${query}",
  "geo": "${geo}",${buildReqBodyFromMap(params, padding ?? 2)}
}
headers = {
  "accept": "application/json",
  "content-type": "application/json",
  "authorization": "Basic {AUTH}"
}

response = requests.post(url, json=payload, headers=headers)

print(response.text)`;
};

export const serpRequestTemplates = {
  curl: curlTemplate,
  node: nodeTemplate,
  python: pythonTemplate,
};
