import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { useContextUpdate, useLabels, useTopic } from 'context';
import React from 'react';
import {
  BlockWrapper,
  ContentContainer,
  HideableButtonContainer,
  HideableRichText,
  StyledButton,
} from './styledComponents';
import { IContentTags } from './types';
import { Box } from 'components/v2/Box';
import { themev2 } from 'theme-v2';

const ContentTags: React.FC<IContentTags> = ({ primary: { title }, items }) => {
  const setSelectedTopic = useContextUpdate();
  const selectedTopic = useTopic();
  const topicsLabels = useLabels();

  return (
    <BlockWrapper>
      <ContentContainer>
        <Box
          sx={{
            textAlign: 'center',
            marginBottom: themev2.spacings.L,
          }}
        >
          <HideableRichText field={title} isVisible={title.text} />
        </Box>
        <HideableButtonContainer isVisible={!!items.length}>
          {topicsLabels.map((topicLabel, idx) => {
            const isTopicLabelSelected = selectedTopic === topicLabel;
            return (
              <StyledButton
                themeName={BUTTON_THEMES.IMPORTANT}
                themeType={
                  isTopicLabelSelected
                    ? BUTTON_TYPES.ADDITIONAL
                    : BUTTON_TYPES.SECONDARY
                }
                fontSize={TSHIRT_SIZE.M}
                gutterBot={TSHIRT_SIZE.XXS}
                gutterRight={TSHIRT_SIZE.XS}
                gutterTop={TSHIRT_SIZE.XXS}
                onClick={() => {
                  setSelectedTopic(topicLabel);
                }}
                isHovered={isTopicLabelSelected}
                key={idx}
              >
                {topicLabel}
              </StyledButton>
            );
          })}
        </HideableButtonContainer>
      </ContentContainer>
    </BlockWrapper>
  );
};

export default ContentTags;
