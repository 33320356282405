import React from 'react';
import { IScraperLocationConfig } from '../types';

export const LocationsDropdownOption: React.FC<{
  location: IScraperLocationConfig;
}> = ({ location }) => (
  <span>
    <span>{location.shortLabel}</span>
    <span>{location.label}</span>
  </span>
);
