import React from 'react';
import { IPlans, IPricingCard } from '../../types';
import { Badge } from '@smartproxy-web/ui';
import {
  StyledPricingCardPerPrice,
  StyledPricingCardPerTotalPrice,
  StyledPricingCardPrice,
  StyledPricingCardPriceInnerWrapper,
  StyledPricingCardPriceWrapper,
  StyledCta,
  StyledTableHeader,
  StyledTableItem,
  StyledTableItemWrapper,
  StyledTableItemNeedMoreTitle,
  StyledTableItemNeedMoreDescription,
  StyledTable,
  StyledTableItemPrice,
  StyledTableItemTitle,
} from 'features/AutomatedPricingCards/ABTest/Version1/styled';
import { useSliceDetails } from '@smartproxy-web/features';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';
import { appendPlanIdToURL, ctaLinkHandler } from 'features/utils';
import { getPlanId } from 'utils/plans';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';

const TableHeader = () => {
  return (
    <StyledTableHeader>
      <StyledTableItem fontSize="14px">Subscription </StyledTableItem>
      <StyledTableItem fontSize="14px">Price per GB</StyledTableItem>
      <StyledTableItem fontSize="14px" flexCol="1">
        Total amount
      </StyledTableItem>
      <StyledTableItem fontSize="14px" flexCol="1"></StyledTableItem>
    </StyledTableHeader>
  );
};

export const TableItem: React.FC<IPricingCard & { index: number }> = ({
  data: {
    gb_value: gbValue,
    save_percentage: savePercentage,
    new_price: newPrice,
    new_total: newTotal,
    billing_name: billingName,
    cta_label: ctaLabel,
    cta_url: ctaUrl,
    old_total: oldTotal,
    flag_text: flagText,
    product,
    card_plan_name: cardPlanName,
    is_ddc_non_seq: isDDCNonSeq,
  },
  index,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });
  const planId = cardPlanName && getPlanId(cardPlanName, product, isDDCNonSeq);
  const normalizedCtaLink = ctaLinkHandler(ctaUrl.url!);
  const checkedCtaLink =
    !planId || ctaLabel === 'Contact sales'
      ? normalizedCtaLink
      : appendPlanIdToURL(normalizedCtaLink, planId);

  const [extractedPrice] = newTotal.text.split('+');
  const newPriceWithDecimal = newPrice?.includes('.')
    ? newPrice
    : `${newPrice}.0`;

  return (
    <StyledTableItemWrapper>
      {product ? (
        <>
          <StyledTableItem>
            {!oldTotal && flagText ? (
              <StyledTableItemTitle>
                {flagText.toLowerCase()}
              </StyledTableItemTitle>
            ) : (
              gbValue
            )}
            {oldTotal && flagText && (
              <Badge variant="filled" color="black" size="lg">
                {flagText}
              </Badge>
            )}
          </StyledTableItem>

          <StyledTableItem>
            <StyledPricingCardPriceWrapper>
              <StyledPricingCardPriceInnerWrapper>
                <StyledPricingCardPrice>
                  ${newPriceWithDecimal}
                </StyledPricingCardPrice>
                <StyledPricingCardPerPrice>
                  /{billingName}
                </StyledPricingCardPerPrice>
              </StyledPricingCardPriceInnerWrapper>
              {savePercentage && (
                <Badge color="gradient" variant="outlined" size="lg">
                  Save {savePercentage}
                </Badge>
              )}
            </StyledPricingCardPriceWrapper>
          </StyledTableItem>

          <StyledTableItem flexCol="1">
            <div>
              <StyledTableItemPrice>{extractedPrice}</StyledTableItemPrice>
              <StyledPricingCardPerTotalPrice>
                {gbValue === 'No commitment'
                  ? `+ VAT / Billed per ${billingName}`
                  : `+ VAT / Billed monthly`}
              </StyledPricingCardPerTotalPrice>
            </div>
          </StyledTableItem>

          <StyledTableItem align="end" flexCol="1">
            <StyledCta
              width="128px"
              isButtonBlue={'primary'}
              ctaText={ctaLabel}
              ctaLink={checkedCtaLink}
              isVisible={ctaUrl.url}
              themeName={'default'}
              themeType={'primary'}
              sendTrackingEvent={() => {
                logPostHogEvent(trackingSelector({ context: cardPlanName }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `${ctaLabel}:${index}`,
                    sectionId: sliceId,
                  });
                }
              }}
              dataTracker={trackingSelector({ context: cardPlanName })}
            />
          </StyledTableItem>
        </>
      ) : (
        <>
          <StyledTableItem>
            <StyledTableItemNeedMoreTitle>
              Need a bigger subscription?
            </StyledTableItemNeedMoreTitle>
          </StyledTableItem>
          <StyledTableItem flexCol="3">
            <StyledTableItemNeedMoreDescription>
              Chat with us and we’ll find the best solution for you.
            </StyledTableItemNeedMoreDescription>
          </StyledTableItem>

          <StyledTableItem align="end" flexCol="1">
            <StyledCta
              width="128px"
              ctaText={ctaLabel}
              ctaLink={checkedCtaLink}
              isVisible={ctaUrl.url}
              themeName={'default'}
              themeType={'secondary'}
              sendTrackingEvent={() => {
                logPostHogEvent(trackingSelector({ context: cardPlanName }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `${ctaLabel}:${index}`,
                    sectionId: sliceId,
                  });
                }
              }}
              dataTracker={trackingSelector({ context: cardPlanName })}
            />
          </StyledTableItem>
        </>
      )}
    </StyledTableItemWrapper>
  );
};

const PricingTable: React.FC<IPlans> = ({ plans }) => {
  return (
    <StyledTable>
      <TableHeader />
      {plans.map(
        (
          {
            pricing_card: {
              type,
              document: { data },
            },
          },
          index
        ) => {
          if (type === 'pricing_card') {
            return <TableItem index={index} data={data} key={data.gb_value} />;
          }
        }
      )}
    </StyledTable>
  );
};

export default PricingTable;
