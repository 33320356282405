import { PRISMIC_VALUES } from 'constants/common';
import { IconSearchGray } from 'images';
import React, { useState } from 'react';
import Input from './Input';
import {
  EmptyOption,
  EmptyText,
  HideableList,
  Option,
  SearchBarContainer,
} from './styledComponents';
import { ISearchBar } from './types';
import { filterOptionsByInputValue } from './utils';

const SearchBar: React.FC<ISearchBar> = ({
  placeholder,
  options,
  ...props
}) => {
  const [isListVisible, setListVisibility] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const filteredOptions = filterOptionsByInputValue(options, inputValue);

  const handleListVisibility = () => {
    setListVisibility(!isListVisible);
  };

  const closeList = () => {
    setListVisibility(false);
  };

  const openList = () => {
    setListVisibility(true);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const searchQuery = `?q=${inputValue}`;

  return (
    <SearchBarContainer {...props}>
      <Input
        handleListVisibility={handleListVisibility}
        isListVisible={isListVisible}
        onFocus={openList}
        onBlur={closeList}
        onClear={clearInput}
        placeholder={placeholder}
        onInputChange={setInputValue}
        inputValue={inputValue}
      />
      <HideableList
        isVisible={inputValue.length >= 2 && isListVisible}
        onMouseDown={(e) => e.preventDefault()}
      >
        <EmptyOption
          className="search-no-options"
          isVisible={!filteredOptions.length}
        >
          <IconSearchGray />
          <EmptyText>{PRISMIC_VALUES.EMPTY_LIST_TEXT}</EmptyText>
        </EmptyOption>
        {filteredOptions.map(({ label, value }) => {
          const updatedUrl = value.startsWith('/questions')
            ? value.replace('questions', 'faq')
            : value;
          return (
            <Option
              key={value}
              to={`${updatedUrl}${searchQuery}`}
              isForcedInternalLink={true}
            >
              {label}
            </Option>
          );
        })}
      </HideableList>
    </SearchBarContainer>
  );
};

export default SearchBar;
