import { BingWhite, GoogleWhite, IconBingColor, IconGoogleColor } from 'images';
import SerpSplashScreen from '../components/SerpSplashScreen/SerpSplashScreen';
import { serpRequestTemplates } from '../templates/serp';
import { IScraperPlaygroundConfig } from '../types';
import {
  commonSerpScraperFormConfig,
  commonScraperLocations,
  commonSerpScraperRequestParams,
} from './common';
import googleSchema from 'scrapers/Serp/Requests/Google/empty.json';
import bingSchema from 'scrapers/Serp/Requests/Bing/empty.json';

export const serpScraperConfig: IScraperPlaygroundConfig = {
  splashScreen: SerpSplashScreen,
  title: 'API Playground Demo',
  requestLabel: 'Request',
  responseLabel: 'Response',
  banner: {
    text: 'Want the unlimited API experience? Activate your 7-day free trial now!',
    ctaText: 'Get free trial',
    ctaLink:
      'https://dashboard.smartproxy.com/register?page=serp-scraping-api/pricing',
  },
  configParamsButtonLabel: 'Additional configurable parameters',
  trackingSelectors: {
    section: 'serp-scraper-playground',
  },
  languages: [
    {
      value: 'curl',
      label: 'cURL',
      template: serpRequestTemplates.curl,
    },
    {
      value: 'node',
      label: 'Node',
      template: serpRequestTemplates.node,
    },
    {
      value: 'python',
      label: 'Python',
      template: serpRequestTemplates.python,
    },
  ],
  targets: [
    {
      iconLarge: GoogleWhite,
      iconRegular: IconGoogleColor,
      label: 'Google search',
      shortLabel: 'Google',
      labelType: 'title',
      value: 'google_search',
      info: {
        title: 'All Google targets',
        description:
          'Google search, Google hotels, Google travel hotels, Google shopping search, Google shopping product, Google shopping pricing, Google, Google images, Google suggest, Google ads',
      },
      configParams: {
        title: 'Google search parameters',
        items: [
          {
            title: 'query',
            description: 'Google query you want to target',
          },
          {
            title: 'domain',
            description: 'The Google top level domain',
          },
          {
            title: 'locale',
            description:
              'The setting to access a particular language version of Google',
          },
          {
            title: 'geo',
            description: 'The geographical location that the result depends on',
          },
          {
            title: 'device_type',
            description:
              'The device type and browser (desktop, mobile, desktop chrome etc.)',
          },
          {
            title: 'page_from',
            description: 'The starting page number',
          },
          {
            title: 'num_pages',
            description: 'The number of results to retrieve in each page',
          },
          {
            title: 'parse',
            description: 'The output returned in parsed JSON format',
          },
          {
            title: 'google_results_language',
            description: 'Shows results in a particular language',
          },
          {
            title: 'google_safe_search',
            description: 'Hide explicit content',
          },
          {
            title: 'google_tbm',
            description:
              'Google Search results for specific types of content (news, apps, videos etc.)',
          },
          {
            title: 'google_tbs',
            description:
              'Narrow down your search using Google parameters like limiting/sorting results by date',
          },
        ],
      },
      requestParams: {
        curl: commonSerpScraperRequestParams.google_search,
        node: commonSerpScraperRequestParams.google_search,
        python: {
          ...commonSerpScraperRequestParams.google_search,
          parse: 'True',
        },
      },
      responseSchema: googleSchema,
      locations: commonScraperLocations,
      formConfig: commonSerpScraperFormConfig,
    },
    {
      iconLarge: BingWhite,
      iconRegular: IconBingColor,
      labelType: 'title',
      label: 'Bing search',
      shortLabel: 'Bing',
      value: 'bing_search',
      info: {
        title: 'All Bing targets',
        description: 'Bing, Bing Search',
      },
      configParams: {
        title: 'Bing search parameters',
        items: [
          {
            title: 'query',
            description: 'Bing query you want to target',
          },
          {
            title: 'domain',
            description: 'Bing top-level domain',
          },
          {
            title: 'locale',
            description:
              'The setting to access a particular language version of Bing',
          },
          {
            title: 'geo',
            description: 'The geographical location that the result depends on',
          },
          {
            title: 'device_type',
            description:
              'The device type and browser (desktop, mobile, desktop chrome etc.)',
          },
          {
            title: 'page_from',
            description: 'The starting page number',
          },
          {
            title: 'num_pages',
            description: 'The number of results to retrieve in each page',
          },
          {
            title: 'parse',
            description: 'The output returned in parsed JSON format',
          },
        ],
      },
      requestParams: {
        curl: commonSerpScraperRequestParams.bing_search,
        node: commonSerpScraperRequestParams.bing_search,
        python: commonSerpScraperRequestParams.bing_search,
      },
      responseSchema: bingSchema,
      locations: commonScraperLocations,
      formConfig: commonSerpScraperFormConfig,
    },
  ],
};
