import {
  Carousel,
  CarouselRenderItem,
  IconChevronRight,
} from '@smartproxy-web/ui';
import { Arrow, Container, StyledSlice } from './styledComponents';
import { ImageItem } from './ImageItem';
import { LeftArrowTracker, RightArrowTracker, swiperConfig } from './constants';
import { useState, useCallback } from 'react';
import { Swiper as SwiperInstance } from 'swiper/types';
import { CardContentItem } from './CardContentItem';
import { useScreenSize } from '@smartproxy-web/shared/hooks';
import { ImageCarouselProps, ImageItemProps } from './types';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { debounce } from '@smartproxy-web/shared/utils';

export const ImageCarouselBlock = ({ items }: ImageCarouselProps) => {
  const [swiperRef, setSwiperRef] = useState<SwiperInstance | null>(null);
  const [activeCard, setActiveCard] = useState(1);
  const [isCarouselTouched, setIsCarouselTouched] = useState(false);
  const { isDesktop } = useScreenSize();

  const images = items.map(({ big_image: image }, index) => {
    return { image, id: index + 1 };
  });
  const cards = items.map(
    (
      { badge_text: badgeText, cardDescription, card_heading: cardHeading },
      index
    ) => {
      return {
        badgeText,
        cardDescription,
        cardHeading,
        id: index + 1,
      };
    }
  );

  const handleRightArrowClick = () => {
    swiperRef!.slideNext();
    setActiveCard((prevState) =>
      prevState < items.length ? prevState + 1 : 1
    );
    setIsCarouselTouched(true);
    logPostHogEvent(RightArrowTracker);
  };
  const handleLeftArrowClick = () => {
    swiperRef!.slidePrev();
    setActiveCard((prevState) =>
      prevState === 1 ? items.length : prevState - 1
    );
    setIsCarouselTouched(true);
    logPostHogEvent(LeftArrowTracker);
  };
  const debounceRightClick = useCallback(debounce(handleRightArrowClick, 300), [
    swiperRef,
  ]);

  const debounceLeftClick = useCallback(debounce(handleLeftArrowClick, 300), [
    swiperRef,
  ]);

  const renderCarouselImageItem: CarouselRenderItem<ImageItemProps> = ({
    item,
  }) => <ImageItem {...item} />;

  return (
    isDesktop && (
      <StyledSlice>
        <Container>
          <Arrow
            isLeft
            onClick={debounceLeftClick}
            data-tracker={LeftArrowTracker}
          >
            <IconChevronRight />
          </Arrow>
          <Carousel
            items={images}
            renderItem={renderCarouselImageItem}
            sideGradient={false}
            config={{
              ...swiperConfig,
              loop: true,
              onSwiper: (swiper) => setSwiperRef(swiper),
              onAutoplay: () =>
                setActiveCard((prevState) =>
                  prevState < items.length ? prevState + 1 : 1
                ),
            }}
          />
          {cards.map(
            (item) =>
              item.id === activeCard && (
                <CardContentItem
                  key={item.id}
                  {...item}
                  items={cards}
                  activeCard={activeCard}
                  isCarouselTouched={isCarouselTouched}
                  setActiveCard={setActiveCard}
                  setIsCarouselTouched={setIsCarouselTouched}
                  swiper={swiperRef}
                />
              )
          )}
          <Arrow onClick={debounceRightClick} data-tracker={RightArrowTracker}>
            <IconChevronRight />
          </Arrow>
        </Container>
      </StyledSlice>
    )
  );
};
