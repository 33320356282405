import SerpSplashScreenForm from './SerpSplashScreenForm';
import {
  StyledSplashScreen,
  StyledSplashScreenHeader,
  StyledSplashScreenImage,
  StyledSplashScreenMain,
} from './styledComponents';
import SplashScreenTargetList from './SplashScreenTargetList';
import { ISplashScreenProps } from './types';

const SerpSplashScreen = ({
  product,
  targets,
  activeTarget,
  formField,
  setFormField,
  onSubmit,
  isLoading,
  isTargetValuesVisible,
  isLocationValuesVisible,
  isPopupVisible,
  handleActiveTargetChange,
  buildClickSelector,
  setSubmitDisabled,
  setIsLocationValuesVisible,
  setIsTargetValuesVisible,
  setIsPopupVisible,
}: ISplashScreenProps) => {
  const Image = activeTarget.iconLarge;
  const closePopups = () => {
    isPopupVisible && setIsPopupVisible(false);
    isLocationValuesVisible && setIsLocationValuesVisible(false);
    isTargetValuesVisible && setIsTargetValuesVisible(false);
  };
  return (
    <StyledSplashScreen onClick={closePopups}>
      <StyledSplashScreenHeader>
        <h6>API Playground Demo</h6>
        <div>
          <SplashScreenTargetList
            targets={targets}
            activeTarget={activeTarget}
            buildClickSelector={buildClickSelector}
            handleChange={handleActiveTargetChange}
          />
        </div>
      </StyledSplashScreenHeader>
      <StyledSplashScreenMain>
        <StyledSplashScreenImage>
          <Image />
        </StyledSplashScreenImage>
        <SerpSplashScreenForm
          product={product}
          activeTarget={activeTarget}
          targets={targets}
          onSubmit={onSubmit}
          setFormField={setFormField}
          isTargetValuesVisible={isTargetValuesVisible}
          formField={formField}
          isLoading={isLoading}
          isSubmitDisabled={formField.query.length <= 0 || isLoading}
          isLocationValuesVisible={isLocationValuesVisible}
          isPopupVisible={isPopupVisible}
          handleActiveTargetChange={handleActiveTargetChange}
          setSubmitDisabled={setSubmitDisabled}
          setIsLocationValuesVisible={setIsLocationValuesVisible}
          setIsTargetValuesVisible={setIsTargetValuesVisible}
          setIsPopupVisible={setIsPopupVisible}
          buildClickSelector={buildClickSelector}
        />
      </StyledSplashScreenMain>
    </StyledSplashScreen>
  );
};

export default SerpSplashScreen;
