import { track } from '@smartproxy/sp-tracker-js';
import RadioButtons from 'components/RadioButtons';
import {
  RESIDENTIAL_PRICING_VALUES,
  SECTION_NAMES,
  TABS,
} from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { generateSectionsId, options } from 'features/utils';
import React, { useState } from 'react';
import { buildTrackingSelector, initBuildTrackingSelector } from 'utils/page';
import Feature from '../PricingCards/Feature';
import {
  AnimationContainer,
  BlockContainer,
  ContentContainer,
  Features,
  HideableSubtitle,
  ResidentialPricingCardsContainer,
  ResidentialTitleWrapper,
  TopLabelComponent,
  ValidationTextComponent,
} from '../PricingCards/styledComponents';
import { IPricing } from '../PricingCards/types';
import { OfferCountdownStatus } from './OfferCountdownStatus';
import PricingCard from './PricingCard';
import { useResiPromo } from './useResiPromo';
import { RichText } from 'components/v2/RichText';
import { Heading } from 'components/v2/Heading';

const ResidentialPricingCards: React.FC<IPricing> = ({
  primary: {
    number_of_columns: numberOfColumns,
    theme,
    background,
    is_yearly_visible_: isYearlyVisible,
    toggle_label_text: labelTextPlacement,
    title,
    subtitle,
  },
  items,
}) => {
  const { tags, type } = usePageContext();

  const [selectedBilling, setSelectedBilling] = useState(TABS.LEFT);
  const monthlyItems = items.filter(
    ({
      pricing_plan: {
        document: {
          data: { type: billingType },
        },
      },
    }) => !billingType
  );
  const yearlyItems = items.filter(
    ({
      pricing_plan: {
        document: {
          data: { type: billingType },
        },
      },
    }) => billingType
  );

  const crossedOutPrices = items.filter(
    ({
      pricing_plan: {
        document: {
          data: { crossed_out_price: crossedOutPrice },
        },
      },
    }) => crossedOutPrice
  );

  const pricingCardItems =
    selectedBilling === TABS.LEFT ? monthlyItems : yearlyItems;

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });

  const handleOnChange = (value) => {
    track(trackingSelector({ context: value, type: 'button' }));
    setSelectedBilling(value as TABS);
  };

  const { variant } = useSplitTesting();
  const promo = useResiPromo(variant);

  const isPromoBannerVisible = !!promo.expiry;

  return (
    <BlockContainer
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRICING_CARDS,
        tags,
        type
      )}
    >
      <ContentContainer
        id={buildTrackingSelector({
          prefix: type,
          page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
          context: RESIDENTIAL_PRICING_VALUES.PRICING_CARDS_TITLE,
        })}
      >
        {isPromoBannerVisible && (
          <TopLabelComponent>
            <OfferCountdownStatus
              key={promo.expiry}
              expiry={promo.expiry}
              label={RESIDENTIAL_PRICING_VALUES.LABEL}
              isTimerVisible={promo.isTimerVisible}
            />
          </TopLabelComponent>
        )}
        {!!promo.description?.text && (
          <ValidationTextComponent>
            <RichText field={promo.description} />
          </ValidationTextComponent>
        )}
        <ResidentialTitleWrapper>
          {title?.text ? (
            <RichText field={title} />
          ) : (
            <Heading level={2}>
              {RESIDENTIAL_PRICING_VALUES.PRICING_CARDS_TITLE}
            </Heading>
          )}
        </ResidentialTitleWrapper>
        <HideableSubtitle isSubtitleCentered isVisible={subtitle?.text}>
          <RichText field={subtitle} />
        </HideableSubtitle>
        <RadioButtons
          name="pricing"
          badgeText={RESIDENTIAL_PRICING_VALUES.PRICING_DISCOUNT}
          options={options(
            RESIDENTIAL_PRICING_VALUES.BILLED_MONTHLY,
            RESIDENTIAL_PRICING_VALUES.BILLED_YEARLY
          )}
          onChange={(value) => handleOnChange(value)}
          labelTextPlacement={labelTextPlacement}
          isVisible={isYearlyVisible}
        />
        <AnimationContainer key={selectedBilling}>
          <Features>
            <Feature>
              {RESIDENTIAL_PRICING_VALUES.PRICING_CARDS_FEATURE_ONE}
            </Feature>
            <Feature>
              {RESIDENTIAL_PRICING_VALUES.PRICING_CARDS_FEATURE_TWO}
            </Feature>
            <Feature>
              {RESIDENTIAL_PRICING_VALUES.PRICING_CARDS_FEATURE_THREE}
            </Feature>
          </Features>
          <ResidentialPricingCardsContainer
            numberOfColumns={numberOfColumns || 3}
          >
            {pricingCardItems.map((item, index) => (
              <PricingCard
                {...item}
                crossedOutPrices={!!crossedOutPrices.length}
                theme={theme}
                key={index}
                selector={trackingSelector({
                  context: item.pricing_plan.document.data.title.text,
                })}
              />
            ))}
          </ResidentialPricingCardsContainer>
        </AnimationContainer>
      </ContentContainer>
    </BlockContainer>
  );
};

export default ResidentialPricingCards;
