import { graphql, useStaticQuery } from 'gatsby';

export const footerQuery = () =>
  useStaticQuery(graphql`
    query footer {
      prismicNewFooter {
        data {
          body {
            ... on PrismicNewFooterDataBodyNavItem {
              id
              slice_type
              primary {
                category {
                  richText
                  text
                }
              }
              items {
                link {
                  url
                }
                link_label {
                  richText
                  text
                }
              }
            }
          }
        }
      }
    }
  `);
