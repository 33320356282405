import { track } from '@smartproxy/sp-tracker-js';
import { Button } from 'components/v2/Button';
import { StyledHeaderNavToggle } from 'features/v2/Header/styledComponents';
import { IconClosev2, IconMenuv2 } from 'images';
import React, { useRef } from 'react';
import { IHeaderComponents } from '../types';

const ToggleButton: React.FC<IHeaderComponents> = ({
  buildTrackingId,
  setIsOpen,
  isOpen,
}) => {
  const navToggleElRef = useRef<HTMLButtonElement>(null);

  const hamburgerTrackerId = buildTrackingId({
    context: 'hamburger',
    type: 'button',
  });

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
    track(hamburgerTrackerId);
  };

  return (
    <StyledHeaderNavToggle>
      <Button
        ref={navToggleElRef}
        variant="text"
        color="white"
        onClick={toggleDrawer}
        aria-expanded={isOpen}
        aria-controls="header-nav-menu"
        data-tracker={hamburgerTrackerId}
      >
        {isOpen ? <IconClosev2 /> : <IconMenuv2 />}
      </Button>
    </StyledHeaderNavToggle>
  );
};

export default ToggleButton;
