import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { GeneratedIdWrapper } from 'features/GeneratedIdWrapper';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_DISPLAY,
  FONT_FAMILY,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';

export const StyledBackground = styled(BackgroundWrapper)({
  maxWidth: 'unset',
  margin: 0,
  padding: '80px 20px',
});

export const ContentContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '420px 1fr',
  maxWidth: '1024px',
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const TextContent = styled.div<{ isTextLight: boolean }>(
  ({ isTextLight, ...props }) => ({
    'h1,h2,h3,h4,h5,h6': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: TEXT_ALIGN.LEFT,
      color: isTextLight
        ? getThemeMainColor(props, MAIN_COLOR.WHITE)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
    'p, strong, em': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      color: isTextLight
        ? getThemeMainColor(props, MAIN_COLOR.WHITE)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
    [MEDIA_QUERIES.TABLET]: {
      'h1,h2,h3,h4,h5,h6': {
        textAlign: TEXT_ALIGN.CENTER,
      },
    },
  })
);

export const StyledCta = styled(Cta)((props) => ({
  border: '1px solid currentColor',
  background: 'transparent',
  color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  width: '180px',
  height: '42px',
  margin: 0,
  padding: 0,
  [DOM_STATE.HOVER]: {
    padding: 0,
    border: '2px solid currentColor',
    background: 'transparent',
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '180px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    maxWidth: '180px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    maxWidth: '100%',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const Metrics = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  marginTop: getThemeGutter(props, TSHIRT_SIZE.L),

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateColumns: '1fr',
  },
}));

export const MetricTitle = styled.div((props) => ({
  'p, strong, em': {
    fontSize: '42px',
    lineHeight: '42px',
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
    fontWeight: 600,
    marginBottom: getThemeGutter(props),
  },
}));

export const MetricDescription = styled.div((props) => ({
  'p, strong, em ': {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 500,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
  },
}));

export const TitleWrapper = styled(GeneratedIdWrapper)({
  width: '100%',
});
