import styled from '@emotion/styled';
import { BackgroundWrapper, flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';

export const StyledBackgroundWrapper = styled(BackgroundWrapper)((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
}));

export const ContentContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '250px auto',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  padding: `${getThemeGutter(props, TSHIRT_SIZE.L)} 0`,
  maxWidth: '1024px',
  width: '100%',
  borderTop: '1px solid rgba(0,0,0, .1)',
  borderBottom: '1px solid rgba(0,0,0,.1)',
  img: {
    width: '210px',
    height: '210px',
    borderRadius: '50%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
    rowGap: '16px',
    justifyItems: CSS_ALIGN.CENTER,
  },
}));

export const SaleRepInfo = styled.div((props) => ({
  ...flexColContainerStyles,
  'h2, h3, h4,h5,h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    textAlign: TEXT_ALIGN.LEFT,
    fontSize: '24px',
    lineHeight: '36px',
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
    fontWeight: 600,
  },
  p: {
    marginBottom: 0,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    textAlign: TEXT_ALIGN.CENTER,
    'h2, h3, h4,h5,h6': {
      marginBottom: 0,
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const SalesRepName = styled.div((props) => ({
  p: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    marginTop: '20px',
    fontWeight: 500,
    fontFamily: getThemeFontFamily(props, FONT_FAMILY.MAIN),
  },
}));

export const SalesAdditionalInfo = styled.div((props) => ({
  p: {
    marginBottom: '10px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
  },
}));

export const CtaContainer = styled.div({
  textAlign: TEXT_ALIGN.LEFT,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    textAlign: TEXT_ALIGN.CENTER,
    a: {
      margin: 0,
      button: {
        width: 'unset',
      },
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      button: {
        width: '100%',
      },
    },
  },
});

export const StyledCta = styled(Cta)((props) => ({
  minWidth: '180px',
  height: '43px',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  margin: 0,
  marginTop: '18px',
  [MEDIA_QUERIES.TABLET]: {
    width: 'unset',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: '5px',
  },
}));
