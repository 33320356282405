import styled from '@emotion/styled';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeAccentColor,
  getThemeButtonImportantColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputFontSizeShape,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const TableWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    padding: '0 20px',
    width: '100%',
    paddingBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
  })
);

export const StyledWrapper = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: '100%',
  marginRight: 'auto',
  marginLeft: 'auto',
  border: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  borderRadius: '12px',
  [MEDIA_QUERIES.TABLET_PORTRAIT]: {
    overflow: 'scroll',
    overflowY: 'hidden',
  },
}));

export const TableContainer = styled.div({
  height: 'auto',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: `repeat(5, minmax(100px, 1fr))`,
  gridTemplateRows: `repeat(7, auto)`,
  gridAutoFlow: 'column',
  '> div:nth-of-type(-n+7)': {
    borderLeft: 'none',
    p: {
      textAlign: CSS_ALIGN.START,
    },
  },
});

export const HeaderCell = styled.div<
  ITheme & { isLast: boolean } & { isDarkHeader: boolean }
>(({ isDarkHeader, isLast, ...props }) => ({
  background: isDarkHeader
    ? getThemeMainColor(props, MAIN_COLOR.BLACK)
    : getThemeMainColor(props, MAIN_COLOR.WHITE),
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  fontFamily: getThemeFontFamily(props),
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
  color: getThemeMainColor(props),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
  lineHeight: '20px',
  fontWeight: 500,
  textAlign: TEXT_ALIGN.CENTER,
  height: '60px',
  borderTopRightRadius: isLast ? '12px' : 0,
  ':first-of-type': {
    borderTopLeftRadius: '12px',
  },
  svg: {
    margin: 0,
  },
}));

export const BiggerIconContainer = styled.div({
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    svg: {
      width: '90%',
    },
  },
});

export const SmallerIconContainer = styled.div({
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    svg: {
      width: '80%',
    },
  },
});
export const StyledCell = styled.div<
  IColor & ITheme & { isTextLight: boolean }
>(({ background, isTextLight, ...props }) => ({
  background,
  borderTop: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  borderLeft: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,
  padding: `${getThemeInputPaddingShape(
    props,
    TSHIRT_SIZE.M
  )} ${getThemeInputPaddingShape(
    props,
    TSHIRT_SIZE.L
  )} ${getThemeInputPaddingShape(props, TSHIRT_SIZE.L)}`,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  wordBreak: 'break-word',
  height: '60px',
  p: {
    textAlign: CSS_ALIGN.CENTER,
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
    margin: 0,
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    color: isTextLight
      ? getThemeMainColor(props, MAIN_COLOR.WHITE)
      : getThemeMainColor(props, MAIN_COLOR.BLACK),
    a: {
      display: CSS_DISPLAY.BLOCK,
      marginTop: '4px',
      color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
      textDecoration: 'none',
      fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
      fontFamily: getThemeFontFamily(props),
    },
  },
}));

export const HandIconWrapper = styled.div((props) => ({
  display: CSS_DISPLAY.NONE,
  svg: {
    width: '42px',
    height: '42px',
    margin: `${getThemeGutter(props, TSHIRT_SIZE.S)} auto ${getThemeGutter(
      props,
      TSHIRT_SIZE.XXS
    )}`,
  },
  [MEDIA_QUERIES.TABLET]: {
    display: CSS_DISPLAY.FLEX,
    maxWidth: '1024px',
    width: '100%',
    margin: '0 auto',
  },
}));

export const Title = styled.div((props) => ({
  marginTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.XL),
  'h2, h3,h4,h5,h6': {
    marginBottom: getThemeGutter(props),
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XL),
  },
}));

export const Subtitle = styled.div((props) => ({
  margin: '0 auto',
  textAlign: TEXT_ALIGN.CENTER,
  'p, em, strong': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const TextBelow = styled.div((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  textAlign: TEXT_ALIGN.CENTER,
  'p, em, strong': {
    color: '#b8b8b8',
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    marginBottom: 0,
  },
  a: {
    color: getThemeButtonImportantColor(props)?.background.main,
  },
}));

export const StyledCta = styled(Cta)((props) => ({
  marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
  marginBottom: 0,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  width: '180px',
  height: '42px',
  padding: 0,
  [DOM_STATE.HOVER]: {
    padding: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '180px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '180px',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const HideableTitle = hideable(Title);
export const HideableSubtitle = hideable(Subtitle);
export const HideableTextBelow = hideable(TextBelow);
