import { BUTTON_TYPES } from 'components/Button';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { colors } from 'theme-v2/colors';

export enum COLOR_SHADE {
  MAIN = 'main',
  LIGHT = 'light',
  GRAY = 'gray',
  CHARCOAL = 'charcoal',
  INHERIT = 'inherit',
  INACTIVE = 'inactive',
  SECONDARY = 'secondary',
  ERROR = 'error',
  PRIMARY = 'primary',
  OPTIONAL = 'optional',
  INVISIBLE = 'invisible',
  DEEP = 'deep',
  WHITE = 'white',
  ACCENT = 'accent',
  BLACK = 'black',
}

export enum CSS_DISPLAY {
  FLEX = 'flex',
  BLOCK = 'block',
  INLINE_BLOCK = 'inline-block',
  INLINE_FLEX = 'inline-flex',
  INLINE = 'inline',
  GRID = 'grid',
  NONE = 'none',
  TABLE = 'table',
  WEBKIT_BOX = '-webkit-box',
  TABLE_ROW = 'table-row',
  TABLE_CELL = 'table-cell',
}

export enum CSS_FLEX_WRAP {
  WRAP = 'wrap',
  NOWRAP = 'nowrap',
}

export enum CSS_JUSTIFY_CONTENT {
  CENTER = 'center',
  SPACE_EVENLY = 'space-evenly',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  NORMAL = 'normal',
  START = 'start',
  END = 'end',
}

export enum CSS_FLEX_DIRECTION {
  ROW = 'row',
  ROW_REVERSE = 'row-reverse',
  COLUMN = 'column',
  COLUMN_REVERSE = 'column-reverse',
}

export enum CSS_ALIGN {
  CENTER = 'center',
  START = 'start',
  END = 'end',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  STRETCH = 'stretch',
  BASELINE = 'baseline',
}

export enum CSS_POSITION {
  STATIC = 'static',
  STICKY = 'sticky',
  FIXED = 'fixed',
  RELATIVE = 'relative',
  ABSOLUTE = 'absolute',
  UNSET = 'unset',
}

export interface ICssContainerProps {
  display: CSS_DISPLAY;
  justifyContent?: CSS_JUSTIFY_CONTENT;
  justifySelf?: CSS_JUSTIFY_CONTENT;
  flexDirection?: CSS_FLEX_DIRECTION;
  alignItems?: CSS_ALIGN;
  flex?: number;
  gutterTop?: TSHIRT_SIZE;
  gutterBot?: TSHIRT_SIZE;
  gutterLeft?: TSHIRT_SIZE;
  gutterRight?: TSHIRT_SIZE;
  flexWrap?: CSS_FLEX_WRAP;
  alignContent?: CSS_ALIGN;
  position?: CSS_POSITION;
}

export enum STATUS_COLOR_TYPE {
  ACTIVE = 'active',
  WARNING = 'warning',
  DANGER = 'danger',
}

export interface IColorGroup {
  light?: string;
  main?: string;
  gray?: string;
  charcoal?: string;
  inherit?: string;
  inactive?: string;
  invisible?: string;
  secondary: string;
  optional?: string;
  deep?: string;
  primary: string;
  white?: string;
  error?: string;
  accent?: string;
  black?: string;
}

export interface IColorWithHover {
  main: string;
  hover?: string;
}

export interface IThemeStyles {
  text: IColorWithHover;
  border?: string;
  cursor?: string;
  background: IColorWithHover;
}

export interface IButtonColors {
  [BUTTON_TYPES.PRIMARY]: IThemeStyles;
  [BUTTON_TYPES.SECONDARY]?: IThemeStyles;
  [BUTTON_TYPES.ADDITIONAL]?: IThemeStyles;
}

interface ITshirtSize {
  xxxl?: string;
  xxl?: string;
  xl?: string;
  l: string;
  m: string;
  s: string;
  xs?: string;
  xxs?: string;
}

interface IFontSizes {
  large: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  p1: string;
  p2: string;
  small: string;
}

type TMainColors = { [key in MAIN_COLOR]: string };

interface ICommonColors {
  [COLOR_SHADE.PRIMARY]: string;
  [COLOR_SHADE.SECONDARY]: string;
  [COLOR_SHADE.DEEP]: string;
}

export interface IClickable {
  hover: ICommonColors;
}

export interface IClickableColors {
  clickable: IClickable;
}

type TFunctionalColors = IColorGroup;
type TBackgroundColors = ICommonColors;

export enum ACCENT_COLOR {
  COLOR1 = 'color1',
  COLOR2 = 'color2',
  COLOR3 = 'color3',
}

export interface IColorProp {
  color?: COLOR_SHADE;
}

type TBrandedColors = IColorGroup;

interface IAccentColors {
  color1: string;
  color2: string;
}

interface IStatus {
  active: string;
  warning: string;
  danger: string;
}
export enum FONT_FAMILY {
  MAIN = 'main',
  ADDITIONAL = 'additional',
  MONOSPACE = 'monospace',
}

export enum BORDER_PROPS {
  RADIUS = 'radius',
}

export enum TEXT_OVERFLOW {
  ELLIPSIS = 'ellipsis',
}

type TBorder = { [key in BORDER_PROPS]: string };

export interface IThemeProps {
  colors: {
    main: TMainColors;
    branded: TBrandedColors;
    functional: TFunctionalColors;
    simulator: TBackgroundColors;
    playground: IColorGroup;
    background: TBackgroundColors;
    neutral: IColorGroup;
    accent: IAccentColors;
    link: IColorGroup;
    border: IColorGroup;
    text: IColorGroup;
    error: IColorGroup;
    default: IButtonColors;
    conversion: IButtonColors;
    operational: IButtonColors;
    important: IButtonColors;
    unImportant: IButtonColors;
    following: IButtonColors;
    inactive: IButtonColors;
    status: IStatus;
  };
  shapes: {
    input: {
      padding: ITshirtSize;
      width: ITshirtSize;
      height: ITshirtSize;
      border: TBorder;
      fontSize: ITshirtSize;
    };
    avatar: {
      borderRadius: string;
      width: string;
      height: string;
      maxWidth: string;
      maxHeight: string;
      marginRight: string;
    };
  };
  fonts: {
    family: { [key in FONT_FAMILY]: string };
    size: IFontSizes;
    lineHeight: IFontSizes;
  };
  gutter: ITshirtSize;
  shadows: ITshirtSize;
}

export interface ITheme {
  theme: IThemeProps;
}

export enum MAIN_COLOR {
  WHITE = 'white',
  BLACK = 'black',
}

const theme: IThemeProps = {
  colors: {
    main: {
      white: '#FFFFFF',
      black: '#122130',
    },
    branded: {
      primary: '#F44862',
      secondary: '#122130',
      deep: '#040A0E',
    },
    functional: {
      primary: '#2C96F7',
      secondary: '#00BE7A',
      error: '#F44862',
      accent: '#F8A300',
    },
    simulator: {
      primary: '#83888c',
      secondary: '#3898ec',
      deep: '#ccc',
    },
    playground: {
      primary: '#182939',
      secondary: '#213548',
      deep: '#38526A',
      gray: '#415365',
      light: '#acbfd1',
      accent: '#3B5166',
      charcoal: '#415B74',
      invisible: '#203649',
      inactive: '#637B92',
      black: '#0f1c27',
      optional: '#768FA7',
    },
    background: {
      primary: '#EEFAFF',
      secondary: 'transparent',
      deep: '#333333',
    },
    accent: {
      color1: '#3F51F2',
      color2: '#F8A300',
    },
    neutral: {
      invisible: '#EAEAEA',
      light: '#B8B8B8',
      primary: '#D2D7DC',
      secondary: '#585858',
      deep: '#071018',
    },
    link: {
      primary: '#41688E',
      main: '#1890FF',
      secondary: '#B0C1D1',
      inherit: 'inherit',
      white: '#FFFFFF',
    },
    text: {
      gray: '#9D9D9D',
      charcoal: '#333333',
      main: '#213548',
      inherit: 'inherit',
      inactive: '#B0C1D1',
      invisible: '#D8E2EB',
      primary: '#F44862',
      secondary: '#385D7E',
      white: '#FFFFFF',
      deep: '#FFA100',
      error: '#FF0000',
      black: '#000000',
    },
    status: {
      active: '#3CC700',
      warning: '#FFBC26',
      danger: '#F44862',
    },
    border: {
      primary: '#DBDBDB',
      secondary: '#DFEBF7',
      main: '#A4A4A4',
      gray: '#D8E2EB',
      error: '#F42242',
      inactive: '#D8E2EB',
      white: '#FFFFFF',
    },
    error: {
      primary: 'none',
      secondary: 'none',
      main: '#F42242',
      light: '#FFF1F0',
    },
    operational: {
      primary: {
        border: 'none',
        text: { main: '#122130' },
        background: {
          main: '#FFFFFF',
        },
      },
    },
    default: {
      primary: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: colors.blue.default, hover: colors.blue.S600 },
      },
      secondary: {
        text: {
          main: colors.blue.default,
        },
        border: '1px solid currentColor',
        background: { main: 'transparent' },
      },
    },
    conversion: {
      primary: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: {
          main: '#F44862',
          hover: '#f44862e6',
        },
      },
      secondary: {
        text: { main: '#FFFFFF' },
        border: '1px solid #FFFFFF',
        background: {
          main: 'transparent',
        },
      },
      additional: {
        text: { main: '#122130' },
        border: 'none',
        background: {
          main: '#FFFFFF',
        },
      },
    },
    important: {
      primary: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#2C96F7', hover: '#2c96f7e6' },
      },
      secondary: {
        text: {
          main: '#3F51F2',
        },
        border: '1px solid #3F51F2',
        background: { main: 'transparent' },
      },
      additional: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#3F51F2', hover: '#3f51f2e6' },
      },
    },
    unImportant: {
      primary: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#F8A300', hover: '#f8a300e6' },
      },
      secondary: {
        text: { main: '#122130' },
        background: { main: 'transparent' },
      },
      additional: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#122130' },
      },
    },
    following: {
      primary: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#00BE7A', hover: '#39AE73' },
        cursor: 'pointer',
      },
      secondary: {
        text: { main: '#5363F4' },
        border: '1px solid #5363F4',
        background: { main: 'transparent' },
      },
      additional: {
        text: { main: '#FFFFFF' },
        border: 'none',
        background: { main: '#5363F4' },
      },
    },
    inactive: {
      primary: {
        text: { main: '#D8E2EB' },
        border: 'none',
        background: { main: '#F9FBFC' },
        cursor: 'not-allowed',
      },
      secondary: {
        text: { main: '#385D7E' },
        border: '1px solid #385D7E',
        background: { main: 'transparent' },
        cursor: 'not-allowed',
      },
      additional: {
        text: { main: '#D8E2EB' },
        border: '1px solid #D8E2EB',
        background: { main: '#FFFFFF' },
        cursor: 'not-allowed',
      },
    },
  },
  shapes: {
    input: {
      padding: {
        xs: '8px',
        s: '10px',
        m: '11px',
        l: '12px',
        xl: '80px',
      },
      width: {
        s: '25%',
        m: '50%',
        l: '75%',
        xl: '100%',
      },
      height: {
        xs: '24px',
        s: '32px',
        m: '42px',
        l: '50px',
        xl: '60px',
        xxl: '70px',
        xxxl: '95px',
      },
      border: {
        radius: '6px',
      },
      fontSize: {
        s: '12px',
        m: '14px',
        l: '16px',
        xl: '18px',
        xxl: '20px',
      },
    },
    avatar: {
      borderRadius: '50px',
      width: '100px',
      height: '100px',
      maxWidth: '110px',
      maxHeight: '110px',
      marginRight: '30px',
    },
  },
  fonts: {
    family: {
      main: 'Poppins, sans-serif',
      additional: 'DM Sans',
      monospace: 'monospace',
    },
    size: {
      large: '50px',
      h1: '36px',
      h2: '30px',
      h3: '22px',
      h4: '20px',
      h5: '18px',
      h6: '18px',
      p1: '16px',
      p2: '14px',
      small: '12px',
    },
    lineHeight: {
      large: '75px',
      h1: '54px',
      h2: '45px',
      h3: '33px',
      h4: '30px',
      h5: '27px',
      h6: '27px',
      p1: '24px',
      p2: '21px',
      small: '18px',
    },
  },
  gutter: {
    xxxl: '100px',
    xxl: '72px',
    xl: '60px',
    l: '48px',
    m: '36px',
    s: '24px',
    xs: '12px',
    xxs: '0',
  },
  shadows: {
    s: '24px',
    m: '24px',
    l: '24px',
  },
};

export const getThemeButtonDefaultColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.default[type];

export const getThemeBrandedColors = (props: ITheme) =>
  props.theme.colors.branded;
export const getThemeFunctionalColors = (props: ITheme) =>
  props.theme.colors.functional;
export const getThemeBackgroundColors = (props: ITheme) =>
  props.theme.colors.background;
export const getThemeNeutralColors = (props: ITheme) =>
  props.theme.colors.neutral;

export const getThemeLinkColor = (props: ITheme, shade = COLOR_SHADE.MAIN) =>
  props.theme.colors.link[shade];

export const getThemeMainColor = (props: ITheme, color = MAIN_COLOR.WHITE) =>
  props.theme.colors.main[color];
export const getThemeNeutralColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.neutral[shade];
export const getThemeBrandedColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.branded[shade];
export const getThemeFunctionalColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.functional[shade];
export const getThemeSimulatorColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.simulator[shade];
export const getThemePlaygroundColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.playground[shade];
export const getThemeBackgroundColor = (
  props: ITheme,
  shade = COLOR_SHADE.PRIMARY
) => props.theme.colors.background[shade];
export const getThemeAccentColor = (props: ITheme, shade: ACCENT_COLOR) =>
  props.theme.colors.accent[shade];

export const getThemeErrorColor = (props: ITheme, shade = COLOR_SHADE.MAIN) =>
  props.theme.colors.error[shade];

export const getThemeStatusColor = (
  props: ITheme,
  shade = STATUS_COLOR_TYPE.ACTIVE
) => props.theme.colors.status[shade];

export const getThemeBorderColor = (props: ITheme, shade = COLOR_SHADE.MAIN) =>
  props.theme.colors.border[shade];

export const getThemeFontFamily = (props: ITheme, font = FONT_FAMILY.MAIN) =>
  props.theme.fonts.family[font];

export const getThemeFontSize = (props: ITheme, size = TEXT_TYPE.P1) =>
  props.theme.fonts.size[size];

export const getThemeFontLineHeight = (props: ITheme, size = TEXT_TYPE.P1) =>
  props.theme.fonts.lineHeight[size];

export const getThemeInputBorderShape = (props: ITheme, name: BORDER_PROPS) =>
  props.theme.shapes.input.border[name];
export const getThemeInputBorderColor = (props: ITheme, name: COLOR_SHADE) =>
  props.theme.colors.border[name];

export const getThemeInputPaddingShape = (
  props: ITheme,
  size = TSHIRT_SIZE.S
) => props.theme.shapes.input.padding[size];

export const getThemeInputWidthShape = (props: ITheme, size = TSHIRT_SIZE.XL) =>
  props.theme.shapes.input.width[size];

export const getThemeInputHeightShape = (props: ITheme, size = TSHIRT_SIZE.S) =>
  props.theme.shapes.input.height[size];

export const getThemeInputFontSizeShape = (
  props: ITheme,
  size = TSHIRT_SIZE.S
) => props.theme.shapes.input.fontSize[size];

export const getThemeAvatarShape = (props: ITheme) => props.theme.shapes.avatar;

export const getThemeButtonConversionColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.conversion[type];
export const getThemeButtonOperationalColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.operational[type];
export const getThemeButtonNotImportantColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.unImportant[type];
export const getThemeButtonImportantColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.important[type];
export const getThemeButtonFollowingColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.following[type];
export const getThemeButtonInactiveColor = (
  props: ITheme,
  type = BUTTON_TYPES.PRIMARY
) => props.theme.colors.inactive[type];

export const getThemeGutter = (props: ITheme, size = TSHIRT_SIZE.XS) =>
  props.theme.gutter[size];

export const getThemeShadow = (props: ITheme, size = TSHIRT_SIZE.M) =>
  props.theme.shadows[size];

export const getThemeTextColor = (props: ITheme, shade = COLOR_SHADE.MAIN) =>
  props.theme.colors.text[shade];

export default theme;
