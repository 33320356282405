export const defaultNofollowExclusions = [
  {
    domain: 'smartproxy.com',
  },
  {
    domain: 'dashboard.smartproxy.com',
  },
  {
    domain: 'help.smartproxy.com',
  },
];
