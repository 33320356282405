import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';
import { generateSectionsId } from 'features/utils';
import { IconHandSwipe } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import { TableBlockColumn } from './Column';
import {
  HandIconWrapper,
  StyledWrapper,
  Subtitle,
  TableContainer,
  TabledContainer,
  TitleWrapper,
} from './styledComponents';
import { ITable } from './types';
import { getTableHeader, groupTableData } from './utils';

const HideableSubtitle = hideable(Subtitle);

const TableBlock: React.FC<ITable> = ({
  primary: {
    background,
    header_background: headerBackground,
    title,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
  },
  items,
}) => {
  const tableData = groupTableData(items);
  const tableHeader = getTableHeader(items);
  const noOfRows = tableData[Object.keys(tableData)[0]].length + 1;
  const { tags, type } = usePageContext();

  const sliceId = generateSectionsId('table', tags, type);

  return (
    <TabledContainer background={background} id={sliceId}>
      <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
        <RichText field={title} />
      </TitleWrapper>
      <HideableSubtitle
        isVisible={subtitle?.text}
        isSubtitleCentered={isSubtitleCentered}
      >
        <RichText field={subtitle} />
      </HideableSubtitle>
      <StyledWrapper>
        <TableContainer noOfCols={tableHeader.length} noOfRows={noOfRows}>
          {tableHeader.map((item, index) => (
            <TableBlockColumn
              headerBackground={headerBackground}
              header={item}
              data={tableData[item]}
              key={index}
              isLast={tableHeader.length - 1 === index}
            />
          ))}
        </TableContainer>
      </StyledWrapper>
      <HandIconWrapper>
        <IconHandSwipe />
      </HandIconWrapper>
    </TabledContainer>
  );
};
export default TableBlock;
