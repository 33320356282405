import { TEXT_ALIGN } from './../../components/constants';
import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  getThemeGutter,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';
import { RichText } from 'components/v2/RichText';

export const TextColumnsContainer = styled.div<ITheme>((props) => ({
  width: '100%',
  padding: '0 20px',
  maxWidth: '1024px',
  margin: `0 auto ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
  'h1, h2, h3, h4, h5, h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    textAlign: TEXT_ALIGN.LEFT,
  },

  h3: {
    fontSize: '22px',
  },
  h4: {
    fontSize: '20px',
  },
}));

export const StyledListContainer = styled.ul<ITheme>((props) => ({
  listStyle: 'none',
  padding: 0,
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    columnGap: getThemeGutter(props),
  },
}));

export const ListItem = styled.li<ITheme>((props) => ({
  padding: 0,
  width: '100%',
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  p: {
    wordBreak: 'break-word',
  },
  'h2, h3, h4': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    lineHeight: '26px',
    wordBreak: 'break-word',
    textAlign: TEXT_ALIGN.LEFT,
  },
}));

export const HideableNewRichText = hideable(RichText);
