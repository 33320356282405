import GatsbyImg from 'components/GatsbyImg';

import * as React from 'react';
import { PrismicTextField, TGatsbyImage } from 'types';
import {
  BlogAuthorContainer,
  BlogAuthorDescription,
  BlogAuthorImageWrapper,
  BlogAuthorInfoContainer,
  BlogAuthorName,
  BlogAuthorWrapper,
} from './styledComponents';
import { RichText } from 'components/v2/RichText';

export interface IBlogAuthor {
  primary: {
    blog_author: {
      document: {
        data: {
          authors_image: TGatsbyImage;
          authors_bio: PrismicTextField;
          authors_title: PrismicTextField;
          full_name: PrismicTextField;
        };
      };
    };
  };
}

const BlogAuthor: React.FC<IBlogAuthor> = ({
  primary: {
    blog_author: {
      document: {
        data: {
          authors_image: authorImage,
          authors_bio: authorBio,
          authors_title: authorTitle,
          full_name: fullname,
        },
      },
    },
  },
}) => (
  <BlogAuthorWrapper>
    <BlogAuthorContainer>
      <BlogAuthorImageWrapper>
        <GatsbyImg image={authorImage} />
      </BlogAuthorImageWrapper>
      <BlogAuthorInfoContainer>
        <BlogAuthorName>
          <RichText field={fullname} />
        </BlogAuthorName>
        <BlogAuthorDescription>
          <RichText field={authorTitle} />
        </BlogAuthorDescription>
        <RichText field={authorBio} />
      </BlogAuthorInfoContainer>
    </BlogAuthorContainer>
  </BlogAuthorWrapper>
);

export default BlogAuthor;
