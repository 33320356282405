import React from 'react';
import hideable from 'utils/hideable';
import { PricingCardsContainer } from './styled';
import { PricingCard } from './PricingCard';
import { IPlans } from '../../types';

const Plans: React.FC<IPlans> = ({ plans }) => {
  return (
    <PricingCardsContainer itemsLength={plans.length}>
      {plans.map(
        (
          {
            pricing_card: {
              type,
              document: { data },
            },
          },
          index
        ) =>
          type === 'pricing_card' && (
            <PricingCard index={index} data={data} key={data.gb_value} />
          )
      )}
    </PricingCardsContainer>
  );
};

export default hideable(Plans);
