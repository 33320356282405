import React from 'react';
import hideable from 'utils/hideable';
import { InfoPopup, InfoTitle } from '../../styledComponents';
import { IPopup } from '../../types';

const InfoPopupContainer: React.FC<IPopup> = ({
  title,
  info,
  isRequestSent,
  dataTracker,
}) => (
  <InfoPopup isRequestSent={isRequestSent} data-tracker={dataTracker}>
    <InfoTitle>{title}</InfoTitle>
    <p>{info}</p>
  </InfoPopup>
);

export default hideable(InfoPopupContainer);
