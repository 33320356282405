import { themev2 } from 'theme-v2';
import { ctaActionsMap } from './constants';

export const getHeroAccentColor = (
  accentName?: 'red' | 'blue' | 'green' | 'purple' | string
) => {
  if (!accentName) return themev2.colors.neutral.N00;

  const colorMap = {
    red: themev2.colors.brand.B99,
    blue: themev2.colors.primary.P99,
    green: themev2.colors.green.default,
    purple: themev2.colors.purple.default,
  };

  return colorMap[accentName.toLowerCase()] ?? themev2.colors.neutral.N00;
};

export const convertEmbedDimensionsToFill = (embedCode: string) =>
  embedCode.replace(
    /(width|height)="\w+"/g,
    (_, dimensionType) => `${dimensionType}="100%"`
  );

export const assertCtaAction = (
  action: string
): action is keyof typeof ctaActionsMap => {
  return action in ctaActionsMap;
};
