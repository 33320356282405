import { IconCloseBlue } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import {
  ParametersContainer,
  ParametersContent,
  ParametersHeader,
  ParametersItem,
  ParametersItemTitle,
  ParametersScrollContainer,
  ParametersTitle,
} from '../styledComponents';
import { IParametersDrawer } from '../types';

const ParametersDrawer: React.FC<IParametersDrawer> = ({
  title,
  parameters,
  closeParameters,
  trackingSelector,
}) => (
  <ParametersContainer>
    <ParametersScrollContainer>
      <IconCloseBlue
        onClick={closeParameters}
        data-tracker={trackingSelector}
      />
      <ParametersHeader>
        <ParametersTitle>{title}</ParametersTitle>
      </ParametersHeader>
      <ParametersContent>
        {parameters.map((param) => (
          <ParametersItem key={param.title}>
            <ParametersItemTitle>{param.title}</ParametersItemTitle>
            <p>{param.description}</p>
          </ParametersItem>
        ))}
      </ParametersContent>
    </ParametersScrollContainer>
  </ParametersContainer>
);

export default hideable(ParametersDrawer);
