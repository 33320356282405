import RadioButtons from 'components/RadioButtons';
import { RichText } from 'components/v2/RichText';
import { SECTION_NAMES, TABS } from 'constants/common';
import { generateSectionsId, options } from 'features/utils';
import React, { useState } from 'react';
import { buildTrackingSelector, initBuildTrackingSelector } from 'utils/page';
import Feature from './Feature';
import PricingCard from './PricingCard';
import {
  AnimationContainer,
  BlockContainer,
  ContentContainer,
  Features,
  HideableSubtitle,
  PricingCardsContainer,
  TitleWrapper,
} from './styledComponents';
import { IPricingCards } from './types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const PricingCards: React.FC<IPricingCards> = ({
  items,
  primary: {
    feature_one: featureOne,
    feature_two: featureTwo,
    feature_three: featureThree,
    feature_four: featureFour,
    feature_five: featureFive,
    switch_monthly_label: switchMonthlyLabel,
    switch_yearly_label: switchYearlyLabel,
    switch_discount_label: discountLabel,
    theme,
    title,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
    product,
    background,
    is_yearly_visible: isYearlyVisible,
    toggle_label_text: labelTextPlacement,
  },
  pageContext: { tags, type },
}) => {
  const { id: sliceId } = useSliceDetails();
  const [selectedBilling, setSelectedBilling] = useState(TABS.LEFT);

  const monthlyItems = items.filter(
    ({ billing_type: billingType }) => !billingType
  );
  const yearlyItems = items.filter(
    ({ billing_type: billingType }) => billingType
  );

  const crossedOutPrices = items.filter(
    ({ crossed_out_price: crossedOutPrice }) => crossedOutPrice
  );

  const pricingCardItems =
    selectedBilling === TABS.LEFT ? monthlyItems : yearlyItems;

  const getNumberOfColumns = (cardsLength) => {
    switch (cardsLength) {
      case 1:
        return 1;
      case 2:
        return 2;
      case 3 || 5 || 6 || 9:
        return 3;
      default:
        return 4;
    }
  };

  const numberOfColumns = getNumberOfColumns(pricingCardItems.length);

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });

  const handleOnChange = (value) => {
    logPostHogEvent(trackingSelector({ context: value, type: 'button' }));
    if (sliceId) {
      logGAElementClickEvent({
        elementId: value,
        sectionId: sliceId,
      });
    }
    setSelectedBilling(value as TABS);
  };

  const sectionId = generateSectionsId(SECTION_NAMES.PRICING_CARDS, tags, type);

  return (
    <BlockContainer
      background={background}
      data-section-id={sectionId}
      id={sectionId}
    >
      <ContentContainer
        id={buildTrackingSelector({
          prefix: type,
          page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
          context: title.text,
        })}
      >
        <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
          <RichText field={title} />
        </TitleWrapper>
        <HideableSubtitle
          isVisible={subtitle?.text}
          isSubtitleCentered={isSubtitleCentered}
        >
          <RichText field={subtitle} />
        </HideableSubtitle>
        <RadioButtons
          options={options(switchMonthlyLabel, switchYearlyLabel)}
          labelTextPlacement={labelTextPlacement}
          badgeText={discountLabel}
          onChange={(value) => handleOnChange(value)}
          isVisible={isYearlyVisible}
          name="pricing"
        />
        <AnimationContainer key={selectedBilling}>
          <Features>
            <Feature>{featureOne}</Feature>
            <Feature>{featureTwo}</Feature>
            <Feature>{featureThree}</Feature>
            <Feature>{featureFour}</Feature>
            <Feature>{featureFive}</Feature>
          </Features>
          <PricingCardsContainer
            numberOfColumns={numberOfColumns}
            isSingleCard={numberOfColumns === 1}
          >
            {pricingCardItems.map((item, index) => (
              <PricingCard
                {...item}
                crossedOutPrices={!!crossedOutPrices.length}
                product={product}
                theme={theme}
                key={index}
                index={index}
                selector={trackingSelector({
                  context:
                    selectedBilling === TABS.LEFT
                      ? `${item.card_title}-${switchMonthlyLabel}`
                      : `${item.card_title}-${switchYearlyLabel}`,
                })}
              />
            ))}
          </PricingCardsContainer>
        </AnimationContainer>
      </ContentContainer>
    </BlockContainer>
  );
};

export default PricingCards;
