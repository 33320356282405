import {
  getThemeGutter,
  getThemeInputWidthShape,
  ITheme,
  IThemeProps,
} from '../theme';
import { TSHIRT_SIZE } from './constants';

export type TGutter = IGutterProps & ITheme;

export interface IGutterProps {
  gutterTop?: TSHIRT_SIZE;
  gutterBot?: TSHIRT_SIZE;
  gutterLeft?: TSHIRT_SIZE;
  gutterRight?: TSHIRT_SIZE;
}
export const getGutter = ({
  gutterTop,
  gutterBot,
  gutterLeft,
  gutterRight,
  ...props
}: TGutter) =>
  `${getThemeGutter(props, gutterTop)}
    ${getThemeGutter(props, gutterRight || TSHIRT_SIZE.XXS)}
    ${getThemeGutter(props, gutterBot)}
    ${getThemeGutter(props, gutterLeft || TSHIRT_SIZE.XXS)}`;

export const getInputMaxWidth = (
  props: { theme: IThemeProps },
  inputWidth?: TSHIRT_SIZE
) => (inputWidth ? getThemeInputWidthShape(props, inputWidth) : '400px');

export const noop = () => {};

export const getTypeFromPath = (pathname: string) =>
  pathname?.split('/')[1]?.toLocaleLowerCase();
