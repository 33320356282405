import React from 'react';
import {
  HideableNewRichText,
  ListItem,
  StyledListContainer,
  TextColumnsContainer,
} from './styledComponent';
import { ITextColumns } from './types';
import { RichText } from 'components/v2/RichText';

const TextColumns: React.FC<ITextColumns> = ({ primary: { title }, items }) => (
  <TextColumnsContainer>
    <RichText field={title} />
    <StyledListContainer>
      {items.map(({ small_title: smallTitle, content }, index) => (
        <ListItem key={index}>
          <HideableNewRichText field={smallTitle} isVisible={smallTitle.text} />
          <RichText field={content} />
        </ListItem>
      ))}
    </StyledListContainer>
  </TextColumnsContainer>
);

export default TextColumns;
