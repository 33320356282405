import GatsbyImg from 'components/GatsbyImg';
import { RichText } from 'components/v2/RichText';
import { SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import {
  appendPlanIdToURL,
  ctaLinkHandler,
  getUpdatedAccentColor,
} from 'features/utils';
import { IconArrowRightv2, IconContactSalesv2, IconMoneyBackv2 } from 'images';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { getPlanId } from 'utils/plans';
import { MONEY_BACK_TEXT } from '../constants';
import {
  CardTitle,
  CrossedOutPrice,
  CtaContainer,
  TimePeriod,
  HideableCardFlag,
  HideableIconContainer,
  HideableOldPrice,
  HideablePriceWrapper,
  HideableSaveBadge,
  HideableTotalPrice,
  IconText,
  IconWrapper,
  MoneyBack,
  NewPrice,
  PricingCardContainer,
  StyledCta,
} from '../styledComponents';
import { IPricingCard } from '../types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

export const PricingCard: React.FC<IPricingCard & { index: number }> = ({
  data: {
    gb_value: gbValue,
    save_percentage: savePercentage,
    old_price: oldPrice,
    new_price: newPrice,
    new_total: newTotal,
    billing_name: billingName,
    cta_label: ctaLabel,
    cta_url: ctaUrl,
    old_total: oldTotal,
    button_color: buttonColor,
    text_under_icon: iconText,
    icon,
    is_most_popular: isMostPopular,
    is_payg: isPayg,
    cta_icon: ctaIcon,
    flag_text: flagText,
    product,
    card_plan_name: cardPlanName,
    is_ddc_non_seq: isDDCNonSeq,
    is_money_back_guarantee_visible: isMoneyBackGuaranteeVisible,
  },
  index,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_CARDS,
    prefix: type,
  });
  const ctaColor = getUpdatedAccentColor(buttonColor);
  const planId = cardPlanName && getPlanId(cardPlanName, product, isDDCNonSeq);
  const normalizedCtaLink = ctaLinkHandler(ctaUrl.url!);
  const checkedCtaLink =
    !planId || ctaLabel === 'Contact sales'
      ? normalizedCtaLink
      : appendPlanIdToURL(normalizedCtaLink, planId);

  switch (ctaIcon) {
    case 'Contact sales':
      ctaIcon = <IconContactSalesv2 />;
      break;
    case 'Arrow':
      ctaIcon = <IconArrowRightv2 />;
      break;
    default:
      ctaIcon = null;
  }
  const showTopLabel = isMostPopular || isPayg || !!flagText;
  return (
    <PricingCardContainer
      isMostPopular={isMostPopular}
      isFlagAdded={showTopLabel}
    >
      <HideableCardFlag isVisible={showTopLabel} isMostPopular={isMostPopular}>
        {flagText}
      </HideableCardFlag>
      <CardTitle isDiscountAdded={!!savePercentage} isIconAdded={!!icon.url}>
        {gbValue}
      </CardTitle>
      <HideableSaveBadge isVisible={savePercentage}>
        Save {savePercentage}
      </HideableSaveBadge>
      <HideablePriceWrapper isVisible={!!newPrice}>
        <HideableOldPrice isVisible={!!oldPrice}>${oldPrice}</HideableOldPrice>
        <NewPrice>
          ${newPrice}
          <TimePeriod>/{billingName}</TimePeriod>
        </NewPrice>
      </HideablePriceWrapper>
      <HideableTotalPrice isVisible={!!oldTotal || !!newTotal.text}>
        Total: <CrossedOutPrice>{oldTotal}</CrossedOutPrice>
        <RichText field={newTotal} />
      </HideableTotalPrice>
      <HideableIconContainer isVisible={iconText}>
        <IconWrapper>
          <GatsbyImg image={icon} />
        </IconWrapper>
        <IconText>{iconText}</IconText>
      </HideableIconContainer>
      <CtaContainer>
        <StyledCta
          isButtonBlue={ctaColor?.themeType === 'primary'}
          ctaText={ctaLabel}
          ctaLink={checkedCtaLink}
          isVisible={ctaUrl.url}
          themeName={ctaColor?.themeName}
          themeType={ctaColor?.themeType}
          icon={ctaIcon}
          sendTrackingEvent={() => {
            logPostHogEvent(trackingSelector({ context: cardPlanName }));
            if (sliceId) {
              logGAElementClickEvent({
                elementId: `${ctaLabel}:${index}`,
                sectionId: sliceId,
              });
            }
          }}
          dataTracker={trackingSelector({ context: cardPlanName })}
        />
      </CtaContainer>
      <MoneyBack>
        {isMoneyBackGuaranteeVisible && (
          <>
            <IconMoneyBackv2 /> <p>{MONEY_BACK_TEXT}</p>
          </>
        )}
      </MoneyBack>
    </PricingCardContainer>
  );
};
