import styled from '@emotion/styled';

export const StyledRadialProgress = styled.svg<{
  size: number;
  trackWidth: number;
}>(({ size, trackWidth }) => ({
  height: size,
  transform: 'rotate(-90deg)',

  circle: {
    transition: 'all 0.3s linear',
    strokeWidth: trackWidth,
  },
}));
