import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import {
  CONTACT_SALES_URL_HASH,
  NAMES,
  PRISMIC_VALUES,
  SECTION_NAMES,
  TABS,
} from 'constants/common';
import { clearPlanCookies, setPlanCookies } from 'helpers/cookiesHelper';
import { RefObject } from 'react';
import { PrismicTextField } from 'types';
import { isBrowser } from 'utils/build';
import { Product, products } from 'constants/products';
import { colors } from 'theme-v2/colors';
import { normalizeString } from '@smartproxy-web/shared/utils';

type TGenerateTitleId = (title: string) => string;
export interface IBackground {
  isBackgroundDark: boolean;
}

export type TSetGeneratedId = (
  text: PrismicTextField,
  titleType: string,
  ref: RefObject<HTMLDivElement>
) => void;

export const slugify: TGenerateTitleId = (title) => {
  if (!title) return '';

  const initialNumberingRegex = /^([0-9]\.\s?)+/g;
  const spacesRegex = /\/|\s+/g;
  const punctuationRegex = /[^a-zA-Z0-9-]+/g;

  return title
    .toLowerCase()
    .trim()
    .replaceAll(initialNumberingRegex, '')
    .replaceAll(spacesRegex, '-')
    .replaceAll('&', 'and')
    .replaceAll(punctuationRegex, '');
};

export const shortenText = (text: string, number: number) => {
  const formatedText = text.split('').slice(0, number).join('');
  if (text.length > number + 1) {
    return formatedText + PRISMIC_VALUES.DOTS;
  }
  return formatedText;
};

export const scrollSmoothUnset = () => {
  document.querySelector('html')!.style.scrollBehavior = 'unset';
};

export const scrollSmooth = () => {
  document.querySelector('html')!.style.scrollBehavior = 'smooth';
};

export const defaultAnimationOptions404 = {
  loop: true,
  autoplay: true,
};

export const contactSalesPath =
  isBrowser && window.location.href + CONTACT_SALES_URL_HASH;

export const assertProduct = (
  possibleProduct: string
): possibleProduct is Product => {
  return products.includes(normalizeString(possibleProduct) as Product);
};

export const cookieHandler = ({
  name,
  scrapingApiPlan,
  DDCNonSeq,
  product,
  e,
}: {
  name: string;
  scrapingApiPlan?: string;
  DDCNonSeq?: boolean;
  product?: Product;
  e?: React.SyntheticEvent;
}) => {
  e?.preventDefault();

  name === NAMES.PAYG_NAME
    ? clearPlanCookies()
    : setPlanCookies({ planName: name, scrapingApiPlan, DDCNonSeq, product });
};

export const ctaLinkHandler = (ctaLink: string) => {
  return ctaLink == `https://${CONTACT_SALES_URL_HASH}`
    ? contactSalesPath
    : ctaLink;
};

export const options = (labelValueOne: string, labelValueTwo: string) => {
  return [
    {
      label: labelValueOne,
      value: TABS.LEFT,
    },
    { label: labelValueTwo, value: TABS.RIGHT },
  ];
};

export const setGeneratedId: TSetGeneratedId = (
  { richText },
  titleType,
  ref
) => {
  richText?.find((item) =>
    item.type === titleType
      ? ref.current?.setAttribute('id', slugify(item.text))
      : null
  );
};

export const scrollHelper = (clientHeight: number, scrollHeight: number) => {
  const height = scrollHeight - clientHeight;
  const scrolled = (window.pageYOffset / height) * 100;
  const progressWidth = scrolled.toString() + '%';
  return progressWidth;
};

export const copyCode = (
  element: string | undefined,
  toggleVisibility: (value: boolean) => void
) => {
  toggleVisibility(true);

  navigator.clipboard.writeText(element!);

  setTimeout(() => {
    toggleVisibility(false);
  }, 2000);
};

const checkSearchText = (text: string) => {
  const punctuationRegex = /[^a-zA-Z0-9-]+/g;
  const checkedText = text
    .trim()
    .toLowerCase()
    .split('-')
    .join()
    .replaceAll(punctuationRegex, ' ');
  return checkedText;
};

export const matchTitle = (text: string, value: string) => {
  const checkedTitle = checkSearchText(text);
  const checkedValue = checkSearchText(value);
  const matchedTitle = checkedTitle.includes(checkedValue);
  return matchedTitle;
};

export const generateSectionsId = (
  section: SECTION_NAMES | string,
  tags: string[],
  type: string
) =>
  tags.length > 1
    ? `${section}-${slugify(tags.join('-'))}`
    : `${section}-${slugify(tags[0] || type)}`;

export const getCheckmarkColor = (theme: string) => {
  if (theme === 'Green') return '#3CC700';
  if (theme === 'Red') return '#F44862';
  if (theme === 'Yellow') return '#F8A300';
  return '#2C96F7';
};

export const updateColorInfo = (color: string) => {
  const colorMap = {
    blue: {
      themeName: BUTTON_THEMES.DEFAULT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: colors.blue.default,
    },
    red: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F44862',
    },
    green: {
      themeName: BUTTON_THEMES.FOLLOWING,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#00BE7A',
    },
    yellow: {
      themeName: BUTTON_THEMES.UNIMPORTANT,
      themeType: BUTTON_TYPES.PRIMARY,
      color: '#F8A300',
    },
    transparent: {
      themeName: BUTTON_THEMES.CONVERSION,
      themeType: BUTTON_TYPES.SECONDARY,
      color: '#FFFF',
    },
    blue_border: {
      themeName: BUTTON_THEMES.DEFAULT,
      themeType: BUTTON_TYPES.SECONDARY,
      color: colors.blue.default,
    },
  };

  return colorMap[color];
};

export const getUpdatedAccentColor = (accentColor: string) => {
  const updatedColor =
    accentColor !== 'None' &&
    updateColorInfo(accentColor?.toLowerCase().split(' ').join('_'));

  return updatedColor;
};

export const appendPlanIdToURL = (url: string, planId: string) =>
  url && url.includes('?')
    ? `${url}&planId=${planId}`
    : `${url}?planId=${planId}`;
