import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import Scraper from './Scraper';
import { Wrapper, HideableTitles } from './styledComponents';
import { ISimulatorNoCode } from './utils';

const SimulatorNoCode: React.FC<ISimulatorNoCode> = ({
  primary: { title, description, background },
}) => (
  <Wrapper background={background}>
    <HideableTitles isVisible={!!title || !!description}>
      <NewRichText text={title} />
      <NewRichText text={description} />
    </HideableTitles>
    <Scraper />
  </Wrapper>
);

export default SimulatorNoCode;
