import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { Button, CtaWrapper } from '../styledComponents';
import { IVideoCta } from '../types';

const CtaContainer: React.FC<IVideoCta> = ({
  labelOne,
  labelTwo,
  linkOne,
  linkTwo,
  tags,
  type,
}) => {
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'streaming',
    prefix: type,
  });

  return (
    <CtaWrapper>
      <Button
        linkId={trackingSelector({ context: labelOne.text })}
        linkClassName={trackingSelector({ context: labelOne.text })}
        dataTracker={trackingSelector({ context: labelOne.text })}
        sendTrackingEvent={() =>
          track(trackingSelector({ context: labelOne.text }))
        }
        isVisible={labelOne.text && linkOne.url}
        ctaText={labelOne.text}
        ctaLink={linkOne.url}
      />
      <Button
        linkId={trackingSelector({ context: labelTwo.text })}
        linkClassName={trackingSelector({ context: labelTwo.text })}
        dataTracker={trackingSelector({ context: labelTwo.text })}
        sendTrackingEvent={() =>
          track(trackingSelector({ context: labelTwo.text }))
        }
        isVisible={labelTwo.text && linkTwo.url}
        ctaText={labelTwo.text}
        ctaLink={linkTwo.url}
      />
    </CtaWrapper>
  );
};

export default CtaContainer;
