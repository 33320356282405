import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import Spinner from 'components/Spinner';
import React from 'react';
import { FormControl, StyledButton } from '../styledComponents';
import { IInquiryFormControl } from '../types';
import { RichText } from 'components/v2/RichText';

const InquiryFormControl: React.FC<IInquiryFormControl> = ({
  bottomText,
  ctaText,
  ctaClassName,
  isLoading,
  isDisabled,
}) => (
  <FormControl>
    <RichText field={bottomText} />
    <StyledButton
      id="contact-sales-form-request-trial-cta"
      fontSize={TSHIRT_SIZE.L}
      gutterTop={TSHIRT_SIZE.XXS}
      gutterBot={TSHIRT_SIZE.XXS}
      themeName={BUTTON_THEMES.FOLLOWING}
      themeType={BUTTON_TYPES.PRIMARY}
      className={ctaClassName}
      isDisabled={isDisabled}
      data-tracker={ctaClassName}
      onClick={() => track(ctaClassName)}
    >
      {!isLoading ? ctaText : <Spinner />}
    </StyledButton>
  </FormControl>
);

export default InquiryFormControl;
