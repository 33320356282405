import React from 'react';
import { PrismicTextField } from 'types';
import { TitleWrapper } from './styledComponents';
import { RichText } from 'components/v2/RichText';

interface ITitle {
  primary: {
    background: string;
    title: PrismicTextField;
    center: boolean;
  };
}
const Title: React.FC<ITitle> = ({
  primary: { background, title, center },
}) => (
  <TitleWrapper background={background} isCentered={center}>
    <div>
      <RichText field={title} />
    </div>
  </TitleWrapper>
);

export default Title;
