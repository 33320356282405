export const mergeObjects = <
  T extends Record<string, unknown>,
  U extends T = T
>(
  obj1: T,
  obj2: U
) => {
  const mergedObj = { ...obj1 };

  for (const prop in obj2) {
    if (typeof obj2[prop] !== 'undefined' && obj2[prop] !== null) {
      mergedObj[prop] = obj2[prop];
    }
  }

  return mergedObj;
};
