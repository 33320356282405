import BlogAuthor from 'features/Blog/BlogPost/BlogAuthor';
import BlogHero from 'features/Blog/BlogPost/Hero';
import { SocialButtonsBar } from 'features/SocialButtonsBar';
import { SliceMap } from 'types';

export enum BLOG_SLICES {
  Hero = 'hero_block',
  BlogAuthor = 'blog_author',
  SocialButtonsBar = 'social_buttons_bar',
}

export const blogSliceMap: SliceMap<BLOG_SLICES> = {
  [BLOG_SLICES.Hero]: BlogHero,
  [BLOG_SLICES.BlogAuthor]: BlogAuthor,
  [BLOG_SLICES.SocialButtonsBar]: SocialButtonsBar,
};
