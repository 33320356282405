import styled from '@emotion/styled';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import NotFoundPageBackground from '/static/images/PageNotFound.svg';

export const NotFoundPageWrapper = styled.div<ITheme>((props) => ({
  position: CSS_POSITION.STATIC,
  width: '100%',
  left: 0,
  top: 0,
  right: 0,
  bottom: 'auto',
  overflow: 'hidden',
  height: 'auto',
  backgroundColor: getThemeMainColor(props, MAIN_COLOR.BLACK),
  backgroundImage: `url(${NotFoundPageBackground})`,
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

export const NotFoundPageContainer = styled.div({
  maxWidth: '1024px',
  margin: '0 auto',
});

export const NotFoundPage = styled.div({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  position: CSS_POSITION.RELATIVE,
  height: 'fit-content',
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_END,
  },
});

export const NotFoundPageAnimationWrapper = styled.div({});

export const NotFoundPageTextContainer = styled.div<ITheme>((props) => ({
  position: CSS_POSITION.ABSOLUTE,
  zIndex: 1,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: 'auto',
  height: 'auto',
  maxWidth: '420px',
  alignItems: CSS_ALIGN.CENTER,
  alignSelf: CSS_ALIGN.CENTER,
  'h1, h2, p': {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    color: getThemeMainColor(props),
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const NotFoundPageTitle = styled.div({
  h1: {
    margin: 0,
    fontSize: '120px',
    lineHeight: '120px',
  },
});

export const NotFoundPageSubtitle = styled.div({
  h2: {
    margin: 0,
    fontSize: '32px',
    lineHeight: '48px',
  },
});

export const NotFoundPageDescription = styled.div<ITheme>((props) => ({
  p: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const NotFoundPageCtaContainer = styled(Cta)<ITheme>((props) => ({
  width: '200px !important',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  margin: 0,
  lineHeight: '20px',
  [DOM_STATE.HOVER]: {
    padding: `${getThemeInputPaddingShape(
      props,
      TSHIRT_SIZE.S
    )} ${getThemeInputPaddingShape(props, TSHIRT_SIZE.M)}`,
  },
}));
