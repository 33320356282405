import styled from '@emotion/styled';
import { TEXT_TYPE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { getThemeFontLineHeight, getThemeFontSize } from 'theme';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';

export const StyledCountdownTimer = styled.div({
  padding: `2px ${theme.spacings.XS}`,
  width: 'fit-content',
  height: '24px',
  alignItems: 'center',
  borderRadius: '24px',
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  color: themev2.colors.black.S900,
  blockSize: 'fit-content',
  fontWeight: 500,
  background: '#00F0D3',
  display: 'flex',
  gap: '0 4px',
  position: 'relative',
  span: {
    fontFamily: theme.fonts.family.additional,
    letterSpacing: 1.2,
  },

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    '.hide-on-mobile': {
      display: 'none',
    },
  },
});

export const StyledCountdownTimeComponentsWrapper = styled.span({
  display: 'flex',
  gap: '4px',

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: 'center',
  },
});

export const StyledCountdownTimeWrapper = styled.span({
  position: 'relative',
  display: 'inline-flex',
  justifyContent: 'center',

  '[data-hidden=true]': {
    visibility: 'hidden',
  },
  '[data-hidden=false]': {
    position: 'absolute',
  },
});

export const StyledCountdownTimeComponent = styled.span((props) => ({
  display: 'flex',
  alignItems: 'baseline',
  gap: '2px',

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: 'center',
  },

  span: {
    fontVariantNumeric: 'tabular-nums',
  },
  small: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.SMALL),
    fontWeight: '300',
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
  },
}));
