import { track } from '@smartproxy/sp-tracker-js';
import { SCRAPER_VALUES, SIMULATOR_PLANS } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import React, { useEffect, useRef, useState } from 'react';
import { buildTrackingSelector } from 'utils/page';
import Form from './components/Form';
import FormTop from './components/FormTop';
import RequestField from './components/RequestField';
import {
  HideableJsonCodeField,
  JsonContainer,
  ScraperCodeContainer,
  SearchContainer,
  SearchTitle,
} from './styledComponents';
import { ISimulatorName } from './types';
import { getSelectedSimulatorCuts } from './utils';

const Scraper: React.FC<ISimulatorName> = ({ simulator }) => {
  const { type, tags } = usePageContext();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedSimulator, setSelectedSimulator] = useState<string>(simulator);
  const highlightedCodeRef = useRef<any>(null);
  const { jsonCut1, jsonCut2, jsonCut3 } =
    getSelectedSimulatorCuts(selectedSimulator);

  useEffect(() => {
    setSelectedSimulator(simulator);
  }, [simulator]);

  useEffect(() => {
    const codeBlockRef = highlightedCodeRef?.current.parentNode;
    codeBlockRef.scrollTop = highlightedCodeRef?.current.offsetTop;
  }, []);

  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: `scraping-simulator-${simulator}-form-button`,
    prefix: type,
  });

  const ctaAction = () => {
    setIsFormVisible(!isFormVisible);
    setShowMessage(false);
    track(trackingSelector);
  };

  return (
    <ScraperCodeContainer>
      <SearchContainer>
        <SearchTitle>{SCRAPER_VALUES.SEARCH_TITLE}</SearchTitle>
        <RequestField simulator={simulator} />
      </SearchContainer>
      <JsonContainer>
        <FormTop
          isFormVisible={isFormVisible}
          ctaAction={ctaAction}
          simulator={SIMULATOR_PLANS[simulator]}
        />
        <HideableJsonCodeField id="start" isVisible={!isFormVisible}>
          <span>{jsonCut1}</span>
          <span ref={highlightedCodeRef}>{jsonCut2}</span>
          <span>{jsonCut3}</span>
        </HideableJsonCodeField>
        <Form
          isFormVisible={isFormVisible}
          setShowMessage={() => setShowMessage(true)}
          showMessage={showMessage}
          simulator={SIMULATOR_PLANS[simulator]}
        />
      </JsonContainer>
    </ScraperCodeContainer>
  );
};

export default Scraper;
