import { SCRAPER_VALUES, SIMULATOR_PLANS } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  HideableJsonTitle,
  JsonContainerTop,
  JsonCta,
} from '../styledComponents';
import { IForm } from '../types';

const FormTop: React.FC<IForm> = ({ isFormVisible, ctaAction, simulator }) => {
  const { type, tags } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: `scraping-simulator-${simulator}-form-top`,
    prefix: type,
  });

  return (
    <JsonContainerTop isTitleVisible={!isFormVisible}>
      <HideableJsonTitle isVisible={!isFormVisible}>
        {simulator === SIMULATOR_PLANS.WEB
          ? SCRAPER_VALUES.HTML_TITLE
          : SCRAPER_VALUES.JSON_TITLE}
      </HideableJsonTitle>
      {!isFormVisible ? (
        <JsonCta
          onClick={ctaAction}
          isFormVisible={!isFormVisible}
          id={trackingSelector({ context: 'show full results' })}
          className={trackingSelector({ context: 'show full results' })}
          data-tracker={trackingSelector({ context: 'show full results' })}
        >
          {SCRAPER_VALUES.CTA_TEXT}
        </JsonCta>
      ) : (
        <JsonCta
          onClick={ctaAction}
          isFormVisible={!isFormVisible}
          id={trackingSelector({ context: 'close-form' })}
          className={trackingSelector({ context: 'close-form' })}
          data-tracker={trackingSelector({ context: 'close-form' })}
        >
          {SCRAPER_VALUES.CTA_TEXT_CLOSE}
        </JsonCta>
      )}
    </JsonContainerTop>
  );
};

export default FormTop;
