import { LinkButton } from 'components/v2/LinkButton';
import React from 'react';
import { initDataLayer } from '../../helpers';
import { BottomNavMenuListItemProps } from './types';

export const BottomNavMenuListItem: React.FC<BottomNavMenuListItemProps> = ({
  index,
  link,
  trackingId,
  handleClick,
}) => {
  const variant = index === 0 ? 'filled' : 'outlined';
  const color = index == 0 ? 'blue' : 'white';

  return (
    <li key={link.key}>
      <LinkButton
        to={link.url}
        variant={variant}
        color={color}
        data-tracker={trackingId}
        onClick={() => {
          handleClick(trackingId), initDataLayer(trackingId);
        }}
        fullWidth
      >
        {link.label}
      </LinkButton>
    </li>
  );
};
