import { useContext } from 'react';
import { createContext } from 'react';

interface ISplitTestingContextValue {
  bodyKey: 'body' | 'body1';
  experimentId?: string;
  variant: '0' | '1';
  variantId?: string;
}

const contextValue: ISplitTestingContextValue = {
  bodyKey: 'body',
  variant: '0',
};

export const SplitTestingContext = createContext(contextValue);

export const useSplitTesting = () => useContext(SplitTestingContext);

const SplitTestingProvider = ({ children, data }) => (
  <SplitTestingContext.Provider value={data}>
    {children}
  </SplitTestingContext.Provider>
);

export default SplitTestingProvider;
