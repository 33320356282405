export const HEADER_HEIGHT_DESKTOP = 57;
export const HEADER_HEIGHT_MOBILE = 48;
export const HEADER_CONTENTS_MAX_WIDTH = 1440;
export const COMPACT_HEADER_WIDTH = 1200;
export const COMPACT_HEADER_MEDIA_QUERY = `@media (max-width: ${COMPACT_HEADER_WIDTH}px)`;
export const MENU_MAX_HEIGHT = 960;
export const MENU_CONTENT_HEIGHT = 633;
export const MENU_CONTENT_CATEGORY_HEIGHT = 550;
export const MENU_CONTENT_CATEGORY_WIDTH = 550;
export const MENU_CONTENT_HEIGHT_ADDITIONAL = 440;
export const MEGA_MENU_HEIGHT_MEDIA_QUERY = `@media (max-height: 670px)`;

export const MENU_CONTENT_VALUES = {
  PRICE_FROM: 'from',
  DEFAULT_PRICE_PERIOD: '/month',
  CONTACT_SALES_CTA: 'Ask expert',
};
