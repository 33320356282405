module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"preloadTags":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"smartproxy-main","accessToken":"MC5ZUzMwc3hJQUFDMEFnRTIw.77-977-9E--_vW7vv73vv73vv71P77-9Lu-_vXkiWFDvv70K77-977-977-9Y--_ve-_ve-_ve-_vUcIXlLvv73vv70","promptForAccessToken":true,"apiEndpoint":"https://smartproxy-main.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Smartproxy","short_name":"SP","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/favicon.png","icons":[{"src":"/static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
