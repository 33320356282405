import React from 'react';
import { IProxyCell } from '.';
import { HeaderCell, StyledCell } from './styledComponents';
import { RichText } from 'components/v2/RichText';

export interface ITableColumn {
  data: IProxyCell[];
  header: string;
  isLast: boolean;
  headerBackground: string;
}

export const TableBlockColumn: React.FC<ITableColumn> = ({
  data,
  header,
  isLast,
  headerBackground,
}) => (
  <>
    <HeaderCell background={headerBackground} isLast={isLast}>
      {header}
    </HeaderCell>
    {data.map((column, index) => (
      <StyledCell background={column.cell_background} key={index}>
        <RichText field={column.cell_value} />
      </StyledCell>
    ))}
  </>
);
