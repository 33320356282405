import {
  DASHBOARD_SUBDOMAIN,
  SITE_DOMAIN,
  STAGING_SUBDOMAIN,
} from 'constants/common';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { TARGET } from '../constants';
import { useLinkConfig } from './useLinkConfig';
import { additionalSiteDomain, defaultSiteDomain } from 'constants/build';
import { useBuildContext } from 'hooks/useBuildContext';
import {
  checkIfItIsNextjsPage,
  extractPathname,
} from '@smartproxy-web/features/src/components/GatsbyLink/GatsbyLink';

export type TLink = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> & {
  isForcedInternalLink?: boolean;
};

const relativePathRegex = new RegExp(`^(\/|#).*`, 'g');
// You can test out this regex here: https://regexr.com/79cmu
const absolutePathRegex = new RegExp(
  `^(https?://)?(www.)?(${STAGING_SUBDOMAIN}.)?${SITE_DOMAIN}`,
  'gim'
);

export const Link: React.FC<TLink> = ({
  children,
  to,
  isForcedInternalLink = false,
  ...props
}) => {
  const { getFollowStatus } = useLinkConfig();
  const { site } = useBuildContext();
  const isInternalLink = [absolutePathRegex, relativePathRegex].some((regex) =>
    regex.test(to)
  );

  const pathname = extractPathname(to);
  const isNextJsLink = pathname && checkIfItIsNextjsPage(pathname);
  if (!isInternalLink || isNextJsLink) {
    const dashboardPathRegex = new RegExp(
      `^(https?://)?(www.)?${DASHBOARD_SUBDOMAIN}.${SITE_DOMAIN}`,
      'gim'
    );

    const isDashboardLink = dashboardPathRegex.test(to);

    const isHelpOrDashboardUrl =
      to &&
      (to.includes('https://help.smartproxy.com/') ||
        to.includes('https://dashboard.smartproxy.com/'));

    const robotsFollowStatus = getFollowStatus(to);

    return (
      <a
        {...props}
        href={
          site === additionalSiteDomain && isHelpOrDashboardUrl
            ? to.replace(defaultSiteDomain, additionalSiteDomain)
            : to
        }
        target={
          isDashboardLink || isForcedInternalLink || isNextJsLink
            ? TARGET.SELF
            : TARGET.BLANK
        }
        rel={robotsFollowStatus}
      >
        {children}
      </a>
    );
  }

  if (process.env.GATSBY_NODE_ENV !== 'production') {
    return (
      <a
        {...props}
        href={to.replace(absolutePathRegex, '').padStart(1, '/')}
        target={TARGET.SELF}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      {...props}
      to={to.replace(absolutePathRegex, '').padStart(1, '/')}
    >
      {children}
    </GatsbyLink>
  );
};
