export const SIGNIN_URL = 'https://dashboard.smartproxy.com/login';
export const SIGNUP_URL = 'https://dashboard.smartproxy.com/register';
export const SITE_URL = 'https://dashboard.smartproxy.com/';
export const PAGE_URL = 'https://smartproxy.com';
export const HELP_DOCS_URL = 'https://help.smartproxy.com/docs';
export const DATA_PROTECTION_URL =
  'https://smartproxy.com/legal/data-protection';
export const SITE_DOMAIN = 'smartproxy.com';
export const STAGING_SUBDOMAIN = 'stage-gatsby-web1';
export const DASHBOARD_SUBDOMAIN = 'dashboard';
export const CONTACT_SALES_URL_HASH = '#contact-sales';
export const IP_CHECKER_URL = 'https://ip.smartproxy.com/json';
export const DEDICATED_ISP_WAITLIST = '#dedicated-isp-waitlist';
export const ISP_WAITLIST = '#shared-isp-ip';
export const DEALS = 'deals';
export const LP = 'lp';
export const BLOG = 'blog';
export const FAQ = 'faq';
export const QUESTIONS = 'questions';
export const CAREERS = 'careers';

export const CATEGORY_TEXT_LINE_HEIGHT = 1.3;
export const SPACING_DEFAULT_HEIGHT = 80;

export const GA_MEASUREMENT_ID = 'UA-114742430-1';
export const GA_TEST_MEASUREMENT_ID = 'UA-114742430-7';
export const GA4_MEASUREMENT_ID = 'G-DCKW4XDVMY';
export const GTM_ID = 'GTM-W55WPZS';

export const PH_PROJECT_NAME = 'web';
export const GOOGLE_SIGN_UP_TEXT = 'Sign up with Google';

export const headerIsAdditional = (pathname: string) => {
  return (
    pathname.includes('/lp') ||
    pathname.includes('/deals') ||
    (pathname.includes('/careers') && true)
  );
};

export const headerIsCareers = (pathname: string) => {
  return pathname.includes('/careers') && true;
};

export const SCREEN_OPTIONS = {
  tabletLimit: 991,
  phoneLandscapeLimit: 767,
  phonePortraitLimit: 479,
  smallMobileLimit: 239,
  shouldListenOnResize: true,
};

export const FILE_SIZE = {
  B: 1024 ** 0,
  KB: 1024 ** 1,
  MB: 1024 ** 2,
};

export const TEXT_LENGTH_VALUES = {
  DESCRIPTION: 140,
  ARTICLES_DESCRIPTION: 280,
  HOMEPAGE_PROXIES: 250,
  TOPIC_ARTICLES_DESCRIPTION: 460,
  CASE_STUDIES: 250,
  HOMEPAGE_BLOG: 90,
};

export enum TABLE_VALUES {
  SMARTPROXY = 'Smartproxy',
  OXYLABS = 'Oxylabs',
  BRIGHT_DATA = 'Bright Data',
  NETNUT = 'NetNut',
}

export enum LANGUAGE_CODES {
  ENGLISH = 'English',
  CHINESE = '中文',
}

export enum LANGUAGE_CODES_EN {
  ENGLISH = 'English',
  CHINESE = 'Chinese',
}

export const LANGUAGES = [
  {
    value: LANGUAGE_CODES.ENGLISH,
    valueEn: LANGUAGE_CODES_EN.ENGLISH,
    label: 'English',
    url: 'https://smartproxy.com',
  },
  {
    value: LANGUAGE_CODES.CHINESE,
    valueEn: LANGUAGE_CODES_EN.CHINESE,
    label: '中文 Chinese',
    url: 'https://smartdaili.cn',
  },
];

export const isLanguage = ['English', '中文 Chinese'];

export const FOOTER_LINKS = {
  social: {
    youtube: 'https://www.youtube.com/smartproxy',
    github: 'https://github.com/Smartproxy',
    linkedin: 'https://www.linkedin.com/company/smartproxy/',
    discord: 'https://discord.gg/gvJhWJPaB4',
  },
  connect: {
    mail: 'mailto:sales@smartproxy.com',
    chat: 'https://direct.lc.chat/12092754',
    skype: 'https://join.skype.com/invite/bZDHw4NZg2G9',
    telegram: 'https://t.me/smartproxy_com',
  },
  languages: {
    en: 'https://smartproxy.com/',
    cn: 'https://smartdaili.cn/',
  },
};

export const NAMES = {
  BASIC_NAME: 'Basic',
  LITE_NAME: 'Lite',
  MICRO_NAME: 'Micro',
  REGULAR_NAME: 'Regular',
  STANDARD_NAME: 'Standard',
  STARTER_NAME: 'Starter',
  ADVANCED_NAME: 'Advanced',
  PREMIUM_NAME: 'Premium',
  PROFESSIONAL_NAME: 'Professional',
  ENTERPRISE_NAME: 'Enterprise',
  SOLID_NAME: 'Solid',
  FREE_TRIAL_NAME: '3 Day Trial',
  NIMBLE_NAME: 'Nimble',
  FOXY_NAME: 'Foxy',
  CLEVER_NAME: 'Clever',
  SHARP_NAME: 'Sharp',
  TINY_NAME: 'Tiny',
  MINI_NAME: 'Mini',
  GENERAL_NAME: 'General',
  MAIN_NAME: 'Main',
  PAYG_NAME: 'Pay As You Go',
  TEENY_NAME: 'Teeny',
  PLUS_NAME: 'Plus',
  DELUX_NAME: 'Delux',
  PRO_NAME: 'Pro',
  GURU_NAME: 'Guru',
  NANO_NAME: 'Nano',
};

export const PRODUCT_NAME = {
  ISP: 'ISP',
};

export const TYPES = {
  RESIDENTIAL_TYPE: 'residential_proxies',
  DATACENTER_TYPE: 'shared_proxies',
  RTC_TYPE: 'rtc_proxies',
  DEDICATED_DC_TYPE: 'dedicated_dc_proxies',
  ECOMMERCE_TYPE: 'rtc_ecommerce_proxies',
  UNIVERSAL_TYPE: 'rtc_universal_proxies',
  NO_CODE_TYPE: 'rtc_no_code_proxies',
};

export const COOKIE_VALUES = {
  PLAN_ID: 'plan_id',
  PLAN_NAME: 'plan_name',
  PLAN_TYPE: 'plan_type',
  TRAFFIC_LIMIT: 'traffic_limit',
};

export const USE_CASES_BLOCK = {
  MARKET: 'Market Research',
  MEDIA: 'Social Media',
  RETAIL: 'Retail',
};
export enum SECTION_NAMES {
  FOOTER = 'footer',
  HERO = 'hero',
  PRICING_CARDS = 'pricing-cards',
  PRODUCT_CARDS = 'product-cards',
  LOCATION_FLAGS = 'location-flags',
  USE_CASES = 'use-cases',
  TRUSTPILOT_REVIEWS = 'trustpilot-reviews',
  RELATED_ARTICLES = 'related-articles',
  TEXT_IMAGE_COLUMNS = 'text-image-columns',
  TEXT_IMAGE_CTA = 'text-image-cta',
  IMAGE_TEXT_COLUMN = 'image-text-column',
  TEXT_GRID = 'text-grid',
  PRICING_SLIDER = 'pricing-slider',
  BADGES = 'badges',
  FAQ = 'faq',
  PAYMENT_METHOD = 'payment-method',
  HERO_FEATURES = 'hero-3-usp-columns-homepage',
  HERO_LOGOS = 'hero-recognized-by-homepage',
  WEBINAR_REGISTRATION_FORM = 'webinar-registration-form',
  BIGGER_PLANS = 'bigger-plans-banner',
  VIDEO_BLOCK = 'video-block',
  SIMPLE_CARDS_SLICE = 'simple-cards-slice',
}

export enum SOCIAL_BUTTON_TYPE {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  REDDIT = 'REDDIT',
  LINKEDIN = 'LINKEDIN',
}

export enum SHARE_LINKS {
  FACEBOOK = 'https://www.facebook.com/sharer/sharer.php?u=',
  TWITTER = 'http://www.twitter.com/share?url=',
  REDDIT = 'https://reddit.com/submit?url=',
  LINKEDIN = 'https://www.linkedin.com/share?',
}

export enum TABS {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum GOOGLE_SCHEMA {
  schemaMarkup = 'schema-markup',
  entity = 'mainEntity',
  answers = 'acceptedAnswer',
  name = 'name',
  text = 'text',
}

export enum SCHEMA_LINKS {
  FAQ = 'http://schema.org/FAQPage',
  QUESTION = 'http://schema.org/Question',
  ANSWER = 'http://schema.org/Answer',
}

export enum COOKIE_SLICES {
  Cookie = 'cookie_content',
}

export const PRISMIC_VALUES = {
  ALL: 'All',
  DOTS: '...',
  LEARN_MORE: 'Learn more',
  READ_MORE: 'Read more',
  SEARCH_PLACEHOLDER: 'Search...',
  SEARCH_BLOG: 'Search in blog',
  SEARCH_FAQ: 'Search in FAQ...',
  TIME_READ_ARTICLES: 'min. read',
  TIME_READ: 'minutes read',
  TOPICS: 'Topics: ',
  VISIT_BLOG: 'Visit the Blog',
  EMPTY_LIST_TEXT: 'No options',
  FROM: 'From',
  PERIOD_PER_MONTH: '/month',
  PERIOD_PER_GB: '/GB',
  PER_K_REQ: '/1k req',
  PERIOD_PER_IP: '/IP',
  PERIOD_NOTHING: '/nothing',
  PERIOD_EMPTY: '',
  LABEL_NEW: 'New',
  TOTAL: 'Total: ',
  SIGNUP_BUTTON_TEXT: 'Sign up',
  SIGNIN_BUTTON_TEXT: 'Log in',
  CONTACTSALES_BUTTON_TEXT: 'Contact sales',
  ENGLISH: 'English',
  PRICING: 'Pricing',
  HEADING_2: 'heading2',
  LANGUAGE: 'Languages',
  FOLLOW_US: 'Follow',
  CONNECT_WITH_US: 'Connect',
  LANGUAGES: 'Languages',
  FOLLOW: 'Follow us',
  GET_IN_TOUCH: 'Get in touch',
};

export const RESIDENTIAL_PRICING_VALUES = {
  PRICING_CARDS_TITLE: 'Fastest residential proxies – now for less',
  PRICING_CARDS_FEATURE_ONE: '14-day money-back option',
  PRICING_CARDS_FEATURE_TWO: 'HTTP(s) + SOCKS5',
  PRICING_CARDS_FEATURE_THREE: 'Unlimited connections and threads',
  PRICING_SLIDER_TITLE: 'Enterprise plans',
  NEED_BIGGER_PLANS_TITLE: 'Need bigger plans',
  NEED_BIGGER_PLANS_DESCRIPTION:
    'Chat with us and we’ll find the best solution for you.',
  PRICING_DISCOUNT: '-10%',
  BILLED_MONTHLY: 'Billed monthly',
  BILLED_YEARLY: 'Billed yearly',
  LABEL: 'LIMITED TIME OFFER*',
  VALIDATION_TEXT:
    '*Buy until 31.05.2023 and save big on first-time or recurring purchases',
};

export enum SIMULATOR_PLANS {
  SERP = 'SERP',
  ECOMMERCE = 'ECOMMERCE',
  WEB = 'WEB',
}

export const SERPJsonCutsPositions = {
  position1: {
    start: 0,
    end: 2235,
  },
  position2: {
    start: 2234,
    end: 2640,
  },
  position3: {
    start: 2634,
  },
};

export const ECommJsonCutsPositions = {
  position1: {
    start: 0,
    end: 16080,
  },
  position2: {
    start: 16080,
    end: 17275,
  },
  position3: {
    start: 17275,
  },
};

export const WebJsonCutsPositions = {
  position1: {
    start: 0,
    end: 717,
  },
  position2: {
    start: 717,
    end: 8656,
  },
  position3: {
    start: 8656,
  },
};

export const SCRAPER_VALUES = {
  SEARCH_TITLE: 'HTTP Endpoint example',
  JSON_TITLE: 'JSON example',
  HTML_TITLE: 'HTML example',
  CTA_TEXT: 'Full results',
  CTA_TEXT_CLOSE: 'Close',
  FORM_SUBMIT_TEXT: 'Send me full results sample',
  FORM_SUCCESS_MESSAGE: 'Success',
  FORM_ERROR_MESSAGE: 'Something went wrong',
  FORM_SUCCESS_TEXT:
    'Sit back and relax, you will receive a full request in your email.',
  FORM_ERROR_TEXT:
    'Ooops, something went wrong! Please try to fill in the form again.',
  FORM_LABEL_NAME: 'Your name',
  FORM_LABEL_EMAIL: 'Your email address',
  FORM_TITLE: 'Contact us for full results',
  POPUP_TEXT: 'copied',
  NAME_VALIDATION: '*Required field',
  EMAIL_VALIDATION: '*Invalid email address',
  cURL_REQUESTS: {
    SERP: 'https://scrape.smartproxy.com/v1/tasks?target=google_search&query=adidas&domain=com&access_token=xxxxxxx',
    ECOMMERCE:
      'https://scrape.smartproxy.com/v1/tasks?target=amazon_search&query=laptop&domain=com&geo=60007&parse=true&access_token=xxxxxx',
    WEB: 'https://scrape.smartproxy.com/v1/tasks?target=universal&url=https://www.youtube.com/results?search_query=Smartproxy&headless=html&access_token=xxxxxx',
  },
};

export const WEBINAR_FORM = {
  NAME: 'Full Name',
  EMAIL: 'Email',
  COMPANY: 'Company name',
  BUTTON_TEXT_DEFAULT: 'Register Now',
  BUTTON_TEXT_ERROR: 'Try again',
  BUTTON_TEXT_LOADING: 'Sending...',
  MESSAGE_TITLE: 'Your registration was successful',
  MESSAGE_TEXT_SUCCESS: "Soon, you'll receive confirmation email from us.",
  MESSAGE_TEXT_ERROR: 'Please, fill out all fields and try again.',
  MESSAGE_TEXT_NOT_SENT: 'Form was not sent. Please try again.',
  MESSAGE_BUTTON: 'Close',
  NO_COMPANY_NAME: 'Company name is not provided',
};

export const richTextElements = 'h1, h2, h3, h4, h5, h6, p, a, strong, em';
export const richTextHeadingElements = 'h1, h2, h3, h4, h5, h6';
export const richTextParagraphElements = 'p, a, strong, em';
