import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';
import {
  ACTIVE_CAMPAING_MOBILE_BREAKPOINT,
  EXTRA_SMALL_MOBILE_QUERY,
} from './constants';
import { keyframes } from '@emotion/react';
import { mediaQueries } from '@smartproxy-web/ui';

const FadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});
export const Backdrop = styled.div({
  position: 'fixed',
  top: 0,
  background: 'rgba(13, 15, 16, .2)',
  backdropFilter: 'blur(10px)',
  height: '100vh',
  width: '100vw',
  zIndex: 11,
  display: 'block',
});

export const FormContainer = styled.div({
  ...flexColContainerStyles,
  position: 'fixed',
  alignItems: 'center',
  top: '50%',
  left: '50%',
  animation: `${FadeIn} .2s forwards`,
  transform: 'translate(-50%, -50%)',
  background: theme.colors.white.default,
  width: '650px',
  minHeight: '600px',
  borderRadius: '6px',
  zIndex: 12,
  form: {
    bottom: '220px',
    fontFamily: theme.fonts.family.main,
    width: '100%',
    top: '220px',
    '._form-inner': {
      position: 'absolute !important' as 'absolute',
      top: '-75px',
      zIndex: 2,
    },
    '#_form_13_submit': {
      padding: '0 !important',
      fontSize: '16px !important',
      width: '100% !important',
      marginTop: '0 !important',
    },
    '._form-label': {
      fontWeight: '300 !important',
      maxWidth: '110px !important',
      marginTop: '-7px !important',
    },
  },
  '._form_13': {
    width: '100%',
  },
  '._form-content': {
    width: '100%',
  },
  h2: {
    fontSize: theme.fonts.size.h3,
    lineHeight: theme.fonts.lineHeight.h2,
    color: '#0D0F10',
    fontWeight: 500,
    textAlign: 'center',
    height: '96px',
    display: 'flex',
    alignItems: 'center',
  },
  p: {
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
    color: theme.colors.gray.S500,
    fontFamily: theme.fonts.family.main,
  },
  svg: {
    position: 'absolute',
    right: '28px',
    top: '28px',
    cursor: 'pointer',
  },
  [ACTIVE_CAMPAING_MOBILE_BREAKPOINT]: {
    alignItems: 'flex-start',
    width: '80%',
    height: '530px',
    form: {
      width: '90%',
    },
    'h2, p': {
      textAlign: 'left',
    },
  },
  [mediaQueries.md]: {
    form: {
      bottom: '220px',
      '#_form_13_submit': {
        marginTop: '16px !important',
      },
    },
  },
  [mediaQueries.sm]: {
    h2: {
      fontSize: theme.fonts.size.h2,
    },
  },
  [EXTRA_SMALL_MOBILE_QUERY]: {
    display: 'none',
  },
});

export const TextContainer = styled.div({
  position: 'absolute',
  top: '110px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  rowGap: theme.spacings.S,
  maxWidth: '85%',
  [ACTIVE_CAMPAING_MOBILE_BREAKPOINT]: {
    margin: '0 20px',
    alignItems: 'flex-start',
  },
});
export const Disclaimer = styled.div({
  position: 'absolute',
  bottom: '110px',
  padding: '0 20px',
  p: {
    textAlign: 'left',
    fontFamily: theme.fonts.family.main,
    color: theme.colors.neutral.N50,
    maxWidth: '400px',
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    marginBottom: 0,
    fontWeight: 300,
  },
  [mediaQueries.md]: {
    p: {
      textAlign: 'center',
    },
  },
  [ACTIVE_CAMPAING_MOBILE_BREAKPOINT]: {
    bottom: '60px',
  },
});

export const HideableBackdrop = hideable(Backdrop);
export const HideableForm = hideable(FormContainer);
