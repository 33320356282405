import styled from '@emotion/styled';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
} from 'theme';
import { themev2 } from 'theme-v2';

export const ListItemsWrapper = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  height: '182px',
  alignItems: CSS_ALIGN.CENTER,
  position: CSS_POSITION.RELATIVE,
  span: {
    transform: 'translate(0%, -50%)',
    top: '50%',
    [MEDIA_QUERIES.TABLET]: {
      display: CSS_DISPLAY.NONE,
    },
  },
});

export const ListItemsContainer = styled.div({
  maxWidth: '95%',
  height: '100%',
  display: CSS_DISPLAY.FLEX,
  overflow: 'hidden',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  scrollBehavior: 'smooth',
  margin: '0 auto',
  paddingLeft: '16px',
  alignItems: CSS_ALIGN.CENTER,
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    display: CSS_DISPLAY.NONE,
  },
  [MEDIA_QUERIES.TABLET]: {
    maxWidth: '100%',
  },
});

export const ListItemContainer = styled.div((props) => ({
  a: {
    display: CSS_DISPLAY.FLEX,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    width: '210px',
    height: '134px',
    minWidth: '202px',
    padding: getThemeInputPaddingShape(props, TSHIRT_SIZE.L),
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,
    borderRadius: '12px',
    background: getThemeMainColor(props),
    boxShadow: '0 0 16px 0 rgba(0,0,0,.1)',
    marginRight: '32px',
    div: {
      width: '48px',
      height: '48px',
      marginBottom: getThemeGutter(props),
    },
    p: {
      textAlign: TEXT_ALIGN.CENTER,
      fontWeight: 500,
      color: themev2.colors.neutral.N99,
    },
  },
}));
