import { RichText } from 'components/v2/RichText';
import { RESIDENTIAL_PRICING_VALUES, SECTION_NAMES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { OfferCountdownStatus } from 'features/ResidentialPricingCards/OfferCountdownStatus';
import { useResiPromo } from 'features/ResidentialPricingCards/useResiPromo';
import { assertProduct, generateSectionsId } from 'features/utils';
import React from 'react';
import { themev2 } from 'theme-v2';
import { buildTrackingSelector } from 'utils/page';
import { PaymentMethods } from './PaymentMethods';
import Plans from './Plans';
import { Features } from './Features';
import {
  TopLabelComponent,
  ValidationTextComponent,
} from '../../styledComponents';
import {
  PricingCardsWrapper,
  Subtitle,
  Title,
  SubtitleExtra,
  SubtitleExtraInner,
  PaymentMethodsSpacing,
} from './styled';
import { IAutomatedPricingCards } from '../../types';
import { excludePricingFeatures, getProductType } from '../../helpers';
import { Box, Button, theme } from '@smartproxy-web/ui';
import { useShowMoreLess } from '@smartproxy-web/features';
import PricingTable from 'features/AutomatedPricingCards/ABTest/Version1/Table';
import { IconShield, IconSupportv2 } from 'images';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { initBuildTrackingId } from '@smartproxy-web/shared/helpers';
import { useScreenSize } from '@smartproxy-web/shared/hooks';

export type AutomatedPricingToggleType = 'regular' | 'enterprise';

export const ABV1AutomatedPricingCards: React.FC<IAutomatedPricingCards> = ({
  primary: {
    is_limited_time_offer_visible: isLimitedTimeOfferVisible,
    title,
    background_color: backgroundColor,
  },
  items,
}) => {
  const { type, tags } = usePageContext();
  const trackingSelector = initBuildTrackingId({
    section: SECTION_NAMES.PRICING_CARDS,
  });

  const { isDesktop, isMobile } = useScreenSize();

  const { buttonText, handleShowMoreLess, showAll } = useShowMoreLess();
  const isItemsMoreThanPredifined = items.length > 4;

  function handleShowAll() {
    handleShowMoreLess();
    logPostHogEvent(trackingSelector({ context: `show-more-${buttonText}` }));
  }

  const getBackgroundColor = (color) =>
    color && color.toLowerCase() === 'gray'
      ? themev2.colors.neutral.N02
      : themev2.colors.white.default;

  const regularPlans = items.filter(
    ({
      pricing_card: {
        document: {
          data: { is_enterprise: isEnterprise },
        },
      },
    }) => !isEnterprise
  );

  const enterprisePlans = items.filter(
    ({
      pricing_card: {
        document: {
          data: { is_enterprise: isEnterprise },
        },
      },
    }) => isEnterprise
  );
  const { variant } = useSplitTesting();
  const promo = useResiPromo(variant);
  const isPromoBannerVisible = !!promo.expiry;

  const normalizedRawGroupProduct = getProductType(
    items.filter(excludePricingFeatures)
  );
  const groupProduct = assertProduct(normalizedRawGroupProduct)
    ? normalizedRawGroupProduct
    : 'mixed';

  const combinedPlans = [...regularPlans, ...enterprisePlans];
  const slicedItems = !isDesktop
    ? combinedPlans.slice(0, showAll ? items.length : 4)
    : combinedPlans;

  const features = regularPlans.find(
    (plan) => plan.pricing_card.type === 'pricing_cards_features'
  )?.pricing_card.document.data;

  return (
    <PricingCardsWrapper
      backgroundColor={getBackgroundColor(backgroundColor)}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRICING_CARDS,
        tags,
        type
      )}
      id={buildTrackingSelector({
        prefix: type,
        page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
        context: title.text,
      })}
    >
      {isPromoBannerVisible && isLimitedTimeOfferVisible && (
        <TopLabelComponent>
          <OfferCountdownStatus
            isGreen
            key={promo.expiry}
            expiry={promo.expiry}
            label={RESIDENTIAL_PRICING_VALUES.LABEL}
            isTimerVisible={promo.isTimerVisible}
          />
        </TopLabelComponent>
      )}
      {!!promo.description?.text && isLimitedTimeOfferVisible && (
        <ValidationTextComponent>
          <RichText field={promo.description} />
        </ValidationTextComponent>
      )}
      <Title>
        <h2>Easy-to-integrate residential proxies</h2>
      </Title>
      <Subtitle>
        Pick a subscription that suits you and unlock web data with ease.
      </Subtitle>

      <SubtitleExtra>
        <SubtitleExtraInner>
          <IconSupportv2 style={{ color: theme.colors.neutral.N99 }} /> 24/7
          support
        </SubtitleExtraInner>
        <SubtitleExtraInner>
          <IconShield style={{ color: theme.colors.success.S99 }} /> 14 day
          money-back
        </SubtitleExtraInner>
      </SubtitleExtra>

      {isDesktop ? (
        <PricingTable plans={slicedItems} />
      ) : (
        <Plans plans={slicedItems} isVisible={true} />
      )}
      {isItemsMoreThanPredifined && !isDesktop && (
        <Box
          sx={{
            marginBottom: '56px',
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <Button
            size="sm"
            variant="outlined"
            color="black"
            fullWidth={isMobile}
            onClick={handleShowAll}
            data-tracker={trackingSelector({
              context: `show-more-${buttonText}`,
            })}
          >
            {buttonText} subscriptions
          </Button>
        </Box>
      )}

      {features && (
        <Features
          data={features}
          key={features.card_title}
          itemsLength={regularPlans.length - 1}
        />
      )}
      <PaymentMethodsSpacing />
      <PaymentMethods product={groupProduct} />
    </PricingCardsWrapper>
  );
};
