import { IconCloseBlue } from 'images';
import React from 'react';
import { ResponseHeader, ResponseResultsContainer } from '../styledComponents';
import { IResults } from '../types';
import CodeBlockContainer from './CodeBlockContainer';

const ResultsContainer: React.FC<IResults> = ({
  responseTitle,
  requestValue,
  responseValue,
  buttonValue,
  trackingSelector,
  isWindowClosed,
  isResponseWindowVisible,
  handleRequestClose = (trackingSelector) => {
    trackingSelector;
  },
}) => {
  return (
    <ResponseResultsContainer>
      <ResponseHeader
        isReponseColorWhite={isWindowClosed || isResponseWindowVisible}
      >
        {isWindowClosed || isResponseWindowVisible ? (
          <p>{responseTitle}</p>
        ) : (
          <>
            <p>{buttonValue}</p>
            <IconCloseBlue
              onClick={() => handleRequestClose(trackingSelector)}
              data-tracker={trackingSelector}
            />
          </>
        )}
      </ResponseHeader>
      <CodeBlockContainer
        code={isResponseWindowVisible ? responseValue : requestValue}
      />
    </ResponseResultsContainer>
  );
};
export default ResultsContainer;
