export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
};

export const formatList = (list: Iterable<string>, joinType?: 'and' | 'or') => {
  const formatter = new Intl.ListFormat('en', {
    type: joinType === 'or' ? 'disjunction' : 'conjunction',
  });

  return formatter.format(list);
};

export const stripHash = (idWithHash: string) => {
  return idWithHash.replace(/^#/, '');
};

export const getCountForm = (
  count: number,
  singularForm: string,
  pluralForm: string
) => {
  if (count === 1) return singularForm;
  return pluralForm;
};
