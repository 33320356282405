import { IconRedditSocial, IconTikTok, Reddit, TikTok } from 'images';
import redditSchema from 'scrapers/Social/Requests/Reddit/empty.json';
import tiktokSchema from 'scrapers/Social/Requests/Tiktok/empty.json';
import { socialRequestTemplates } from '../templates/social';
import { IScraperPlaygroundConfig } from '../types';
import {
  commonScraperLocations,
  commonSocialScraperFormConfig,
  commonSocialScraperRequestParams,
} from './common';

export const socialMediaScraperConfig: IScraperPlaygroundConfig = {
  title: 'API Playground Demo',
  requestLabel: 'Request',
  responseLabel: 'Response',
  banner: {
    text: 'Want the unlimited API experience? Activate your 7-day free trial now!',
    ctaText: 'Get free trial',
    ctaLink: 'https://dashboard.smartproxy.com/register?planId=815488',
  },
  configParamsButtonLabel: 'Additional configurable parameters',
  trackingSelectors: {
    section: 'social-scraper-playground',
  },
  languages: [
    {
      value: 'curl',
      label: 'cURL',
      template: socialRequestTemplates.curl,
    },
    {
      value: 'node',
      label: 'Node',
      template: socialRequestTemplates.node,
    },
    {
      value: 'python',
      label: 'Python',
      template: socialRequestTemplates.python,
    },
  ],
  targets: [
    {
      label: 'TikTok post',
      value: 'tiktok_post',
      iconRegular: IconTikTok,
      iconLarge: TikTok,
      info: {
        title: 'TikTok post',
        description: 'Profiles, Usernames, Posts, Hashtags, Sounds, Videos',
      },
      configParams: {
        title: 'TikTok post parameters',
        items: [
          {
            title: 'target',
            description:
              'The target within TikTok, either tiktok_profile or tiktok_post.',
          },
          {
            title: 'url',
            description: 'The direct TikTok URL you wish to scrape.',
          },
          {
            title: 'locale',
            description:
              'The setting to access a particular language version of TikTok.',
          },
          {
            title: 'geo',
            description:
              'The setting to access a particular version of TikTok based on geographic location.',
          },
        ],
      },
      requestParams: {
        curl: commonSocialScraperRequestParams.tiktok_post,
        node: commonSocialScraperRequestParams.tiktok_post,
        python: commonSocialScraperRequestParams.tiktok_post,
      },
      responseSchema: tiktokSchema,
      locations: commonScraperLocations,
      formConfig: commonSocialScraperFormConfig,
    },
    {
      label: 'Reddit post',
      value: 'reddit_post',
      iconRegular: IconRedditSocial,
      iconLarge: Reddit,
      info: {
        title: 'Reddit post',
        description:
          'Usernames, Upvotes, Downvotes, Images, Videos, Posts, Subreddit name, Subreddit description, Subreddit members',
      },
      configParams: {
        title: 'Reddit post parameters',
        items: [
          {
            title: 'target',
            description: 'Should be set to reddit_post.',
          },
          {
            title: 'url',
            description: 'Reddit post URL to retrieve data from.',
          },
          {
            title: 'locale',
            description: 'Locale, as expected in the Accept-Language header.',
          },
          {
            title: 'geo',
            description:
              'The geographical location that the result depends on.',
          },
        ],
      },
      requestParams: {
        curl: commonSocialScraperRequestParams.reddit_post,
        node: commonSocialScraperRequestParams.reddit_post,
        python: commonSocialScraperRequestParams.reddit_post,
      },
      responseSchema: redditSchema,
      locations: commonScraperLocations,
      formConfig: commonSocialScraperFormConfig,
    },
  ],
};
