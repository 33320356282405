import { NewRichText } from 'components/PrismicRichText';
import React, { useEffect } from 'react';
import { IGatedContent } from './types';
import {
  Disclaimer,
  HideableGatedContent,
  TextContainer,
} from './styledComponents';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/browserStorage';
import { track } from '@smartproxy/sp-tracker-js';
import { shouldScriptLoad } from './utils';

const GatedContent: React.FC<IGatedContent> = ({
  primary: {
    form_title: formTitle,
    form_description: formDescription,
    disclaimer,
  },
  pageContext,
}) => {
  const { isGatedContent, setIsGatedContent } = pageContext;

  const setGatedContentVisible = () => {
    if (!getLocalStorageItem('gated-content')) {
      setIsGatedContent(false);
      setLocalStorageItem('gated-content', 'visible');
      track('gated-content-visible');
    }
  };
  const onFormSubmit = () => {
    window._show_thank_you = () => setGatedContentVisible();
  };
  useEffect(() => {
    shouldScriptLoad(onFormSubmit);
    !!getLocalStorageItem('gated-content') && setIsGatedContent(false);
  }, []);

  return (
    <HideableGatedContent isVisible={isGatedContent} className="paywall">
      <TextContainer>
        <NewRichText text={formTitle} />
        <NewRichText text={formDescription} />
      </TextContainer>
      <div className="_form_9" />
      <Disclaimer>
        <NewRichText text={disclaimer} />
      </Disclaimer>
    </HideableGatedContent>
  );
};

export default GatedContent;
