import { track } from '@smartproxy/sp-tracker-js';
import { usePageContext } from 'contexts/PageContext';
import React, { useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { scraperConfig } from '../constants';
import { useScraperForm } from '../hooks';
import {
  Modal,
  RequestFormContainer,
  RequestWrapper,
  ScraperContainer,
} from '../styledComponents';
import { IScraperTargetConfig, TScraperProduct } from '../types';
import Form from './Form/Form';
import ParametersActionsContainer from './ParametersActionsContainer';
import ParametersDrawer from './ParametersContainer';
import TitleContainer from './TitleContainer';
import WindowContainer from './WindowContainer';

const Scraper: React.FC<{ product: TScraperProduct }> = ({ product }) => {
  const config = scraperConfig[product];
  const SplashScreen = config.splashScreen;

  const [activeTarget, setActiveTarget] = useState(config.targets[0]);

  const {
    isPopupVisible,
    setIsPopupVisible,
    isParametersVisible,
    setIsParametersVisible,
    isTargetValuesVisible,
    setIsTargetValuesVisible,
    isLocationValuesVisible,
    setIsLocationValuesVisible,
    setFormField,
    isSubmitDisabled,
    isResponseWindowVisible,
    setResponseWindowVisible,
    isLoading,
    setSubmitDisabled,
    submitForm,
    formField,
    responseValue,
  } = useScraperForm(activeTarget);

  const handleActiveTargetChange = (target?: IScraperTargetConfig) => {
    if (!target) return;
    setActiveTarget(target);
    setFormField({ ...formField, target: target.value });
  };

  const { tags, type } = usePageContext();
  const buildClickSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: config.trackingSelectors.section,
    prefix: type,
  });
  const parametersCloseBtnSelector = buildClickSelector({
    context: 'parameters-close',
    type: 'button',
  });

  const isSplashScreenVisible =
    !!SplashScreen && !isResponseWindowVisible && !responseValue;

  const { iconLarge: TitleIcon, iconRegular: Icon } = activeTarget;

  return (
    <ScraperContainer>
      {isSplashScreenVisible ? (
        <SplashScreen
          targets={config.targets}
          activeTarget={activeTarget}
          onSubmit={submitForm}
          product={product}
          setFormField={setFormField}
          isTargetValuesVisible={isTargetValuesVisible}
          formField={formField}
          isSubmitDisabled={isSubmitDisabled}
          isLoading={isLoading}
          isLocationValuesVisible={isLocationValuesVisible}
          isPopupVisible={isPopupVisible}
          handleActiveTargetChange={handleActiveTargetChange}
          buildClickSelector={buildClickSelector}
          setSubmitDisabled={setSubmitDisabled}
          setIsLocationValuesVisible={setIsLocationValuesVisible}
          setIsTargetValuesVisible={setIsTargetValuesVisible}
          setIsPopupVisible={setIsPopupVisible}
        />
      ) : (
        <>
          <RequestWrapper>
            <Modal
              onClick={() => {
                setIsLocationValuesVisible(false);
                setIsTargetValuesVisible(false);
                setIsPopupVisible(false);
              }}
            />
            <ParametersDrawer
              isVisible={isParametersVisible}
              title={activeTarget.configParams.title}
              closeParameters={() => {
                setIsParametersVisible(false);
                track(parametersCloseBtnSelector);
              }}
              parameters={activeTarget.configParams.items}
              trackingSelector={parametersCloseBtnSelector}
            />
            <TitleContainer
              requestTitle={config.title}
              icon={activeTarget.labelType !== 'title' ? <Icon /> : null}
              title={activeTarget.labelType !== 'icon' ? <TitleIcon /> : null}
            />
            <RequestFormContainer>
              <Form
                product={product}
                activeTarget={activeTarget}
                targets={config.targets}
                onSubmit={submitForm}
                setFormField={setFormField}
                isTargetValuesVisible={isTargetValuesVisible}
                formField={formField}
                isSubmitDisabled={isSubmitDisabled}
                isLocationValuesVisible={isLocationValuesVisible}
                isPopupVisible={isPopupVisible}
                handleActiveTargetChange={handleActiveTargetChange}
                setSubmitDisabled={(isSubmitDisabled) =>
                  setSubmitDisabled(isSubmitDisabled)
                }
                setIsLocationValuesVisible={(isVisible) =>
                  setIsLocationValuesVisible(isVisible)
                }
                setIsTargetValuesVisible={(isVisible) =>
                  setIsTargetValuesVisible(isVisible)
                }
                setIsPopupVisible={(isVisible) => setIsPopupVisible(isVisible)}
                buildClickSelector={buildClickSelector}
                isRequestSent={!isSplashScreenVisible}
              />
            </RequestFormContainer>
            <ParametersActionsContainer
              configurationTitle={config.configParamsButtonLabel}
              setParametersIsVisible={() => {
                setIsParametersVisible(true);
                setIsLocationValuesVisible(false);
                setIsTargetValuesVisible(false);
                track(
                  buildClickSelector({
                    context: 'parameters',
                    type: 'button',
                  })
                );
              }}
              trackingSelector={buildClickSelector({
                context: 'parameters',
                type: 'button',
              })}
            />
          </RequestWrapper>
          <WindowContainer
            product={product}
            url={formField.query}
            activeTarget={activeTarget}
            geo={formField.geo}
            languages={config.languages}
            responseValue={responseValue}
            isLoaderVisible={isLoading}
            isResponseWindowVisible={isResponseWindowVisible}
            setResponseWindowVisible={() => setResponseWindowVisible(false)}
          />
        </>
      )}
    </ScraperContainer>
  );
};

export default Scraper;
