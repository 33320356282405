import styled from '@emotion/styled';
import { Link } from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';
import { ISideImage } from './types';

export const Content = styled.div<ISideImage>(
  ({ isOnTheRight, isSplitEqually }) => ({
    display: 'grid',
    width: '100%',
    gap: isSplitEqually ? '48px' : '160px',
    gridTemplateColumns: isSplitEqually
      ? 'repeat(2, 1fr)'
      : isOnTheRight
      ? '1fr 360px'
      : '360px 1fr',
    alignItems: 'center',
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, auto)',
      gap: '60px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gap: '32px',
    },
  })
);

export const StyledFeaturesWrapper = styled.div<{ isDark?: boolean }>(
  ({ isDark }) => ({
    marginTop: '36px',
    flexWrap: 'wrap',
    display: 'flex',
    gap: '24px',
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginTop: '32px',
    },

    'p, li': {
      color: isDark ? theme.colors.neutral.N00 : theme.colors.neutral.N99,
    },
  })
);

export const StyledLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: 700,

  p: {
    margin: 0,
    fontSize: '18px',
  },

  ':hover': {
    textDecoration: 'underline',
  },
  ':visited': {
    textDecoration: 'none',
  },
});

export const StyledItem = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: 400,

  p: {
    margin: 0,
    fontSize: '18px',
  },
  a: {
    p: {
      margin: 0,
    },
    ':hover': {
      textDecoration: 'underline',
    },
    ':visited': {
      textDecoration: 'none',
    },
  },
});

export const SmallImageWrapper = styled.div({
  marginRight: '12px',
  img: {
    height: '30px',
    width: '30px',
    maxHeight: '100%',
    marginBottom: 0,
  },
});

export const ImageWrapper = styled.div<ISideImage>(
  ({ isOnTheRight, isZoomIconVisible, isSplitEqually }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    order: isOnTheRight ? 1 : 0,
    cursor: isZoomIconVisible ? 'pointer' : 'default',
    maxWidth: isSplitEqually ? '500px' : '360px',
    maxHeight: isSplitEqually ? '500px' : '360px',

    '.gatsby-image-wrapper': {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      height: '100%',
      maxWidth: isSplitEqually ? '500px' : '360px',
      maxHeight: isSplitEqually ? '500px' : '360px',

      div: {
        width: '100%',
        height: '100%',
        maxWidth: 'unset !important',
      },
    },

    [MEDIA_QUERIES.TABLET]: {
      justifyContent: 'flex-start',
      order: 0,
    },
  })
);

export const IconWrapper = styled.div({
  backgroundColor: 'rgba(18,33,48,.2)',
  borderRadius: '32px',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '64px',
  height: '64px',
  zIndex: 1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const Review = styled.div({
  display: 'flex',
  borderTop: '1px solid rgba(0,0,0,.1)',
  borderBottom: '1px solid rgba(0,0,0,.1)',
  marginTop: '36px',
  padding: '24px 0',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
  },
});

export const ReviewText = styled.p({
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  fontWeight: 600,
});

export const ReviewBottomText = styled.p({
  '*': {
    fontFamily: theme.fonts.family.main,
    marginTop: '24px',
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
  },
});

export const ReviewImage = styled.div({
  marginRight: '24px',
  maxWidth: '100px',
  img: {
    minWidth: '100px',
    height: '100px',
    borderRadius: '100px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    alignSelf: 'center',
    marginRight: 0,
    marginBottom: '24px',
  },
});

export const HideableReview = hideable(Review);

export const TextBlockContent = styled.div({
  'h2, h3, h4': {
    marginBottom: '24px',
    [MEDIA_QUERIES.TABLET]: {
      marginBottom: '20px',
    },
  },
  a: {
    fontFamily: themev2.fonts.family.additional,
  },
  p: {
    marginBottom: '16px',
  },
});
