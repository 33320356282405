import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { scraperConfig } from '../constants';
import { useResponseRequestWindow } from '../hooks';
import {
  HideableRequestWindowWrapper,
  RequestButton,
  RequestLanguagesontainer,
  ResponseWrapper,
} from '../styledComponents';
import { IWindowData } from '../types';
import BannerContainer from './BannerContainer';
import LoaderWrapper from './LoaderWrapper';
import ResultsContainer from './ResultsContainer';

const WindowContainer: React.FC<IWindowData> = ({
  product,
  url,
  activeTarget,
  languages,
  geo,
  isLoaderVisible,
  isResponseWindowVisible,
  responseValue,
  setResponseWindowVisible = () => {},
}) => {
  const {
    isWindowClosed,
    requestValue,
    handleLanguageSelect,
    handleRequestClose,
    selectedLangIndex,
  } = useResponseRequestWindow({
    url,
    activeTarget,
    geo,
    languages,
    setResponseWindowVisible,
  });

  const config = scraperConfig[product];

  const { tags, type } = usePageContext();
  const buildClickSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: config.trackingSelectors.section,
    prefix: type,
  });

  return (
    <ResponseWrapper>
      <HideableRequestWindowWrapper isVisible={!isLoaderVisible}>
        <RequestLanguagesontainer>
          <p>{config.requestLabel}</p>
          <div>
            {languages.map((language, index) => (
              <RequestButton
                key={language.value}
                onClick={() => {
                  setResponseWindowVisible();
                  handleLanguageSelect(index);
                  track(
                    buildClickSelector({
                      context: `request-language-${language.label}`,
                      type: 'button',
                    })
                  );
                }}
                isSelected={selectedLangIndex === index}
                data-tracker={buildClickSelector({
                  context: `request-language-${language.label}`,
                  type: 'button',
                })}
                themeName={BUTTON_THEMES.CONVERSION}
                themeType={BUTTON_TYPES.SECONDARY}
              >
                {language.label}
              </RequestButton>
            ))}
          </div>
        </RequestLanguagesontainer>
        <ResultsContainer
          responseTitle={config.responseLabel}
          buttonValue={languages[selectedLangIndex]?.label}
          requestValue={requestValue}
          responseValue={responseValue}
          trackingSelector={buildClickSelector({
            context: 'request-close',
            type: 'button',
          })}
          isWindowClosed={isWindowClosed}
          isResponseWindowVisible={isResponseWindowVisible}
          handleRequestClose={() =>
            handleRequestClose(
              buildClickSelector({
                context: 'request-close',
                type: 'button',
              })
            )
          }
        />
      </HideableRequestWindowWrapper>
      <LoaderWrapper isVisible={isLoaderVisible} />
      <BannerContainer
        bannerText={config.banner.text}
        bannerCtaText={config.banner.ctaText}
        bannerCtaLink={config.banner.ctaLink}
        trackingSelector={buildClickSelector({
          context: 'banner',
          type: 'cta',
        })}
      />
    </ResponseWrapper>
  );
};

export default WindowContainer;
