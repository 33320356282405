import {
  PRISMIC_VALUES,
  SECTION_NAMES,
  TEXT_LENGTH_VALUES,
} from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';

import { generateSectionsId, shortenText } from 'features/utils';
import { Link } from 'components/GatsbyLink';
import * as React from 'react';
import { ITextImageColumns } from '../types';
import {
  TextImageColumnsContainer,
  TextImageColumnsItems,
  TextImageColumnsWrapper,
  TextImageColumnBlock,
  TextImageColumnImageAndTitleContainer,
  TextImageColumnDescription,
  TextImageColumnLinkContainer,
  Subtitle,
  TitleWrapper,
} from './styledComponents';
import { initBuildTrackingSelector } from 'utils/page';
import hideable from 'utils/hideable';
import { track } from '@smartproxy/sp-tracker-js';
import { RichText } from 'components/v2/RichText';

const HideableSubtitle = hideable(Subtitle);

const TextImageColumn: React.FC<ITextImageColumns> = ({
  primary: {
    column_background: background,
    column_list_title: columnsTitle,
    subtitle: subtitle,
    is_subtitle_centered: isSubtitleCentered,
  },
  items,
  pageContext: { tags, type },
}) => {
  const trackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: columnsTitle?.text,
  });
  return (
    <TextImageColumnsWrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.TEXT_IMAGE_COLUMNS,
        tags,
        type
      )}
    >
      <TextImageColumnsContainer>
        <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
          <RichText field={columnsTitle} />
        </TitleWrapper>
        <HideableSubtitle
          isVisible={subtitle?.text}
          isSubtitleCentered={isSubtitleCentered}
        >
          <RichText field={subtitle} />
        </HideableSubtitle>
        <TextImageColumnsItems>
          {items.map(
            (
              {
                column_link: link,
                column_description: description,
                column_image: image,
                column_title: title,
                link_text: linkText,
              },
              index
            ) => (
              <TextImageColumnBlock key={index}>
                <TextImageColumnImageAndTitleContainer>
                  <GatsbyImg image={image} />
                  <RichText field={title} />
                </TextImageColumnImageAndTitleContainer>
                <TextImageColumnDescription>
                  {shortenText(
                    description.text,
                    TEXT_LENGTH_VALUES.HOMEPAGE_PROXIES
                  )}
                </TextImageColumnDescription>
                <TextImageColumnLinkContainer>
                  <Link
                    to={link.url}
                    className={trackingSelector({ context: image.alt })}
                    data-tracker={trackingSelector({ context: image.alt })}
                    onClick={() =>
                      track(trackingSelector({ context: image.alt }))
                    }
                  >
                    {linkText || PRISMIC_VALUES.LEARN_MORE}
                  </Link>
                </TextImageColumnLinkContainer>
              </TextImageColumnBlock>
            )
          )}
        </TextImageColumnsItems>
      </TextImageColumnsContainer>
    </TextImageColumnsWrapper>
  );
};

export default TextImageColumn;
