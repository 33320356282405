import { Wrapper } from 'commonStyles';
import { IJobDetails } from 'features/JobApplicationForm/types';
import { JobIntroduction } from 'features/JobIntroduction';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice, PrismicTextField } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicCareersJobPost {
  prismicCareersJobPost: {
    type: string;
    tags?: string[];
    id: string;
    data: {
      type: string;
      hr_email: string;
      description: PrismicTextField;
      location: PrismicTextField;
      salary: PrismicTextField;
      title: PrismicTextField;
      body: DefaultSlice[];
    };
  };
  allPrismicCareersJobPost: {
    nodes: {
      id: string;
      data: DefaultSlice[];
    };
  };
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

export interface Props {
  data: IWithPrismicCareersJobPost;
}

const CareersJobPost = ({ data }: Props & PageTemplateProps) => {
  const { prismicCareersJobPost, allPrismicCareersJobPost } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicCareersJobPost.data.body,
    sliceMap
  );

  const jobId = prismicCareersJobPost.id;

  const extractJobDetails = (
    rawJob: IWithPrismicCareersJobPost['prismicCareersJobPost']
  ): IJobDetails => {
    return {
      id: rawJob.id,
      title: rawJob.data.title.text,
      description: rawJob.data.description.text,
      type: rawJob.data.type,
      location: rawJob.data.location.text,
      hrEmail: rawJob.data.hr_email,
    };
  };

  return (
    <Wrapper>
      <JobIntroduction
        title={prismicCareersJobPost.data.title}
        description={prismicCareersJobPost.data.description}
      />
      {templates.map(({ Component, slice }, key) =>
        slice.slice_type === 'job_application_form' ? (
          <Component
            key={key}
            {...slice}
            job={extractJobDetails(prismicCareersJobPost)}
          />
        ) : slice.slice_type === 'related_positions' ? (
          <Component
            key={key}
            {...slice}
            jobId={jobId}
            recentJobs={allPrismicCareersJobPost.nodes}
          />
        ) : (
          <Component key={key} {...slice} />
        )
      )}
    </Wrapper>
  );
};

export const query = graphql`
  query CareersJobPostQuery($id: String!) {
    prismicCareersJobPost(id: { eq: $id }) {
      _previewable
      tags
      type
      id
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        title {
          richText
          text
        }
        type
        description {
          richText
        }
        location {
          text
        }
        title {
          richText
          text
        }
        hr_email
        body {
          ... on PrismicCareersJobPostDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicCareersJobPostDataBodyListWithTitle {
            items {
              item {
                richText
              }
            }
            primary {
              introduction {
                richText
              }
              list_title {
                richText
              }
              footer {
                richText
              }
            }
            slice_type
          }
          ... on PrismicCareersJobPostDataBodyJobApplicationForm {
            slice_type
          }
          ... on PrismicCareersJobPostDataBodyRelatedPositions {
            slice_type
            primary {
              background
              related_positions_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              job {
                document {
                  ... on PrismicCareersJobPost {
                    id
                    data {
                      title {
                        richText
                        text
                      }
                      type
                      location {
                        richText
                        text
                      }
                    }
                    url
                  }
                }
              }
            }
          }
          ... on PrismicCareersJobPostDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
    allPrismicCareersJobPost(limit: 6) {
      nodes {
        id
        data {
          title {
            richText
            text
          }
          type
          location {
            richText
            text
          }
        }
        url
      }
    }
  }
`;

export default withPrismicPreview(CareersJobPost);
