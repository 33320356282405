import FileUpload from 'components/FileUpload';
import React from 'react';
import {
  StyledErrorText,
  StyledFileUploadGroup,
  StyledFileUploadWrapper,
} from '../styledComponents';

interface IFileUploadFormControlProps {
  id: string;
  dataTracker?: string;
  name: string;
  value?: File | null;
  label: React.ReactNode | string;
  inputLabel: React.ReactNode;
  error?: string;
  acceptedFileTypes: string[];
  handleFile: (file: File | null) => void;
}

const FileUploadFormControl = ({
  id,
  dataTracker,
  name,
  value,
  label,
  inputLabel,
  error,
  acceptedFileTypes,
  handleFile,
}: IFileUploadFormControlProps) => (
  <StyledFileUploadGroup>
    <label htmlFor={id}>
      {typeof label === 'string' ? <p>{label}</p> : label}
    </label>
    <StyledFileUploadWrapper>
      <FileUpload
        id={id}
        dataTracker={dataTracker}
        name={name}
        value={value}
        fileTypes={acceptedFileTypes}
        label={inputLabel}
        handleFile={handleFile}
      />
      {!!error && <StyledErrorText>{error}</StyledErrorText>}
    </StyledFileUploadWrapper>
  </StyledFileUploadGroup>
);

export default FileUploadFormControl;
