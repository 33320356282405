import { PRISMIC_VALUES } from 'constants/common';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import GatsbyImg from 'components/GatsbyImg';
import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import { TCard } from '../types';
import Benefits from './Benefits';
import {
  CardItem,
  CardTitle,
  ImageWrapper,
  StyledCta,
  StyledPrice,
  StyledPriceContainer,
} from './styledComponents';
import { track } from '@smartproxy/sp-tracker-js';

const Card: React.FC<TCard> = ({
  card_icon: icon,
  card_price: price,
  card_name: cardTitle,
  benefit_1: benefit1,
  benefit_2: benefit2,
  benefit_3: benefit3,
  cta_label: ctaLabel,
  cta_link_field: ctaLink,
  linkId,
  linkClassName,
}) => (
  <CardItem>
    <StyledPriceContainer>
      <small>{PRISMIC_VALUES.FROM}</small>
      <StyledPrice>
        <NewRichText text={price} />
      </StyledPrice>
    </StyledPriceContainer>
    <ImageWrapper>
      <GatsbyImg image={icon} />
    </ImageWrapper>
    <CardTitle>
      <NewRichText text={cardTitle} />
    </CardTitle>
    <Benefits benefit1={benefit1} benefit2={benefit2} benefit3={benefit3} />
    <StyledCta
      linkId={linkId}
      linkClassName={linkClassName}
      dataTracker={linkClassName}
      sendTrackingEvent={() => track(linkClassName)}
      isVisible={ctaLabel.text && ctaLink.url}
      ctaText={ctaLabel.text}
      ctaLink={ctaLink.url}
      themeName={BUTTON_THEMES.IMPORTANT}
      themeType={BUTTON_TYPES.SECONDARY}
    />
  </CardItem>
);

export default Card;
