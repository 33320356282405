import { track } from '@smartproxy/sp-tracker-js';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React from 'react';
import { headerCarrerQuery } from '../query';
import { StyledHeaderLogoLinkCareers } from '../styledComponents';
import { HEADER_SLICES, IDataTracker, ILogo } from '../types';
import { GatsbyImg } from '@smartproxy-web/ui';

const Logo: React.FC<IDataTracker> = ({ buildTrackingId }) => {
  const { data } = useMergePrismicPreviewData(headerCarrerQuery());
  const logoSlice: ILogo = data.prismicHeaderCareer.data.body.find(
    (slice) => slice.slice_type === HEADER_SLICES.Logo
  );
  const logoTrackerId = buildTrackingId({ context: 'logo', type: 'link' });

  return (
    <StyledHeaderLogoLinkCareers
      to="/careers"
      data-tracker={logoTrackerId}
      onClick={() => track(logoTrackerId)}
    >
      <GatsbyImg image={logoSlice.primary.logo} loading="eager" />
    </StyledHeaderLogoLinkCareers>
  );
};

export default Logo;
