import styled from '@emotion/styled';
import { TSHIRT_SIZE } from 'components/constants';
import {
  CSS_ALIGN,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import { IColor } from 'types';

export const VideoBlockWrapper = styled.div<IColor>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    height: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const StyledWrapper = styled.div<ITheme>((props) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  width: '100%',
  'h2, h3, h4, h5, h6': {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
  p: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
    textAlign: CSS_ALIGN.CENTER,
  },
}));

export const StyledVideoWrapper = styled.video({
  height: 'auto',
  width: '100%',
});
