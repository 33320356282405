import { usePageContext } from 'contexts/PageContext';
import { HideableTitles } from 'features/ScrapingSimulator/styledComponents';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import ProductCard from './ProductCard';
import { ProductCardsWrapper, Wrapper } from './styledComponents';
import { IProductCards } from './types';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';

const ProductCards: React.FC<IProductCards> = ({
  primary: { background, accent_color: accentColor, title, subtitle },
  items,
}) => {
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'products-cards',
    prefix: type,
  });

  return (
    <Wrapper background={background} data-tracker={trackingSelector}>
      <HideableTitles isVisible={!!title || !!subtitle}>
        <RichText field={title} />
        {!!subtitle && (
          <Box sx={{ marginBottom: '2rem' }}>
            <RichText field={subtitle} />
          </Box>
        )}
      </HideableTitles>
      <ProductCardsWrapper>
        {items.map((item) => (
          <ProductCard
            key={item.product_title.text}
            {...item}
            accentColor={accentColor}
            trackerCta={trackingSelector({
              context: item.product_title.text,
              type: 'cta',
            })}
            trackerPopup={trackingSelector({
              context: item.product_title.text + '-popup',
              type: 'button',
            })}
          />
        ))}
      </ProductCardsWrapper>
    </Wrapper>
  );
};

export default ProductCards;
