import { logPostHogEvent } from '@smartproxy-web/analytics';
import GatsbyImg from 'components/GatsbyImg';
import { Link } from 'components/GatsbyLink';
import { COOKIE_SLICES } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import { useEffect, useState } from 'react';
import { getLocalStorageItem, setLocalStorageItem } from 'utils/browserStorage';
import { isBrowser } from 'utils/build';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import { CookieBannerQuery } from './cookieQuery';
import {
  CookieButton,
  CookieButtonWrapper,
  CookieContainer,
  CookieImageWrapper,
  CookieTextWrapper,
  HideableCookieWrapper,
} from './styledComponents';

const COOKIES_ACCEPTED_ITEM = 'cookiesAccepted';

const CookieBanner = () => {
  const { tags, type } = usePageContext();
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  const { data } = useMergePrismicPreviewData(CookieBannerQuery());
  const content = data.prismicCookieBanner.data.body.filter(
    (slice) => slice.slice_type === COOKIE_SLICES.Cookie
  );

  useEffect(() => {
    setShowCookieBanner(!areCookiesAccepted());
  }, []);

  const areCookiesAccepted = () => {
    if (!isBrowser) {
      return true;
    }
    return !!getLocalStorageItem(COOKIES_ACCEPTED_ITEM);
  };

  const trackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'cookie-banner',
    type: 'cta',
  });

  const handleCookieAccept = () => {
    setLocalStorageItem(COOKIES_ACCEPTED_ITEM, 'TRUE');
    setShowCookieBanner(false);
  };

  return (
    <HideableCookieWrapper isVisible={showCookieBanner}>
      {content.map(
        (
          {
            primary: {
              cookies_icon: image,
              cookie_text: text,
              cookie_link: link,
              cookie_link_text: linkText,
              cookie_cta_text: ctaText,
            },
          },
          index
        ) => (
          <CookieContainer key={index}>
            <CookieImageWrapper>
              <GatsbyImg image={image} />
            </CookieImageWrapper>
            <CookieTextWrapper>
              {text.text}
              <Link
                to={link.url}
                data-tracker={trackingSelector({ context: linkText.text })}
                onClick={() =>
                  logPostHogEvent(
                    trackingSelector({ context: 'cta-' + ctaText?.text })
                  )
                }
              >
                {linkText.text}
              </Link>
            </CookieTextWrapper>
            <CookieButtonWrapper>
              <CookieButton
                onClick={() => {
                  logPostHogEvent(trackingSelector({ context: ctaText.text }));
                  handleCookieAccept();
                }}
                data-tracker={trackingSelector({ context: ctaText.text })}
              >
                {ctaText.text}
              </CookieButton>
            </CookieButtonWrapper>
          </CookieContainer>
        )
      )}
    </HideableCookieWrapper>
  );
};

export default hideable(CookieBanner);
