import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES } from 'components/Button';
import { IconBanner } from 'images';
import React from 'react';
import { StickyBannerContainer, StickyBannerCta } from '../styledComponents';
import { IBanner } from '../types';

const BannerContainer: React.FC<IBanner> = ({
  trackingSelector,
  bannerText,
  bannerCtaText,
  bannerCtaLink,
}) => (
  <StickyBannerContainer>
    <IconBanner />
    <p>{bannerText}</p>
    <StickyBannerCta
      isVisible
      ctaText={bannerCtaText}
      ctaLink={bannerCtaLink}
      themeName={BUTTON_THEMES.IMPORTANT}
      dataTracker={trackingSelector}
      sendTrackingEvent={() => track(trackingSelector)}
    />
  </StickyBannerContainer>
);

export default BannerContainer;
