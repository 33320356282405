import { PRISMIC_VALUES } from 'constants/common';
import { IconClock } from 'images';
import * as React from 'react';
import hideable from 'utils/hideable';
import {
  BackgroundHeroWrapper,
  BlogHeroContainer,
  BlogHeroImageWrapper,
  BlogHeroInfo,
  DateComponent,
  StyledBlogHeroDescription,
  TimeComponent,
} from './styledComponents';
import { THeroSlice } from './types';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const HideableTimeComponent = hideable(TimeComponent);

const BlogHero: React.FC<THeroSlice> = ({
  publishDate,
  title,
  readingTime,
  selectedDate,
  description,
  mainImage,
  primary: { background },
}) => {
  return (
    <BackgroundHeroWrapper background={background}>
      <BlogHeroContainer>
        <BlogHeroInfo>
          <DateComponent>{selectedDate || publishDate}</DateComponent>
          <HideableTimeComponent isVisible={readingTime}>
            <IconClock />
            {readingTime} {PRISMIC_VALUES.TIME_READ}
          </HideableTimeComponent>
        </BlogHeroInfo>
        <RichText field={title} />
        <StyledBlogHeroDescription>
          <RichText field={description} />
        </StyledBlogHeroDescription>
        <BlogHeroImageWrapper>
          <GatsbyImg image={mainImage} loading="eager" />
        </BlogHeroImageWrapper>
      </BlogHeroContainer>
    </BackgroundHeroWrapper>
  );
};

export default BlogHero;
