import { PRISMIC_VALUES } from 'constants/common';
import { TimePeriod } from 'features/PricingCards/styledComponents';
import React from 'react';
import hideable from 'utils/hideable';
import { IScreenProps } from 'utils/screenTypeHOC';
import {
  AnimationWrapper,
  DiscountLabelComponent,
  HideableCrossedOutPrice,
  HideableResidentialTitle,
  InfoText,
  PlanAmount,
  PlanContainer,
  PlanFeature,
  PlanFeatures,
  PlanFeaturesContainer,
  PlanPrice,
} from '../PricingSlider/styledComponents';
import { IItemResidential } from '../PricingSlider/types';

const PricingPlan: React.FC<IItemResidential & IScreenProps> = ({
  pricing_slider_details: {
    document: {
      data: {
        crossed_out_total_price: crossedOutTotalPrice,
        plan_name: planTitle,
        price_monthly: priceMonthly,
        price_per_gb: pricePerGb,
        price_per_gb_text: pricePerGbText,
        trafic,
        username,
        username_text: userNameText,
        vat_text: vatText,
        whitelisted,
        whitelisted_text: whitelistedText,
        dedicated,
        dedicated_account_text: dedicatedAccountText,
        discount,
        price_per_gb_crossed_out: pricePerGbCrossedOut,
        billing_name: billingName,
      },
    },
  },
  isTablet,
  sliderTheme,
}) => (
  <PlanContainer>
    {!!discount && <DiscountLabelComponent>{discount}</DiscountLabelComponent>}
    <AnimationWrapper>
      <AnimationWrapper key={planTitle}>
        <HideableResidentialTitle isVisible={!isTablet}>
          {planTitle}
        </HideableResidentialTitle>
      </AnimationWrapper>
      <AnimationWrapper key={trafic}>
        <PlanAmount colorShade={sliderTheme}>{trafic}</PlanAmount>
      </AnimationWrapper>
      <AnimationWrapper key={crossedOutTotalPrice}>
        <HideableCrossedOutPrice isVisible={crossedOutTotalPrice}>
          {crossedOutTotalPrice}
        </HideableCrossedOutPrice>
      </AnimationWrapper>
      <AnimationWrapper key={priceMonthly}>
        <PlanPrice>
          {priceMonthly}
          <TimePeriod>/{billingName}</TimePeriod>
        </PlanPrice>
      </AnimationWrapper>
      <InfoText>
        {PRISMIC_VALUES.TOTAL}
        {vatText}
      </InfoText>
      <PlanFeaturesContainer>
        <PlanFeatures>
          <PlanFeature>{pricePerGbText}</PlanFeature>
          <AnimationWrapper key={pricePerGb}>
            <span>{pricePerGbCrossedOut}</span>
            <PlanFeature>{pricePerGb}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{whitelistedText}</PlanFeature>
          <AnimationWrapper key={whitelisted}>
            <PlanFeature>{whitelisted}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{userNameText}</PlanFeature>
          <AnimationWrapper key={username}>
            <PlanFeature>{username}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
        <PlanFeatures>
          <PlanFeature>{dedicatedAccountText}</PlanFeature>
          <AnimationWrapper key={dedicated}>
            <PlanFeature>{dedicated}</PlanFeature>
          </AnimationWrapper>
        </PlanFeatures>
      </PlanFeaturesContainer>
    </AnimationWrapper>
  </PlanContainer>
);

export default hideable(PricingPlan);
