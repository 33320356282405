import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import React from 'react';
import Reviews from './Reviews';
import { Logos } from './Logos';
import {
  StyledContainer,
  StyledWrapper,
  CtaContainer,
  CtaPricing,
  CtaStart,
  StyledTextContainer,
  Subtitle,
  Title,
  ImageContainer,
} from './styledComponents';
import { TLpHeroSlice } from './types';
import { NewRichText } from 'components/PrismicRichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const LpHero: React.FC<TLpHeroSlice> = ({
  primary: {
    title,
    background,
    sub_title: subtitle,
    link_label_start: linkLabelStart,
    link_to_start: linkToStart,
    link_label_pricing: linkLabelPricing,
    link_to_pricing: linkToPricing,
    image,
    review_trustpilot: reviewTrustpilot,
    review_stars: reviewStars,
    review_text: reviewText,
    accent_color: accentColor,
    button_color: buttonColor,
    title_for_logos: titleForLogo,
  },
  items,
}) => {
  const isReviewOn = reviewStars.url && reviewTrustpilot.url && reviewText.text;

  return (
    <StyledWrapper background={background}>
      <StyledContainer>
        <StyledTextContainer color={accentColor} isReviewOn={isReviewOn}>
          <Reviews
            isVisible={isReviewOn}
            review_stars={reviewStars}
            review_trustpilot={reviewTrustpilot}
            review_text={reviewText}
          />
          <Title isReviewOn={isReviewOn} color={accentColor}>
            <NewRichText text={title} />
          </Title>
          <Subtitle>
            <NewRichText text={subtitle} />
          </Subtitle>
          <CtaContainer>
            <CtaStart
              isVisible={linkLabelStart.text && linkToStart.url}
              ctaText={linkLabelStart.text}
              ctaLink={linkToStart.url}
              themeName={BUTTON_THEMES[buttonColor]}
              themeType={BUTTON_TYPES.PRIMARY}
            />
            <CtaPricing
              isVisible={linkLabelPricing.text && linkToPricing.url}
              ctaText={linkLabelPricing.text}
              ctaLink={linkToPricing.url}
              themeName={BUTTON_THEMES.CONVERSION}
              themeType={BUTTON_TYPES.SECONDARY}
            />
          </CtaContainer>
        </StyledTextContainer>
        <ImageContainer>
          <GatsbyImg image={image} loading="eager" fetchPriority="high" />
        </ImageContainer>
        <Logos items={items} title={titleForLogo} />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default LpHero;
