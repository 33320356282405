import { usePageContext } from 'contexts/PageContext';
import { FormikErrors, FormikTouched } from 'formik';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { VALID_PHONE_CHAR_REGEX, PHONE_REGEX } from './constants';
import FileUploadFormControl from './FormControls/FileUploadFormControl';
import InputFormControl from './FormControls/InputFormControl';
import { IJobApplicationForm } from './types';
import { SUPPORTED_FILE_TYPES } from './validations';

type ICandidateInfoForm = Pick<
  IJobApplicationForm,
  'full_name' | 'profile_link' | 'email' | 'phone' | 'resume'
>;

interface CandidateInfoFormProps {
  values: ICandidateInfoForm;
  errors: FormikErrors<ICandidateInfoForm>;
  touched: FormikTouched<IJobApplicationForm>;
  handleChange: React.ChangeEventHandler;
  handleBlur: React.FocusEventHandler;
  handleResumeSelect: (file: File | null) => void;
}

const CandidateInfoForm = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  handleResumeSelect,
}: CandidateInfoFormProps) => {
  const isFileUploadClean = touched.resume && values.resume === undefined;

  const { tags, type } = usePageContext();

  const buildClickSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'job-application-form',
    type: 'field',
  });

  const handlePhoneChange = (event) => {
    if (
      event.currentTarget.value.length &&
      !VALID_PHONE_CHAR_REGEX.test(event.currentTarget.value)
    )
      return;
    handleChange(event);
  };

  return (
    <>
      <div>
        <InputFormControl
          label="Full name*"
          id="full_name"
          name="full_name"
          dataTracker={buildClickSelector({ context: 'full_name' })}
          value={values.full_name}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.full_name && errors.full_name ? errors.full_name : ''}
        />
        <InputFormControl
          label="Email*"
          type="email"
          id="email"
          dataTracker={buildClickSelector({ context: 'email' })}
          name="email"
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          error={touched.email && errors.email ? errors.email : ''}
        />
        <InputFormControl
          label="Phone*"
          type="tel"
          pattern={PHONE_REGEX.source}
          id="phone"
          dataTracker={buildClickSelector({ context: 'phone' })}
          name="phone"
          value={values.phone}
          onBlur={handleBlur}
          onChange={handlePhoneChange}
          error={touched.phone && errors.phone ? errors.phone : ''}
        />
        <InputFormControl
          label={
            <div>
              <p>Link to your profile</p>
              <small>(Linkedin, Github, etc)</small>
            </div>
          }
          id="profile_link"
          dataTracker={buildClickSelector({ context: 'profile_link' })}
          name="profile_link"
          value={values.profile_link}
          onBlur={handleBlur}
          onChange={handleChange}
          error={
            touched.profile_link && errors.profile_link
              ? errors.profile_link
              : ''
          }
        />
        <FileUploadFormControl
          label={
            <div>
              <p>Resume/CV*</p>
              <small>(You can also attach LinkedIn CV)</small>
            </div>
          }
          id="file-upload"
          dataTracker={buildClickSelector({ context: 'resume' })}
          name="resume"
          value={values.resume}
          acceptedFileTypes={SUPPORTED_FILE_TYPES}
          inputLabel={
            <p>
              Attach <small>(PDF./JPG./PNG.)</small>
            </p>
          }
          handleFile={handleResumeSelect}
          error={
            errors.resume && (isFileUploadClean || touched.resume)
              ? errors.resume
              : ''
          }
        />
      </div>
    </>
  );
};

export default CandidateInfoForm;
