import { track } from '@smartproxy/sp-tracker-js';
import {
  StyledSplashScreenTargetList,
  StyledSplashScreenTargetListItem,
} from './styledComponents';
import { ISplashScreenTargetListProps } from './types';

const SplashScreenTargetList = ({
  targets,
  activeTarget,
  handleChange,
  buildClickSelector,
}: ISplashScreenTargetListProps) => (
  <StyledSplashScreenTargetList>
    {targets.map((target) => (
      <StyledSplashScreenTargetListItem key={target.value}>
        <label
          data-tracker={buildClickSelector({
            context: `target ${target.label}`,
            type: 'field',
          })}
        >
          <input
            type="radio"
            value={target.value}
            checked={activeTarget.value === target.value}
            onChange={() => handleChange(target)}
            onClick={() =>
              track(
                buildClickSelector({
                  context: `target ${target.label}`,
                  type: 'field',
                })
              )
            }
          />
          <span>{target.shortLabel ?? target.label}</span>
        </label>
      </StyledSplashScreenTargetListItem>
    ))}
  </StyledSplashScreenTargetList>
);

export default SplashScreenTargetList;
