import { useLocation } from '@reach/router';

export const useHash = () => {
  const { hash } = useLocation();

  const clearHash = () => {
    history.back();
  };

  return { hash, clearHash };
};
