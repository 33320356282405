import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { DOM_STATE } from 'components/constants';
import { ACCENT_COLOR, CSS_ALIGN, getThemeAccentColor, ITheme } from 'theme';

export const QuestionsContainer = styled.section({
  ...flexColContainerStyles,
  alignItems: CSS_ALIGN.CENTER,
});

export const StyledFakeLink = styled.div<ITheme & { isActive: boolean }>(
  ({ isActive, ...props }) => ({
    color: getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
    opacity: isActive ? 0.5 : 1,
    [DOM_STATE.HOVER]: {
      textDecoration: isActive ? 'none' : 'underline',
      cursor: isActive ? 'unset' : 'pointer',
    },
  })
);
