import { TABLE_VALUES } from 'constants/common';
import { NewRichText } from 'components/PrismicRichText';
import { IconBrightdata, IconNetNut, IconOxy, IconSP } from 'images';
import React from 'react';
import {
  HeaderCell,
  BiggerIconContainer,
  StyledCell,
  SmallerIconContainer,
} from './styledComponents';
import { ITableColumn } from './types';

export const TableBlockColumn: React.FC<ITableColumn> = ({
  data,
  header,
  isLast,
  isDarkHeader,
}) => {
  const headerLogo = (headerName: TABLE_VALUES) => {
    switch (headerName) {
      case TABLE_VALUES.SMARTPROXY:
        return (
          <BiggerIconContainer>
            <IconSP />
          </BiggerIconContainer>
        );
      case TABLE_VALUES.OXYLABS:
        return (
          <SmallerIconContainer>
            <IconOxy />
          </SmallerIconContainer>
        );
      case TABLE_VALUES.BRIGHT_DATA:
        return (
          <SmallerIconContainer>
            <IconBrightdata />
          </SmallerIconContainer>
        );
      case TABLE_VALUES.NETNUT:
        return (
          <BiggerIconContainer>
            <IconNetNut />
          </BiggerIconContainer>
        );
      default:
        return;
    }
  };
  return (
    <>
      <HeaderCell isDarkHeader={isDarkHeader} isLast={isLast}>
        {headerLogo(header)}
      </HeaderCell>
      {data.map(
        ({
          cell_background: background,
          cell_text: text,
          is_text_light: isTextLight,
        }) => (
          <StyledCell
            background={background}
            key={text.text}
            isTextLight={isTextLight}
          >
            <NewRichText text={text} />
          </StyledCell>
        )
      )}
    </>
  );
};
