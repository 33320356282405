import List from 'features/Topic/List';
import { SliceMap } from 'types';

export enum TOPIC_SLICES {
  AllArticles = 'all_articles',
}

export const topicSliceMap: SliceMap<TOPIC_SLICES> = {
  [TOPIC_SLICES.AllArticles]: List,
};
