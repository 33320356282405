import loadable from '@loadable/component';
import React from 'react';
import { HideableHandleText } from './styledComponents';
import { THandle } from './types';
const HandleWrapper = loadable(() => import('rc-slider'), {
  resolveComponent: (imports) => imports.Handle,
});

const Handle: React.FC<THandle> = ({ text, isTablet, ...props }) => (
  <HandleWrapper {...props}>
    <HideableHandleText isVisible={isTablet}>{text}</HideableHandleText>
  </HandleWrapper>
);

export default Handle;
