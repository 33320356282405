import React from 'react';
import hideable from 'utils/hideable';
import { INeedMoreBanner } from '../PricingSlider/types';
import {
  AnimationWrapper,
  NeedMoreContainer,
  NeedMoreDescription,
  NeedMoreIcon,
  NeedMoreTitle,
} from '../PricingSlider/styledComponents';
import { NeedBiggerPlans } from 'images';
import { RESIDENTIAL_PRICING_VALUES } from 'constants/common';

const NeedMoreBanner: React.FC<INeedMoreBanner> = () => (
  <AnimationWrapper>
    <NeedMoreContainer>
      <NeedMoreIcon>
        <NeedBiggerPlans />
      </NeedMoreIcon>
      <NeedMoreTitle>
        {RESIDENTIAL_PRICING_VALUES.NEED_BIGGER_PLANS_TITLE}
      </NeedMoreTitle>
      <NeedMoreDescription>
        {RESIDENTIAL_PRICING_VALUES.NEED_BIGGER_PLANS_DESCRIPTION}
      </NeedMoreDescription>
    </NeedMoreContainer>
  </AnimationWrapper>
);

export default hideable(NeedMoreBanner);
