import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { track } from '@smartproxy/sp-tracker-js';
import { Link } from 'components/GatsbyLink';
import { Button } from 'components/v2/Button';
import { IconTranslationv2 } from 'images';
import React from 'react';
import { initDataLayer } from '../../helpers';
import { useGetLangLink } from '../useLangLink';
import {
  StyledDropdownArrow,
  StyledLanguageDropdownContents,
  StyledLanguageDropdownItem,
} from './styledComponents';
import { LanguageToggleProps } from './types';

export const LanguageToggle: React.FC<LanguageToggleProps> = ({
  languages,
  buildTrackingId,
}) => {
  const getLangLink = useGetLangLink();
  const languagesTrackingId = (label: string) => {
    return buildTrackingId({
      context: `translation ${label}`,
      type: 'link',
    });
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Button
          variant="text"
          color="gray"
          size="sm"
          compact
          aria-label="change language"
          data-tracker={buildTrackingId({
            context: 'translation',
            type: 'trigger',
          })}
        >
          <IconTranslationv2 />
        </Button>
      </DropdownMenu.Trigger>
      <StyledLanguageDropdownContents>
        <DropdownMenu.Arrow asChild>
          <StyledDropdownArrow />
        </DropdownMenu.Arrow>
        {languages.map((language) => (
          <StyledLanguageDropdownItem
            key={language.label}
            disabled={!getLangLink(language)}
          >
            <Link
              to={getLangLink(language)}
              data-active={!!getLangLink(language)}
              data-tracker={languagesTrackingId(language.label)}
              onClick={() => {
                track(languagesTrackingId(language.label));
                initDataLayer(languagesTrackingId(language.label));
              }}
            >
              {language.label}
            </Link>
          </StyledLanguageDropdownItem>
        ))}
      </StyledLanguageDropdownContents>
    </DropdownMenu.Root>
  );
};
