import { apiEndpoints } from 'apiEndpoints';
import { CONTACT_SALES_URL_HASH } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import InquiryFormConfirmation from 'features/InquiryForm/components/InquiryFormConfirmation';
import InquiryFormContent from 'features/InquiryForm/components/InquiryFormContent';
import InquiryFormFeatures from 'features/InquiryForm/components/InquiryFormFeatures';
import InquiryFormWrapper from 'features/InquiryForm/components/InquiryFormWrapper';
import { INQUIRY_FORM_SLICES } from 'features/InquiryForm/constants';
import {
  AnimationContainer,
  Overlay,
} from 'features/InquiryForm/styledComponents';
import {
  IFormState,
  IInquiryFormConfirmation,
  TForkmikHelpers,
} from 'features/InquiryForm/types';
import {
  getSlice,
  mapFormDataToPostData,
  onFinally,
} from 'features/InquiryForm/utils';
import { HTTP_METHOD, useApi } from 'hooks/useApi';
import { useHash } from 'hooks/useHash';
import { useLiveChat } from 'hooks/useLiveChat';
import { useScrollBlock } from 'hooks/useScrollBlock';
import React, { useEffect, useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import InquiryFormBooking from './components/InquiryFormBooking';
import { inquiryFormQuery } from './query';

export enum InquiryPage {
  FORM = 'form_page',
  SUCCESS = 'success_page',
  ERROR = 'error_page',
  BOOKING = 'booking_page',
}

const InquiryForm: React.FC = () => {
  const { ErrorModal, Features, SuccessModal } = INQUIRY_FORM_SLICES;
  const { isLoading, isError, isSuccess, request, resetRequestStatus } = useApi(
    HTTP_METHOD.POST,
    apiEndpoints.inquiryFormSubmit
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { clearHash, hash } = useHash();
  const { showChatBubble } = useLiveChat();

  const { body, ...rest } = inquiryFormQuery().prismicInquiryForm.data;
  const successSlice = getSlice<IInquiryFormConfirmation>(body, SuccessModal);
  const errorSlice = getSlice<IInquiryFormConfirmation>(body, ErrorModal);
  const featuresSlice = getSlice(body, Features);

  const [showing, setShowing] = useState<InquiryPage>(InquiryPage.FORM);

  const { tags, type } = usePageContext();

  useEffect(() => {
    setIsModalVisible(hash === CONTACT_SALES_URL_HASH);
  }, [hash]);

  useEffect(() => {
    if (isError) setShowing(InquiryPage.ERROR);
    if (isSuccess) setShowing(InquiryPage.SUCCESS);
  }, [isError, isSuccess]);

  const handleClose = () => {
    showChatBubble();
    resetRequestStatus();
    clearHash();
    setShowing(InquiryPage.FORM);
  };

  const handleBooking = () => {
    setShowing(InquiryPage.BOOKING);
  };

  const handleTryAgain = () => {
    resetRequestStatus();
    setShowing(InquiryPage.FORM);
  };

  const handleSubmit = (values: IFormState, actions: TForkmikHelpers) => {
    window.dataLayer = window.dataLayer || [];
    request({
      body: mapFormDataToPostData(values),
      onFinally: () => onFinally(actions),
    });
    window.dataLayer.push({
      event: 'formSubmission',
      formType: 'Contact sales',
      formPosition: 'Footer',
    });
  };

  useScrollBlock(isModalVisible);

  const buildCloseBtnClassName = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'contact-sales',
    type: 'button',
  });

  return (
    <Overlay isVisible={isModalVisible}>
      <AnimationContainer>
        <InquiryFormContent
          closeBtnClassName={buildCloseBtnClassName({ context: 'form-close' })}
          onClose={handleClose}
          isVisible={showing === InquiryPage.FORM}
        >
          <InquiryFormFeatures {...featuresSlice} isVisible={true} />
          <InquiryFormWrapper
            {...rest}
            isLoading={isLoading}
            onSubmit={handleSubmit}
          />
        </InquiryFormContent>
        <InquiryFormContent
          closeBtnClassName={buildCloseBtnClassName({
            context: 'success-close',
          })}
          isVisible={showing === InquiryPage.SUCCESS}
          onClose={handleClose}
        >
          <InquiryFormConfirmation
            {...successSlice}
            primary={{
              ...successSlice.primary,
              cta_text:
                showing === InquiryPage.SUCCESS
                  ? 'Book a demo call'
                  : successSlice.primary.cta_text,
            }}
            onClick={
              showing === InquiryPage.SUCCESS ? handleBooking : handleClose
            }
          />
        </InquiryFormContent>
        <InquiryFormContent
          closeBtnClassName={buildCloseBtnClassName({ context: 'error-close' })}
          isVisible={showing === InquiryPage.ERROR}
          onClose={handleClose}
        >
          <InquiryFormConfirmation {...errorSlice} onClick={handleTryAgain} />
        </InquiryFormContent>
        <InquiryFormContent
          closeBtnClassName={buildCloseBtnClassName({
            context: 'booking-close',
          })}
          isVisible={showing === InquiryPage.BOOKING}
          onClose={handleClose}
        >
          <InquiryFormBooking />
        </InquiryFormContent>
      </AnimationContainer>
    </Overlay>
  );
};

export default InquiryForm;
