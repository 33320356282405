import { NewRichText } from 'components/PrismicRichText';
import { IconCheckmarkHome } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import { IBenefits } from '../types';
import { Benefit, BenefitsList } from './styledComponents';

const HideableBenefit = hideable(Benefit);

const Benefits: React.FC<IBenefits> = ({ benefit1, benefit2, benefit3 }) => (
  <BenefitsList>
    <HideableBenefit isVisible={benefit1.text}>
      <IconCheckmarkHome />
      <NewRichText text={benefit1} />
    </HideableBenefit>
    <HideableBenefit isVisible={benefit2.text}>
      <IconCheckmarkHome />
      <NewRichText text={benefit2} />
    </HideableBenefit>
    <HideableBenefit isVisible={benefit3.text}>
      <IconCheckmarkHome />
      <NewRichText text={benefit3} />
    </HideableBenefit>
  </BenefitsList>
);

export default Benefits;
