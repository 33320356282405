import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { ImageContainer } from 'features/Hero/styledComponents';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputWidthShape,
  getThemeMainColor,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledWrapper = styled.section({
  background: themev2.colors.black.S900,
  width: '100%',
  padding: `60px 20px`,
});

export const StyledContainer = styled.div((props) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '1fr 400px',
  alignItems: CSS_ALIGN.CENTER,
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: 'unset',
    margin: '0 auto',
    [String(ImageContainer)]: {
      display: CSS_DISPLAY.FLEX,
    },
  },
}));

export const StyledTextContainer = styled.div<{ color: string }>(
  ({ color, ...props }) => ({
    ...flexColContainerStyles,
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXXL),
    'h1, h2, h3': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
      color: color,
      textAlign: TEXT_ALIGN.LEFT,
      [MEDIA_QUERIES.TABLET]: {
        textAlign: TEXT_ALIGN.CENTER,
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      },
      [MEDIA_QUERIES.PHONE_PORTRAIT]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
        textAlign: TEXT_ALIGN.CENTER,
      },
    },
    p: {
      color: getThemeMainColor(props),
      fontFamily: getThemeFontFamily(props),
      marginBottom: '27px',
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
      fontWeight: 400,
      ':last-of-type': {
        marginBottom: 0,
      },
      a: {
        color: color || getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
        fontFamily: getThemeFontFamily(props),
        [DOM_STATE.HOVER]: {
          textDecoration: 'underline',
        },
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      alignItems: CSS_ALIGN.CENTER,
      gridRow: 2,
      textAlign: TEXT_ALIGN.CENTER,
      marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
    },
  })
);

export const TrustpilotContainer = styled.div((props) => ({
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  display: CSS_DISPLAY.INLINE_BLOCK,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  div: {
    width: '123px',
    height: '28px',
  },
  img: {
    width: '123px',
    height: '28px',
  },
  span: {
    color: getThemeMainColor(props),
    marginLeft: getThemeGutter(props, TSHIRT_SIZE.XS),
    marginBottom: 0,
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    fontWeight: 500,
  },
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const BlocksContainer = styled.div<{ color: string }>(
  ({ color, ...props }) => ({
    width: '100%',
    display: CSS_DISPLAY.GRID,
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
    gridAutoColumns: '1fr',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
    rowGap: '16px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'auto',
    marginTop: getThemeGutter(props, TSHIRT_SIZE.S),
    [String(BlockTitle)]: {
      p: {
        color: color,
      },
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'repeat(2, auto)',
    },
  })
);

export const Block = styled.div({
  display: CSS_DISPLAY.FLEX,
  width: 'auto',
  height: 'auto',
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  padding: '24px 0',
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  border: '1px dashed hsla(0,0%,100%,.2)',
  borderRadius: '12px',
});

export const BlockTitle = styled.div((props) => ({
  p: {
    marginBottom: 0,
    fontFamily: getThemeFontFamily(props),
    fontSize: '52px',
    lineHeight: '52px',
    fontWeight: 500,
    textAlign: TEXT_ALIGN.CENTER,
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      marginBottom: 0,
    },
  },
}));

export const BlockText = styled.div((props) => ({
  p: {
    fontFamily: getThemeFontFamily(props),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontWeight: 400,
    textAlign: TEXT_ALIGN.CENTER,
    color: getThemeMainColor(props),
    marginBottom: 0,
  },
}));
