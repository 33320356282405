import { SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React, { useState } from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import { IScreenProps } from 'utils/screenTypeHOC';
import BottomContainerItem from './BottomContainer';
import Handle from './Handle';
import NeedMoreBanner from './NeedMoreBanner';
import PricingPlan from './PricingPlan';
import {
  BlockContainer,
  Container,
  ContentContainer,
  HideableTitleWrapper,
  Range,
  StyledRangeContainer,
} from './styledComponents';
import { IPricingSlider } from './types';
import { getPricingPlans } from './utils';
import { RichText } from 'components/v2/RichText';
import { Heading } from 'components/v2/Heading';
import { usePageContext } from 'contexts/PageContext';
import { logPostHogEvent } from '@smartproxy-web/analytics';

const RANGE_STEP = 0.01;

const PricingSlider: React.FC<IPricingSlider & IScreenProps> = ({
  isTablet,
  items,
  primary: {
    theme,
    background,
    bigger_plan_cta_link: biggerPlanCtaLink,
    bigger_plan_cta_text: biggerPlanCtaText,
    bigger_plan_description: biggerPlanDescription,
    bigger_plan_icon: biggerPlanIcon,
    bigger_plan_title: biggerPlanTitle,
    switch_monthly_label: switchMonthlyLabel,
    switch_yearly_label: switchYearlyLabel,
    title,
    subtitle,
    is_yearly_visible: isYearlyVisible,
  },
}) => {
  const { tags, type } = usePageContext();
  const [isBillingYearly, setIsBillingYearly] = useState(false);
  const [inputValue, setInputValue] = useState(0);

  const {
    pricingPlans,
    currentPlan,
    isMaxValue,
    ctaLink,
    ctaText,
    rangeHandleTitle,
    handleSwitchBillingType,
    marks,
    sliderTheme,
  } = getPricingPlans(
    theme,
    items,
    setIsBillingYearly,
    biggerPlanCtaLink,
    biggerPlanCtaText,
    isBillingYearly,
    inputValue
  );

  const getPlanTitle = () => {
    if (currentPlan) {
      return currentPlan.plan_title;
    }
    return 'contact-sales';
  };

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.PRICING_SLIDER,
    prefix: type,
  });

  const handleOnChange = (value) => {
    logPostHogEvent(trackingSelector({ type: 'trigger' }));
    setInputValue(value);
  };

  return (
    <BlockContainer
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.PRICING_SLIDER,
        tags,
        type
      )}
    >
      <Heading level={2}>{title?.text}</Heading>
      <HideableTitleWrapper isVisible={!!subtitle?.text}>
        <RichText field={subtitle} />
      </HideableTitleWrapper>
      <Container>
        <NeedMoreBanner
          isVisible={isMaxValue}
          description={biggerPlanDescription}
          icon={biggerPlanIcon}
          title={biggerPlanTitle}
        />
        <PricingPlan
          isVisible={!isMaxValue}
          isTablet={isTablet}
          {...currentPlan}
          sliderTheme={sliderTheme}
        />
        <ContentContainer>
          <StyledRangeContainer>
            <Range
              colorShade={sliderTheme}
              step={RANGE_STEP}
              value={inputValue}
              max={pricingPlans.length}
              onChange={(value) => handleOnChange(value)}
              marks={marks}
              handle={(props) => (
                <Handle
                  isTablet={isTablet}
                  text={rangeHandleTitle}
                  {...props}
                />
              )}
            />
          </StyledRangeContainer>
          <BottomContainerItem
            switchYearlyLabel={switchYearlyLabel}
            switchMonthlyLabel={switchMonthlyLabel}
            isYearlyVisible={isYearlyVisible}
            handleSwitchBillingType={handleSwitchBillingType}
            isBillingYearly={isBillingYearly}
            ctaText={ctaText}
            ctaLink={ctaLink}
            selector={trackingSelector({
              context: getPlanTitle(),
              type: 'cta',
            })}
            buttonTheme={theme}
            radioButtonTheme={sliderTheme}
          />
        </ContentContainer>
      </Container>
    </BlockContainer>
  );
};

export default PricingSlider;
