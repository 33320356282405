import { IconGoogle } from 'images';
import React from 'react';
import {
  ScraperContainer,
  ScraperHeaderContainer,
  ScraperContentContainer,
  HeaderTextValues,
} from './styledComponents';
import { headerText } from './utils';
import CVSResults from './CSVResults';
import JsonResults from './JSONResults';

const Scraper: React.FC = () => (
  <ScraperContainer>
    <ScraperHeaderContainer>
      <IconGoogle />
      {Object.entries(headerText).map(([key, value], index) => (
        <HeaderTextValues key={index}>
          <span>{key}: </span>
          {value}
        </HeaderTextValues>
      ))}
    </ScraperHeaderContainer>
    <ScraperContentContainer>
      <CVSResults />
      <JsonResults />
    </ScraperContentContainer>
  </ScraperContainer>
);

export default Scraper;
