import { IconTranslationv2 } from 'images';
import { INavItem, INavItemWithChildren } from './Nav/types';
import {
  PrismicNavCategory,
  PrismicNavLanguage,
  PrismicNavLink,
} from './types';

export const prepareNavCategories = (
  rawCategories: PrismicNavCategory[]
): INavItem[] => {
  return rawCategories.map((category) => {
    return {
      key: category.category_label.toLowerCase(),
      label: category.category_label,
      badgeLabel: category.badge_label,
      navItem: category.category_item,
      children: category.category_item.document?.data.nav_item_name.map(
        (item) => ({
          key: item.nav_item.document.data.category_item_name,
          label: item.nav_item.document.data.category_item_name,
          badgeLabel: item.nav_item.document.data.badge_label,
          title: item.nav_item.document.data.category_item_list_title,
          titleUrl: item.nav_item.document.data.category_item_title_url,
          children: item.nav_item.document.data.category_items_list.map(
            (item) => ({
              key: item.category_item_title,
              label: item.category_item_title,
              url: item.category_item_link.url,
              categoryTitle: item.category_item_title,
              badgeLabel: item.category_item_label,
              categoryDescription: item.category_item_description,
              price: item.price,
              pricePer: item.price_prefix,
              badgeColor: item.badge_color,
            })
          ),
        })
      ),
      additionalFeaturesTitle:
        category.category_item.document?.data.additional_item?.document?.data
          ?.features,
      additionalFeaturesUrl:
        category.category_item.document?.data.additional_item?.document?.data
          ?.features_url,
      additionalFeaturesItems:
        category.category_item.document?.data.additional_item?.document?.data?.features_items?.map(
          (item) => ({
            key: item.item_label,
            label: item.item_label,
            url: item.item_link,
          })
        ),
      additionalTargetsTitle:
        category.category_item.document?.data.additional_item?.document?.data
          ?.targets,
      additionalTargetsUrl:
        category.category_item.document?.data.additional_item?.document?.data
          ?.targets_url,
      additionalTargetsItems:
        category.category_item.document?.data.additional_item?.document?.data?.targets_items?.map(
          (item) => ({
            key: item.item_label,
            label: item.item_label,
            url: item.item_link,
          })
        ),
      additionalBannerFieldTitle:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner,
      additionalBannerTitle:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner_title,
      additionalBannerDescription:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner_description,
      additionalBannerIcon:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner_icon,
      additionalBannerCtaLabel:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner_cta_label,
      additionalBannerCtaLink:
        category.category_item.document?.data.additional_item?.document?.data
          ?.banner_cta_link,
    };
  });
};

export const prepareNavLanguages = (
  rawLanguages: PrismicNavLanguage[]
): INavItemWithChildren => ({
  key: 'languages',
  label: 'Languages',
  className: 'show-on-mobile',
  renderLabel: () => (
    <>
      <IconTranslationv2 />
      Languages
    </>
  ),
  children: rawLanguages.map((rawLanguage) => ({
    key: rawLanguage.key ?? rawLanguage.label.toLowerCase(),
    label: rawLanguage.label,
    url: rawLanguage.link?.url ?? '',
  })),
});

export const prepareNavLinks = (rawLinks: PrismicNavLink[]): INavItem[] => {
  return rawLinks.map((rawLink) => ({
    key: rawLink.label.toLowerCase(),
    label: rawLink.label,
    url: rawLink.link?.url ?? '',
  }));
};

export const initDataLayer = (clickedElementUniqueIdentifier: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'element_click',
    clicked_element_data: [
      {
        element_identifier: clickedElementUniqueIdentifier,
        element_section: 'menu',
      },
    ],
  });
};

export const transformUrl = (linkUrl: string) => {
  return linkUrl.split('/').pop() === 'pricing'
    ? linkUrl?.split('/').splice(-2).join('-')
    : linkUrl?.split('/').pop();
};
