import styled from '@emotion/styled';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { Text } from 'components/v2/Text';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  FONT_FAMILY,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';

export const TextImageColumnsWrapper = styled.div<IColor & ITheme>(
  ({ background, ...props }) => ({
    background,
    width: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  })
);

export const TitleWrapper = styled.div<ITheme & { isSubtitleAdded: boolean }>(
  ({ isSubtitleAdded, ...props }) => ({
    textAlign: TEXT_ALIGN.CENTER,
    'h1, h2, h3': {
      marginBottom: isSubtitleAdded
        ? getThemeGutter(props, TSHIRT_SIZE.S)
        : getThemeGutter(props, TSHIRT_SIZE.XL),
    },
  })
);

export const Subtitle = styled.div<ITheme & { isSubtitleCentered: boolean }>(
  ({ isSubtitleCentered, ...props }) => ({
    textAlign: isSubtitleCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
    'h2, h3, h4, h5, h6': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      textAlign: TEXT_ALIGN.LEFT,
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
    },
  })
);

export const TextImageColumnsContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  maxWidth: '1024px',
  margin: `${getThemeGutter(props, TSHIRT_SIZE.XXS)} auto`,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
}));

export const TextImageColumnsItems = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.STRETCH,
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  gridTemplateColumns: 'repeat(3, 1fr)',
  [MEDIA_QUERIES.TABLET]: {
    columnGap: '20px',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.XXXL),
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, auto)',
  },
}));

export const TextImageColumnBlock = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  alignItems: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.FLEX_START,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  },
}));

export const TextImageColumnImageAndTitleContainer = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  objectFit: 'cover',
  'h2, h3, h4': {
    textAlign: TEXT_ALIGN.LEFT,
    height: 'auto',
    fontSize: '22px',
    fontWeight: '500',
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H3),
    [MEDIA_QUERIES.TABLET]: {},
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      textAlign: TEXT_ALIGN.CENTER,
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
      whiteSpace: 'normal',
    },
  },
  div: {
    img: {
      margin: '0 auto 40px',
      verticalAlign: 'middle',
      maxHeight: '160px',
      maxWidth: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      height: '100%',
      width: '100%',
    },
  },
}));

export const TextImageColumnDescription = styled(Text)((props) => ({
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
  color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P2),
  textAlign: TEXT_ALIGN.LEFT,
  flexGrow: '1',

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const TextImageColumnLinkContainer = styled.div((props) => ({
  alignSelf: CSS_ALIGN.FLEX_START,
  marginTop: '6px',
  height: '30px',
  a: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
    lineHeight: '20px',
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  },
}));
