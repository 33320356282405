import { GatsbyImg, IconGoogle, IconMoneyBack } from '@smartproxy-web/ui';
import { HeroBadges } from '../Hero';
import { LinkButton } from '../LinkButton';
import { RichText } from '../RichText';
import {
  CtaBlockContainer,
  CtaContainer,
  Ctas,
  Features,
  ListItem,
  MoneyBack,
  StyledSlice,
  UpperHeroPart,
} from './styledComponents';
import { CarouselHeroProps } from './types';
import { initBuildTrackingSelector } from 'utils/page';
import { logPostHogEvent } from '@smartproxy-web/analytics';
import { useSliceDetails } from '../../hooks';
import { logEvent } from '../../helpers';

export const CarouselHero = ({
  primary: {
    hero_title: title,
    hero_subtitle: subtitle,
    primary_button_url: primaryButtonUrl,
    secondary_button_url: secondaryButtonUrl,
    primary_button_text: primaryButtonText,
    secondary_button_text: secondaryButtonText,
    checkmarks_icon: checkmark,
  },
  items,
}: CarouselHeroProps) => {
  const { id: sliceId } = useSliceDetails();

  const createBadgeTrackingID = initBuildTrackingSelector({
    section: 'hero-badge',
    type: 'link',
  });
  const handleBadgeClick = (badge: string) => {
    logEvent({
      sliceId,
      elementId: badge,
      eventId: createBadgeTrackingID(),
    });
  };

  const buildCtaTrackingSelector = initBuildTrackingSelector({
    section: 'hero',
    type: 'cta',
  });
  return (
    <StyledSlice>
      <UpperHeroPart>
        <HeroBadges
          handleBadgeClick={handleBadgeClick}
          trackingSelectors={{
            trustpilot: createBadgeTrackingID({ context: 'trustpilot' }),
            g2: createBadgeTrackingID({ context: 'g2' }),
            proxyway: createBadgeTrackingID({ context: 'proxyway' }),
          }}
          isDark={false}
          isCentered
        />
        <RichText field={title} />
        <RichText field={subtitle} />
        <CtaBlockContainer>
          <Ctas>
            <CtaContainer>
              <LinkButton
                to={primaryButtonUrl.url}
                color="blue"
                fullWidth
                data-tracker={buildCtaTrackingSelector({ context: 'primary' })}
                onClick={() =>
                  logPostHogEvent(
                    buildCtaTrackingSelector({ context: 'primary' })
                  )
                }
              >
                {primaryButtonText}
              </LinkButton>
            </CtaContainer>
            <CtaContainer>
              <LinkButton
                to={secondaryButtonUrl.url}
                color="black"
                variant="outlined"
                fullWidth
                data-tracker={buildCtaTrackingSelector({
                  context: 'secondary',
                })}
                onClick={() =>
                  logPostHogEvent(
                    buildCtaTrackingSelector({ context: 'secondary' })
                  )
                }
              >
                <IconGoogle /> {secondaryButtonText}
              </LinkButton>
            </CtaContainer>
          </Ctas>
          <MoneyBack>
            <IconMoneyBack />
            14-day money-back option
          </MoneyBack>
        </CtaBlockContainer>
      </UpperHeroPart>
      <Features>
        {items.map(({ feature_text: featureText }) => (
          <ListItem key={featureText}>
            <GatsbyImg image={checkmark} />
            <span>{featureText}</span>
          </ListItem>
        ))}
      </Features>
    </StyledSlice>
  );
};
