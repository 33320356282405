import { useEffect, useState } from 'react';
import { ProgressBarContainer } from './styledComponents';
import { CARD_ANIMATION_DURATION } from './constants';

export const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      } else {
        setProgress(0);
      }
    }, CARD_ANIMATION_DURATION);

    return () => clearInterval(interval);
  }, [progress]);

  return <ProgressBarContainer progress={progress} />;
};
