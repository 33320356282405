import { NewRichText } from 'components/PrismicRichText';
import { SECTION_NAMES } from 'constants/common';
import ImageVideoPlayer from 'features/Hero/ImageVideoPlayer';
import { generateSectionsId, slugify, updateColorInfo } from 'features/utils';
import React from 'react';
import Blocks from './Blocks';
import {
  StyledContainer,
  StyledTextContainer,
  StyledWrapper,
  TrustpilotContainer,
} from './styledComponents';
import { IHeroAbout } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const HeroAboutUs: React.FC<IHeroAbout> = ({
  primary: {
    accent_color: accentColor,
    block_one_text: blockOneText,
    block_one_title: blockOneTitle,
    block_two_text: blockTwoText,
    block_two_title: blockTwoTitle,
    description,
    hero_image: image,
    review_text: ReviewText,
    review_trustpilot: reviewImage,
    title,
    video_embed: video,
  },
  pageContext,
}) => {
  const { tags, type } = pageContext || { tags: [] };
  const buildClickId = (btnType: string) => {
    return `hero-${slugify(tags[0] || type)}-${btnType}-cta`;
  };
  const updatedAccentColor = updateColorInfo(accentColor.toLowerCase());

  return (
    <StyledWrapper
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledContainer>
        <StyledTextContainer color={updatedAccentColor.color}>
          <TrustpilotContainer>
            <GatsbyImg image={reviewImage} loading="eager" />
            <span>{ReviewText.text}</span>
          </TrustpilotContainer>
          <NewRichText text={title} />
          <NewRichText text={description} />
          <Blocks
            blockOneText={blockOneText}
            blockOneTitle={blockOneTitle}
            blockTwoText={blockTwoText}
            blockTwoTitle={blockTwoTitle}
            updatedAccentColor={updatedAccentColor.color}
          />
        </StyledTextContainer>
        <ImageVideoPlayer
          image={image}
          video={video}
          buildClickId={buildClickId}
          tags={tags}
          type={type}
        />
      </StyledContainer>
    </StyledWrapper>
  );
};

export default HeroAboutUs;
