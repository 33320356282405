import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  FeaturesContainer,
  FeaturesList,
  Feature,
  FeatureTitle,
} from './styled';
import { IPricingCard } from '../../types';

export const Features: React.FC<IPricingCard> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data: { card_title: title, checkmark, features },
  itemsLength,
}) => (
  <FeaturesContainer itemsLength={itemsLength || 4}>
    <FeatureTitle>With each subscription you access</FeatureTitle>
    <FeaturesList>
      {features.map(({ feature }) => (
        <Feature key={feature}>
          <div>
            <GatsbyImg image={checkmark} />
          </div>
          <p>{feature}</p>
        </Feature>
      ))}
    </FeaturesList>
  </FeaturesContainer>
);
