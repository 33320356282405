import React from 'react';
import GatsbyImg from 'components/GatsbyImg';
import { Link } from 'components/GatsbyLink';
import { IconDropdownv2 } from 'images';
import {
  HideableBadge,
  StyledCheckmarkListItemWrapper,
  StyledCheckmarkListWrapper,
  StyledImageTitleContainer,
  StyledImageWrapper,
  StyledPriceWrapper,
  StyledProductAccordionBody,
  StyledProductAccordionTrigger,
  StyledProductDescription,
  StyledProductDropdownIconWrapper,
  StyledProductItem,
  StyledTitleContainer,
  StyledTitleDetailsContainer,
} from './styledComponents';
import { IExpandablePricingCardsListItem } from './types';
import { RichText } from 'components/v2/RichText';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { LinkButton } from 'components/v2/LinkButton';
import { Box } from 'components/v2/Box';
import { useSliceDetails } from 'hooks/useSliceDetails';

const ExpandablePricingCardsListItem: React.FC<
  IExpandablePricingCardsListItem
> = ({
  product: {
    checklist,
    product,
    product_description: productDescription,
    learn_more_link: learnMoreLink,
    learn_more_text: learnMoreText,
    product_cta_link: ctaLink,
    product_cta_text: ctaText,
    product_image: productImage,
    product_starting_price: startingPrice,
    price_prefix: pricePrefix,
    badge_label: badgeLabel,
    badge_color: badgeColor = 'red',
  },
  checkmark,
  buildTrackingSelector,
}) => {
  const { id: sliceId } = useSliceDetails();
  const ctaTrackingSelector = buildTrackingSelector({
    context: product.text,
  });
  const learnMoreTrackingSelector = buildTrackingSelector({
    context: `${product.text}-learn-more`,
    type: 'link',
  });
  const accordionTrackingSelector = buildTrackingSelector({
    context: `${product.text}-trigger`,
    type: 'button',
  });
  const normalizedBadgeColor = badgeColor && badgeColor.toLowerCase();
  const handleToggleAccordion = () => {
    logPostHogEvent(accordionTrackingSelector);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: `${product.text}:trigger`,
        sectionId: sliceId,
      });
    }
  };
  const handleLearnMoreClick = () => {
    logPostHogEvent(learnMoreTrackingSelector);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: `${product.text}:learn-more`,
        sectionId: sliceId,
      });
    }
  };
  const handleCtaClick = () => {
    logPostHogEvent(ctaTrackingSelector);
    if (sliceId) {
      logGAElementClickEvent({
        elementId: `${product.text}:cta`,
        sectionId: sliceId,
      });
    }
  };

  const isCtaVisible = !!ctaText && !!ctaLink.url;

  return (
    <StyledProductItem>
      <input type="checkbox" id={product.text} />
      <StyledProductAccordionTrigger
        className="accordion-trigger"
        id={accordionTrackingSelector}
        onClick={handleToggleAccordion}
        htmlFor={product.text}
      >
        <StyledImageWrapper>
          <GatsbyImg image={productImage} />
        </StyledImageWrapper>
        <StyledImageTitleContainer>
          <StyledTitleDetailsContainer>
            <StyledTitleContainer>
              <RichText field={product} />
            </StyledTitleContainer>
            <HideableBadge
              color={normalizedBadgeColor}
              isVisible={!!badgeLabel}
            >
              {badgeLabel}
            </HideableBadge>
          </StyledTitleDetailsContainer>
          {!!startingPrice && (
            <StyledPriceWrapper>
              From: <span className="product-price">${startingPrice}</span>/
              {pricePrefix}
            </StyledPriceWrapper>
          )}
        </StyledImageTitleContainer>
        <StyledProductDropdownIconWrapper className="dropdown-icon">
          <IconDropdownv2 />
        </StyledProductDropdownIconWrapper>
      </StyledProductAccordionTrigger>
      <StyledProductAccordionBody className="accordion-body">
        <div>
          {!!checklist.text && (
            <StyledCheckmarkListWrapper>
              {checklist.richText.map((checklistText) => (
                <StyledCheckmarkListItemWrapper key={checklistText.text}>
                  <GatsbyImg image={checkmark} />
                  <p>{checklistText.text}</p>
                </StyledCheckmarkListItemWrapper>
              ))}
            </StyledCheckmarkListWrapper>
          )}
          {!!productDescription.text && (
            <StyledProductDescription>
              <RichText field={productDescription} />
              {learnMoreText && learnMoreLink.url && (
                <Link
                  data-tracker={learnMoreTrackingSelector}
                  className={learnMoreTrackingSelector}
                  id={learnMoreTrackingSelector}
                  onClick={handleLearnMoreClick}
                  to={learnMoreLink.url}
                >
                  {learnMoreText}
                </Link>
              )}
            </StyledProductDescription>
          )}
          {isCtaVisible && (
            <Box sx={{ marginBottom: '1.5rem' }}>
              <LinkButton
                variant="outlined"
                color="blue"
                to={ctaLink.url}
                data-tracker={ctaTrackingSelector}
                onClick={handleCtaClick}
              >
                {ctaText}
              </LinkButton>
            </Box>
          )}
        </div>
      </StyledProductAccordionBody>
    </StyledProductItem>
  );
};
export default ExpandablePricingCardsListItem;
