import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import RelatedArticles from 'features/RelatedArticles';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { useState } from 'react';
import { DefaultSlice, PrismicTextField, TGatsbyImage } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { blogSliceMap } from './constants';
import {
  BlogPostIntro,
  transformBlogPostData,
  SliceContextProvider,
} from '@smartproxy-web/features';
import {
  ArticlesShortcutsWrapper,
  AsideColumnWrapper,
  BlogWrapper,
  FullwidthWrapper,
  MainColumnWrapper,
} from './styledComponents';
import ArticlesShortcuts, {
  ArticlesShortcutsProps,
} from '@smartproxy-web/features/src/components/Blog/ArticlesShortcuts';

interface IWithPrismicBlog {
  prismicBlog: {
    first_publication_date: Date;
    type: string;
    tags?: string[];
    isGatedContent: boolean;
    data: {
      new_until?: string;
      title: PrismicTextField;
      time_spent_reading?: number;
      release_date: string;
      description: PrismicTextField;
      article_image?: TGatsbyImage;
      article_video?: string;
      blog_v2_enabled?: boolean;
      body: DefaultSlice[];
    };
  };
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

export interface Props {
  data: IWithPrismicBlog;
}

enum RELATED_ARTICLES {
  RelatedArticles = 'related_articles',
}

blogSliceMap[RELATED_ARTICLES.RelatedArticles] = RelatedArticles;

const excludedSlicesWhenHeroV2Enabled = new Set([
  'breadcrumbs',
  'hero_block',
  'table_of_content',
]);

const Blog = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();

  const { prismicBlog } = data;
  const allTemplates = getTemplates<SliceType, DefaultSlice>(
    prismicBlog.data[bodyKey],
    {
      ...sliceMap,
      ...blogSliceMap,
    }
  );
  const isBlogV2Enabled = prismicBlog.data.blog_v2_enabled;
  const templates = isBlogV2Enabled
    ? allTemplates.filter(
        (template) =>
          !excludedSlicesWhenHeroV2Enabled.has(template.slice.slice_type)
      )
    : allTemplates;

  const gatedContentIndex = prismicBlog.data.body.findIndex(
    (item) => item.slice_type === 'gated_content'
  );

  const slicedContent = templates.slice(0, gatedContentIndex + 1);
  const [isGatedContent, setIsGatedContent] = useState(gatedContentIndex > -1);

  const filteredTemplates = isGatedContent ? slicedContent : templates;

  const blogProps = {
    publishDate: prismicBlog.first_publication_date,
    title: prismicBlog.data.title,
    readingTime: prismicBlog.data.time_spent_reading,
    selectedDate: prismicBlog.data.release_date,
    description: prismicBlog.data.description,
    mainImage: prismicBlog.data.article_image,
    isV2: prismicBlog.data.blog_v2_enabled,
    pageContext: buildPageContext({
      context: pageContext,
      tags: prismicBlog.tags,
      type: prismicBlog.type,
      isGatedContent,
      setIsGatedContent,
    }),
  };

  return isBlogV2Enabled ? (
    <BlogV2
      templates={filteredTemplates}
      blogProps={blogProps}
      prismicBlog={prismicBlog}
    />
  ) : (
    <BlogV1 templates={filteredTemplates} blogProps={blogProps} />
  );
};

const BlogV1 = ({ templates, blogProps }) => {
  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component key={key} {...slice} {...blogProps} />
      ))}
    </Wrapper>
  );
};

const BlogV2 = ({ templates, blogProps, prismicBlog }) => {
  const asideComponentsNames = ['side_banner_component'];
  const asideComponents = templates.filter(({ slice }) =>
    asideComponentsNames.includes(slice.slice_type)
  );

  const mainComponentsNames = [
    'author_v2',
    'cta_block',
    'text_block',
    'blog_image',
    'code_block_v2',
    'proxy_table',
    'quote_component',
    'video_block',
  ];
  const mainComponents = templates.filter(({ slice }) =>
    mainComponentsNames.includes(slice.slice_type)
  );

  const restComponents = templates.filter(
    ({ slice }) =>
      !asideComponentsNames.includes(slice.slice_type) &&
      !mainComponentsNames.includes(slice.slice_type)
  );

  const mainIsEmpty = mainComponents.length === 0;
  const restInsteadOfMainComponents = mainIsEmpty
    ? restComponents
    : mainComponents;

  const totalH2 = prismicBlog.data.body.reduce((count, slice) => {
    if (
      slice.slice_type === 'text_block' &&
      slice.primary.text.richText.some((text) => text.type === 'heading2')
    ) {
      count += 1;
    }
    return count;
  }, 0);
  const showSideNavigation = totalH2 > 1 || asideComponents.length > 0;

  return (
    <Wrapper>
      <BlogWrapper>
        <MainColumnWrapper id="main-content" fullwidth={!showSideNavigation}>
          <BlogPostIntro {...transformBlogPostData(prismicBlog)} />
          {restInsteadOfMainComponents.map(({ Component, slice }, key) => (
            <SliceContextProvider
              key={key}
              name={slice.slice_type}
              position={key}
            >
              <Component {...slice} {...blogProps} />
            </SliceContextProvider>
          ))}
        </MainColumnWrapper>
        {showSideNavigation && (
          <AsideColumnWrapper>
            {totalH2 > 1 && (
              <ArticlesShortcutsWrapper>
                <ArticlesShortcuts
                  title="In this article"
                  data={
                    prismicBlog.data
                      .body as unknown as ArticlesShortcutsProps['data']
                  }
                />
              </ArticlesShortcutsWrapper>
            )}
            {asideComponents.map(({ Component, slice }, key) => (
              <SliceContextProvider
                key={key}
                name={slice.slice_type}
                position={key}
              >
                <Component {...slice} {...blogProps} />
              </SliceContextProvider>
            ))}
          </AsideColumnWrapper>
        )}
      </BlogWrapper>
      {!mainIsEmpty && (
        <FullwidthWrapper>
          {restComponents.map(({ Component, slice }, key) => (
            <SliceContextProvider
              key={key}
              name={slice.slice_type}
              position={restInsteadOfMainComponents.length + key}
            >
              <Component {...slice} {...blogProps} />
            </SliceContextProvider>
          ))}
        </FullwidthWrapper>
      )}
    </Wrapper>
  );
};

export const query = graphql`
  query BlogQuery($id: String!) {
    prismicBlog(id: { eq: $id }) {
      _previewable
      tags
      type
      first_publication_date(formatString: "MMMM DD, yyyy")
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        new_until(formatString: "YYYY-MM-DD")
        release_date(formatString: "MMM DD, yyyy")
        time_spent_reading
        title {
          richText
          text
        }
        description {
          richText
          text
        }
        article_image {
          alt
          url
          gatsbyImageData(placeholder: BLURRED)
        }
        article_video
        blog_v2_enabled
        body {
          ... on PrismicBlogDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicBlogDataBodyCodeBlockV2 {
            id
            slice_label
            slice_type
            items {
              code {
                richText
                text
              }
              language
            }
          }
          ... on PrismicBlogDataBodyHeroBlock {
            id
            slice_type
            slice_label
            primary {
              background
            }
          }
          ... on PrismicBlogDataBodyGatedContent {
            id
            slice_type
            slice_label
            primary {
              disclaimer {
                richText
                text
              }
              form_description {
                richText
                text
              }
              form_title {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodyCodeBlock {
            id
            slice_type
            slice_label
            primary {
              copy_option
              code_field {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodySideImageTextBlock {
            id
            slice_type
            slice_label
            primary {
              side_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              text_field {
                richText
                text
              }
              title_field {
                richText
                text
              }
              is_image_right
            }
          }
          ... on PrismicBlogDataBodyCtaBlock {
            id
            primary {
              is_centered
              background
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodyBreadcrumbs {
            id
            slice_type
            slice_label
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicBlogDataBodyTextBlock {
            id
            slice_type
            slice_label
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodySocialButtonsBar {
            id
            slice_type
            slice_label
            primary {
              is_fb_visible
              is_reddit_visible
              is_twitter_visible
              is_linkedin_visible
              is_icons_centered
              text {
                richText
                text
              }
            }
            items {
              topic {
                document {
                  ...TopicItemFragment
                }
              }
            }
          }
          ... on PrismicBlogDataBodyTableOfContent {
            id
            slice_type
            slice_label
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicBlogDataBodyBlogAuthor {
            id
            slice_type
            slice_label
            primary {
              blog_author {
                document {
                  ...BlogPostAuthorFragment
                }
              }
            }
          }
          ... on PrismicBlogDataBodyAuthorV2 {
            id
            slice_type
            slice_label
            primary {
              blog_author {
                document {
                  ...BlogPostAuthorFragment
                }
              }
              is_global
            }
          }
          ... on PrismicBlogDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodyRelatedArticles {
            id
            slice_type
            slice_label
            primary {
              background_color
              title {
                richText
                text
              }
            }
            items {
              article {
                document {
                  ...BlogItemFragment
                }
              }
            }
          }
          ... on PrismicBlogDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodyProxyTable {
            id
            slice_type
            slice_label
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
              title {
                richText
                text
              }
              is_subtitle_centered
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodyIconListBlock {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
                target
              }
              text_field {
                richText
                text
              }
            }
            primary {
              is_text_centered
              column_number
              list_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
            }
            slice_type
            slice_label
          }
          ... on PrismicBlogDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            slice_label
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicBlogDataBodyFooterBannerSlice {
            id
            slice_type
            slice_label
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicBlogDataBodyBiggerPlans {
            id
            slice_type
            slice_label
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicBlogDataBodyComparisonTable {
            id
            slice_label
            items {
              cell_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              cell_value {
                richText
                text
              }
              column
              cta_color
              cta_text
              cta_url {
                url
              }
            }
            primary {
              is_upper_content_centered
              background
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              cta_text
              cta_link {
                url
              }
              fifth_column_header
              fifth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              fourth_column_header
              second_column_header
              sixth_column_header
              fourth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              second_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sixth_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              subtitle {
                richText
                text
              }
              table_text_alignment
              third_column_header
              third_column_header_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicBlogDataBodySideBannerComponent {
            id
            slice_type
            slice_label
            primary {
              background
              side_banner_heading {
                richText
                text
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              title {
                richText
                text
              }
              subtitle {
                richText
              }
              primary_cta_label {
                richText
                text
              }
              primary_cta_link {
                url
              }
              primary_cta_icon_position
              primary_cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              secondary_cta_icon_position
              secondary_cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicBlogDataBodyQuoteComponent {
            id
            slice_type
            slice_label
            primary {
              background
              text {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Blog);
