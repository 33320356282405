import { track } from '@smartproxy/sp-tracker-js';
import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import GatsbyImg from 'components/GatsbyImg';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  ConfirmationDescription,
  ConfirmationTitle,
  Container,
  CtaText,
  LogoWrapper,
  ModalContainer,
  StatusIconWrapper,
  StyledButton,
} from '../styledComponents';
import { IInquiryFormConfirmation } from '../types';
import { RichText } from 'components/v2/RichText';

const InquiryFormConfirmation: React.FC<IInquiryFormConfirmation> = ({
  primary: { cta_text: ctaText, description, icon, logo, title },
  onClick,
}) => {
  const { tags, type } = usePageContext();
  const trackinSelector = buildTrackingSelector({
    page: tags[0] || type,
    section: 'contact-sales',
    context: ctaText,
    type: 'cta',
  });

  return (
    <ModalContainer>
      <LogoWrapper>
        <GatsbyImg image={logo} />
      </LogoWrapper>
      <Container>
        <StatusIconWrapper>
          <GatsbyImg image={icon} />
        </StatusIconWrapper>
        <ConfirmationTitle>
          <RichText field={title} />
        </ConfirmationTitle>
        <ConfirmationDescription>
          <RichText field={description} />
        </ConfirmationDescription>
        <StyledButton
          height={TSHIRT_SIZE.L}
          fontSize={TSHIRT_SIZE.L}
          themeName={BUTTON_THEMES.FOLLOWING}
          themeType={BUTTON_TYPES.PRIMARY}
          onClick={() => {
            onClick();
            track(trackinSelector);
          }}
          data-tracker={trackinSelector}
          className={trackinSelector}
        >
          <CtaText>{ctaText}</CtaText>
        </StyledButton>
      </Container>
    </ModalContainer>
  );
};
export default InquiryFormConfirmation;
