import GatsbyImg from 'components/GatsbyImg';
import { IconCheckmarkHome } from 'images';
import React from 'react';
import hideable from 'utils/hideable';
import {
  FeatureContent,
  FeatureRow,
  FeaturesContainer,
  FeaturesIconWrapper,
  FeatureTitle,
  ImageWrapper,
  StyledTitle,
} from '../styledComponents';
import { IInquiryFormFeatures } from '../types';

const InquiryFormFeatures: React.FC<IInquiryFormFeatures> = ({
  items,
  primary: { title_text, image },
}) => (
  <FeaturesContainer>
    <ImageWrapper>
      <GatsbyImg image={image} />
    </ImageWrapper>
    <FeatureContent>
      <StyledTitle>{title_text}</StyledTitle>
      {items.map(({ feature_item: featureItem }, index) => (
        <FeatureRow key={index}>
          <FeaturesIconWrapper>
            <IconCheckmarkHome />
          </FeaturesIconWrapper>
          <FeatureTitle>{featureItem}</FeatureTitle>
        </FeatureRow>
      ))}
    </FeatureContent>
  </FeaturesContainer>
);

export default hideable(InquiryFormFeatures);
