import { GOOGLE_SCHEMA, SCHEMA_LINKS } from 'constants/common';
import React from 'react';
import { Subtitle, TextContent, FaqItem } from './styledComponents';
import { IItem } from './types';
import { RichText } from 'components/v2/RichText';

const FaqListItem: React.FC<IItem> = ({ subtitle, text: textContent }) => (
  <FaqItem
    itemScope
    itemProp={GOOGLE_SCHEMA.entity}
    itemType={SCHEMA_LINKS.QUESTION}
  >
    <Subtitle itemProp={GOOGLE_SCHEMA.name}>
      <RichText field={subtitle} />
    </Subtitle>
    <div
      itemType={SCHEMA_LINKS.ANSWER}
      itemScope
      itemProp={GOOGLE_SCHEMA.answers}
    >
      <TextContent itemProp={GOOGLE_SCHEMA.text}>
        <RichText field={textContent} />
      </TextContent>
    </div>
  </FaqItem>
);

export default FaqListItem;
