import { IFeatureComparisonTableItem, ITableDataByColumn } from './types';

/**
 * Contrived, but each cell is of the form {column: CellColumn, value: CellValue}
 * We convert that list of cells to the form {column: Column, cells: CellValue[]}[]
 * Each item in the output array is a column and columns contain list of cells that belong to them
 */
export const generateTableFromCells = (
  cells: IFeatureComparisonTableItem[]
) => {
  const data = new Map<string, ITableDataByColumn>();

  for (const cell of cells) {
    const colHeader = cell.column_header;
    const colKey = colHeader?.text ?? '';
    const colAction =
      cell.cta_text && cell.cta_link?.url
        ? { label: cell.cta_text, url: cell.cta_link.url }
        : undefined;
    const col = data.get(colKey);

    if (!col) {
      data.set(colKey, {
        id: colKey ?? 'col',
        column: colHeader,
        rows: [cell],
        action: colAction,
      });
      continue;
    }

    data.set(colKey, {
      ...col,
      rows: [...col.rows, cell],
      action: col.action ?? colAction,
    });
  }

  const columnsData = Array.from(data.values());
  const columns = columnsData.map((columnData) => ({
    action: columnData.action,
    value: columnData.column,
  }));
  const maxNoOfRows = Math.max(...columnsData.map((col) => col.rows.length));
  const rows: IFeatureComparisonTableItem[][] = [];

  let colIndex = 0;
  let rowIndex = 0;

  while (rowIndex < maxNoOfRows) {
    const cell = columnsData[colIndex].rows[rowIndex];

    rows[rowIndex] = rows[rowIndex] ? [...rows[rowIndex], cell] : [cell];

    colIndex++;
    colIndex = colIndex === columnsData.length ? 0 : colIndex;
    if (colIndex === 0) rowIndex++;
  }

  return { columns, rows };
};
