import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { getArticles } from 'pageUtils/blog';
import { IAllPosts } from 'pageUtils/blog/types';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { topicSliceMap } from './constants';
import { getFilteredArticles } from './utils';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicTopic {
  allPrismicBlog: {
    nodes: IAllPosts[];
  };
  prismicTopic: {
    type: string;
    tags?: string[];
    data: {
      topic_label: string;
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicTopic;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const TopicPage = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicTopic, allPrismicBlog } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicTopic.data[bodyKey],
    { ...sliceMap, ...topicSliceMap }
  );
  const topicLabel = prismicTopic.data.topic_label;
  const articles = getArticles(allPrismicBlog.nodes);
  const filteredArticles = getFilteredArticles(articles, topicLabel);

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          articles={filteredArticles}
          topicLabel={topicLabel}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicTopic.tags,
            type: prismicTopic.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query TopicQuery($id: String!) {
    prismicTopic(id: { eq: $id }) {
      _previewable
      tags
      type
      ...TopicFragment
    }
    allPrismicBlog(sort: { fields: data___release_date, order: DESC }) {
      nodes {
        id
        type
        tags
        data {
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          body {
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
              slice_type
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(TopicPage);
