import { track } from '@smartproxy/sp-tracker-js';
import { INPUT_TYPE } from 'components/constants';
import { IconArrowNav, IconInfo } from 'images';
import React from 'react';
import {
  FormButton,
  FormComponent,
  FormInput,
  FormInputContainer,
  FormSelectTarget,
  IconInfoContainer,
} from '../../styledComponents';
import { IForm, IScraperTargetConfig } from '../../types';
import { LocationsDropdownOption } from '../LocationsDropdownOption';
import { TargetsDropdownOption } from '../TargetsDropdownOption';
import FormSelectDropdown from './FormSelectDropdown';
import InfoPopupContainer from './InfoPopupContainer';

const Form: React.FC<IForm> = ({
  activeTarget,
  targets,
  onSubmit,
  setFormField,
  isTargetValuesVisible,
  formField,
  isSubmitDisabled,
  isLocationValuesVisible,
  isPopupVisible,
  handleActiveTargetChange,
  setSubmitDisabled,
  setIsLocationValuesVisible,
  setIsTargetValuesVisible,
  setIsPopupVisible,
  buildClickSelector,
  isRequestSent,
}) => {
  const selectedGeo = activeTarget.locations.find(
    (location) => location.value === formField.geo
  );
  const handleClick = () => {
    setIsPopupVisible((prevState) => !prevState);
    setIsTargetValuesVisible(false);
    setIsLocationValuesVisible(false);
    track(
      buildClickSelector({
        context: 'targets-info-popup',
        type: 'trigger',
      })
    );
  };
  const handleTargetsDropdown = () => {
    setIsPopupVisible(false);
    setIsTargetValuesVisible(!isTargetValuesVisible);
    setIsLocationValuesVisible(false);
    track(buildClickSelector({ context: 'target-dropdown' }));
  };

  const handleLocationsDropdown = () => {
    setIsPopupVisible(false);
    setIsLocationValuesVisible(!isLocationValuesVisible);
    setIsTargetValuesVisible(false);
    track(buildClickSelector({ context: 'location' }));
  };
  return (
    <FormComponent noValidate onSubmit={onSubmit} autoComplete="off">
      <FormInputContainer>
        <InfoPopupContainer
          isVisible={isPopupVisible}
          title={activeTarget.info.title}
          info={activeTarget.info.description}
          isRequestSent={isRequestSent}
        />
        <IconInfoContainer
          onClick={handleClick}
          data-tracker={buildClickSelector({
            context: 'targets-info-popup',
            type: 'trigger',
          })}
        >
          <IconInfo />
        </IconInfoContainer>
        <FormSelectTarget
          isLocationsSelect={false}
          onClick={handleTargetsDropdown}
          valuesVisible={isTargetValuesVisible}
          data-tracker={buildClickSelector({ context: 'target-dropdown' })}
        >
          {activeTarget.label}
          <IconArrowNav />
        </FormSelectTarget>
        <FormSelectDropdown
          isVisible={isTargetValuesVisible}
          options={targets.map((target) => ({
            value: target.value,
            label: target.label,
          }))}
          onChange={(target) => {
            handleActiveTargetChange(
              targets.find((targetItem) => targetItem.value === target)
            );
            setFormField({ ...formField, target });
            setIsTargetValuesVisible(false);
          }}
          selected={formField.target}
          renderOption={(option) => (
            <TargetsDropdownOption
              target={
                targets.find(
                  (target) => target.value === option.value
                ) as IScraperTargetConfig
              }
            />
          )}
          buildClickSelector={buildClickSelector}
        />
      </FormInputContainer>
      <FormInputContainer>
        <FormInput
          placeholder={activeTarget.formConfig.queryField.placeholder}
          value={formField.query}
          disabledSubmitButton={isSubmitDisabled}
          onFocus={() => setSubmitDisabled(false)}
          onChange={(e) =>
            setFormField({ ...formField, query: e.target.value })
          }
          data-tracker={buildClickSelector({
            context: activeTarget.formConfig.queryField.key,
          })}
          onClick={() =>
            track(
              buildClickSelector({
                context: activeTarget.formConfig.queryField.key,
              })
            )
          }
        />
        <FormSelectTarget
          isLocationsSelect
          onClick={handleLocationsDropdown}
          valuesVisible={isLocationValuesVisible}
          data-tracker={buildClickSelector({ context: 'location' })}
        >
          {selectedGeo?.shortLabel}
          <IconArrowNav />
        </FormSelectTarget>
        <FormSelectDropdown
          isVisible={isLocationValuesVisible}
          options={activeTarget.locations}
          onChange={(geo) => {
            setFormField({ ...formField, geo });
            setIsLocationValuesVisible(false);
          }}
          selected={formField.geo}
          renderOption={(option) => (
            <LocationsDropdownOption location={option} />
          )}
          buildClickSelector={buildClickSelector}
        />
      </FormInputContainer>
      <FormButton
        type={INPUT_TYPE.SUBMIT}
        data-tracker={buildClickSelector({ context: 'submit' })}
        onClick={() => track(buildClickSelector({ context: 'submit' }))}
      >
        Send Request
      </FormButton>
    </FormComponent>
  );
};

export default Form;
