import { ContactChannelType, IFormState } from './types';

export const USE_CASE_MAX_CHARS = 500;
export const CONTACT_CHANNEL_MAX_CHARS = 100;
export const PHONE_NUMBER_MAX_CHARS = 50;
export const PHONE_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
export const FREE_DOMAIN_EMAIL_REGEX =
  /^([\w-.]+@(?!datacomp)(?!vusra)(?!zohomail)(?!wetra.life)(?!orange)(?!pussport)(?!o2)(?!herrain)(?!betaalverzoek.cyou)(?!grabmail)(?!afcamail)(?!advew)(?!uniromax)(?!kibwot)(?!ymail)(?!vip.qq)(?!wetra.life)(?!workingtall)(?!riseup)(?!5sword)(?!zooape)(?!seek4wap)(?!trythe)(?!inbox)(?!tuta)(?!macauvpn)(?!pm.me)(?!pm.me)(?!school)(?!tutanota)(?!go)(?!0range)(?!students)(?!activesniper)(?!nhmty)(?!naver)(?!email)(?!me)(?!qq)(?!100xbit)(?!dedatre)(?!alumno)(?!hitmail)(?!pigicorn)(?!ptd)(?!list)(?!yandex)(?!paseacuba)(?!solarunited)(?!msn)(?!live)(?!ya)(?!bk)(?!w3boats)(?!pasecuba)(?!my)(?!hotmail)(?!seatto)(?!jq600)(?!aol)(?!edu)(?!outlook)(?!icloud)(?!yahoo)(?!mail)(?!web)(?!protonmail)(?!gmx)([\w-]+.)+[\w-]{2,4})?$/;

export const initialValues: IFormState = {
  companyEmail: '',
  companyName: '',
  companyPhone: '',
  contactChannel: 'Skype',
  contactChannelId: '',
  countryCode: '',
  fullName: '',
  useCase: '',
  source: 'Website Inquiry',
};

export enum FIELD_NAMES {
  fullName = 'fullName',
  companyEmail = 'companyEmail',
  companyName = 'companyName',
  useCase = 'useCase',
  contactChannel = 'contactChannel',
  contactChannelId = 'contactChannelId',
  countryCode = 'countryCode',
  companyPhone = 'companyPhone',
}

export enum INQUIRY_FORM_SLICES {
  ErrorModal = 'error_modal',
  Features = 'features',
  SuccessModal = 'success_modal',
}

export const contactChannels: ContactChannelType[] = [
  'QQ',
  'Skype',
  'Telegram',
  'WeChat',
  'WhatsApp',
];

export const phoneCodes = [
  1, 7, 20, 27, 30, 31, 32, 33, 34, 36, 39, 40, 41, 43, 44, 45, 46, 47, 48, 49,
  51, 52, 53, 54, 55, 56, 57, 58, 60, 61, 62, 63, 64, 65, 66, 81, 82, 84, 86,
  90, 91, 92, 93, 94, 95, 98, 211, 212, 213, 216, 218, 220, 221, 222, 223, 224,
  225, 226, 227, 228, 229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239,
  240, 241, 242, 243, 244, 245, 246, 248, 249, 250, 251, 252, 253, 254, 255,
  256, 257, 258, 260, 261, 262, 263, 264, 265, 266, 267, 268, 269, 290, 291,
  297, 298, 299, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359, 370, 371,
  372, 373, 374, 375, 376, 377, 378, 379, 380, 381, 382, 385, 386, 387, 389,
  420, 421, 423, 500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 590, 591,
  592, 593, 594, 595, 596, 597, 598, 599, 670, 672, 673, 674, 675, 676, 677,
  678, 679, 680, 681, 682, 683, 685, 686, 687, 688, 689, 690, 691, 692, 850,
  852, 853, 855, 856, 870, 880, 886, 960, 961, 962, 963, 964, 965, 966, 967,
  968, 970, 971, 972, 973, 974, 975, 976, 977, 992, 993, 994, 995, 996, 998,
];

export const phoneCodeOptions = phoneCodes.map((code) => ({
  label: `+${code}`,
  value: `+${code}`,
}));

export const chatServicesOptions = contactChannels.map((channel) => ({
  value: channel,
  label: channel,
}));
