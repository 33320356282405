import { TEXT_LENGTH_VALUES } from 'constants/common';
import GatsbyImg from 'components/GatsbyImg';
import { shortenText } from 'features/utils';
import { Link } from 'components/GatsbyLink';
import { IArticle } from 'pageUtils/blog/types';
import * as React from 'react';
import { getArticleUrl } from 'templates/Topic/utils';
import {
  ArticleContainer,
  ArticleDescription,
  ArticleDescriptionContainer,
  ArticleImageWrapper,
  ArticlesContainer,
  ArticleTitle,
  StyledBackgroundWrapper,
} from './styledComponents';

interface IList {
  articles: IArticle[];
}

const getShortenedDesc = (description: string) =>
  shortenText(description, TEXT_LENGTH_VALUES.TOPIC_ARTICLES_DESCRIPTION);

const List: React.FC<IList> = ({ articles }) => (
  <StyledBackgroundWrapper>
    <ArticlesContainer>
      {articles.map(
        ({ id, tags, type, data: { articleImage, description, title } }) => (
          <ArticleContainer key={id}>
            <ArticleImageWrapper>
              <Link to={getArticleUrl(type, tags)}>
                <GatsbyImg image={articleImage} />
              </Link>
            </ArticleImageWrapper>
            <ArticleDescriptionContainer>
              <Link to={getArticleUrl(type, tags)}>
                <ArticleTitle>{title.text}</ArticleTitle>
              </Link>
              <ArticleDescription>
                {getShortenedDesc(description.text)}
              </ArticleDescription>
            </ArticleDescriptionContainer>
          </ArticleContainer>
        )
      )}
    </ArticlesContainer>
  </StyledBackgroundWrapper>
);

export default List;
