import styled from '@emotion/styled';
import { GatsbyLink } from '@smartproxy-web/features';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { Link } from 'components/GatsbyLink';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  BORDER_PROPS,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  CSS_POSITION,
  getThemeBrandedColor,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputBorderShape,
  getThemeInputHeightShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  getThemeNeutralColor,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';

export const StyledRelatedJobsContainer = styled.div<IColor>(
  ({ background, ...props }) => ({
    background,
    margin: `${getThemeGutter(props, TSHIRT_SIZE.XL)} auto 0`,
    padding: '80px 20px',
    width: '100%',
    display: CSS_DISPLAY.FLEX,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.CENTER,

    '> div': {
      maxWidth: '1024px',
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      ul: {
        marginTop: getThemeGutter(props, TSHIRT_SIZE.L),
      },
    },
  })
);

export const StyledRelatedJobsHeading = styled.div((props) => ({
  'h1, h2, h3': {
    marginTop: getThemeGutter(props, TSHIRT_SIZE.XXS),
    marginBottom: 0,
    '+ *, + * *': {
      margin: `${getThemeGutter(props, TSHIRT_SIZE.XS)} 0 0 0`,
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
}));

export const StyledJobsList = styled.ul((props) => ({
  listStyle: 'none',
  padding: 0,

  'li:last-of-type': {
    borderBottom: `1px solid ${getThemeNeutralColor(
      props,
      COLOR_SHADE.INVISIBLE
    )}`,
  },
}));

export const StyledJobsListItem = styled.li((props) => ({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.S)} 0`,
  borderTop: `1px solid ${getThemeNeutralColor(props, COLOR_SHADE.INVISIBLE)}`,

  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    gap: getThemeGutter(props, TSHIRT_SIZE.S),
  },

  '> div:last-of-type': {
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '100%',
    },
  },
}));

export const StyledJobsListItemDetails = styled(Link)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  p: {
    margin: 0,

    '&:nth-of-type(1)': {
      color: getThemeMainColor(props, MAIN_COLOR.BLACK),
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 500,
    },
    '&:nth-of-type(2)': {
      marginTop: '6px',
      color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      lineHeight: getThemeFontSize(props, TEXT_TYPE.P1),
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    alignItems: 'center',
  },
}));

export const StyledCta = styled(GatsbyLink)((props) => ({
  background: 'transparent',
  color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
  fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
  padding: '11px 15px',
  margin: 0,
  height: getThemeInputHeightShape(props, TSHIRT_SIZE.M),
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  position: CSS_POSITION.RELATIVE,
  minWidth: '85px',

  '&::before': {
    borderRadius: getThemeInputBorderShape(props, BORDER_PROPS.RADIUS),
    content: '""',
    position: CSS_POSITION.ABSOLUTE,
    width: '100%',
    height: '100%',
    border: `1px solid ${getThemeBrandedColor(props, COLOR_SHADE.PRIMARY)}`,
  },
  [DOM_STATE.HOVER]: {
    background: 'transparent',
    padding: '11px 15px',

    '&::before': {
      borderWidth: '2px',
    },
  },

  div: {
    display: CSS_DISPLAY.FLEX,
    alignItems: CSS_ALIGN.CENTER,
    gap: '6px',

    svg: {
      transform: 'rotate(-90deg)',
      width: '12px',

      path: {
        fill: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      },
    },
  },
}));
