import styled from '@emotion/styled';
import { StyledBaseSection } from 'components/Section';
import { Widget } from '@typeform/embed-react';

export const StyledTypeformEmbedContainer = styled(StyledBaseSection)(
  ({ shouldContentBleed }) => ({
    padding: shouldContentBleed ? 0 : undefined,
  })
);

export const StyledTypeformWidget = styled(Widget)({
  height: '100vh',
  width: '100%',
});
