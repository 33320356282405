import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  BackgroundContainer,
  LinksList,
  LinksListItem,
} from './styledComponents';
import { IColumnLinks } from './types';
import { RichText } from 'components/v2/RichText';

const HideableRichText = hideable(RichText);

const ColumnLinks: React.FC<IColumnLinks> = ({
  primary: { background, number_of_columns: columns },
  items,
  pageContext,
}) => {
  const { tags, type } = pageContext || { tags: [] };
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'columns-links',
    prefix: type,
  });

  return (
    <BackgroundContainer background={background}>
      <LinksList columns={columns}>
        {items.map(({ title, text }, idx) => (
          <LinksListItem
            key={idx}
            isTitleVisible={!!title.text}
            data-tracker={trackingSelector({ context: text.text })}
          >
            <HideableRichText field={title} isVisible={title.text} />
            <div
              onClick={() => track(trackingSelector({ context: text.text }))}
            >
              <RichText field={text} />
            </div>
          </LinksListItem>
        ))}
      </LinksList>
    </BackgroundContainer>
  );
};

export default ColumnLinks;
