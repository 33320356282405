import { getLocalStorageItem } from 'utils/browserStorage';

export const shouldScriptLoad = (callback: () => void) => {
  const isEmailAdded = !!getLocalStorageItem('gated-content');
  if (!isEmailAdded) {
    const script = document.createElement('script');
    script.src = 'https://smartproxy.com/activehosted/f/embed.php?id=9';
    document.body.appendChild(script);
    script.onload = () => callback();
  }
};
