import styled from '@emotion/styled';
import { GatsbyLink } from '@smartproxy-web/features';
import { flexColContainerStyles, HideOnMobile } from 'commonStyles';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const StyledHeroWrapper = styled.div<IColor & ITheme>((props) => ({
  background: themev2.colors.black.S900,
  overflow: 'hidden',
  padding: `${getThemeGutter(props, TSHIRT_SIZE.XXXL)} 20px`,
  height: 'auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.TABLET]: {
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  },
}));

export const StyledHeroContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.XXXL),
  gridTemplateColumns: '1fr 420px',
  [MEDIA_QUERIES.TABLET]: {
    alignItems: CSS_ALIGN.START,
    rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
    gridTemplateColumns: '1fr',
    columnGap: getThemeGutter(props, TSHIRT_SIZE.XXS),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const HeroTextBlock = styled.div<ITheme & { accentColor: string }>(
  (props) => ({
    gridColumn: 1 / 2,
    gridRow: 1 / 2,
    display: CSS_DISPLAY.FLEX,
    width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
    height: 'auto',
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    alignItems: CSS_ALIGN.FLEX_START,
    a: {
      button: {
        minWidth: '232px',
        fontFamily: getThemeFontFamily(props),
        fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
        lineHeight: '20px',
        margin: 0,
        padding: '15px 0',
        [DOM_STATE.HOVER]: {
          padding: '15px 0',
        },
      },
      width: 'auto',
      height: 'auto',
      color: getThemeMainColor(props),
    },
    [MEDIA_QUERIES.TABLET]: {
      marginTop: 0,
      width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      alignItems: CSS_ALIGN.CENTER,
      order: 1,
      maxWidth: '626px',
      margin: '0 auto',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      maxWidth: 'unset',
      a: {
        margin: 0,
      },
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      a: {
        width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
      },
    },
  })
);
export const ImageWrapper = styled.div({
  div: {
    height: '100%',
    width: '100%',
  },
  [MEDIA_QUERIES.TABLET]: {
    ...HideOnMobile,
  },
});
export const HeroTitle = styled.div<{ accentColor: string }>(
  ({ accentColor, ...props }) => ({
    'h1, h2, h3': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      color: accentColor
        ? accentColor
        : getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
      textAlign: TEXT_ALIGN.LEFT,
      fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H2),
      [MEDIA_QUERIES.TABLET]: {
        marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
        textAlign: CSS_ALIGN.CENTER,
      },
    },
  })
);

export const HeroDescription = styled.div<{ accentColor: string }>(
  ({ accentColor, ...props }) => ({
    p: {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
      color: getThemeMainColor(props),
      lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
      fontFamily: getThemeFontFamily(props),
      fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
      a: {
        color: accentColor,
      },
      [MEDIA_QUERIES.TABLET]: {
        width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
        textAlign: CSS_ALIGN.CENTER,
      },
    },
  })
);

export const Jobs = styled.div((props) => ({
  ...flexColContainerStyles,
  width: '100%',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.L),
}));

export const JobLink = styled(GatsbyLink)((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: '100%',
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  alignItems: CSS_ALIGN.CENTER,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.XS)} 0`,
  ':first-of-type': {
    borderTop: '1px solid rgba(255, 255, 255, .1)',
  },
  borderBottom: '1px solid rgba(255, 255, 255, .1)',
  'h1,h2,h3,h4,h5,h6': {
    margin: 0,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H4),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.H4),
    fontWeight: 500,
    color: getThemeMainColor(props, MAIN_COLOR.WHITE),
  },
  'p, em, strong': {
    margin: 0,
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
  },
  p: {
    marginTop: '6px',
  },
}));
export const HideableJobs = hideable(Jobs);
