export const SeoLinks = [
  {
    rel: 'dns-prefetch',
    href: 'https://cdn.livechatinc.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.googletagmanager.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.google-analytics.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://youtube.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.google.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://connect.facebook.net',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.googleadservices.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://bat.bing.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://snap.licdn.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://px.ads.linkedin.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://googleads.g.doubleclick.net',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.linkedin.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://www.facebook.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://static.doubleclick.net',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://yt3.ggpht.com',
  },
  {
    rel: 'dns-prefetch',
    href: 'https://i.ytimg.com',
  },
];
