import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import { CtaBlockWrapper, CtaBlockContainer } from './styledComponents';
import { ICtaBlock } from './types';
import { usePageContext } from 'contexts/PageContext';
import { useSliceDetails } from 'hooks/useSliceDetails';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { LinkButton } from 'components/v2/LinkButton';

const CtaBlock: React.FC<ICtaBlock> = ({
  primary: {
    background,
    is_centered: isCentered,
    cta_link: ctaLink,
    cta_label: ctaLabel,
  },
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'Cta-block',
    prefix: type,
    context: ctaLabel.text,
  });

  return (
    <CtaBlockContainer background={background}>
      <CtaBlockWrapper isCentered={isCentered}>
        <LinkButton
          fullWidthOnMobile
          color="blue"
          variant="outlined"
          to={ctaLink.url}
          data-tracker={trackingSelector}
          onClick={() => {
            logPostHogEvent(trackingSelector);
            if (sliceId) {
              logGAElementClickEvent({
                elementId: ctaLabel.text,
                sectionId: sliceId,
              });
            }
          }}
        >
          {ctaLabel.text}
        </LinkButton>
      </CtaBlockWrapper>
    </CtaBlockContainer>
  );
};

export default CtaBlock;
