import styled from '@emotion/styled';
import { TEXT_ALIGN, TSHIRT_SIZE } from 'components/constants';
import { getThemeInputPaddingShape, ITheme } from 'theme';
import { IColor } from 'types';

export const TitleWrapper = styled.div<
  IColor & ITheme & { isCentered: boolean }
>(({ background, isCentered, ...props }) => ({
  background,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  width: '100%',
  maxWidth: '1024px',
  textAlign: isCentered ? TEXT_ALIGN.CENTER : TEXT_ALIGN.LEFT,
  'h1, h2, h3, h4, h5, h6': {
    margin: 0,
  },
}));
