export enum Paths {
  home_page = '/',
  case_studies = '/case-studies',
  blog_page = '/blog',
  about = '/about',
  why_smartproxy = '/why-smartproxy',
  proxy_affiliate_program = '/proxy-affiliate-program',
  smartproxy_quick_start_guide = '/smartproxy-quick-start-guide',
  how_it_works = '/how-it-works',
  careers_page = '/careers',
}

export enum TemplatePaths {
  proxies = '/proxies',
  careers_job_post = '/careers',
  blog = '/blog',
  topic = '/',
  apps = '/apps',
  case_post = '/case-studies',
  legal = '/legal',
  questions = '/questions',
  faq = '/faq',
  deals = '/deals',
  integrations = '/integrations',
  lp = '/lp',
  proxy_list = '/proxy-list',
  what_is_my_ip = '/what-is-my-ip',
  what_is_web_scraping = '/what-is-web-scraping',
  what_is_a_proxy = '/what-is-a-proxy',
  ru = '/ru',
  scraping = '/scraping',
  targets = '/targets',
  partners_guide = '/partners-guide',
  use_cases = '/use-cases',
  knowledge_hub_landing = '/devs',
  coupons = '/coupons',
  glossary = '/glossary',
  smartproxy_quick_start_guide_pages = '/smartproxy-quick-start-guide',
}
