import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import FormComponent from './components/FormComponent';
import {
  Description,
  StyledDate,
  Subtitle,
  Title,
  WebinarRegistrationBlockContainer,
  WebinarRegistrationBlockWrapper,
  WebinarRegistrationFormContainer,
  WebinarRegistrationTextContainer,
} from './styledComponents';
import { IWebinarRegistrationForm } from './types';
import { usePageContext } from 'contexts/PageContext';
import { buildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';

const WebinarRegistrationForm: React.FC<IWebinarRegistrationForm> = ({
  primary: {
    title,
    subtitle,
    description,
    webinar_info: info,
    background,
    form_title: formTitle,
    webinar_date: date,
  },
}) => {
  const { tags, type } = usePageContext();

  const sectionTrackingId = buildTrackingSelector({
    page: tags[0] || type,
    section: SECTION_NAMES.WEBINAR_REGISTRATION_FORM,
  });

  return (
    <WebinarRegistrationBlockWrapper
      background={background}
      id={sectionTrackingId}
      data-section-id={sectionTrackingId}
    >
      <WebinarRegistrationBlockContainer>
        <WebinarRegistrationTextContainer>
          <Title>
            <NewRichText text={title} />
          </Title>
          <Subtitle>
            <NewRichText text={subtitle} />
          </Subtitle>
          <StyledDate>
            <NewRichText text={date} />
          </StyledDate>
          <Description>
            <NewRichText text={description} />
          </Description>
        </WebinarRegistrationTextContainer>
        <WebinarRegistrationFormContainer>
          <FormComponent
            title={formTitle}
            info={info.text}
            date={date}
            formButtonId={title.text}
          />
        </WebinarRegistrationFormContainer>
      </WebinarRegistrationBlockContainer>
    </WebinarRegistrationBlockWrapper>
  );
};
export default WebinarRegistrationForm;
