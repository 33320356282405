import { SCRAPER_VALUES } from 'constants/common';
import { IconError, IconSuccess } from 'images';
import React from 'react';
import {
  HideableMessageContainer,
  MessageStatus,
  MessageText,
} from '../styledComponents';
import { IMessage } from '../types';

const Messages: React.FC<IMessage> = ({ showMessage, isSuccess, isError }) => (
  <HideableMessageContainer isVisible={showMessage}>
    {isSuccess && (
      <>
        <IconSuccess />
        <MessageStatus>{SCRAPER_VALUES.FORM_SUCCESS_MESSAGE}</MessageStatus>
        <MessageText>{SCRAPER_VALUES.FORM_SUCCESS_TEXT}</MessageText>
      </>
    )}
    {isError && (
      <>
        <IconError />
        <MessageStatus>{SCRAPER_VALUES.FORM_ERROR_MESSAGE}</MessageStatus>
        <MessageText>{SCRAPER_VALUES.FORM_ERROR_TEXT}</MessageText>
      </>
    )}
  </HideableMessageContainer>
);

export default Messages;
