import RelatedArticles from 'features/RelatedArticles';
import { SliceMap } from 'types';

export enum SCRAPING_SLICES {
  RelatedArticles = 'related_articles',
}

export const scrapingSliceMap: SliceMap<SCRAPING_SLICES> = {
  [SCRAPING_SLICES.RelatedArticles]: RelatedArticles,
};
