import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicAbout {
  prismicAbout: {
    type: string;
    tags?: string[];
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicAbout;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const About = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicAbout } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicAbout.data[bodyKey],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicAbout.tags,
            type: prismicAbout.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicAboutDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicAboutDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicAboutDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicAboutDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicAboutDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              cta_color
              is_split_equally
              video_embed
              title_color
              is_background_dark
              image_zoom_modal
              is_image_right
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
              columns_number
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyVisionAndMission {
            id
            slice_type
            items {
              description {
                text
                richText
              }
              icon {
                alt
                gatsbyImageData
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              description {
                richText
                text
              }
              is_background_dark
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyBadgesBlock {
            id
            primary {
              background
              accent_color
              is_text_centered
              is_image_on_left
              is_text_white
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
            slice_type
            items {
              icon {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicAboutDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicAboutDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicAboutDataBodyTestimonials {
            id
            items {
              description {
                richText
                text
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
              name {
                richText
                text
              }
              role {
                richText
                text
              }
            }
            primary {
              background
              is_text_light
              for_id_wrapper {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicAboutDataBodyHeroAboutUs {
            id
            slice_type
            primary {
              video_embed
              accent_color
              block_one_text {
                richText
                text
              }
              block_one_title {
                richText
                text
              }
              block_two_text {
                richText
                text
              }
              block_two_title {
                richText
                text
              }
              description {
                richText
                text
              }
              hero_image {
                alt
                gatsbyImageData
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                gatsbyImageData
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyRecognisedByBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title_for_logos {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyTextAndCta {
            id
            slice_type
            primary {
              accent_color
              background
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              is_background_dark
              is_text_centered
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicAboutDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              accent_color
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicAboutDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicAboutDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(About);
