import { SECTION_NAMES, USE_CASES_BLOCK } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import ColumnItem from './ColumnItem';
import {
  Container,
  ListItem,
  Subtitle,
  TitleWrapper,
  UseCasesContainer,
} from './styledComponents';
import { IUseCasesBlock } from './types';
import { RichText } from 'components/v2/RichText';
import { usePageContext } from 'contexts/PageContext';

const HideableSubtitle = hideable(Subtitle);

const UseCasesBlock: React.FC<IUseCasesBlock> = ({
  primary: {
    background_color: background,
    main_title: mainTitle,
    subtitle,
    is_subtitle_centered: isSubtitleCentered,
    column_title_1: column1,
    column_title_2: column2,
    column_title_3: column3,
    number_of_columns: numbOfColumns,
  },
  items,
}) => {
  const { tags, type } = usePageContext();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.USE_CASES,
    prefix: type,
  });

  return (
    <Container
      background={background}
      data-section-id={generateSectionsId(SECTION_NAMES.USE_CASES, tags, type)}
    >
      <TitleWrapper isSubtitleAdded={!!subtitle?.text}>
        <RichText field={mainTitle} />
      </TitleWrapper>
      <HideableSubtitle
        isVisible={subtitle?.text}
        isSubtitleCentered={isSubtitleCentered}
      >
        <RichText field={subtitle} />
      </HideableSubtitle>
      <UseCasesContainer numbOfColumns={numbOfColumns}>
        {[
          USE_CASES_BLOCK.MARKET,
          USE_CASES_BLOCK.MEDIA,
          USE_CASES_BLOCK.RETAIL,
        ].map((useCase, index) => (
          <ListItem key={useCase}>
            <RichText field={[column1, column2, column3][index]} />
            {items.map(({ group_name: groupName, ...item }) =>
              groupName === useCase ? (
                <ColumnItem
                  key={item.subtitle?.text}
                  {...item}
                  selector={trackingSelector({
                    context: `${useCase}-${item.subtitle?.text || ''}`,
                  })}
                />
              ) : null
            )}
          </ListItem>
        ))}
      </UseCasesContainer>
    </Container>
  );
};

export default UseCasesBlock;
