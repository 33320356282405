import { Button } from 'components/v2/Button';
import { usePageContext } from 'contexts/PageContext';
import { IconClosev2, IconMenuv2 } from 'images';
import React, { useEffect, useRef, useState } from 'react';
import hideable from 'utils/hideable';
import { initBuildTrackingSelector } from 'utils/page';
import {
  initDataLayer,
  prepareNavCategories,
  prepareNavLanguages,
  prepareNavLinks,
} from './helpers';
import HeaderLogo from './Nav/Logo';
import { headerQuery } from './query';
import {
  StyledHeader,
  StyledHeaderContents,
  StyledHeaderNavToggle,
} from './styledComponents';
import { HeaderProps } from './types';
import { Box } from 'components/v2/Box';
import Nav from './Nav/Nav';

const Header: React.FC<HeaderProps> = ({ location }) => {
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const navToggleElRef = useRef<HTMLButtonElement>(null);

  const {
    prismicHeaderV2: { data: prismicHeaderData },
  } = headerQuery();

  const { logo } = prismicHeaderData;
  const navCategories = prepareNavCategories(prismicHeaderData.nav_categories);
  const navLanguages = prepareNavLanguages(prismicHeaderData.languages);
  const navLinks = prepareNavLinks(prismicHeaderData.nav_links);
  const navCategoriesWithLanguages = navCategories.concat(navLanguages);

  const { type, tags } = usePageContext();
  const buildTrackingId = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'header',
  });

  const logoTrackerId = buildTrackingId({ context: 'logo', type: 'link' });

  const hamburgerTrackerId = buildTrackingId({
    context: 'hamburger',
    type: 'button',
  });

  const toggleDrawer = (isOpen?: boolean) => {
    setIsNavDrawerOpen((prevState) => {
      const nextState = isOpen ?? !prevState;
      if (!nextState) {
        navToggleElRef.current?.focus();
      }
      return nextState;
    });
  };

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        toggleDrawer(false);
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <StyledHeader>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <StyledHeaderContents>
          <HeaderLogo
            logo={logo}
            logoTrackerId={logoTrackerId}
            isClickable={true}
          />
          <StyledHeaderNavToggle>
            <Button
              ref={navToggleElRef}
              variant="text"
              color="white"
              onClick={() => {
                toggleDrawer(), initDataLayer(hamburgerTrackerId);
              }}
              aria-expanded={isNavDrawerOpen}
              aria-controls="header-nav-menu"
              data-tracker={hamburgerTrackerId}
            >
              {isNavDrawerOpen ? <IconClosev2 /> : <IconMenuv2 />}
            </Button>
          </StyledHeaderNavToggle>
          <Nav
            location={location}
            categories={navCategoriesWithLanguages}
            languages={navLanguages}
            links={navLinks}
            isOpen={isNavDrawerOpen}
            toggleOpen={toggleDrawer}
          />
        </StyledHeaderContents>
      </Box>
    </StyledHeader>
  );
};

export default hideable(Header);
