import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import {
  FormSelectDropdownBox,
  FormSelectDropdownValue,
} from '../../styledComponents';
import { IDropdownSelect } from '../../types';

const FormSelectDropdown: React.FC<IDropdownSelect> = ({
  options,
  onChange = () => {},
  selected,
  isVisible,
  renderOption,
  buildClickSelector,
}) => (
  <FormSelectDropdownBox isVisible={isVisible}>
    {options.map((option) => (
      <FormSelectDropdownValue
        key={option.value}
        onClick={() => {
          onChange(option.value);
          track(buildClickSelector({ context: option.label }));
        }}
        selected={selected === option.value}
        className={buildClickSelector({ context: option.label })}
        id={buildClickSelector({ context: option.label })}
        data-tracker={buildClickSelector({ context: option.label })}
      >
        {renderOption ? renderOption(option) : <span>{option.label}</span>}
      </FormSelectDropdownValue>
    ))}
  </FormSelectDropdownBox>
);

export default FormSelectDropdown;
