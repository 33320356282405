import { LinksListItem } from 'features/ColumnsLinks/styledComponents';
import React from 'react';
import hideable from 'utils/hideable';
import { ipCheckerMsgs } from '../ipCheckerMsgs';
import { IIpInfo } from '../types';
import ListItem from './ListItem';

const PlatformColumn: React.FC<IIpInfo> = ({ info }) => {
  const { ipCheckerPlatform, ipCheckerPlatformName, ipCheckerPlatformOs } =
    ipCheckerMsgs;

  return (
    <LinksListItem>
      <h2>{ipCheckerPlatform.defaultMessage}</h2>
      <ListItem
        text={ipCheckerPlatformName.defaultMessage}
        info={info.platform?.name}
      />
      <ListItem
        text={ipCheckerPlatformOs.defaultMessage}
        info={info.platform?.os}
      />
    </LinksListItem>
  );
};

export default hideable(PlatformColumn);
