import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { useHasMounted } from 'hooks/useHasMounted';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { IWithPrismicFaq } from './types';
import { getQuestions } from './utils';
import { sliceMap, SliceType } from 'features';
import { faqSliceMap } from './constants';

export interface Props {
  data: IWithPrismicFaq;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const Faq = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicFaq, allPrismicQuestions } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicFaq.data[bodyKey],
    { ...sliceMap, ...faqSliceMap }
  );

  const hasMounted = useHasMounted();

  if (!hasMounted) return null;

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          {...slice}
          articles={getQuestions(allPrismicQuestions.nodes)}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicFaq.tags,
            type: prismicFaq.type,
          })}
        />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(Faq);

export const query = graphql`
  query FaqQuery($id: String!) {
    prismicFaq(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicFaqDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicFaqDataBodyBreadcrumbs {
            id
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
            primary {
              text_color
            }
            slice_type
          }
          ... on PrismicFaqDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicFaqDataBodyColumnLinks {
            id
            items {
              text {
                text
                richText
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              number_of_columns
            }
            slice_type
          }
          ... on PrismicFaqDataBodyFaqQuestions {
            id
            primary {
              title {
                richText
                text
              }
            }
            items {
              question_document {
                tags
                type
                document {
                  ...QuestionsFragment
                }
              }
            }
            slice_type
          }
          ... on PrismicFaqDataBodyFaqText {
            id
            items {
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background
              main_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicFaqDataBodyHeroBlock {
            id
            slice_type
            primary {
              background
              accent_color
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicFaqDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
    allPrismicQuestions {
      nodes {
        id
        type
        tags
        data {
          question_title {
            text
            richText
          }
        }
      }
    }
  }
`;
