import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import { PrismicTextField, TGatsbyImage } from 'types';
import {
  ArticleauthorName,
  ArticleAuthorsContainer,
  ArticleAuthorsImageWrapper,
  ArticleAuthorsInfoContainer,
  ArticleReleaseDate,
} from './styledComponents';

interface IArticlesAuthor {
  authorName: PrismicTextField;
  authorImage: TGatsbyImage;
  releaseDate: string;
}
export const ArticleAuthorItem: React.FC<IArticlesAuthor> = ({
  authorImage,
  authorName,
  releaseDate,
}) => (
  <ArticleAuthorsContainer>
    <ArticleAuthorsImageWrapper>
      <GatsbyImg image={authorImage} />
    </ArticleAuthorsImageWrapper>
    <ArticleAuthorsInfoContainer>
      <ArticleauthorName>{authorName.text}</ArticleauthorName>
      <ArticleReleaseDate>{releaseDate}</ArticleReleaseDate>
    </ArticleAuthorsInfoContainer>
  </ArticleAuthorsContainer>
);
