import { IconMoneyBackCheckmarkv2 } from 'images';
import { StyledMoneyBackContainer } from './styledComponents';
import hideable from 'utils/hideable';
import { MONEY_BACK_OPTION_TEXT } from 'features/AutomatedPricingCards/constants';

const MoneyBackTag = ({ isOnHomepage = false }) => (
  <StyledMoneyBackContainer isOnHomepage={isOnHomepage}>
    <IconMoneyBackCheckmarkv2 />
    <span>{MONEY_BACK_OPTION_TEXT}</span>
  </StyledMoneyBackContainer>
);

export default hideable(MoneyBackTag);
