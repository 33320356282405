import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_JUSTIFY_CONTENT,
  getThemeFontLineHeight,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  getThemeNeutralColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import hideable from 'utils/hideable';

export const BiggerPlansContainer = styled.div<ITheme>((props) => ({
  background: getThemeMainColor(props, MAIN_COLOR.WHITE),
  width: '100%',
  maxWidth: '1024px',
  display: CSS_DISPLAY.GRID,
  gridTemplateColumns: '60px 1fr 200px',
  padding: getThemeGutter(props, TSHIRT_SIZE.S),
  marginTop: getThemeGutter(props, TSHIRT_SIZE.XS),
  alignItems: CSS_ALIGN.CENTER,
  columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
  borderRadius: '12px',
  boxShadow: `0 0 ${getThemeGutter(props, TSHIRT_SIZE.S)} 0 rgba(0, 0, 0, .1)`,
  zIndex: 1,
  'h1, h2, h3': {
    textAlign: TEXT_ALIGN.LEFT,
    fontSize: getThemeFontSize(props, TEXT_TYPE.H3),
    lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.P1),
    fontWeight: '500',
  },
  p: {
    marginTop: '6px',
    color: getThemeNeutralColor(props, COLOR_SHADE.SECONDARY),
  },
  [MEDIA_QUERIES.TABLET]: {
    width: '50%',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, auto)',
    justifyItems: CSS_JUSTIFY_CONTENT.CENTER,
    textAlign: TEXT_ALIGN.CENTER,
    rowGap: '24px',
    'h1, h2, h3': {
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.XS),
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: '80%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
    rowGap: '12px',
  },
}));

export const ImageWrapper = styled.div({
  width: '60px',
  height: '60px',
});

export const StyledCtaContainer = styled.div({
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
});
export const StyledCta = styled(Cta)((props) => ({
  width: '200px',
  height: 44,
  padding: `${getThemeGutter(props, TSHIRT_SIZE.XS)} ${getThemeGutter(
    props,
    TSHIRT_SIZE.S
  )}`,
  margin: 0,
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
}));

export const Wrapper = styled.div<ITheme & { background: string }>(
  ({ background }) => ({
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    width: '100%',
    padding: '0 20px',
    background,
  })
);

export const AdditionalCtaContainer = styled.div<ITheme>((props) => ({
  marginTop: getThemeInputPaddingShape(props, TSHIRT_SIZE.XL),
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    width: '100%',
  },
}));

export const HideableAdditionalCtaContainer = hideable(AdditionalCtaContainer);

export const AdditionalCta = styled(Cta)<ITheme>((props) => ({
  minWidth: '180px',
  margin: '0',
  fontSize: getThemeFontSize(props, TEXT_TYPE.P2),
}));
