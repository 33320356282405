import spTracker from '@smartproxy/sp-tracker-js';

export const getFeatureFlagValue = <T extends string>(flag: string) => {
  const value = spTracker.getFeatureFlag<T>(flag);

  if (flag.includes('experiment') && value !== undefined) {
    spTracker.track('experiment_impression', {
      exp_experiment_id: flag,
      exp_variant_id: value === 'control' ? 0 : 1,
      variant_id: `${value}`,
      event_label: `${value}`,
    });
  }

  return value;
};

export function trackExperimentImpresion(flag: string, value: string) {
  if (flag.includes('experiment') && value !== undefined) {
    spTracker.track('experiment_impression', {
      exp_experiment_id: flag,
      exp_variant_id: value === 'control' ? 0 : 1,
      variant_id: `${value}`,
      event_label: `${value}`,
    });
  }
}
