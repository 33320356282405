import {
  PRISMIC_VALUES,
  SECTION_NAMES,
  TEXT_LENGTH_VALUES,
} from 'constants/common';
import { ArticlesList } from 'features/AllArticles/ArticlesList';
import { generateSectionsId } from 'features/utils';
import { TemplatePaths } from 'paths';
import * as React from 'react';
import {
  RelatedArticlesContainer,
  StyledBackgroundWrapper,
  ThumbnailWrapper,
} from '../../ArticleThumbnails/styledComponents';
import { CtaContainer } from '../Blog/styledComponents';
import { IBlogPosts } from './types';
import { buildTrackingSelector } from 'utils/page';
import { RichText } from 'components/v2/RichText';
import { Box } from 'components/v2/Box';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { usePageContext } from 'contexts/PageContext';
import { useSliceDetails } from 'hooks/useSliceDetails';
import { LinkButton } from 'components/v2/LinkButton';

const HomepageBlogPosts: React.FC<IBlogPosts> = ({
  primary: { title1: title, background },
  articles,
}) => {
  const { id: sliceId } = useSliceDetails();
  const { tags, type } = usePageContext();
  const trackingSelector = buildTrackingSelector({
    page: tags[0] || type,
    context: PRISMIC_VALUES.VISIT_BLOG,
    type: 'cta',
  });
  return (
    <StyledBackgroundWrapper
      background={background}
      data-section-id={generateSectionsId(
        SECTION_NAMES.RELATED_ARTICLES,
        tags,
        type
      )}
    >
      <RelatedArticlesContainer>
        <Box sx={{ textAlign: 'center', marginBottom: '60px' }}>
          <RichText field={title} />
        </Box>
        <ThumbnailWrapper>
          <ArticlesList
            articles={articles}
            descriptionLength={TEXT_LENGTH_VALUES.HOMEPAGE_BLOG}
            isHomepage={true}
          />
        </ThumbnailWrapper>
        <CtaContainer>
          <LinkButton
            color="blue"
            variant="outlined"
            to={TemplatePaths.blog}
            onClick={() => {
              logPostHogEvent(trackingSelector);
              if (sliceId) {
                logGAElementClickEvent({
                  elementId: PRISMIC_VALUES.VISIT_BLOG,
                  sectionId: sliceId,
                });
              }
            }}
          >
            {PRISMIC_VALUES.VISIT_BLOG}
          </LinkButton>
        </CtaContainer>
      </RelatedArticlesContainer>
    </StyledBackgroundWrapper>
  );
};

export default HomepageBlogPosts;
