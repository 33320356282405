import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { IpCheckerSliceMap } from 'pageUtils/what-is-my-ip/constant';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';

interface IWithPrismicWhatIsMyIp {
  prismicWhatIsMyIp: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicWhatIsMyIp;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const IpCheckerPage = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicWhatIsMyIp } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicWhatIsMyIp.data[bodyKey],
    { ...sliceMap, ...IpCheckerSliceMap }
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicWhatIsMyIp.tags,
            type: prismicWhatIsMyIp.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query WhatIsMyIpQuery {
    prismicWhatIsMyIp {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicWhatIsMyIpDataBodyBreadcrumbs {
            id
            slice_label
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsMyIpDataBodyHeroBlock {
            id
            slice_label
            slice_type
            primary {
              accent_color
              button_color
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              link_label_start {
                text
                richText
              }
              link_to_start {
                url
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsMyIpDataBodyImageTextBlock {
            id
            slice_label
            slice_type
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              cta_color
              is_split_equally
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_zoom_modal
              is_image_right
              review_bottom_text {
                richText
                text
              }
              review_description
              review_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsMyIpDataBodyFaqBlock {
            id
            slice_label
            slice_type
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicWhatIsMyIpDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IpCheckerPage);
