import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import {
  Card,
  CardsContainer,
  CardTitle,
  CtaContainer,
  HideableCardDescription,
  StyledBackgroundWrapper,
  StyledCta,
} from './styledComponents';
import { ICaseStudyColumns } from './types';
import { RichText } from 'components/v2/RichText';

const CaseStudyColumns: React.FC<ICaseStudyColumns> = ({
  primary: { background, title, cta_label: ctaLabel, cta_url: ctaUrl },
  items,
}) => {
  return (
    <StyledBackgroundWrapper background={background}>
      <RichText field={title} />
      <CardsContainer>
        {items.map(({ subtitle, icon, text }) => (
          <Card key={subtitle.text}>
            <CardTitle isImage={!!icon.url}>
              <GatsbyImg image={icon} />
              <RichText field={subtitle} />
            </CardTitle>
            <HideableCardDescription isVisible={text.text}>
              <RichText field={text} />
            </HideableCardDescription>
          </Card>
        ))}
      </CardsContainer>
      <CtaContainer>
        <StyledCta
          ctaText={ctaLabel.text}
          ctaLink={ctaUrl.url}
          isVisible={ctaUrl.url}
        />
      </CtaContainer>
    </StyledBackgroundWrapper>
  );
};

export default CaseStudyColumns;
