import { Script } from 'gatsby';

type GooogleTagManagerProps = {
  id: string;
  dataLayer?: string[];
  dataLayerName?: string;
  auth?: string;
  preview?: string;
};

let currDataLayerName: string | undefined;

export const GooogleTagManager = ({
  id: gtmId,
  dataLayer,
  dataLayerName = 'dataLayer',
  auth,
  preview,
}: GooogleTagManagerProps) => {
  if (currDataLayerName === undefined) {
    currDataLayerName = dataLayerName;
  }

  const gtmLayer = dataLayerName !== 'dataLayer' ? `$l=${dataLayerName}` : '';
  const gtmAuth = auth ? `&gtm_auth=${auth}` : '';
  const gtmPreview = preview ? `&gtm_preview=${preview}&gtm_cookies_win=x` : '';

  return (
    <>
      <noscript id="_gatsby-gtm-iframe">
        <iframe
          src={`https://googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
          aria-hidden="true"
        ></iframe>
      </noscript>
      <Script
        id="_gatsby-gtm-init"
        dangerouslySetInnerHTML={{
          __html: `
        (function(w,l){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
            ${dataLayer ? `w[l].push(${JSON.stringify(dataLayer)})` : ''}
        })(window,'${dataLayerName}');`,
        }}
      />
      <Script
        id="_gatsby-gtm"
        src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}${gtmLayer}${gtmAuth}${gtmPreview}`}
      />
    </>
  );
};

export const sendGTMEvent = (data: unknown) => {
  if (currDataLayerName === undefined) {
    console.warn(`GTM has not been initialized`);
    return;
  }

  if (window[currDataLayerName]) {
    window[currDataLayerName].push(data);
  } else {
    console.warn(`GTM dataLayer ${currDataLayerName} does not exist`);
  }
};

export function onRouteUpdate() {
  if (process.env.NODE_ENV === `production`) {
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
      const eventName = `gatsby-route-change`;
      sendGTMEvent({ event: eventName });
    }, 50);
  }
}
