import { TSHIRT_SIZE } from 'components/constants';
import { Formik } from 'formik';
import React from 'react';
import { StyledForm, FormRow } from '../styledComponents';
import { getValidationSchema } from '../utils';
import {
  chatServicesOptions,
  phoneCodeOptions,
  initialValues,
} from '../constants';
import TextInput from 'components/Form/TextInput';
import TextareaInput from 'components/Form/TextareaInput';
import SelectInput from 'components/Form/SelectInput';
import { IFormState, TInquiryFormWrapper } from '../types';
import InquiryFormControl from './InquiryFormControl';
import { FIELD_NAMES } from '../constants';
import { usePageContext } from 'contexts/PageContext';
import { initBuildTrackingSelector } from 'utils/page';

const InquiryFormWrapper: React.FC<TInquiryFormWrapper> = ({
  bottom_text: bottomText,
  company_email_label: companyEmailLabel,
  company_name_label: companyNameLabel,
  cta_text: ctaText,
  full_name_label: fullNameLabel,
  phone_number_label: phoneNumberLabel,
  social_media_contact_label: socialMediaLabel,
  use_case_label: useCaseLabel,
  use_case_description: useCaseDescription,
  isLoading,
  onSubmit,
  ...rest
}) => {
  const { tags, type } = usePageContext();
  const validationSchema = getValidationSchema(rest);

  const buildTrackingSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'contact-sales-form',
    type: 'field',
  });

  return (
    <Formik<IFormState>
      initialValues={{
        ...initialValues,
        countryCode: phoneCodeOptions[0].value,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(context) => (
        <StyledForm id="contact-sales-form">
          <FormRow>
            <TextInput
              name={FIELD_NAMES.fullName}
              label={fullNameLabel}
              context={context}
              className={buildTrackingSelector({
                context: FIELD_NAMES.fullName,
              })}
            />
            <TextInput
              name={FIELD_NAMES.companyName}
              label={companyNameLabel}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              gutterBot={TSHIRT_SIZE.XXS}
              className={buildTrackingSelector({
                context: FIELD_NAMES.companyName,
              })}
            />
          </FormRow>
          <TextInput
            name={FIELD_NAMES.companyEmail}
            label={companyEmailLabel}
            context={context}
            className={buildTrackingSelector({
              context: FIELD_NAMES.companyEmail,
            })}
          />
          <FormRow>
            <SelectInput
              name={FIELD_NAMES.contactChannel}
              label={socialMediaLabel}
              options={chatServicesOptions}
              context={context}
              className={buildTrackingSelector({
                context: FIELD_NAMES.contactChannel,
              })}
            />
            <TextInput
              name={FIELD_NAMES.contactChannelId}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              className={buildTrackingSelector({
                context: FIELD_NAMES.contactChannelId,
              })}
            />
          </FormRow>
          <FormRow>
            <SelectInput
              isSearchable
              name={FIELD_NAMES.countryCode}
              label={phoneNumberLabel}
              options={phoneCodeOptions}
              context={context}
              className={buildTrackingSelector({
                context: FIELD_NAMES.countryCode,
              })}
            />
            <TextInput
              name={FIELD_NAMES.companyPhone}
              context={context}
              gutterLeft={TSHIRT_SIZE.S}
              className={buildTrackingSelector({
                context: FIELD_NAMES.companyPhone,
              })}
            />
          </FormRow>
          <TextareaInput
            name={FIELD_NAMES.useCase}
            description={useCaseDescription}
            label={useCaseLabel}
            context={context}
            className={buildTrackingSelector({ context: FIELD_NAMES.useCase })}
          />
          <InquiryFormControl
            isDisabled={!(context.isValid && context.dirty) || isLoading}
            isLoading={isLoading}
            bottomText={bottomText}
            ctaText={ctaText}
            ctaClassName={buildTrackingSelector({
              context: ctaText,
              type: 'cta',
            })}
          />
        </StyledForm>
      )}
    </Formik>
  );
};

export default InquiryFormWrapper;
