import styled from '@emotion/styled';
import { DOM_STATE, TEXT_ALIGN } from 'components/constants';
import Cta from 'components/Cta';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
} from 'theme';
import { themev2 } from 'theme-v2';

export const FooterApiWrapper = styled.div({
  display: CSS_DISPLAY.FLEX,
  background: themev2.colors.black.S900,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  width: '100%',
  margin: '0 auto',
  maxWidth: '100%',
  padding: '0 20px',
});

export const Container = styled.div(() => ({
  margin: '0 auto',
  width: '1024px',
  padding: `60px ${themev2.spacings.XS}`,
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  textAlign: TEXT_ALIGN.CENTER,
  borderTop: '1px solid hsla(0,0%,100%,.2)',
  'h1, h2, h3, h4, h5': {
    color: themev2.colors.neutral.N00,
    marginBottom: themev2.spacings.S,
    fontWeight: '600',
    fontSize: themev2.fonts.size.h2,
  },
  p: {
    fontFamily: themev2.fonts.family.additional,
    color: themev2.colors.neutral.N20,
    fontSize: themev2.fonts.size.p_medium,
    lineHeight: themev2.fonts.lineHeight.p_medium,
    marginBottom: '36px',
    fontWeight: '400',
    textAlign: TEXT_ALIGN.CENTER,
    'a, strong, em': {
      fontFamily: themev2.fonts.family.main,
    },
  },
  a: {
    color: themev2.colors.blue.default,
  },
  [MEDIA_QUERIES.TABLET]: {
    padding: '32px 0',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    'h1, h2, h3, h4, h5': {
      textAlign: TEXT_ALIGN.LEFT,
    },
    p: {
      marginBottom: '32px',
      textAlign: TEXT_ALIGN.LEFT,
    },
    div: {
      width: '100%',
    },
  },
}));

export const CtasContainer = styled.div(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  columnGap: themev2.spacings.S,
  'a: first-of-type button': {
    backgroundColor: themev2.colors.primary.P99,
    borderRadius: '4px',
    ':hover': {
      backgroundColor: themev2.colors.blue.S600,
    },
  },

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      margin: 0,
      width: 'auto',
    },
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
    'a:nth-of-type(2)': {
      marginLeft: 0,
      marginTop: '20px',
    },
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: '100%',
    },
  },
}));

export const CtaBlock = styled(Cta)({
  width: 'auto',
  height: '40px',
  minWidth: '300px',
  padding: '0 15px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  fontWeight: 500,
  margin: 0,
  fontSize: themev2.fonts.size.p_small,
  fontFamily: themev2.fonts.family.additional,
  [DOM_STATE.HOVER]: {
    padding: '0 15px',
  },
});

export const MoneyBackWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  fontWeight: '400',
  fontFamily: themev2.fonts.family.additional,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: 'flex-start',
  },
});
