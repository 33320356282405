import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { TSHIRT_SIZE } from 'components/constants';
import { usePageContext } from 'contexts/PageContext';
import { copyCode } from 'features/utils';
import { IconCheckmarkGreen } from 'images';
import React, { useRef, useState } from 'react';
import hideable from 'utils/hideable';
import { buildTrackingSelector } from 'utils/page';
import {
  CopyButtonContainer,
  CopyMessage,
  CopyMessageContainer,
  StyledCodeBlock,
  StyledCodeSection,
  StyledCopyButton,
} from './styledComponents';
import { ICodeBlock } from './types';
import { RichText } from 'components/v2/RichText';

const HideableCopyButton = hideable(StyledCopyButton);
const HideableMessage = hideable(CopyMessageContainer);

const CodeBlock: React.FC<ICodeBlock> = ({
  primary: { code_field: codeField, copy_option: copyOption },
}) => {
  const [messageVisibility, setMessageVisibility] = useState(false);
  const codeBlockRef = useRef<HTMLDivElement>(null);
  const { type, tags } = usePageContext();

  return (
    <StyledCodeSection>
      <StyledCodeBlock ref={codeBlockRef}>
        <RichText field={codeField} />
      </StyledCodeBlock>
      <CopyButtonContainer>
        <HideableCopyButton
          className={buildTrackingSelector({
            page: tags[0] || type,
            section: 'code-block',
            context: 'copy',
          })}
          isVisible={copyOption}
          themeName={BUTTON_THEMES.IMPORTANT}
          themeType={BUTTON_TYPES.SECONDARY}
          fontSize={TSHIRT_SIZE.M}
          onClick={() =>
            copyCode(codeBlockRef.current?.innerText, setMessageVisibility)
          }
        >
          Copy code
        </HideableCopyButton>
        <HideableMessage isVisible={messageVisibility}>
          <IconCheckmarkGreen />
          <CopyMessage>Code copied to clipboard</CopyMessage>
        </HideableMessage>
      </CopyButtonContainer>
    </StyledCodeSection>
  );
};

export default CodeBlock;
