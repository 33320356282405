import styled from '@emotion/styled';
import { BUTTON_TYPES } from 'components/Button';
import {
  DOM_STATE,
  TEXT_ALIGN,
  TEXT_TYPE,
  TSHIRT_SIZE,
} from 'components/constants';
import { HomepageStyling } from 'features/AllArticles/styledComponents';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  ACCENT_COLOR,
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeAccentColor,
  getThemeButtonFollowingColor,
  getThemeFontSize,
  getThemeFunctionalColor,
  getThemeGutter,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const StyledWrapper = styled.button<ITheme>((props) => ({
  padding: 0,
  border: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY).border,
  background: 'none',
  cursor: getThemeButtonFollowingColor(props, BUTTON_TYPES.PRIMARY).cursor,
  marginRight: '15px',
  [MEDIA_QUERIES.TABLET]: {
    marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
  },
}));

const TextContainer = styled.div<ITheme>((props) => ({
  textAlign: TEXT_ALIGN.CENTER,
  a: {
    marginLeft: '6px',
  },
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const StyledTopicsText = styled.span<ITheme>((props) => ({
  fontWeight: 700,
  fontSize: getThemeFontSize(props, TEXT_TYPE.H6),
}));

export const StyledTopicsLink = styled.span<ITheme & HomepageStyling>(
  ({ isHomepage, ...props }) => ({
    fontWeight: 400,
    fontSize: isHomepage
      ? getThemeFontSize(props, TEXT_TYPE.P2)
      : getThemeFontSize(props, TEXT_TYPE.H6),
    color: isHomepage
      ? getThemeFunctionalColor(props, COLOR_SHADE.PRIMARY)
      : getThemeAccentColor(props, ACCENT_COLOR.COLOR1),
    [DOM_STATE.HOVER]: {
      textDecoration: 'underline',
    },
  })
);

export const StyledContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  'button:last-of-type': {
    marginRight: 0,
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    'button:last-of-type': {
      marginRight: getThemeGutter(props, TSHIRT_SIZE.XS),
    },
  },
}));

export const ButtonContainer = styled.div<{ isCentered: boolean }>(
  ({ isCentered }) => ({
    margin: isCentered ? '0 auto' : '0 0 0 auto',
    [MEDIA_QUERIES.TABLET]: {
      marginLeft: isCentered ? 'auto' : 0,
    },
  })
);

export const AdditionalTextContainer = styled.div((props) => ({
  p: {
    marginBottom: getThemeGutter(props),
    textAlign: TEXT_ALIGN.CENTER,
    color: getThemeMainColor(props, MAIN_COLOR.BLACK),
  },
}));

export const Wrapper = styled.div<ITheme & IColor>(
  ({ background, isV2, ...props }) => ({
    display: isV2 ? 'none' : 'flex',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
    padding: `0 20px ${getThemeGutter(props, TSHIRT_SIZE.XL)}`,
    width: '100%',
    background,
  })
);

export const HideableTextContainer = hideable(TextContainer);
export const HideableAdditionalText = hideable(AdditionalTextContainer);
