import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'features/v2/Header/constants';

export const BANNER_TOP_CLOSED = 'bannerTopClosed';
export const ADDITIONAL_DESKTOP_OFFSET_START = 90;
export const DESKTOP_OFFSET_START = HEADER_HEIGHT_DESKTOP;
export const DESKTOP_SCROLL_POSITION = 45;
export const DEFAULT_DESKTOP_OFFSET = -200;
export const DEFAULT_MOBILE_OFFSET = -250;
export const MOBILE_OFFSET_START = HEADER_HEIGHT_MOBILE;
export const MOBILE_SCROLL_POSITION = 62;

export enum BANNER_TOP_SLICES {
  PageList = 'page_list',
}
