import { Wrapper } from 'commonStyles';
import { useSplitTesting } from 'contexts/SplitTesting';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { sliceMap, SliceType } from 'features';

interface IWithPrismicWhatIsAProxy {
  prismicWhatIsAProxy: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicWhatIsAProxy;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const WhatIsAProxy = ({ data, pageContext }: Props & PageTemplateProps) => {
  const { bodyKey } = useSplitTesting();
  const { prismicWhatIsAProxy } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicWhatIsAProxy.data[bodyKey],
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) => (
        <Component
          key={key}
          pageContext={buildPageContext({
            context: pageContext,
            tags: prismicWhatIsAProxy.tags,
            type: prismicWhatIsAProxy.type,
          })}
          {...slice}
        />
      ))}
    </Wrapper>
  );
};

export default withPrismicPreview(WhatIsAProxy);

export const query = graphql`
  query WhatIsAProxyQuery($id: String!) {
    prismicWhatIsAProxy(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicWhatIsAProxyDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              accent_color
              is_text_centered
              is_image_on_left
              is_text_white
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyPaymentMethod {
            id
            slice_type
            primary {
              icons_text {
                richText
                text
              }
              background
              security_description {
                richText
                text
              }
              security_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              security_title {
                richText
                text
              }
            }
            items {
              payment_method_icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyPricingCards {
            id
            items {
              card_product_type
              card_plan_name
              scraping_api_plan
              is_ddc_non_seq
              billing_name
              is_new
              amount
              billing_type
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              info_text
              is_most_popular
              price
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              vat_text
              card_title
              card_description
              crossed_out_price
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
                url
              }
              show_discount_label
              discount {
                richText
                text
              }
              price_per_gb_crossed_out
            }
            primary {
              is_yearly_visible
              background
              feature_one
              feature_three
              feature_two
              feature_four
              feature_five
              number_of_columns
              switch_discount_label
              switch_monthly_label
              switch_yearly_label
              theme
              toggle_label_text
              product
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyPricingSlider {
            id
            slice_type
            items {
              amount
              billing_type
              billing_name
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              plan_title
              price
              scale_label
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              fourth_feature_label
              fourth_feature_value
              vat_text
              crossed_out_total_price
            }
            primary {
              is_yearly_visible
              background
              bigger_plan_cta_link {
                url
              }
              bigger_plan_cta_text
              bigger_plan_description
              bigger_plan_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              bigger_plan_title
              switch_monthly_label
              switch_yearly_label
              title {
                richText
                text
              }
              theme
            }
          }
          ... on PrismicWhatIsAProxyDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicWhatIsAProxyDataBodyIconListBlock {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
                target
              }
              text_field {
                richText
                text
              }
            }
            primary {
              column_number
              is_text_centered
              list_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodySideImageTextBlock {
            id
            slice_type
            primary {
              side_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              text_field {
                richText
                text
              }
              title_field {
                richText
                text
              }
              is_image_right
            }
          }
          ... on PrismicWhatIsAProxyDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyPricingCardsNew {
            id
            slice_type
            primary {
              show_top_label
              top_label {
                richText
                text
              }
              background
              is_yearly_visible_
              number_of_columns
              theme
              toggle_label_text
            }
            items {
              pricing_plan {
                document {
                  ...PricingDetailsFragment
                }
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyPricingSliderNew {
            id
            slice_type
            primary {
              theme
              background
              is_yearly_visible
            }
            items {
              pricing_slider_details {
                document {
                  ...PricingSliderDetailsFragment
                }
              }
            }
          }
          ... on PrismicWhatIsAProxyDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicWhatIsAProxyDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
