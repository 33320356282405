import { TimeComponents, buildDateFromTimeComponents } from 'utils/time';
import { residentialPromoQuery } from './query';
import { useEffect, useState } from 'react';
import { deleteCookie, getCookies, setCookie } from 'cookies';

const RESI_PROMO_COOKIE_NAME = 'sp_promo_resi_exp';

export const useResiPromo = (variant: '0' | '1') => {
  const { prismicPromotions } = residentialPromoQuery();

  const [expiry, setExpiry] = useState(new Date().toISOString());

  if (!prismicPromotions) {
    return {
      expiry: null,
      description: undefined,
    };
  }

  const isDurationBasedExpiry =
    prismicPromotions.data.should_use_expiry_duration;

  const getExpiry = () => {
    const expiryCookieValue = getDurationCookie();

    if (
      isDurationBasedExpiry &&
      expiryCookieValue &&
      new Date(expiryCookieValue).getTime() > Date.now()
    ) {
      return expiryCookieValue;
    }

    if (isDurationBasedExpiry) {
      const expiryTimeComponents: Partial<TimeComponents> =
        prepareTimeComponentsFromPrismic(prismicPromotions.data, variant);

      return buildDateFromTimeComponents({
        days: expiryTimeComponents.days,
        hours: expiryTimeComponents.hours,
        mins: expiryTimeComponents.mins,
      }).toISOString();
    }

    return variant === '1'
      ? prismicPromotions.data.expiry1
      : prismicPromotions.data.expiry;
  };

  const getDurationCookie = () => {
    const cookieExpiryValue = getCookies()[RESI_PROMO_COOKIE_NAME];

    return cookieExpiryValue;
  };

  const setDurationCookie = (value: string, maxAgeInDays?: number) => {
    setCookie({
      cname: RESI_PROMO_COOKIE_NAME,
      cvalue: value,
      exdays: maxAgeInDays ? maxAgeInDays + 1 : 1,
      domain: window.location.hostname,
    });
  };

  const removeDurationCookie = () => deleteCookie(RESI_PROMO_COOKIE_NAME);

  useEffect(() => {
    const newExpiry = getExpiry();
    setExpiry(newExpiry);

    if (isDurationBasedExpiry && newExpiry) {
      const msToExpiry = new Date(newExpiry).getTime() - Date.now();
      const daysToExpiry = Math.floor(msToExpiry / (24 * 60 * 60 * 1000));

      setDurationCookie(newExpiry, daysToExpiry);
    } else {
      removeDurationCookie();
    }
  }, []);

  return {
    expiry,
    isTimerVisible:
      variant === '1'
        ? prismicPromotions.data.is_timer_visible1
        : prismicPromotions.data.is_timer_visible,
    description:
      variant === '1'
        ? prismicPromotions.data.offer_description1
        : prismicPromotions.data.offer_description,
  };
};

const prepareTimeComponentsFromPrismic = (
  prismicPromotionData,
  variant: '0' | '1'
) => ({
  days:
    variant === '1' ? prismicPromotionData.days1 : prismicPromotionData.days,
  hours:
    variant === '1' ? prismicPromotionData.hours1 : prismicPromotionData.hours,
  mins:
    variant === '1'
      ? prismicPromotionData.minutes1
      : prismicPromotionData.minutes,
});
