import React from 'react';
import {
  CtaComponentContainer,
  HideableAvailableCitiesContainer,
  HideableCtaContainer,
} from './styledComponents';
import { LinkButton } from 'components/v2/LinkButton';
import { RichText } from 'components/v2/RichText';
import { initBuildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';
import hideable from 'utils/hideable';
import { ICtaComponent } from './types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const CtaComponent: React.FC<ICtaComponent> = ({
  ctaLabel,
  ctaLink,
  availableCities,
  tags,
  type,
  flagsAlignment,
}) => {
  const { id: sliceId } = useSliceDetails();
  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: SECTION_NAMES.LOCATION_FLAGS,
    prefix: type,
  });
  return (
    <CtaComponentContainer flagsAlignment={flagsAlignment}>
      <HideableCtaContainer isVisible={ctaLabel.text && ctaLink.url}>
        <LinkButton
          fullWidth
          color="blue"
          variant="outlined"
          to={ctaLink.url}
          data-tracker={trackingSelector({ context: ctaLabel.text })}
          onClick={() => {
            logPostHogEvent(trackingSelector({ context: ctaLabel.text }));
            if (sliceId) {
              logGAElementClickEvent({
                elementId: ctaLabel.text,
                sectionId: sliceId,
              });
            }
          }}
        >
          {ctaLabel.text}
        </LinkButton>
      </HideableCtaContainer>
      <HideableAvailableCitiesContainer isVisible={availableCities.text}>
        <RichText field={availableCities} />
      </HideableAvailableCitiesContainer>
    </CtaComponentContainer>
  );
};

export default hideable(CtaComponent);
