import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  getThemeGutter,
  getThemeInputWidthShape,
  ITheme,
} from 'theme';

export const StyledCaseList = styled.div({
  ...flexColContainerStyles,
});

export const ListCaseItemsContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.GRID,
  gridAutoColumns: '1fr',
  gridTemplateColumns: `1fr 1fr`,
  gridGap: getThemeGutter(props, TSHIRT_SIZE.L),
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  height: 'auto',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XXS),
  [MEDIA_QUERIES.TABLET]: {
    gridTemplateColumns: `1fr`,
    height: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gridTemplateRows: 'auto auto',
  },
}));
