import { RESIDENTIAL_PRICING_VALUES, SECTION_NAMES } from 'constants/common';
import { generateSectionsId } from 'features/utils';
import React from 'react';
import { IScreenProps } from 'utils/screenTypeHOC';
import Cta from './Cta';
import Features from './Features';
import Reviews from './Reviews';
import {
  ImageContainer,
  StyledContainer,
  StyledCountdownTimerWrapper,
  StyledTextContainer,
  StyledWrapper,
  Subtitle,
  Title,
} from './styledComponents';
import { INewHeroSlice } from './types';
import { useResiPromo } from 'features/ResidentialPricingCards/useResiPromo';
import { usePageContext } from 'contexts/PageContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { OfferCountdownStatus } from 'features/ResidentialPricingCards/OfferCountdownStatus';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const NewHero: React.FC<INewHeroSlice & IScreenProps> = ({
  primary: {
    main_title: title,
    cta_start_url: ctaStartUrl,
    cta_start_label: ctaStartLabel,
    cta_pricing_url: ctaPricingUrl,
    cta_pricing_label: ctaPricingLabel,
    subtitle,
    hero_image: image,
    trustpilot_logo: trustpilotLogo,
    trustpilot_stars: trustpilotStars,
    main_color: mainColor,
    start_button_color: buttonColor,
    is_money_back_visible,
  },
  items,
}) => {
  const { tags, type } = usePageContext();

  const isResidentialProxiesPage = tags.includes('residential-proxies');

  const { variant } = useSplitTesting();
  const promo = useResiPromo(variant);

  const isPromoBannerVisible = !!promo.expiry && isResidentialProxiesPage;
  const isMoneyBackVisible = is_money_back_visible ?? true;

  return (
    <StyledWrapper
      data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
    >
      <StyledContainer>
        <StyledTextContainer color={mainColor}>
          <Reviews
            trustpilotLogo={trustpilotLogo}
            trustpilotStars={trustpilotStars}
          />
          {isPromoBannerVisible && (
            <StyledCountdownTimerWrapper>
              <OfferCountdownStatus
                key={promo.expiry}
                expiry={promo.expiry}
                label={RESIDENTIAL_PRICING_VALUES.LABEL}
                isTimerVisible={promo.isTimerVisible}
              />
            </StyledCountdownTimerWrapper>
          )}
          <Title color={mainColor}>
            <RichText field={title} />
          </Title>
          <Subtitle>
            <RichText field={subtitle} />
          </Subtitle>
          <Cta
            isMoneyBackVisible={isMoneyBackVisible}
            ctaStartLabel={ctaStartLabel}
            ctaStartUrl={ctaStartUrl}
            buttonColor={buttonColor}
            ctaPricingLabel={ctaPricingLabel}
            ctaPricingUrl={ctaPricingUrl}
            tags={tags}
            type={type}
          />
        </StyledTextContainer>
        <ImageContainer>
          <GatsbyImg image={image} loading="eager" fetchPriority="high" />
        </ImageContainer>
      </StyledContainer>
      <Features
        features={items}
        isVisible={!!items.length}
        accentColor={mainColor}
      />
    </StyledWrapper>
  );
};
export default NewHero;
