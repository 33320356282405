import { IconAttachment, IconDelete } from 'images';
import React, { useEffect, useRef, useState } from 'react';
import {
  StyledFileUpload,
  StyledFileUploadDeleteButton,
  StyledFileUploadInput,
  StyledFileUploadLabel,
} from './styledComponents';
import { IFileUploadProps } from './types';
import { track } from '@smartproxy/sp-tracker-js';

const FileUpload = ({
  id,
  dataTracker,
  name,
  value,
  label,
  fileTypes = [],
  handleFile,
}: IFileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  const filesToAccept = fileTypes.length ? fileTypes.join(',') : '*';
  const removeFileSelector = 'careers-form-remove-attached-file';

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.currentTarget.files?.[0] ?? null;
    if (!selectedFile) return;
    if (handleFile) handleFile(selectedFile);
    setFile(selectedFile);
  };

  const handleRemoveFile = () => {
    if (inputRef.current) inputRef.current.value = '';
    if (handleFile) handleFile(null);
    setFile(null);
    track(removeFileSelector);
  };

  useEffect(() => {
    if (value === undefined) {
      setFile(null);
    }
  }, [value]);

  return (
    <StyledFileUpload>
      <StyledFileUploadInput
        type="file"
        ref={inputRef}
        accept={filesToAccept}
        name={name}
        id={id}
        data-tracker={dataTracker}
        onChange={handleChange}
        onClick={() => track(dataTracker)}
      />
      <StyledFileUploadLabel htmlFor={id} hasFile={!!file}>
        <IconAttachment />
        <span>{file ? file.name : label}</span>
      </StyledFileUploadLabel>
      {file && (
        <StyledFileUploadDeleteButton
          title="Remove file"
          type="button"
          data-tracker={removeFileSelector}
          onClick={handleRemoveFile}
        >
          <IconDelete />
        </StyledFileUploadDeleteButton>
      )}
    </StyledFileUpload>
  );
};

export default FileUpload;
