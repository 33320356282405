import { Wrapper } from 'commonStyles';
import SliceContextProvider from 'contexts/SliceContext';
import { useSplitTesting } from 'contexts/SplitTesting';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import React from 'react';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';
import { buildPageContext } from 'utils/page';
import { targetsSliceMap } from './constants';

interface IWithPrismicTargets {
  prismicTargets: {
    tags?: string[];
    type?: string;
    data: {
      body: DefaultSlice[];
    };
  };
}
export interface Props {
  data: IWithPrismicTargets;
}

type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const TargetsPage: React.FC<Props & PageTemplateProps> = ({
  data,
  pageContext,
}) => {
  const { bodyKey } = useSplitTesting();
  const { prismicTargets, allPrismicBlog } = data;
  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicTargets.data[bodyKey],
    { ...sliceMap, ...targetsSliceMap }
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, idx) => (
        <SliceContextProvider name={slice.slice_type} position={idx} key={idx}>
          <Component
            pageContext={buildPageContext({
              context: pageContext,
              tags: prismicTargets.tags,
              type: prismicTargets.type,
            })}
            articles={allPrismicBlog?.nodes}
            {...slice}
          />
        </SliceContextProvider>
      ))}
    </Wrapper>
  );
};

export const query = graphql`
  query TargetsQuery($id: String!) {
    prismicTargets(id: { eq: $id }) {
      _previewable
      tags
      type
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicTargetsDataBodyBreadcrumbs {
            id
            slice_type
            primary {
              text_color
            }
            items {
              link {
                url
              }
              link_label {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyHeroBlock {
            id
            items {
              checkmark {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              feature {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              video_embed
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              accent_color
              button_color
              link_label_pricing {
                richText
                text
              }
              link_label_start {
                richText
                text
              }
              link_to_pricing {
                url
              }
              link_to_start {
                url
              }
              review_stars {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_text {
                richText
                text
              }
              review_trustpilot {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              sub_title {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodySpacing {
            id
            primary {
              background
              height
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyTitle {
            id
            slice_type
            primary {
              background
              center
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyExpandableProductsPricingOverview {
            id
            items {
              badge_color
              badge_label
              checklist {
                richText
                text
              }
              product {
                richText
                text
              }
              learn_more_link {
                url
              }
              learn_more_text
              product_cta_link {
                url
              }
              product_cta_text
              product_description {
                richText
                text
              }
              product_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              product_starting_price
              price_prefix
            }
            slice_type
            primary {
              checkmark {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              product_line {
                richText
                text
              }
              product_line_description {
                richText
                text
              }
              background
            }
          }
          ... on PrismicTargetsDataBodySocialProofBlock {
            id
            slice_type
            primary {
              background
              is_dark
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            items {
              tab_document {
                document {
                  ... on PrismicTrustpilotReviewDetails {
                    ...TrustpilotReviewsFragment
                  }
                  ... on PrismicIndustryExpertsDetails {
                    ...IndustryExpertsFragment
                  }
                  ... on PrismicAwardsDetails {
                    ...AwardsFragment
                  }
                  ... on PrismicG2ReviewDetails {
                    ...G2ReviewsFragment
                  }
                }
              }
            }
          }
          ... on PrismicTargetsDataBodyFeaturedIn {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyProductCard {
            id
            slice_type
            items {
              old_product_price
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              feature_one {
                richText
                text
              }
              feature_three {
                richText
                text
              }
              feature_two {
                richText
                text
              }
              icon {
                gatsbyImageData
                url
                alt
              }
              product_price
              price_time_period {
                richText
                text
              }
              product_description {
                richText
                text
              }
              product_title {
                richText
                text
              }
              recommended
              tooltip
              tooltip_text {
                richText
                text
              }
            }
            primary {
              accent_color
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyBadgesBlock {
            id
            slice_type
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              background
              accent_color
              is_text_centered
              is_image_on_left
              is_text_white
              text_field {
                richText
                text
              }
              title {
                text
                richText
              }
            }
          }
          ... on PrismicTargetsDataBodyPaymentMethod {
            id
            slice_type
            primary {
              icons_text {
                richText
                text
              }
              background
              security_description {
                richText
                text
              }
              security_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              security_title {
                richText
                text
              }
            }
            items {
              payment_method_icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicTargetsDataBodyFaqBlock {
            id
            items {
              answer {
                richText
                text
              }
              question {
                richText
                text
              }
            }
            primary {
              background
              is_background_dark
              title {
                richText
                text
              }
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyBiggerPlans {
            id
            slice_type
            primary {
              background
              is_dark
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
              description {
                richText
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyTrustpilotReviews {
            id
            items {
              review {
                richText
                text
              }
              star_rating {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              user_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              username {
                richText
                text
              }
            }
            primary {
              background
              trustpilot_logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              link_to {
                url
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyImageTextBlock {
            slice_type
            id
            primary {
              text_below_title {
                richText
                text
              }
              is_icon_visible
              cta_color
              is_background_dark
              is_split_equally
              title_color
              video_embed
              is_text_centered
              image_zoom_modal
              is_image_right
              background
              columns_number
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
              sub_title {
                richText
                text
              }
              review_description
              review_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              review_bottom_text {
                richText
                text
              }
              columns_number
            }
            items {
              link {
                url
              }
              small_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              small_image_description {
                richText
                text
              }
              ips_amount_description {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyImageTextColumn {
            id
            items {
              description {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
            primary {
              background
              numbers_of_columns
              cta_url {
                url
                target
              }
              cta_label {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodySideImageTextBlock {
            id
            slice_type
            primary {
              side_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              text_field {
                richText
                text
              }
              title_field {
                richText
                text
              }
              is_image_right
            }
          }
          ... on PrismicTargetsDataBodyIconListBlock {
            id
            items {
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
                target
              }
              text_field {
                richText
                text
              }
            }
            primary {
              column_number
              is_text_centered
              list_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyTextGrid {
            id
            slice_type
            items {
              description {
                richText
                text
              }
              icon {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              columns
              is_dark_theme
              is_header_centered
              is_subtitle_centered
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyProxyListCountries {
            id
            items {
              column_title {
                richText
                text
              }
              first_column {
                richText
                text
              }
              second_column {
                richText
                text
              }
              third_column {
                richText
                text
              }
            }
            primary {
              background
              title_position
              main_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyImageTextColumnsWithCta {
            id
            items {
              cta_label {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              subtitle {
                richText
                text
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              title_field {
                richText
                text
              }
              subtitle_and_text_alignment
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyPricingCards {
            id
            items {
              card_product_type
              card_plan_name
              scraping_api_plan
              is_ddc_non_seq
              billing_name
              is_new
              amount
              billing_type
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              info_text
              is_most_popular
              price
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              vat_text
              card_title
              card_description
              crossed_out_price
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
                url
              }
              show_discount_label
              discount {
                richText
                text
              }
              price_per_gb_crossed_out
            }
            primary {
              is_yearly_visible
              background
              feature_one
              feature_three
              feature_two
              feature_four
              feature_five
              toggle_label_text
              switch_discount_label
              switch_monthly_label
              switch_yearly_label
              theme
              product
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyLocationFlags {
            id
            items {
              flag_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link {
                url
              }
              text {
                richText
                text
              }
            }
            primary {
              background_color
              flags_alignment
              column_number
              available_cities {
                richText
                text
              }
              background
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyPricingSlider {
            id
            slice_type
            items {
              amount
              billing_type
              billing_name
              cta_link {
                url
              }
              cta_text
              first_feature_label
              first_feature_value
              plan_title
              price
              scale_label
              second_feature_label
              second_feature_value
              third_feature_label
              third_feature_value
              fourth_feature_label
              fourth_feature_value
              vat_text
              crossed_out_total_price
            }
            primary {
              is_yearly_visible
              background
              bigger_plan_cta_link {
                url
              }
              bigger_plan_cta_text
              bigger_plan_description
              bigger_plan_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              bigger_plan_title
              switch_monthly_label
              switch_yearly_label
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              theme
            }
          }
          ... on PrismicTargetsDataBodyColumnLinks {
            id
            items {
              text {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            primary {
              background
              number_of_columns
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyTextBlock {
            id
            slice_type
            primary {
              background
              is_centered
              text {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyProxyTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              column_name {
                richText
                text
              }
            }
            primary {
              background
              header_background
            }
          }
          ... on PrismicTargetsDataBodyCtaBlock {
            id
            primary {
              background
              is_centered
              cta_color
              cta_label {
                richText
                text
              }
              cta_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyColumnText {
            id
            items {
              text {
                richText
                text
              }
            }
            primary {
              columns_number
            }
            slice_type
            slice_label
          }
          ... on PrismicTargetsDataBodyVideoBlock {
            id
            primary {
              background
              embed_code
              video_block_title {
                richText
                text
              }
              video_possition
              video_text_field {
                richText
                text
              }
              cta_title {
                text
                richText
              }
              cta_link {
                url
              }
              cta_position
            }
            slice_type
          }
          ... on PrismicTargetsDataBodySimpleCardsSlice {
            id
            slice_label
            slice_type
            items {
              assign_to_toggle
              card_body_text {
                text
                richText
              }
              card_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              card_link {
                url
              }
              card_heading {
                richText
                text
              }
            }
            primary {
              background_color
              content_alignment
              cta_link {
                url
              }
              cta_label {
                text
                richText
              }
              cta_position
              description {
                richText
                text
              }
              title {
                richText
                text
              }
              is_slice_dark
              toggle_one_label
              toggle_two_label
              cta_icon_position
              cta_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicTargetsDataBodyPricingCardsNew {
            id
            slice_type
            primary {
              show_top_label
              top_label {
                richText
                text
              }
              background
              is_yearly_visible_
              number_of_columns
              theme
              toggle_label_text
            }
            items {
              pricing_plan {
                document {
                  ...PricingDetailsFragment
                }
              }
            }
          }
          ... on PrismicTargetsDataBodyPricingSliderNew {
            id
            slice_type
            primary {
              theme
              background
              is_yearly_visible
            }
            items {
              pricing_slider_details {
                document {
                  ...PricingSliderDetailsFragment
                }
              }
            }
          }
          ... on PrismicTargetsDataBodyBlogImage {
            id
            slice_type
            slice_label
            primary {
              background
              blog_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_title {
                richText
                text
              }
              is_expandable
            }
          }
          ... on PrismicTargetsDataBodyScrapingSimulator {
            id
            slice_type
            primary {
              select_simulator
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicTargetsDataBodyVideoNoCode {
            id
            slice_type
            primary {
              background
              title {
                richText
                text
              }
              description {
                richText
                text
              }
              video_link {
                url
                id
              }
            }
          }
          ... on PrismicTargetsDataBodyScraperNoCode {
            id
            primary {
              background
              description {
                text
                richText
              }
              title {
                text
                richText
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyWebinarsRegistrationForm {
            id
            primary {
              description {
                richText
                text
              }
              form_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              background
              webinar_info {
                richText
                text
              }
              webinar_date {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyFeatureComparisonTable {
            id
            slice_type
            items {
              cell_background
              cell_value {
                richText
                text
              }
              cta_text
              cta_link {
                url
              }
              column_header {
                text
                richText
              }
            }
            primary {
              background
              title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyDynamicTextAndImage {
            id
            primary {
              is_image_on_left
              title {
                richText
                text
              }
              theme
              background
              subtitle {
                richText
                text
              }
              is_subtitle_centered
            }
            items {
              cta_text
              cta_link {
                url
              }
              item_description {
                richText
                text
              }
              item_image {
                alt
                url
                gatsbyImageData(placeholder: BLURRED)
              }
              item_title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyVideoStreaming {
            id
            primary {
              embed_code
              title {
                richText
              }
              link_label_one {
                text
              }
              link_label_two {
                text
              }
              link_to_label_one {
                url
              }
              link_to_label_two {
                url
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyUseCaseBlock {
            id
            items {
              group_name
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            primary {
              number_of_columns
              background_color
              column_title_1 {
                richText
                text
              }
              column_title_2 {
                richText
                text
              }
              column_title_3 {
                richText
                text
              }
              main_title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyNewHero {
            id
            items {
              feature_description {
                text
                richText
              }
              feature_title {
                richText
                text
              }
              hero_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_money_back_visible
              cta_pricing_label {
                richText
                text
              }
              cta_start_label {
                richText
                text
              }
              cta_start_url {
                url
              }
              cta_pricing_url {
                url
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              main_color
              main_title {
                richText
                text
              }
              start_button_color
              subtitle {
                richText
                text
              }
              trustpilot_logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              trustpilot_stars {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyNewTable {
            id
            items {
              cell_background
              cell_text {
                richText
                text
              }
              header_name
              is_text_light
            }
            primary {
              background
              cta {
                richText
                text
              }
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              text_below_table {
                richText
                text
              }
              title {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyUseCaseBlock {
            id
            items {
              group_name
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              link_to {
                url
              }
              subtitle {
                richText
                text
              }
            }
            slice_type
            primary {
              number_of_columns
              background_color
              column_title_1 {
                richText
                text
              }
              column_title_2 {
                richText
                text
              }
              column_title_3 {
                richText
                text
              }
              main_title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyTableOfContent {
            id
            slice_type
            primary {
              table_title {
                richText
                text
              }
            }
            items {
              link_label {
                richText
                text
              }
              link_to {
                url
              }
            }
          }
          ... on PrismicTargetsDataBodyRelatedArticles {
            id
            slice_type
            primary {
              background_color
              title {
                richText
                text
              }
            }
            items {
              article {
                document {
                  ...BlogItemFragment
                }
              }
            }
          }
          ... on PrismicTargetsDataBodyCodeBlock {
            id
            slice_type
            primary {
              copy_option
              code_field {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicTargetsDataBodyScrapersPlayground {
            id
            slice_type
            primary {
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyTargetsList {
            id
            slice_type
            items {
              card_item_image {
                alt
                url
                gatsbyImageData
              }
              card_item_link {
                url
              }
              card_item_text {
                richText
                text
              }
            }
            primary {
              background
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
          ... on PrismicTargetsDataBodyHeroV2 {
            id
            slice_label
            slice_type
            items {
              feature_label {
                richText
                text
              }
            }
            primary {
              is_money_back_visible
              accent_color
              cta_label {
                text
                richText
              }
              cta_link {
                url
              }
              description {
                text
                richText
              }
              hero_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              secondary_cta_label {
                text
                richText
              }
              secondary_cta_link {
                url
              }
              title {
                richText
                text
              }
              video_embed
            }
          }
          ... on PrismicTargetsDataBodyHeroV2Features {
            id
            slice_label
            slice_type
            primary {
              is_dark_theme: theme
            }
            items {
              description {
                richText
                text
              }
              icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyHeroV2Logos {
            id
            slice_label
            slice_type
            items {
              logo {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyAutomatedPricingCards {
            id
            items {
              pricing_card {
                type
                document {
                  ...PricingCardFragment
                  ...PricingCardsFeaturesFragment
                }
              }
            }
            slice_type
            primary {
              background_color
              is_limited_time_offer_visible
              enterprise_benefit
              subtitle {
                richText
                text
              }
              switch_enterprise_label
              switch_regular_label
              title {
                richText
                text
              }
            }
          }
          ... on PrismicTargetsDataBodyCodeBlockV2 {
            id
            slice_label
            slice_type
            items {
              code {
                richText
                text
              }
              language
            }
          }
          ... on PrismicTargetsDataBodyCarouselBlock {
            id
            slice_type
            primary {
              title {
                text
                richText
              }
              subtitle {
                richText
                text
              }
              show_cta
              is_dark
              show_tabs
            }
            items {
              category
              category_url {
                url
              }
              icon {
                gatsbyImageData
              }
              itemTitle: title
              action_url {
                url
              }
              action_label
            }
          }
          ... on PrismicTargetsDataBodyGettingStarted {
            id
            slice_label
            slice_type
            primary {
              is_dark
              title_component
              toggle_proxy_label
              toggle_scrape_label
            }
            items {
              button_text
              card_title
              card_toggle_type
              card_type
              doc_link {
                url
                target
              }
              header_icon {
                alt
                gatsbyImageData
                url
              }
              button_link {
                url
                target
              }
              cardDescription: card_description {
                richText
                text
              }
              video_iframe
            }
          }
          ... on PrismicTargetsDataBodyBlogParentBlock {
            id
            slice_type
            primary {
              cta_text
              cta_url {
                url
              }
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              is_dark_theme: theme
            }
            items {
              blog_post {
                url
                document {
                  ... on PrismicBlog {
                    id
                    data {
                      new_until
                      article_image {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                      description {
                        richText
                        text
                      }
                      release_date(formatString: "MMM DD, yyyy")
                      time_spent_reading
                      title {
                        richText
                        text
                      }
                      body {
                        ... on PrismicBlogDataBodySocialButtonsBar {
                          id
                          slice_type
                          primary {
                            is_fb_visible
                            is_reddit_visible
                            is_twitter_visible
                            is_linkedin_visible
                            is_icons_centered
                            text {
                              richText
                              text
                            }
                          }
                          items {
                            topic {
                              document {
                                ...TopicItemFragment
                              }
                            }
                          }
                        }
                        ... on PrismicBlogDataBodyAuthorV2 {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ...BlogPostAuthorFragment
                              }
                            }
                            is_global
                          }
                        }
                        ... on PrismicBlogDataBodyBlogAuthor {
                          id
                          slice_type
                          primary {
                            blog_author {
                              document {
                                ... on PrismicBlogPostAuthor {
                                  id
                                  data {
                                    authors_image {
                                      gatsbyImageData(placeholder: BLURRED)
                                    }
                                    full_name {
                                      richText
                                      text
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicTargetsDataBodyTabbedCodeBlock {
            id
            items {
              code {
                richText
                text
              }
              language
            }
            slice_type
            primary {
              cta_label_1
              cta_label_2
              cta_link_1 {
                url
              }
              cta_link_2 {
                url
              }
              show_copy_button
              subtitle {
                richText
                text
              }
              is_dark_theme: theme
              title {
                richText
                text
              }
            }
          }
        }
      }
    }
    allPrismicBlog(
      sort: { fields: data___release_date, order: DESC }
      filter: { tags: { nin: "test" } }
      limit: 10
    ) {
      nodes {
        id
        type
        tags
        first_publication_date(formatString: "MMMM DD, yyyy")
        url
        data {
          new_until
          description {
            text
            richText
          }
          title {
            text
            richText
          }
          article_image {
            alt
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          release_date(formatString: "MMM DD, yyyy")
          time_spent_reading
          body {
            ... on PrismicBlogDataBodyBlogAuthor {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
              }
            }
            ... on PrismicBlogDataBodyAuthorV2 {
              id
              slice_type
              primary {
                blog_author {
                  document {
                    ...BlogPostAuthorFragment
                  }
                }
                is_global
              }
            }
            ... on PrismicBlogDataBodySocialButtonsBar {
              id
              slice_type
              items {
                topic {
                  document {
                    ...TopicItemFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(TargetsPage);
