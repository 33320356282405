import styled from '@emotion/styled';
import { TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_WRAP,
  CSS_JUSTIFY_CONTENT,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeInputWidthShape,
  getThemeMainColor,
  ITheme,
} from 'theme';
import { IColor } from 'types';

export const BackgroundWrapper = styled.div<ITheme & IColor>(
  ({ background }) => ({
    background,
    width: '100%',
    padding: '0 20px',
    [MEDIA_QUERIES.TABLET]: {
      padding: 0,
    },
  })
);

export const LogosContainer = styled.div<ITheme>((props) => ({
  maxWidth: '1024px',
  margin: '0 auto',
  gridRow: 1 / 1,
  gridColumn: 2 / 2,
  height: 'auto',
  p: {
    margin: 0,
    lineHeight: '20px',
    fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
    fontWeight: 400,
    fontFamily: getThemeFontFamily(props),
    color: getThemeMainColor(props),
    [MEDIA_QUERIES.TABLET]: {
      textAlign: CSS_ALIGN.CENTER,
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    gridRow: '3 / 4',
    gridColumn: '1 / 2',
    justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  },
}));

export const PageLogos = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  width: getThemeInputWidthShape(props, TSHIRT_SIZE.XL),
  justifyContent: CSS_JUSTIFY_CONTENT.SPACE_BETWEEN,
  flexWrap: CSS_FLEX_WRAP.WRAP,
  alignItems: CSS_ALIGN.CENTER,
  margin: `${getThemeGutter(props, TSHIRT_SIZE.S)} 0`,
  div: {
    verticalAlign: 'middle',
    display: CSS_DISPLAY.INLINE_BLOCK,
    img: {
      margin: '0 auto',
      display: CSS_DISPLAY.INLINE_BLOCK,
      height: 'auto',
      width: 'auto',
    },
  },
  [MEDIA_QUERIES.TABLET]: {
    justifyContent: CSS_JUSTIFY_CONTENT.SPACE_AROUND,
    div: {
      margin: `5px ${getThemeInputPaddingShape(props, TSHIRT_SIZE.S)}`,
      img: {
        width: 'auto',
      },
    },
  },
}));
