import { TSHIRT_SIZE } from 'components/constants';
import React, { ChangeEvent } from 'react';
import {
  ArrowDown,
  HeaderContainer,
  HideableDropdownIndicator,
  HideableIconWrapper,
  StyledInput,
} from '../styledComponents';
import { IHeader } from '../types';

const Header: React.FC<IHeader> = ({
  handleListVisibility,
  inputValue,
  placeholder,
  onInputChange,
  isSearchable,
  isListVisible,
  selectedValue,
  icon,
  onFocus,
  onBlur,
  disabled,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    onInputChange(event.target.value);
  };

  return (
    <HeaderContainer tabIndex={1} onClick={onFocus}>
      <HideableIconWrapper
        gutterBot={TSHIRT_SIZE.XXS}
        gutterTop={TSHIRT_SIZE.XXS}
        gutterLeft={TSHIRT_SIZE.XS}
        isVisible={!!icon}
      >
        {icon}
      </HideableIconWrapper>
      <StyledInput
        disabled={disabled}
        isSearchable={isSearchable}
        readOnly={!isListVisible || !isSearchable}
        width={TSHIRT_SIZE.S}
        value={inputValue}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={selectedValue || placeholder}
        onChange={handleInputChange}
        isIcon={!!icon}
      />
      <HideableDropdownIndicator
        tabIndex={1}
        onBlur={onBlur}
        onClick={handleListVisibility}
        isVisible={!disabled}
      >
        <ArrowDown />
      </HideableDropdownIndicator>
    </HeaderContainer>
  );
};

export default Header;
