import styled from '@emotion/styled';
import React from 'react';
import {
  FONT_FAMILY,
  getThemeErrorColor,
  getThemeFontFamily,
  getThemeFontLineHeight,
  getThemeInputFontSizeShape,
  ITheme,
} from 'theme';
import hideable from 'utils/hideable';
import { TEXT_TYPE, TSHIRT_SIZE } from './constants';

export const ErrorText = styled.div<ITheme>((props) => ({
  fontFamily: getThemeFontFamily(props, FONT_FAMILY.ADDITIONAL),
  fontSize: getThemeInputFontSizeShape(props, TSHIRT_SIZE.M),
  lineHeight: getThemeFontLineHeight(props, TEXT_TYPE.SMALL),
  color: getThemeErrorColor(props),
  whiteSpace: 'nowrap',
  height: 0,
}));

interface IInputError {
  error?: string | React.ReactNode;
  dataTracker?: string;
}

const InputError: React.FC<IInputError> = ({ error, dataTracker }) => (
  <ErrorText data-tracker={dataTracker}>{error}</ErrorText>
);
export default hideable(InputError);
