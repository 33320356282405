import React from 'react';
import hideable from 'utils/hideable';
import { LogosContainer, RecognisedByContainer } from './styledComponents';
import { IRecognisedByData } from './types';
import { RichText } from 'components/v2/RichText';
import { GatsbyImg } from '@smartproxy-web/ui';

const RecognisedBy: React.FC<IRecognisedByData> = ({
  data: { title, repeatable_zone: repeatableZone },
}) => (
  <RecognisedByContainer>
    <RichText field={title} />
    <LogosContainer>
      {repeatableZone.map(({ icon }) => (
        <GatsbyImg image={icon} key={icon.alt} loading="lazy" />
      ))}
    </LogosContainer>
  </RecognisedByContainer>
);

export default hideable(RecognisedBy);
