import React, { createContext, useContext, useEffect, useState } from 'react';
import spTracker from '@smartproxy/sp-tracker-js';

export type ISpTrackerContext = {
  spTracker: typeof spTracker | null;
  isError: boolean;
};

export const TrackerContext = createContext<ISpTrackerContext>({
  spTracker: null,
  isError: false,
});

export const useSpTrackerContext = () => useContext(TrackerContext);

export const SpTrackerContextProvider: React.FC<
  React.PropsWithChildren<{ projectName: string }>
> = ({ children, projectName }) => {
  const [isError, setIsError] = useState(false);
  const [spTrackerContext, setSpTrackerContext] = useState<
    typeof spTracker | null
  >(null);

  useEffect(() => {
    async function initSpTracker() {
      try {
        const spTrackerInstance = await spTracker.init({
          project: projectName,
          isProduction: process.env.GATSBY_NODE_ENV === 'production',
          options: {
            api_host: '/ph',
          },
        });

        if (spTrackerInstance) {
          setSpTrackerContext(spTrackerInstance);
        }
      } catch {
        setIsError(true);
      }
    }
    initSpTracker();
  }, []);

  return (
    <TrackerContext.Provider
      value={{
        spTracker: spTrackerContext,
        isError,
      }}
    >
      {children}
    </TrackerContext.Provider>
  );
};
