import { IRequestLanguageTemplateArgs } from '../types';
import { buildReqBodyFromMap } from '../utils';

const cURLTemplate = ({
  target,
  query: url,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `curl --request 'POST',
  --url: 'https://smartproxy.com/scraping/social-media/scraper-api',
  -H: 'Content-Type: application/json',
  -d: '
    {
      "url": "${url}",
      "target": "${target}",
      "geo": "${geo}",${buildReqBodyFromMap(params, padding ?? 6)}
    }
  '`;
};

const nodeTemplate = ({
  target,
  query: url,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `const request = require('request');
  
  const username = 'YOUR_USERNAME';
  const password = 'YOUR_PASSWORD';
  
  const options = {
    method: 'POST',
    url: 'https://smartproxy.com/scraping/social-media/scraper-api',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + Buffer.from(username + ':' + password).toString('base64')
    },
    body: JSON.stringify({
      "target": "${target}",
      "url": "${url}",
      "geo": "${geo}",${buildReqBodyFromMap(params, padding ?? 6)}
    })
  };
  
  request(options, function (error, response, body) {
    if (error) throw new Error(error);
  
    console.log(body);
  });`;
};

const pythonTemplate = ({
  target,
  query: url,
  geo,
  params,
  padding,
}: IRequestLanguageTemplateArgs) => {
  return `import requests
  
headers = {
  'Content-Type': 'application/json'
}
  
task_params = {
  "target": "${target}",
  "url": "${url}",
  "geo": "${geo}",${buildReqBodyFromMap(params, padding ?? 2)}
}
  
username = 'username'
password = 'password'
    
response = requests.post(
  'https://smartproxy.com/scraping/social-media/scraper-api',
  headers = headers,
  json = task_params,
  auth = (username, password)
)
print(response.text)`;
};

export const socialRequestTemplates = {
  curl: cURLTemplate,
  node: nodeTemplate,
  python: pythonTemplate,
};
