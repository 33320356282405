import { Paths, TemplatePaths } from 'paths';

const replacePath = (path: string) =>
  path === '/' ? path.replace(/\/$/, '') : path;

export const linkResolver = ({
  type,
  tags,
}: {
  type: string;
  tags: string[];
}) => {
  if (Paths[type]) {
    return Paths[type];
  } else if (TemplatePaths[type]) {
    const path = replacePath(TemplatePaths[type]);
    return `${path}${tags.length > 0 ? `/${tags.join('/')}` : ''}`;
  }
};
