import styled from '@emotion/styled';
import { BackgroundWrapper } from 'commonStyles';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { GeneratedIdWrapper } from 'features/GeneratedIdWrapper';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  MAIN_COLOR,
} from 'theme';
import { themev2 } from 'theme-v2';

export const StyledBackground = styled(BackgroundWrapper)((props) => ({
  maxWidth: 'unset',
  margin: 0,
  padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  textAlign: TEXT_ALIGN.CENTER,
}));

export const TitleWrapper = styled(GeneratedIdWrapper)<{
  isTextLight: boolean;
}>(({ isTextLight, ...props }) => ({
  marginBottom: themev2.spacings.L,
  color: isTextLight
    ? getThemeMainColor(props, MAIN_COLOR.WHITE)
    : getThemeMainColor(props, MAIN_COLOR.BLACK),
}));

export const ColumnsContainer = styled.div<{ numbOfColumns: number }>(
  ({ numbOfColumns, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    gridTemplateColumns: `repeat(${numbOfColumns}, 1fr)`,
    maxWidth: '1024px',
    gap: getThemeGutter(props, TSHIRT_SIZE.L),
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const Content = styled.div<{ isTextLight: boolean }>(
  ({ isTextLight, ...props }) => ({
    'p, strong, em': {
      marginBottom: 0,
      fontSize: getThemeFontSize(props, TEXT_TYPE.P1),
      color: isTextLight
        ? getThemeMainColor(props, MAIN_COLOR.WHITE)
        : getThemeMainColor(props, MAIN_COLOR.BLACK),
    },
    'p:first-of-type': {
      marginBottom: '6px',
    },
    img: {
      maxWidth: '48px',
      maxHeight: '48px',
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
    },
  })
);
