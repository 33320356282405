import { BUTTON_THEMES, BUTTON_TYPES } from 'components/Button';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  Container,
  CtaBlock,
  CtasContainer,
  FooterApiWrapper,
  MoneyBackWrapper,
} from './styledComponents';
import { IPrismicFooterBanner } from './types';
import MoneyBackTag from 'components/v2/MoneyBackTag/MoneyBackTag';
import { RichText } from 'components/v2/RichText';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const FooterBanner: React.FC<IPrismicFooterBanner> = ({
  primary: {
    title,
    subtitle,
    cta_one_text: ctaOneText,
    cta_one_link: ctaOneLink,
    cta_two_text: ctaTwoText,
    cta_two_link: ctaTwoLink,
    is_money_back_visible,
  },
}) => {
  const { id: sliceId } = useSliceDetails();
  const { type, tags } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'footer-api',
    prefix: type,
  });
  const isMoneyBackVisible = is_money_back_visible ?? true;
  return (
    <FooterApiWrapper>
      <Container>
        <RichText field={title} />
        <RichText field={subtitle} />
        <div>
          <CtasContainer>
            <CtaBlock
              isVisible={ctaOneText.text && ctaOneLink.url}
              ctaText={ctaOneText.text}
              ctaLink={ctaOneLink.url}
              themeName={BUTTON_THEMES.IMPORTANT}
              themeType={BUTTON_TYPES.PRIMARY}
              data-tracker={trackingSelector({ context: ctaOneText.text })}
              sendTrackingEvent={() => {
                logPostHogEvent(trackingSelector({ context: ctaOneText.text }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `cta-${ctaOneText.text}`,
                    sectionId: sliceId,
                  });
                }
              }}
            />
            <CtaBlock
              isVisible={ctaTwoText.text && ctaTwoLink.url}
              ctaText={ctaTwoText.text}
              ctaLink={ctaTwoLink.url}
              themeName={BUTTON_THEMES.CONVERSION}
              themeType={BUTTON_TYPES.SECONDARY}
              data-tracker={trackingSelector({ context: ctaTwoText.text })}
              sendTrackingEvent={() => {
                logPostHogEvent(trackingSelector({ context: ctaTwoText.text }));
                if (sliceId) {
                  logGAElementClickEvent({
                    elementId: `cta-${ctaTwoText.text}`,
                    sectionId: sliceId,
                  });
                }
              }}
            />
          </CtasContainer>
          <MoneyBackWrapper>
            <MoneyBackTag isVisible={isMoneyBackVisible && !!ctaOneLink.url} />
          </MoneyBackWrapper>
        </div>
      </Container>
    </FooterApiWrapper>
  );
};

export default FooterBanner;
