import { TProduct } from 'constants/products';
import { isBrowser } from 'utils/build';
import { getPlanId } from 'utils/plans';
import {
  COOKIE_VALUES,
  DEDICATED_DC_PLANS_NON_SEQ,
  SCRAPER_PLANS,
} from '../constants/common';
import { getRootDomain, setCookie } from '../cookies';

let domain = '';

if (isBrowser) {
  const hostname = window.location.hostname;
  domain = getRootDomain(hostname);
}

export const setPlanCookies = ({
  planName,
  scrapingApiPlan,
  DDCNonSeq,
  product,
}: {
  planName: string;
  scrapingApiPlan?: string;
  DDCNonSeq?: boolean;
  product?: TProduct;
}) => {
  clearPlanCookies();
  if (scrapingApiPlan) setSerpPlanCookieOnClick(scrapingApiPlan, planName);
  else setPlanCookieOnClick({ element: planName, nonSeq: DDCNonSeq, product });
};

export const clearPlanCookies = () => {
  deletePlanCookieOnClick();
};

export const setSerpPlanCookieOnClick = (
  scrapingApiPlan: string,
  planName: string
) => {
  setCookie({
    cname: COOKIE_VALUES.PLAN_ID,
    cvalue: SCRAPER_PLANS[scrapingApiPlan][planName].id,
    domain,
  });
};

export const setPlanCookieOnClick = ({
  element,
  nonSeq,
  product,
}: {
  element: string;
  nonSeq?: boolean;
  product?: TProduct;
}) => {
  if (nonSeq) {
    setCookie({
      cname: COOKIE_VALUES.PLAN_ID,
      cvalue: DEDICATED_DC_PLANS_NON_SEQ[element].id,
      domain,
    });
  } else {
    const planId = getPlanId(element, product);

    if (planId) {
      setCookie({
        cname: COOKIE_VALUES.PLAN_ID,
        cvalue: planId,
        domain,
      });
    }
  }
};

const forcePlanCookieDeletion = (name: string) => {
  document.cookie =
    name + `=;expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=${domain};path=/`;
};

export const deletePlanCookieOnClick = () => {
  forcePlanCookieDeletion(COOKIE_VALUES.PLAN_ID);
  forcePlanCookieDeletion(COOKIE_VALUES.PLAN_NAME);
  forcePlanCookieDeletion(COOKIE_VALUES.TRAFFIC_LIMIT);
  forcePlanCookieDeletion(COOKIE_VALUES.PLAN_TYPE);
};
