import styled from '@emotion/styled';
import { hexToRgba } from '@smartproxy-web/shared/utils';
import { Button } from 'components/v2/Button';
import { richTextElements, richTextParagraphElements } from 'constants/common';
import { MAX_BODY_CONTENT_WIDTH } from 'constants/styles';
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from 'features/v2/Header/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { CSS_ALIGN, CSS_DISPLAY } from 'theme';
import { themev2 } from 'theme-v2';

const DESKTOP_HEADER_HERO_GAP = 60;
const MOBILE_HEADER_HERO_GAP = 32;

export const StyledHeroWrapper = styled.section({
  background: themev2.colors.neutral.N99,
  overflow: 'hidden',
  padding: `${HEADER_HEIGHT_DESKTOP + DESKTOP_HEADER_HERO_GAP}px 20px ${
    themev2.spacings.L
  }`,
  width: '100%',

  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: `${HEADER_HEIGHT_MOBILE + MOBILE_HEADER_HERO_GAP}px 20px ${
      themev2.spacings.L
    }`,
  },
});

export const StyledHeroWrapperInner = styled.div({
  maxWidth: MAX_BODY_CONTENT_WIDTH,
  margin: '0 auto',
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr 360px',
  gap: themev2.spacings.L,
  alignItems: 'center',

  [MEDIA_QUERIES.TABLET]: {
    gap: themev2.spacings.M,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
  },
});

export const StyledHeroImageWrapper = styled.div({
  maxWidth: '360px',
  maxHeight: '360px',
  width: '100%',
  height: '100%',
  position: 'relative',

  '[data-gatsby-image-wrapper]': {
    width: '100%',
    height: '100%',
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    display: 'none',
  },
});

export const StyledHeroLightboxTrigger = styled(Button)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80px',
  width: '80px',
  borderRadius: '80px',
  backgroundColor: hexToRgba(themev2.colors.neutral.N00, 0.2),
  backdropFilter: 'blur(12px)',

  'svg[data-layer-top]': {
    position: 'absolute',
    opacity: 0,
    transition: '0.4s linear',
  },

  ':hover': {
    transform: 'translate(-50%, -50%) scale(1.05)',

    svg: {
      fill: 'white',
    },

    'svg[data-layer-top]': {
      opacity: 1,
    },
  },
  ':focus': {
    'svg[data-layer-top]': {
      opacity: 1,
    },
  },
  svg: {
    fill: 'white',
  },
});

export const StyledHeroMainContentWrapper = styled.div({
  width: '100%',
  height: 'auto',
  color: themev2.colors.white.default,
  display: 'flex',
  flexDirection: 'column',
  [MEDIA_QUERIES.TABLET]: {
    width: '100%',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    a: {
      width: '100%',
    },
  },
});

export const HeroTitleWrapper = styled.div({
  marginBottom: themev2.spacings.S,
  h1: {
    color: themev2.colors.neutral.N00,
  },
});

export const StyledHeroDescriptionWrapper = styled.div({
  marginBottom: '36px',

  [richTextParagraphElements]: {
    color: themev2.colors.neutral.N50,
  },
});

export const StyledHeroFeaturesWrapper = styled.div<{ order: number }>(
  ({ order }) => ({
    display: 'flex',
    gap: '1rem',
    flexWrap: 'wrap',
    ...(!order ? { marginBottom: '36px' } : { marginTop: '36px' }),
    order,

    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      flexWrap: 'unset',
    },
  })
);

export const HeroFeature = styled.div({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  color: themev2.colors.neutral.N20,
  lineHeight: themev2.fonts.lineHeight.p_small,
  fontSize: themev2.fonts.size.p_small,
  gap: '8px',
  fontWeight: 300,
});

export const StyledHeroCtaWrapper = styled.div<{ order: number }>(
  ({ order }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: themev2.spacings.S,
    order,
    'a > div': {
      minWidth: '200px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      'a > div': {
        width: '100%',
      },
    },
  })
);

export const StyledCountdownTimerWrapper = styled.div({
  marginBottom: '8px',
  color: themev2.colors.black.S900,
});

export const ValidationTextComponent = styled.div({
  width: '100%',
  marginBottom: themev2.spacings.S,
  [richTextElements]: {
    color: themev2.colors.green.default,
    fontSize: themev2.fonts.size.p_xsmall,
    lineHeight: themev2.fonts.lineHeight.p_xsmall,
    fontWeight: 400,
  },
  strong: {
    fontWeight: 500,
  },
  a: {
    color: themev2.colors.blue.default,
  },
});
