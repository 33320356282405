import styled from '@emotion/styled';
import { TEXT_ALIGN, TEXT_TYPE, TSHIRT_SIZE } from 'components/constants';
import { richTextParagraphElements } from 'constants/common';
import { IBackground } from 'features/utils';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  COLOR_SHADE,
  CSS_ALIGN,
  CSS_DISPLAY,
  CSS_FLEX_DIRECTION,
  CSS_JUSTIFY_CONTENT,
  getThemeBrandedColor,
  getThemeFontFamily,
  getThemeFontSize,
  getThemeGutter,
  getThemeInputPaddingShape,
  getThemeMainColor,
  ITheme,
  MAIN_COLOR,
} from 'theme';
import { IColor } from 'types';
import hideable from 'utils/hideable';

export const Wrapper = styled.div<IColor & IBackground>(
  ({ background, isBackgroundDark, ...props }) => ({
    background,
    width: '100%',
    height: '100%',
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    'h1, h2, h3, p, ol, ul, strong, em, li': {
      color: isBackgroundDark
        ? getThemeMainColor(props, MAIN_COLOR.BLACK)
        : getThemeMainColor(props),
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      padding: '60px 20px',
    },
    [MEDIA_QUERIES.PHONE_PORTRAIT]: {
      padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    },
  })
);

export const Container = styled.div({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  maxWidth: '1024px',
  width: '100%',
  margin: '0 auto',
});

export const ItemsContainer = styled.div((props) => ({
  display: CSS_DISPLAY.GRID,
  width: '100%',
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.XL),
  gridAutoColumns: '1fr',
  columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
  rowGap: getThemeGutter(props, TSHIRT_SIZE.L),
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'auto',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(2, auto)',
  },
}));

export const ItemContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  alignItems: CSS_ALIGN.CENTER,
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  },
});

export const LogoContainer = styled.div((props) => ({
  display: CSS_DISPLAY.INLINE_BLOCK,
  marginRight: getThemeGutter(props, TSHIRT_SIZE.S),
  div: {
    width: '132px',
    height: '132px',
  },
  [MEDIA_QUERIES.TABLET]: {
    marginRight: 0,
    marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
  },
}));

export const TextContainer = styled.div({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  'p, ol, ul, strong, em, li': {
    marginBottom: '10px',
    [MEDIA_QUERIES.TABLET]: {
      textAlign: TEXT_ALIGN.CENTER,
    },
  },
});

export const ItemTitle = styled.div((props) => ({
  display: CSS_DISPLAY.FLEX,
  [richTextParagraphElements]: {
    fontFamily: getThemeFontFamily(props),
    color: getThemeBrandedColor(props, COLOR_SHADE.PRIMARY),
    fontSize: getThemeFontSize(props, TEXT_TYPE.H2),
    lineHeight: '40px',
    fontWeight: 500,
    marginBottom: '6px',
  },
  [MEDIA_QUERIES.TABLET]: {
    textAlign: TEXT_ALIGN.CENTER,
  },
}));

export const DescriptionContainer = styled.div((props) => ({
  width: '100%',
  marginTop: 0,
  marginBottom: 0,
  [richTextParagraphElements]: {
    fontSize: getThemeFontSize(props, TEXT_TYPE.H5),
  },
}));

const TitlesContainer = styled.div<ITheme>((props) => ({
  display: CSS_DISPLAY.FLEX,
  flexDirection: CSS_FLEX_DIRECTION.COLUMN,
  justifyContent: CSS_JUSTIFY_CONTENT.CENTER,
  alignItems: CSS_ALIGN.CENTER,
  marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
}));

export const HideableTitles = hideable(TitlesContainer);
