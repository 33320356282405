import GatsbyImg from 'components/GatsbyImg';

import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import {
  ContentContainer,
  MetricDescription,
  Metrics,
  MetricTitle,
  StyledBackground,
  StyledCta,
  TextContent,
  TitleWrapper,
} from './styledComponents';
import { ITextAndImageBlock } from './types';
import { RichText } from 'components/v2/RichText';

const TextAndImageBlock: React.FC<ITextAndImageBlock> = ({
  primary: {
    background,
    cta_link: ctaLink,
    cta_text: ctaText,
    description,
    image,
    is_text_light: isTextLight,
    title,
  },
  items,
}) => {
  const { tags, type } = usePageContext();
  return (
    <StyledBackground background={background}>
      <ContentContainer>
        <GatsbyImg image={image} />
        <TextContent isTextLight={isTextLight}>
          <TitleWrapper text={title}>
            <RichText field={title} />
          </TitleWrapper>
          <RichText field={description} />
          <StyledCta
            ctaLink={ctaLink.url}
            ctaText={ctaText}
            isVisible={ctaLink.url && ctaText}
            linkClassName={buildTrackingSelector({
              page: tags[0] || type,
              section: title.text,
              context: ctaText,
              type: 'cta',
            })}
          />
          <Metrics>
            {items.map(
              ({
                metric_description: metricDescription,
                metric_title: metricTitle,
              }) => (
                <div key={metricTitle.text}>
                  <MetricTitle>
                    <RichText field={metricTitle} />
                  </MetricTitle>
                  <MetricDescription>
                    <RichText field={metricDescription} />
                  </MetricDescription>
                </div>
              )
            )}
          </Metrics>
        </TextContent>
      </ContentContainer>
    </StyledBackground>
  );
};

export default TextAndImageBlock;
