import { TEXT_ALIGN } from './../../components/constants';
import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { TSHIRT_SIZE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import {
  CSS_ALIGN,
  CSS_DISPLAY,
  getThemeGutter,
  getThemeInputPaddingShape,
  ITheme,
} from 'theme';
import { IColor } from 'types';

export const BackgroundContainer = styled.section<ITheme & IColor>(
  ({ background, ...props }) => ({
    padding: `${getThemeInputPaddingShape(props, TSHIRT_SIZE.XL)} 20px`,
    width: '100%',
    ...flexColContainerStyles,
    alignItems: CSS_ALIGN.CENTER,
    background,
  })
);

export const LinksList = styled.ul<ITheme & { columns: number }>(
  ({ columns, ...props }) => ({
    display: CSS_DISPLAY.GRID,
    width: '100%',
    maxWidth: '1024px',
    gridTemplateColumns: `repeat(${columns || 3}, 1fr)`,
    columnGap: getThemeGutter(props, TSHIRT_SIZE.L),
    listStyle: 'none',
    padding: 0,
    [MEDIA_QUERIES.TABLET]: {
      columnGap: getThemeGutter(props, TSHIRT_SIZE.S),
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const LinksListItem = styled.li<ITheme & { isTitleVisible?: boolean }>(
  ({ isTitleVisible, ...props }) => ({
    padding: 0,
    'h2,h3,h4,h5,h6': {
      fontWeight: '500',
      marginBottom: getThemeGutter(props, TSHIRT_SIZE.S),
      textAlign: TEXT_ALIGN.LEFT,
    },
    h3: { fontSize: '22px' },
    a: {
      display: CSS_DISPLAY.INLINE_BLOCK,
      marginBottom: '10px',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      marginBottom: isTitleVisible ? getThemeGutter(props, TSHIRT_SIZE.L) : 0,
      ':last-child': {
        marginBottom: 0,
      },
    },
  })
);
