import { PrismicInputField, TGatsbyImage } from 'types';
import { ClickTrackingSelectorGeneratorProps } from 'utils/page';

export enum HEADER_SLICES {
  Nav = 'nav_item',
  Logo = 'logo',
}

export const verticalScrollOffset = 170;
export interface ILogo {
  primary: { logo: TGatsbyImage };
  navbar: boolean;
}
interface IItem {
  nav_title: string;
  nav_url: PrismicInputField;
}
export interface INavItems {
  items: IItem[];
}
export interface IDataTracker {
  buildTrackingId: (props?: ClickTrackingSelectorGeneratorProps) => string;
}
export interface IHeaderComponents extends IDataTracker {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}
