import GatsbyImg from 'components/GatsbyImg';
import { THeroSlice } from 'features/HomePage/types';
import * as React from 'react';
import {
  HeroHomePage,
  HeroHomePageImageBlock,
  HeroHomePageLogos,
  HeroHomePageLogosContainer,
  HeroHomePageTextBlock,
  StyledHomePageHeroContainer,
  StyledHomePageHeroWrapper,
} from './styledComponents';
import Cta from 'components/Cta';
import { NewRichText } from 'components/PrismicRichText';
import { generateSectionsId, slugify } from 'features/utils';
import { IScreenProps } from 'utils/screenTypeHOC';
import { buildTrackingSelector } from 'utils/page';
import { SECTION_NAMES } from 'constants/common';

const Hero: React.FC<THeroSlice & IScreenProps> = ({
  title,
  description,
  ctaText,
  ctaLink,
  homepageHero,
  primary: { background, title_for_logos: logosTitle },
  pageContext: { tags, type },
  items,
}) => (
  <StyledHomePageHeroWrapper
    background={background}
    data-section-id={generateSectionsId(SECTION_NAMES.HERO, tags, type)}
  >
    <StyledHomePageHeroContainer>
      <HeroHomePage>
        <HeroHomePageTextBlock>
          <NewRichText text={title} />
          <NewRichText text={description} />
          <Cta
            linkId={`hero-${slugify(tags[0] || type)}-cta`}
            linkClassName={buildTrackingSelector({
              page: tags[0] || type,
              section: 'hero',
              type: 'cta',
            })}
            ctaText={ctaText.text}
            isVisible
            ctaLink={ctaLink.url}
          />
        </HeroHomePageTextBlock>
        <HeroHomePageImageBlock>
          <GatsbyImg image={homepageHero} />
        </HeroHomePageImageBlock>
        <HeroHomePageLogosContainer>
          <NewRichText text={logosTitle} />
          <HeroHomePageLogos>
            {items.map((item, index) => (
              <GatsbyImg image={item.logos} key={index} />
            ))}
          </HeroHomePageLogos>
        </HeroHomePageLogosContainer>
      </HeroHomePage>
    </StyledHomePageHeroContainer>
  </StyledHomePageHeroWrapper>
);

export default Hero;
