import { Link } from 'components/GatsbyLink';
import React from 'react';
import hideable from 'utils/hideable';
import FlagContent from './FlagContent';
import { LocationFlagsItem } from './styledComponents';
import { IFlagsItem } from './types';
import {
  logGAElementClickEvent,
  logPostHogEvent,
} from '@smartproxy-web/analytics';
import { useSliceDetails } from 'hooks/useSliceDetails';

const HideableLink = hideable(Link);

const FlagsItem: React.FC<IFlagsItem> = ({ url, icon, selector, text }) => {
  const { id: sliceId } = useSliceDetails();

  return (
    <LocationFlagsItem>
      <HideableLink
        isVisible={url}
        to={url}
        className={selector}
        data-tracker={selector}
        onClick={() => {
          logPostHogEvent(selector);
          if (sliceId) {
            logGAElementClickEvent({
              elementId: text.text ?? icon.alt,
              sectionId: sliceId,
            });
          }
        }}
      >
        <FlagContent isVisible icon={icon} text={text} />
      </HideableLink>
      <FlagContent isVisible={!url} icon={icon} text={text} />
    </LocationFlagsItem>
  );
};

export default FlagsItem;
