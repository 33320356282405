import { NewRichText } from 'components/PrismicRichText';
import React from 'react';
import { FormContainer, StyledDate } from '../styledComponents';
import { IFormComponent } from '../types';
import Form from './Form';

const FormComponent: React.FC<IFormComponent> = ({
  title,
  date,
  formButtonId,
  info,
}) => (
  <FormContainer>
    <NewRichText text={title} />
    <StyledDate>
      <NewRichText text={date} />
    </StyledDate>
    <Form formButtonId={formButtonId} info={info} />
  </FormContainer>
);

export default FormComponent;
