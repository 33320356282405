import { Button } from 'components/v2/Button';
import { IconExpandMorev2 } from 'images';
import React, { useEffect, useRef } from 'react';
import { ClickTrackingSelectorGeneratorProps } from 'utils/page';
import { initDataLayer } from '../../helpers';
import { NAV_PAGE_TRANSITION_DURATION } from '../constants';
import { NavMenuItem } from '../NavMenuItem';
import { NavItemPageProps } from '../types';
import { useGetLangLink } from '../useLangLink';
import {
  StyledNavItemPage,
  StyledNavItemPageHeader,
  StyledNavItemPageMenu,
  StyledNavItemPageTitleWrapper,
} from './styledComponents';
import { Link } from 'components/GatsbyLink';
import { track } from '@smartproxy/sp-tracker-js';
import { IconMegaMenuArrow } from '@smartproxy-web/ui';

export const NavItemPage: React.FC<NavItemPageProps> = ({
  isCurrentPage,
  navItem,
  parentNavItem,
  handleBackClick,
  handleItemClick,
  buildTrackingId,
  closeDrawer,
}) => {
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const firstNavItemRef = useRef<HTMLButtonElement>(null);

  const hasParent = !!parentNavItem;

  const getLangLink = useGetLangLink();
  const isLanguageNavItemDisabled = (language) => {
    if (navItem?.key === 'languages') {
      return !getLangLink(language);
    }

    return false;
  };

  useEffect(() => {
    if (isCurrentPage) {
      setTimeout(() => {
        if (backButtonRef.current) {
          backButtonRef.current.focus();
          return;
        }
        if (firstNavItemRef.current) {
          firstNavItemRef.current.focus();
        }
      }, NAV_PAGE_TRANSITION_DURATION);
    }
  }, [isCurrentPage]);

  const buildChildTrackingId = (
    props?: ClickTrackingSelectorGeneratorProps
  ) => {
    return buildTrackingId({
      ...props,
      context: hasParent
        ? `${navItem.label}-${props?.context}`
        : props?.context,
    });
  };

  return (
    <StyledNavItemPage languages={navItem?.key === 'languages'}>
      {hasParent && (
        <StyledNavItemPageHeader>
          <Button
            ref={backButtonRef}
            variant="text"
            color="gray"
            size="sm"
            compact
            leftIcon={<IconExpandMorev2 />}
            onClick={() => {
              handleBackClick(),
                initDataLayer(
                  buildTrackingId({
                    context: `back-to-${parentNavItem.label}`,
                    type: 'button',
                  })
                );
            }}
            tabIndex={isCurrentPage ? 0 : -1}
            data-tracker={buildTrackingId({
              context: `back-to-${parentNavItem.label}`,
              type: 'button',
            })}
          >
            {parentNavItem.renderLabel
              ? parentNavItem.renderLabel()
              : parentNavItem.label}
          </Button>
        </StyledNavItemPageHeader>
      )}
      {hasParent && (
        <StyledNavItemPageTitleWrapper>
          {!!navItem.titleUrl?.url ? (
            <Link
              to={navItem.titleUrl?.url}
              data-tracker={buildTrackingId({
                context: navItem.label,
                section: 'header-dropdown',
                type: 'link',
              })}
              onClick={() => {
                track(
                  buildTrackingId({
                    context: navItem.label,
                    section: 'header-dropdown',
                    type: 'link',
                  })
                );
                initDataLayer(
                  buildTrackingId({
                    context: navItem.label,
                    section: 'header-dropdown',
                    type: 'link',
                  })
                );
              }}
            >
              <p>
                {navItem.label} <IconMegaMenuArrow />
              </p>
            </Link>
          ) : (
            <p>{navItem.renderLabel ? navItem.renderLabel() : navItem.label}</p>
          )}
        </StyledNavItemPageTitleWrapper>
      )}
      <StyledNavItemPageMenu>
        {navItem.children?.map((navItemChild, index) => (
          <li key={navItemChild.key}>
            <NavMenuItem
              disabled={isLanguageNavItemDisabled(navItemChild)}
              ref={index === 0 ? firstNavItemRef : undefined}
              tabIndex={isCurrentPage ? 0 : -1}
              navItem={navItemChild}
              handleClick={handleItemClick}
              buildTrackingId={buildChildTrackingId}
              hasPricing={!!navItemChild.price}
              closeDrawer={closeDrawer}
            />
          </li>
        ))}
      </StyledNavItemPageMenu>
    </StyledNavItemPage>
  );
};
