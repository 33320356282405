import React from 'react';
import {
  CategoryBannerContainer,
  CategoryTitle,
  StyledBannerDescription,
  StyledBannerTitle,
  StyledLinkButtonContainer,
} from '../styledComponents';
import { CategoriesBannerItems } from './types';
import { Box, GatsbyImg, theme } from '@smartproxy-web/ui';
import { RichText } from '@smartproxy-web/features/src/components/RichText';
import { logPostHogEvent } from '@smartproxy-web/analytics';

export const CategoryBanner: React.FC<CategoriesBannerItems> = ({
  title: fieldTitle,
  handleClose,
  buildTrackingId,
  additionalBannerTitle: title,
  additionalBannerDescription: description,
  additionalBannerIcon: icon,
  additionalBannerCtaLabel: ctaLabel,
  additionalBannerCtaLink: ctaLink,
}) => {
  return (
    <>
      {fieldTitle && <CategoryTitle>{fieldTitle}</CategoryTitle>}
      <Box
        sx={{
          paddingTop: '8px',
          marginLeft: theme.spacings.XS,
          width: '200px',
        }}
      >
        <CategoryBannerContainer>
          {!!icon?.url && (
            <Box
              sx={{
                width: '24px',
                height: '24px',
                marginBottom: theme.spacings.XS,
              }}
            >
              <GatsbyImg image={icon} />
            </Box>
          )}
          <StyledBannerTitle>{title}</StyledBannerTitle>
          <StyledBannerDescription>
            <RichText field={description} />
          </StyledBannerDescription>
          <StyledLinkButtonContainer
            color="white"
            variant="outlined"
            to={ctaLink?.url}
            data-tracker={buildTrackingId({
              context: 'banner',
              type: 'button',
            })}
            onClick={() => {
              logPostHogEvent(
                buildTrackingId({
                  context: 'banner',
                  type: 'button',
                })
              ),
                handleClose();
            }}
          >
            {ctaLabel?.text}
          </StyledLinkButtonContainer>
        </CategoryBannerContainer>
      </Box>
    </>
  );
};
