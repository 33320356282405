import { DATA_PROTECTION_URL } from 'constants/common';
import { RadialProgress } from 'components/RadialProgress';
import { usePageContext } from 'contexts/PageContext';
import { FormikErrors, FormikTouched } from 'formik';
import { Link } from 'components/GatsbyLink';
import React from 'react';
import theme from 'theme';
import { initBuildTrackingSelector } from 'utils/page';
import { INFO_MAX_LENGTH, INFO_PROGRESS_VISIBILITY_RATIO } from './constants';
import CheckboxFormControl from './FormControls/CheckboxFormControl';
import {
  StyledErrorText,
  StyledSmallFormGroup,
  StyledTextarea,
  StyledTextareaFooter,
  StyledTextareaWrapper,
} from './styledComponents';
import { IJobApplicationForm } from './types';
import { track } from '@smartproxy/sp-tracker-js';

type IAdditionalInfoForm = Pick<IJobApplicationForm, 'info' | 'consent'>;

interface IAdditionalInfoFormProps {
  values: IAdditionalInfoForm;
  errors: FormikErrors<IAdditionalInfoForm>;
  touched: FormikTouched<IAdditionalInfoForm>;
  handleChange: React.ChangeEventHandler;
  handleBlur: React.FocusEventHandler;
}

const AdditionalInfoForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}: IAdditionalInfoFormProps) => {
  const { tags, type } = usePageContext();

  const buildClickSelector = initBuildTrackingSelector({
    page: tags[0] || type,
    section: 'job-application-form',
    type: 'field',
  });

  const infoLength = values.info.length;
  const infoLengthProgress = infoLength / INFO_MAX_LENGTH;
  const shouldShowInfoProgress =
    infoLengthProgress >= INFO_PROGRESS_VISIBILITY_RATIO;

  return (
    <div>
      <StyledTextareaWrapper error={errors.info}>
        <StyledTextarea
          name="info"
          data-tracker={buildClickSelector({ context: 'info' })}
          value={values.info}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Add a cover letter or anything else you want to share."
          onClick={() => track(buildClickSelector({ context: 'info' }))}
        />
        <StyledTextareaFooter>
          <span>Max. {INFO_MAX_LENGTH} characters</span>
          {shouldShowInfoProgress && (
            <span>
              {infoLength <= INFO_MAX_LENGTH && (
                <RadialProgress
                  progress={infoLengthProgress}
                  color={theme.colors.functional.accent}
                />
              )}
              {infoLength > INFO_MAX_LENGTH && (
                <small>{INFO_MAX_LENGTH - infoLength}</small>
              )}
            </span>
          )}
        </StyledTextareaFooter>
        {!!errors.info && <StyledErrorText>{errors.info}</StyledErrorText>}
      </StyledTextareaWrapper>
      <StyledSmallFormGroup>
        <CheckboxFormControl
          name="consent"
          dataTracker={buildClickSelector({ context: 'consent' })}
          checked={values.consent}
          error={touched.consent && errors.consent ? errors.consent : ''}
          onChange={handleChange}
          label={
            <small>
              I have read and understood the{' '}
              <Link to={DATA_PROTECTION_URL}>Privacy Notice</Link>
            </small>
          }
        />
      </StyledSmallFormGroup>
    </div>
  );
};

export default AdditionalInfoForm;
