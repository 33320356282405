import hideable from 'utils/hideable';
import React from 'react';
import Option from './Option';
import { HideableEmptyOption, StyledList } from '../styledComponents';
import { IList } from '../types';
import { PRISMIC_VALUES } from 'constants/common';

const List: React.FC<IList> = ({ options, onSelect, dataTracker }) => (
  <StyledList onMouseDown={(e) => e.preventDefault()}>
    <HideableEmptyOption isVisible={!options.length}>
      {PRISMIC_VALUES.EMPTY_LIST_TEXT}
    </HideableEmptyOption>
    {options.map(({ label, value }) => (
      <Option
        key={value}
        onClick={() => onSelect(value)}
        label={label}
        dataTracker={dataTracker}
      />
    ))}
  </StyledList>
);

export default hideable(List);
