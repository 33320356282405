import { IconRight } from 'images';
import { JobLink } from './styledComponents';
import { IJobItem } from './types';
import { RichText } from 'components/v2/RichText';
import { track } from '@smartproxy/sp-tracker-js';

const Job = ({ job }: { job: IJobItem }) => (
  <JobLink
    to={job.url}
    data-tracker={job.data.title.text}
    onClick={() => track(job.data.title.text)}
  >
    <div>
      <RichText field={job.data.title} />
      {!!job.data.type && (
        <p>
          {!!job.data.location?.text && (
            <span>{job.data.location.text} / </span>
          )}
          <span>{job.data.type}</span>
        </p>
      )}
    </div>
    <IconRight />
  </JobLink>
);

export default Job;
