import GatsbyImg from 'components/GatsbyImg';
import React from 'react';
import hideable from 'utils/hideable';
import {
  CheckmarkWrapper,
  ListItem,
  StyledFeaturesContainer,
  StyledList,
} from './styledComponents';
import { IFeatures } from './types';

const Features: React.FC<IFeatures> = ({ features, isCtaAdded }) => (
  <StyledFeaturesContainer isCtaAdded={isCtaAdded}>
    <StyledList>
      {features.map(({ logo, feature_text: { text } }) => (
        <ListItem key={text}>
          <CheckmarkWrapper>
            <GatsbyImg image={logo} />
          </CheckmarkWrapper>
          {text}
        </ListItem>
      ))}
    </StyledList>
  </StyledFeaturesContainer>
);

export default hideable(Features);
