import { track } from '@smartproxy/sp-tracker-js';
import React from 'react';
import { initDataLayer } from '../helpers';
import {
  StyledHeaderLogo,
  StyledHeaderLogoLink,
  StyledHeaderLogoWrapper,
} from '../styledComponents';
import { LogoProps } from './types';
import { GatsbyImg } from '@smartproxy-web/ui';

const HeaderLogo: React.FC<LogoProps> = ({
  logo,
  logoTrackerId,
  isClickable,
}) => (
  <>
    {isClickable ? (
      <StyledHeaderLogoLink
        to="/"
        data-tracker={logoTrackerId}
        onClick={() => {
          track(logoTrackerId), initDataLayer(logoTrackerId);
        }}
      >
        <StyledHeaderLogoWrapper>
          {!!logo && <GatsbyImg image={logo} loading="eager" />}
        </StyledHeaderLogoWrapper>
      </StyledHeaderLogoLink>
    ) : (
      <StyledHeaderLogo>
        <StyledHeaderLogoWrapper>
          {!!logo && <GatsbyImg image={logo} loading="eager" />}
        </StyledHeaderLogoWrapper>
      </StyledHeaderLogo>
    )}
  </>
);

export default HeaderLogo;
