import React, { createContext, useContext } from 'react';

interface IPageContext {
  tags: string[];
  type: string;
  isGatedContent: boolean;
}

const contextValue: IPageContext = {
  tags: [],
  type: '',
  isGatedContent: false,
};

export const PageContext = createContext(contextValue);

export const usePageContext = () => useContext(PageContext);

const PageContextProvider: React.FC<
  React.PropsWithChildren<{ pageContext: IPageContext }>
> = ({ children, pageContext }) => {
  return (
    <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
  );
};

export default PageContextProvider;
