import styled from '@emotion/styled';
import { DOM_STATE } from 'components/constants';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 as theme, themev2 } from 'theme-v2';
import { COMPACT_HEADER_MEDIA_QUERY } from '../Header/constants';

interface IFooter {
  itemsNumber?: number;
  isDealsOrLpPage: boolean;
}

export const Footer = styled.footer({
  background: theme.colors.black.S900,
  padding: '0 20px',
});

export const BottomBanner = styled.div({
  background: theme.colors.black.S600,
  padding: '18px 0',
  textAlign: 'center',
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 300,
  fontFamily: theme.fonts.family.main,
  color: theme.colors.gray.S500,
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
});

export const StyledTitle = styled.p({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  textTransform: 'uppercase',
  color: theme.colors.gray.S500,
  fontWeight: 400,
  letterSpacing: '0.1em',
  marginBottom: theme.spacings.XS,
});

export const LinksContainer = styled.div<IFooter>(
  ({ itemsNumber, isDealsOrLpPage }) => ({
    display: 'grid',
    gridTemplateColumns: isDealsOrLpPage
      ? `repeat(${itemsNumber}, auto)`
      : 'repeat(4, auto)',
    gap: '16px',
    maxWidth: 'min-content',
    [COMPACT_HEADER_MEDIA_QUERY]: {
      gridTemplateColumns: 'repeat(4, auto)',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gap: '12px',
      display: isDealsOrLpPage ? 'grid' : 'flex',
    },
  })
);

export const GroupContainer = styled.div({
  marginBottom: '36px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    maxWidth: '200px',
    marginBottom: theme.spacings.S,
    ':last-of-type': {
      marginBottom: theme.spacings.M,
    },
  },
});

export const FooterContainer = styled.div<IFooter>(({ isDealsOrLpPage }) => ({
  margin: '0 auto',
  maxWidth: '1024px',
  display: 'flex',
  borderTop: `1px solid ${theme.colors.neutral.N80}`,
  paddingTop: theme.spacings.L,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    paddingTop: isDealsOrLpPage ? '32px' : theme.spacings.L,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    display: 'flex',
    flexDirection: 'column-reverse',
    paddingTop: isDealsOrLpPage ? 0 : theme.spacings.M,
    borderTop: isDealsOrLpPage
      ? 'none'
      : `1px solid ${theme.colors.neutral.N80}`,
  },
}));

export const StyledNavItem = styled.div({
  width: '160px',
  'p, em, strong': {
    fontSize: theme.fonts.size.p_xsmall,
    textTransform: 'uppercase',
    color: theme.colors.gray.S500,
    fontWeight: 400,
    letterSpacing: '0.1em',
    marginBottom: theme.spacings.XS,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    width: 'unset',
  },
});

export const NavItemContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  paddingLeft: '80px',
  gap: '48px',
  margin: '60px 0',
  borderLeft: `1px solid ${theme.colors.neutral.N80}`,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: 0,
    padding: '0 0 30px 0',
    rowGap: theme.spacings.S,
    columnGap: '48px',
    maxHeight: '350px',
    borderLeft: 'none',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    flexWrap: 'nowrap',
    maxHeight: 'unset',
    maxWidth: 'unset',
  },
});

export const ActionContainer = styled.div<IFooter>(({ isDealsOrLpPage }) => ({
  paddingRight: '80px',
  borderRight: isDealsOrLpPage
    ? 'none'
    : `1px solid ${theme.colors.neutral.N80})`,
  height: isDealsOrLpPage ? 'auto' : '285px',
  paddingBottom: isDealsOrLpPage ? themev2.spacings.S : 0,
  [COMPACT_HEADER_MEDIA_QUERY]: {
    paddingBottom: 0,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    borderRight: 'none',
    height: 'auto',
  },
}));

export const List = styled.ul({
  padding: 0,
  listStyle: 'none',
  li: {
    paddingBottom: '12px',
    ':last-of-type': {
      paddingBottom: 0,
    },
  },
  a: {
    color: theme.colors.white.default,
    fontSize: theme.fonts.size.p_small,
    lineHeight: theme.fonts.lineHeight.p_small,
    fontWeight: 400,
    opacity: 0.8,
    [DOM_STATE.HOVER]: {
      opacity: 1,
    },
  },
});

export const SvgContainer = styled.span({
  paddingBottom: '2px',
  opacity: 0.8,
  [DOM_STATE.HOVER]: {
    opacity: 1,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    padding: 0,
  },
});

export const LogoContainer = styled.div({
  margin: '0 auto',
  marginBottom: '36px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: theme.spacings.S,
    paddingTop: theme.spacings.M,
    borderTop: `1px solid ${theme.colors.neutral.N80}`,
  },
});

export const ActionsWrapper = styled.div({
  [MEDIA_QUERIES.TABLET]: {
    marginLeft: 0,
  },
});
