import React from 'react';
import { OptionButton, StyledWrapper } from '../styledComponents';
import { IOptionProps } from '../types';

const Option: React.FC<IOptionProps> = ({ label, onClick, dataTracker }) => (
  <StyledWrapper>
    <OptionButton
      type="button"
      onClick={onClick}
      data-tracker={dataTracker + '-' + label}
    >
      {label}
    </OptionButton>
  </StyledWrapper>
);

export default Option;
