import { BottomBanner } from './styledComponents';

const FooterBottom = () => {
  const currentYear = new Date().getFullYear();
  return (
    <BottomBanner>
      © 2018-{currentYear} smartproxy.com, All Rights Reserved
    </BottomBanner>
  );
};

export default FooterBottom;
