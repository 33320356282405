import { track } from '@smartproxy/sp-tracker-js';
import GatsbyImg from 'components/GatsbyImg';
import { NewRichText } from 'components/PrismicRichText';
import { SIGNUP_URL } from 'constants/common';
import { usePageContext } from 'contexts/PageContext';
import React from 'react';
import { initBuildTrackingSelector } from 'utils/page';
import {
  IconList,
  IconListBlockContainer,
  IconListItem,
  IconWrapper,
  StyledLink,
  TextFieldContainer,
  Wrapper,
} from './styledComponents';
import { IIconListBlock } from './types';

const IconListBlock: React.FC<IIconListBlock> = ({
  primary: {
    is_text_centered: isTextCentered,
    list_title: listTitle,
    subtitle,
    column_number: columnNumber,
  },
  items,
}) => {
  const { tags, type } = usePageContext();

  const trackingSelector = initBuildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'Icon-list-block',
    prefix: type,
  });

  return (
    <Wrapper text={listTitle}>
      <IconListBlockContainer>
        <TextFieldContainer isCentered={isTextCentered}>
          <NewRichText text={listTitle} />
          <NewRichText text={subtitle} />
        </TextFieldContainer>
        <IconList columnNumber={columnNumber || 6}>
          {items.map(
            ({ icon, link_to: linkTo, text_field: textField }, idx) => (
              <IconListItem key={idx}>
                <StyledLink
                  to={linkTo.url || SIGNUP_URL}
                  target={linkTo.target}
                  onClick={() =>
                    track(trackingSelector({ context: textField.text }))
                  }
                >
                  <IconWrapper>
                    <GatsbyImg image={icon} />
                  </IconWrapper>
                  <NewRichText text={textField} />
                </StyledLink>
              </IconListItem>
            )
          )}
        </IconList>
      </IconListBlockContainer>
    </Wrapper>
  );
};

export default IconListBlock;
