import React from 'react';
import hideable from 'utils/hideable';
import { PricingCardsContainer } from '../styledComponents';
import { Features } from './Features';
import { PricingCard } from './PricingCard';
import { IPlans } from '../types';

const Plans: React.FC<IPlans> = ({ plans }) => (
  <PricingCardsContainer itemsLength={plans.length}>
    {plans
      .filter((plan) => !!plan.pricing_card.document)
      .map(
        (
          {
            pricing_card: {
              type,
              document: { data },
            },
          },
          index
        ) =>
          type === 'pricing_card' ? (
            <PricingCard index={index} data={data} key={data.gb_value} />
          ) : (
            <Features
              data={data}
              key={data.card_title}
              itemsLength={plans.length - 1}
            />
          )
      )}
  </PricingCardsContainer>
);

export default hideable(Plans);
