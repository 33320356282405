import { track } from '@smartproxy/sp-tracker-js';
import { NewRichText } from 'components/PrismicRichText';
import { usePageContext } from 'contexts/PageContext';
import { IconHandSwipe } from 'images';
import React from 'react';
import { buildTrackingSelector } from 'utils/page';
import { TableBlockColumn } from './Column';
import {
  HandIconWrapper,
  HideableSubtitle,
  HideableTextBelow,
  HideableTitle,
  StyledCta,
  StyledWrapper,
  TableContainer,
  TableWrapper,
} from './styledComponents';
import { INewTableBlock } from './types';
import { getTableHeader, groupTableData } from './utils';

const NewTableBlock: React.FC<INewTableBlock> = ({
  primary: {
    background,
    title,
    subtitle,
    text_below_table: textBelowTable,
    cta,
    cta_url: ctaUrl,
  },
  items,
}) => {
  const tableData = groupTableData(items);
  const tableHeader = getTableHeader(items);
  const { type, tags } = usePageContext();
  const trackingSelector = buildTrackingSelector({
    page: tags[1] ? `${tags[0]}-${tags[1]}` : tags[0] || '',
    section: 'new-table',
    prefix: type,
    context: cta.text,
  });

  return (
    <TableWrapper background={background}>
      <HideableTitle isVisible={title?.text}>
        <NewRichText text={title} />
      </HideableTitle>
      <HideableSubtitle isVisible={subtitle?.text}>
        <NewRichText text={subtitle} />
      </HideableSubtitle>
      <StyledWrapper>
        <TableContainer>
          {tableHeader.map((item, index) => (
            <TableBlockColumn
              header={item}
              data={tableData[item]}
              key={index}
              isDarkHeader={index === 1}
              isLast={tableHeader.length - 1 === index}
            />
          ))}
        </TableContainer>
      </StyledWrapper>
      <HandIconWrapper>
        <IconHandSwipe />
      </HandIconWrapper>
      <HideableTextBelow isVisible={textBelowTable?.text}>
        <NewRichText text={textBelowTable} />
      </HideableTextBelow>
      <StyledCta
        linkId={trackingSelector}
        linkClassName={trackingSelector}
        dataTracker={trackingSelector}
        sendTrackingEvent={() => track(trackingSelector)}
        ctaText={cta?.text}
        ctaLink={ctaUrl?.url}
        isVisible={ctaUrl?.url}
      />
    </TableWrapper>
  );
};

export default NewTableBlock;
