import { Wrapper } from 'commonStyles';
import { sliceMap, SliceType } from 'features';
import { graphql, PageProps } from 'gatsby';
import {
  withPrismicPreview,
  WithPrismicPreviewProps,
} from 'gatsby-plugin-prismic-previews';
import { getTemplates } from 'helpers/sliceHelper';
import { DefaultSlice } from 'types';
import { PageTemplateQuery } from 'types.generated';

interface IWithPrismicCareersPage {
  prismicCareersPage: {
    type: string;
    tags?: string[];
    data: {
      body: DefaultSlice[];
    };
  };
  allPrismicCareersJobPost: {
    nodes: {
      id: string;
      data: DefaultSlice[];
    };
  };
}

export interface Props {
  data: IWithPrismicCareersPage;
}
type PageTemplateProps = PageProps<PageTemplateQuery> &
  WithPrismicPreviewProps<PageTemplateQuery>;

const CareersPage = ({ data }: Props & PageTemplateProps) => {
  const { prismicCareersPage, allPrismicCareersJobPost } = data;

  const templates = getTemplates<SliceType, DefaultSlice>(
    prismicCareersPage.data.body,
    sliceMap
  );

  return (
    <Wrapper>
      {templates.map(({ Component, slice }, key) =>
        slice.slice_type === 'related_positions' ? (
          <Component
            key={key}
            {...slice}
            recentJobs={allPrismicCareersJobPost.nodes}
          />
        ) : (
          <Component key={key} {...slice} />
        )
      )}
    </Wrapper>
  );
};

export default withPrismicPreview(CareersPage);

export const query = graphql`
  query CareersPageQuery {
    prismicCareersPage {
      _previewable
      type
      tags
      data {
        meta_title
        meta_description
        robots
        code_snippet {
          text
        }
        canonical_url {
          url
        }
        og_description {
          text
        }
        og_image {
          url
        }
        og_page_url {
          url
        }
        og_secure_image {
          url
        }
        og_title {
          text
        }
        og_locale {
          text
        }
        og_type {
          text
        }
        og_site_name {
          text
        }
        twitter_site {
          text
        }
        twitter_image {
          url
        }
        twitter_card {
          text
        }
        twitter_title {
          text
        }
        twitter_description {
          text
        }
        twitter_creator {
          text
        }
        twitter_image_alt {
          text
        }
        body {
          ... on PrismicCareersPageDataBodyImageTabs {
            id
            items {
              selected_tab
              description {
                richText
                text
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              is_subtitle_centered
              background
              first_tab_label
              second_tab_label
              subtitle {
                richText
                text
              }
              title {
                richText
                text
              }
              cta_text: cta_label
              cta_link {
                url
              }
              cta_position
              is_expandable
            }
            slice_type
          }
          ... on PrismicCareersPageDataBodyImageAndTextBlock {
            id
            slice_type
            primary {
              background
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
              is_text_light
              title {
                text
                richText
              }
            }
            items {
              metric_description {
                richText
                text
              }
              metric_title {
                richText
                text
              }
            }
          }
          ... on PrismicCareersPageDataBodyCareerHero {
            id
            slice_type
            primary {
              accent_color
              button_color
              cta_link {
                url
              }
              cta_text
              description {
                richText
                text
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                richText
                text
              }
            }
            items {
              job_document {
                document {
                  ... on PrismicCareersJobPost {
                    id
                    data {
                      location {
                        richText
                        text
                      }
                      title {
                        richText
                        text
                      }
                      type
                    }
                    url
                  }
                }
              }
            }
          }
          ... on PrismicCareersPageDataBodyNewTextImageColumns {
            id
            items {
              description {
                richText
                text
              }
              icon {
                gatsbyImageData(placeholder: BLURRED)
              }
              small_title {
                richText
                text
              }
            }
            primary {
              background
              is_text_light
              main_title {
                richText
                text
              }
              number_of_columns
            }
            slice_type
          }
          ... on PrismicCareersPageDataBodyTestimonials {
            id
            items {
              description {
                richText
                text
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
              name {
                richText
                text
              }
              role {
                richText
                text
              }
            }
            primary {
              background
              is_text_light
              for_id_wrapper {
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersPageDataBodyRelatedPositions {
            slice_type
            primary {
              background
              related_positions_title {
                richText
                text
              }
              subtitle {
                richText
                text
              }
            }
          }
          ... on PrismicCareersPageDataBodyFooterBannerSlice {
            id
            slice_type
            primary {
              is_money_back_visible
              title {
                richText
                text
              }
              subtitle {
                text
                richText
              }
              cta_two_text {
                text
                richText
              }
              cta_two_link {
                url
              }
              cta_one_text {
                text
                richText
              }
              cta_one_link {
                url
              }
            }
          }
        }
      }
    }
    allPrismicCareersJobPost {
      nodes {
        id
        data {
          title {
            richText
            text
          }
          type
          location {
            richText
            text
          }
        }
        url
      }
    }
  }
`;
