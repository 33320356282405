import { useState } from 'react';

export enum HTTP_METHOD {
  POST = 'POST',
}

interface IRequestStatus {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialRequestState: IRequestStatus = {
  isLoading: false,
  isError: false,
  isSuccess: false,
};

const setSuccess = () => ({
  isLoading: false,
  isError: false,
  isSuccess: true,
});

const setError = () => ({
  isLoading: false,
  isError: true,
  isSuccess: false,
});

const setLoading = (prevState: IRequestStatus) => ({
  ...prevState,
  isLoading: true,
});

const setFinally = (prevState: IRequestStatus) => ({
  ...prevState,
  isLoading: false,
});

interface IUseApiRequestArgs<T> {
  body: T;
  onError?: (error?: unknown) => void;
  onFinally?: () => void;
  onSuccess?: (data?: unknown) => void;
}

export const useApi = (
  method: HTTP_METHOD,
  url: string,
  headers?: HeadersInit
) => {
  const [requestStatus, setRequestStatus] = useState(initialRequestState);
  const resetRequestStatus = () => setRequestStatus(initialRequestState);

  const request = async <T>({
    body,
    onError,
    onFinally,
    onSuccess,
  }: IUseApiRequestArgs<T>) => {
    const httpOptions: RequestInit = {
      body: body instanceof FormData ? body : JSON.stringify(body),
      method,
      headers,
    };

    try {
      setRequestStatus(setLoading);
      const res = await fetch(url, httpOptions);
      const data = await res.json();

      if (res.ok) {
        setRequestStatus(setSuccess);
        !!onSuccess && onSuccess(data);
      } else {
        setRequestStatus(setError);
        !!onError && onError(data);
      }

      return data;
    } catch (error) {
      setRequestStatus(setError);
      !!onError && onError(error);
    } finally {
      setRequestStatus(setFinally);
      !!onFinally && onFinally();
    }
  };

  return {
    ...requestStatus,
    resetRequestStatus,
    request,
  };
};
