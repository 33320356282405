import { track } from '@smartproxy/sp-tracker-js';
import { Link } from 'components/GatsbyLink';
import React from 'react';
import { initDataLayer } from '../../helpers';
import { CategoryItemLinkBlock, CategoryTitle } from '../styledComponents';
import { ICategoryLink } from './types';
import { Box } from 'components/v2/Box';
import { themev2 } from 'theme-v2';
import { IconMegaMenuArrow } from '@smartproxy-web/ui';

export const CategoryItemLink: React.FC<ICategoryLink> = ({
  title,
  link,
  features,
  handleClose,
  buildTrackingId,
}) => (
  <CategoryItemLinkBlock>
    {title && !link?.url && <CategoryTitle>{title}</CategoryTitle>}
    {title && !!link?.url && (
      <Link
        to={link?.url}
        data-tracker={buildTrackingId({
          context: title,
          section: 'header-dropdown',
          type: 'link',
        })}
        onClick={() => {
          track(
            buildTrackingId({
              context: title,
              section: 'header-dropdown',
              type: 'link',
            })
          );
          initDataLayer(
            buildTrackingId({
              context: title,
              section: 'header-dropdown',
              type: 'link',
            })
          );
        }}
      >
        <CategoryTitle>
          {title} <IconMegaMenuArrow />
        </CategoryTitle>
      </Link>
    )}
    <Box
      sx={{
        paddingLeft: themev2.spacings.XS,
        maxWidth: '212px',
        paddingTop: '8px',
      }}
    >
      {features?.map((item, index) => (
        <Link
          to={item.url.url}
          key={index}
          data-tracker={buildTrackingId({
            context: item.label,
            section: `header-dropdown-${title}`,
            type: 'link',
          })}
          onClick={() => {
            track(
              buildTrackingId({
                context: item.label,
                section: `header-dropdown-${title}`,
                type: 'link',
              })
            );
            initDataLayer(
              buildTrackingId({
                context: item.label,
                section: `header-dropdown-${title}`,
                type: 'link',
              })
            );
            handleClose();
          }}
        >
          {item.label}
        </Link>
      ))}
    </Box>
  </CategoryItemLinkBlock>
);
