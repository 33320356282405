import { HideableIconWrapper } from 'components/Input/styledComponents';
import { IconError } from 'images';
import React from 'react';
import {
  StyledErrorText,
  StyledInput,
  StyledInputContainer,
} from '../styledComponents';

export interface IInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string;
  dataTracker?: string;
}

const Input = ({ error, dataTracker, ...rest }: IInputProps) => (
  <StyledInputContainer>
    <StyledInput {...rest} hasError={!!error} data-tracker={dataTracker} />
    <HideableIconWrapper isVisible={error} title={error}>
      <IconError />
    </HideableIconWrapper>
    {!!error && <StyledErrorText>{error}</StyledErrorText>}
  </StyledInputContainer>
);

export default Input;
